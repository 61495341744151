import { Box, Link, Typography } from '@mui/material';
import Head from 'components/Head';
import Header from 'components/Header';
import TokenStorage from 'helpers/TokenStorage';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const ErrorPage: FC = () => {
  const token = TokenStorage.get()?.access;
  const { t } = useTranslation();

  return (
    <>
      <Head title="404" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {token && <Header />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: (theme) => theme.palette.common.white,
            overflow: 'hidden',
            width: 1,
            marginTop: (theme) => ({
              xs: `${theme.appBarHeight}px`,
              sm: `${theme.appBarHeight}px`,
              lg: 0,
            }),
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontWeight: 600, mb: 3, fontSize: 120, lineHeight: '120px' }}
          >
            404
          </Typography>
          <Typography sx={{ fontSize: 24, fontWeight: 400 }}>{t('NOTHING WAS FOUND')}</Typography>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Typography
              sx={{
                fontSize: 16,
                lineHeight: '28px',
                fontWeight: 400,
                display: 'inline',
                whiteSpace: 'pre-wrap',
              }}
            >
              {t(
                'The page you are looking for might have been removed, had its name \n changed or its temporarily unavailable.',
              )}
            </Typography>
            <br />
            <Link
              sx={{
                fontSize: 16,
                lineHeight: '28px',
                fontWeight: 400,
                display: 'inline',
                textDecoration: 'none',
                color: (theme) => theme.customColors.linkText,
              }}
              component={RouterLink}
              to={token ? '/' : '/login'}
            >
              {t('Return to Homepage')}
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ErrorPage;
