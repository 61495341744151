/* eslint-disable no-restricted-syntax */
import { AxiosError } from 'axios';
import { groupBy, isEmpty, pick, pickBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';

// helpers
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { fieldHasValue, getEndorsementGroupTypeFields } from 'helpers/Utils';

// services
import {
  getWorkFlowFormSetsFormsWithFormSetType,
  getWorkFlowPerilFormsWithFormCode,
} from 'api/services/NewQuote';
import {
  getEndorsementDetail,
  getEndorsementExposures,
  getEndorsementPriceChanges,
  referEndorsementRequest,
  sendBackEndorsementRequest,
  updateEndorsement,
} from 'api/services/PolicyEndorsement';
import { getRuleEngineDecisions, updateRuleEngineDecision } from 'api/services/RuleEngineDecisions';

/// types
import { Params } from 'api/helpers/Sender';
import { EndorsementPriceChange } from 'api/models/Policy/Endorsements/endorsementPriceChange.model';
import { groupByExposureAndSumPremiums } from 'helpers/PricingBreakdownHelpers';
import { IContextProps } from 'providers/types';

import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  policyDetailEndorsementStatuses,
  THREE_EndorsementTypes,
  userRoles,
  uwQuestionAliases,
  uwQuestionGroupName,
  wcAuditSubTypes,
} from 'common/constants';
import useUser from 'hooks/useUser/useUser';

/// data
import { IAxiosError } from 'types/ErrorResponseTypes';
import {
  EndorsementDetailContext,
  initialEndorsementDetailData,
} from './EndorsementDetailProviderContext';
import { IEndorsementExposuresProps, TGroups } from './types';

const eligibilityFieldCodes = ['pol_state', 'pol_tax_keyword'];

export const EndorsementDetailProvider = ({
  children,
}: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialEndorsementDetailData);

  const { data: userData } = useUser();

  const checkCanEdit = (
    endorsementState?: string,
    userRole?: string,
    isWc?: boolean,
    isEndorsementGenericType?: boolean,
  ) => {
    let canEdit = false;
    const isUW = userRole === userRoles.UNDERWRITER.code;

    if (policyDetailEndorsementStatuses.APPLICATION === endorsementState) {
      canEdit = isWc || isEndorsementGenericType ? isUW : true;
    } else if (isUW && policyDetailEndorsementStatuses.REFERRED === endorsementState) {
      canEdit = true;
    } else if (policyDetailEndorsementStatuses.DECLINED === endorsementState) {
      canEdit = isEndorsementGenericType ? isUW : true;
    }

    return canEdit;
  };

  useEffect(() => {
    if (userData?.role?.code) {
      setState((prevState) => ({
        ...prevState,
        canEdit: checkCanEdit(
          prevState?.data?.state?.key,
          userData?.role?.code,
          prevState.isWc!,
          prevState?.isEndorsementTypeGeneric,
        ),
      }));
    }
  }, [userData?.role?.code, JSON.stringify(state.data?.state), JSON.stringify(state.isWc)]);

  const fetch = async (
    policyId: string,
    endorsementId: string,
    triggerLoading = true,
  ): Promise<void> => {
    try {
      if (triggerLoading) {
        setState((prevState) => ({
          ...prevState,
          loading: true,
        }));
      }

      const res = await getEndorsementDetail(policyId, endorsementId);

      const groupFields = getEndorsementGroupTypeFields(res.policy?.characteristics?.data);

      const characteristic = res?.policy?.characteristics ?? {};

      const isWc = res?.endorsement_type?.code === THREE_EndorsementTypes.WC_AUDIT;
      const isEndorsementTypeGeneric =
        res?.endorsement_type?.code === THREE_EndorsementTypes.GENERIC;

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
        isWcFinal: characteristic?.data?.pol_wc_audit_type === wcAuditSubTypes.ACTUAL.code,
        isWc,
        isEndorsementTypeGeneric,
        canEdit: checkCanEdit(
          res?.state?.key,
          userData?.role?.code,
          isWc,
          isEndorsementTypeGeneric,
        ),
        groups: {
          ...prevState.groups,
          ...(Object.entries(prevState.groups ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupFields?.[`${key}`] ?? [],
              },
            }),
            {},
          ) as TGroups),
        },
        underwritingQuestionsState: {
          ...prevState.underwritingQuestionsState,
          ...{
            ...(characteristic?.data ?? {}),
            product: res?.policy?.product?.code,
            effective_date: fieldHasValue(characteristic.started_at)
              ? new Date(characteristic.started_at as string)
              : undefined,

            // map answered questions to state
            ...((characteristic?.data?.underwriting_question as any[])?.reduce(
              (c, q) => ({ ...c, [`${q.uwq_question_id}`]: q.uwq_question_answer }),
              {},
            ) ?? {}),
          },
        },
      }));
    } catch (error) {
      const e = error as AxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e.response?.status === 404) {
        displayBackendErrorMessage(e, 'Not found');
      }
      throw e;
    }
  };

  const referEndorsement = async (policyLocator: string, endorsementLocator: string) => {
    try {
      await referEndorsementRequest(policyLocator, endorsementLocator);
      await fetch(policyLocator, endorsementLocator, false);
    } catch (error) {
      const e = error as AxiosError;
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));

      throw e;
    }
  };

  const sendBackEndorsement = async (policyLocator: string, endorsementLocator: string) => {
    try {
      await sendBackEndorsementRequest(policyLocator, endorsementLocator);
      await fetch(policyLocator, endorsementLocator, false);
    } catch (error) {
      const e = error as AxiosError;
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));

      throw e;
    }
  };

  const getEndorsementPrice = async (
    policyLocator: string,
    endorsementLocator: string,
  ): Promise<void> => {
    try {
      setState((prevState) => ({
        ...prevState,
        price: { ...prevState.price, loading: true },
      }));

      const res = (await getEndorsementPriceChanges(
        policyLocator,
        endorsementLocator,
      )) as EndorsementPriceChange;

      const exposureGroups = groupByExposureAndSumPremiums(res.exposure_prices, 'Endorsement');

      setState((prevState) => ({
        ...prevState,
        price: {
          loading: false,
          loaded: true,
          data: { ...res, groups: exposureGroups },
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        price: { ...prevState.price, loading: false, loaded: true },
      }));

      throw error;
    }
  };

  const getExposures = async (
    policyId: string,
    endorsementId: string,
    exposureCode = '',
    fetchEndorsementDetail = false,
  ) => {
    const hasExposureCode = !isEmpty(exposureCode);

    try {
      setState((prevState) => ({
        ...prevState,
        exposures: { ...prevState.exposures, loading: true },
        exposureList: hasExposureCode
          ? {
              ...prevState.exposureList,
              [`${exposureCode}`]: {
                ...prevState.exposureList?.[`${exposureCode}`],
                loading: true,
              },
            }
          : {
              ...prevState.exposureList,
              ...Object.keys(prevState.exposureList ?? {}).reduce(
                (a, curr) => ({
                  ...a,
                  [`${curr}`]: {
                    ...prevState.exposureList?.[`${curr}`],
                    loading: true,
                  },
                }),
                {},
              ),
            },
      }));

      const endorsementExposures: IEndorsementExposuresProps[] = [];

      const res = await getEndorsementExposures(policyId, endorsementId);

      const groupedExposures = groupBy(res ?? [], (item) => item.name);

      for (const ph of res!) {
        endorsementExposures.push({
          ...ph,
          id: ph.locator!,
        });
      }

      setState((prevState) => ({
        ...prevState,
        exposures: {
          ...prevState.exposures,
          loading: false,
          loaded: true,
          data: endorsementExposures,
        },
        exposureList: {
          ...prevState.exposureList,
          ...Object.entries(prevState.exposureList ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupedExposures?.[`${key}`] ?? [],
              },
            }),
            {},
          ),
        },
      }));

      // Modified exposure data will trigger rule engine and rule engine will change quote data
      // So, quote detail data needs to be refetched
      if (fetchEndorsementDetail) {
        await fetch(policyId, endorsementId);
      }

      return endorsementExposures;
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        exposures: { ...prevState.exposures, loading: false, loaded: true },
        exposureList: hasExposureCode
          ? {
              ...prevState.exposureList,
              [`${exposureCode}`]: { loading: false, loaded: true },
            }
          : {
              ...prevState.exposureList,
              ...Object.keys(prevState.exposureList ?? {}).reduce(
                (a, curr) => ({
                  ...a,
                  [`${curr}`]: {
                    ...prevState.exposureList?.[`${curr}`],
                    loading: false,
                    loaded: true,
                  },
                }),
                {},
              ),
            },
      }));

      if (e.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data.message);
      }
      throw e;
    }
  };

  const getExposure = async (exposureId: string, exposureName: string) => {
    try {
      const isNewlyAdded = exposureId?.includes('added-');
      const index = isNewlyAdded ? exposureId?.slice(6) : undefined;

      const foundExposure = state.exposures?.data?.find((e) =>
        isNewlyAdded ? e.index === index : e.locator === exposureId,
      );

      setState((prevState) => ({
        ...prevState,
        exposureDetail: { ...foundExposure, loading: false, loaded: true },
      }));

      if (foundExposure === undefined || foundExposure.name !== exposureName) {
        throw new Error();
      }

      return foundExposure;
    } catch (error) {
      const e = error as IAxiosError;
      setState({ ...state, loading: false, loaded: true });
      if (e.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data.message);
      }
      throw e;
    }
  };

  const resetEndorsementDetailState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialEndorsementDetailData,
    }));
  };

  const hasBusinessNameChanged = (oldState: any, newState: any) => {
    const { policyholder } = oldState?.policy;
    const oldName = policyholder?.data?.business_name ?? '';

    const newName =
      newState?.underwriting_question?.find(
        (q) => q.uwq_question_id === uwQuestionAliases.businessName,
      )?.uwq_question_answer ?? '';

    return {
      changed: oldName !== newName,
      newName,
      policyholder,
    };
  };

  const updateEndorsementDetail = async (
    policyId: string,
    endorsementId: string,
    body: any,
    query?: Params,
    checkHasBusinessNameChanged = false,
  ) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await updateEndorsement(
        policyId as string,
        endorsementId as string,
        body,
        query,
        checkHasBusinessNameChanged ? hasBusinessNameChanged(state.data, body.data) : undefined,
      );

      const groupFields = getEndorsementGroupTypeFields(res.policy?.characteristics?.data);

      const characteristic = res?.policy?.characteristics ?? {};

      const isWc = res?.endorsement_type?.code === THREE_EndorsementTypes.WC_AUDIT;
      const isEndorsementTypeGeneric =
        res?.endorsement_type?.code === THREE_EndorsementTypes.GENERIC;

      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: res,
        isWcFinal: characteristic?.data?.pol_wc_audit_type === wcAuditSubTypes.ACTUAL.code,
        isWc,
        isEndorsementTypeGeneric,
        canEdit: checkCanEdit(
          res?.state?.key,
          userData?.role?.code,
          isWc,
          isEndorsementTypeGeneric,
        ),
        groups: {
          ...prevState.groups,
          ...Object.entries(prevState.groups ?? {}).reduce(
            (a, [key, value]) => ({
              ...a,
              [`${key}`]: {
                ...value,
                loading: false,
                loaded: true,
                data: groupFields?.[`${key}`] ?? [],
              },
            }),
            {},
          ),
        },
        underwritingQuestionsState: {
          ...prevState.underwritingQuestionsState,
          ...{
            ...(characteristic?.data ?? {}),
            product: res?.policy?.product?.code,
            effective_date: fieldHasValue(characteristic.started_at)
              ? new Date(characteristic.started_at as string)
              : undefined,

            // map answered questions to state
            ...((characteristic?.data?.underwriting_question as any[])?.reduce(
              (c, q) => ({ ...c, [`${q.uwq_question_id}`]: q.uwq_question_answer }),
              {},
            ) ?? {}),
          },
        },
      }));
      return res;
    } catch (error) {
      const e = error as IAxiosError;
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));

      if (e.response?.status === 404) {
        displayToastMessage('ERROR', e.response.data.message);
      }
      throw e;
    }
  };

  const getFieldConfig = async (
    productCode: string,
    formset: string,
    formCode?: string,
    query?: Params,
  ) => {
    try {
      if (isEmpty(state.fields?.[`${formset}`]?.data)) {
        setState((prevState) => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [`${formset}`]: {
              ...(prevState.fields![`${formset}`] ?? {}),
              loading: true,
            },
          },
        }));

        if (formCode) {
          const forms = await getWorkFlowPerilFormsWithFormCode({
            product_code: productCode,
            work_flow_name: 'quick-quote',
            config_formset_type: formset,
            form_code: formCode,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: {
                  ...prevState.fields[`${formset}`]?.data,
                  [`${formCode}`]: forms,
                },
              },
            },
          }));
        } else {
          const forms = await getWorkFlowFormSetsFormsWithFormSetType({
            product_code: productCode,
            work_flow_name: 'quick-quote',
            config_formset_type: formset,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: forms,
              },
            },
          }));
        }
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [`${formset}`]: { loading: false, loaded: true },
        },
      }));

      throw error;
    }
  };

  const getRuleEngineResults = async (locator: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: true },
      }));

      const res = await getRuleEngineDecisions(
        {
          policy_transaction_type: 'endorsement',
          socotra_locator: locator,
        },
        userData?.role?.code,
      );

      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: {
          loading: false,
          loaded: true,
          data: res,
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: false, loaded: true },
      }));
      throw error;
    }
  };

  const updateRuleEngineDecisionStatus = async (
    locator: number,
    isUnderwriterApproved: boolean,
    withLoading = true,
  ) => {
    try {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: withLoading },
      }));

      const res = await updateRuleEngineDecision(locator, {
        is_underwriter_approved: isUnderwriterApproved,
      });

      setState((prevState) => {
        const updatedRuleStatus =
          prevState.ruleEngineResults.data?.map((rule) => (rule.id === res.id ? res : rule)) ?? [];

        return {
          ...prevState,
          ruleEngineResults: {
            loading: false,
            loaded: true,
            data: updatedRuleStatus,
          },
        };
      });
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        ruleEngineResults: { ...prevState.ruleEngineResults, loading: false, loaded: true },
      }));

      throw error;
    }
  };

  const getUnderwritingQuestions = async (
    productCode: string,
    formset: string,
    formCode?: string,
    query?: Params,
  ) => {
    try {
      if (isEmpty(state.underwritingQuestions?.[`${formset}`]?.data)) {
        setState((prevState) => ({
          ...prevState,
          underwritingQuestions: {
            ...prevState.underwritingQuestions,
            [`${formset}`]: {
              ...(prevState.underwritingQuestions![`${formset}`] ?? {}),
              loading: true,
            },
          },
        }));

        if (formCode) {
          const forms = await getWorkFlowPerilFormsWithFormCode({
            product_code: productCode,
            work_flow_name: 'underwriting-questions',
            config_formset_type: formset,
            form_code: formCode,
            cache: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            underwritingQuestions: {
              ...prevState.underwritingQuestions,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: {
                  ...prevState.underwritingQuestions[`${formset}`].data,
                  [`${formCode}`]: forms,
                },
              },
            },
          }));
        } else {
          const forms = await getWorkFlowFormSetsFormsWithFormSetType({
            product_code: productCode,
            work_flow_name: 'underwriting-questions',
            config_formset_type: formset,
            cache: true,
            modifyResultForUnderwritingQuestions: true,
            query,
          });

          setState((prevState) => ({
            ...prevState,
            underwritingQuestions: {
              ...prevState.underwritingQuestions,
              [`${formset}`]: {
                loading: false,
                loaded: true,
                data: forms,
              },
            },
          }));
        }
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        underwritingQuestions: {
          ...prevState.underwritingQuestions,
          [`${formset}`]: { loading: false, loaded: true },
        },
      }));

      throw error;
    }
  };

  const setUnderwritingQuestionsState = (newState: any): void => {
    setState((prevState) => ({
      ...prevState,
      underwritingQuestionsState: {
        // ...prevState.underwritingQuestionsState,
        ...newState,
      },
    }));
  };

  const prepareUnderwritingQuestionsForSave = (keyword?: string, overrideData?: any) => {
    const { data } = state;
    let underwritingQuestionsStateValue;
    if (isEmpty(overrideData)) {
      const { underwritingQuestionsState } = state;
      underwritingQuestionsStateValue = underwritingQuestionsState;
    } else if (overrideData) {
      underwritingQuestionsStateValue = overrideData;
    }

    const eligibilityValues = pick(underwritingQuestionsStateValue, eligibilityFieldCodes);
    const questionFields: string[] = [];

    (state.underwritingQuestions?.policy?.data as ProductWorkFlow[])?.forEach((page) =>
      page?.fields?.forEach((f) =>
        (f.nested_fields ?? []).forEach((q) => questionFields.push(q.code!)),
      ),
    );

    // Dictionary for quick access to locator using uwq_question_id
    const questionLocatorDict = Object.fromEntries(
      underwritingQuestionsStateValue?.underwriting_question.map((question) => [
        question.uwq_question_id,
        question.locator,
      ]),
    );

    // TODO: Only get the visible fields, check show conditions.
    const questionValues = pickBy(
      underwritingQuestionsStateValue,
      (value, key) => questionFields.includes(key) && fieldHasValue(value),
    );

    return {
      data: {
        ...(data?.policy?.characteristics?.data ?? {}),
        ...eligibilityValues,
        underwriting_question: Object.entries(questionValues).map(([key, value]) => ({
          uwq_question_id: key,
          uwq_question_answer: value,
          // Retrieve the locator efficiently from the dictionary in constant time (O(1)) using uwq_question_id as the key
          ...(questionLocatorDict[key] ? { locator: questionLocatorDict[key] } : {}),
        })),
        ...(fieldHasValue(keyword) ? { pol_tax_keyword: keyword } : {}),
      },
      endorsement_start_at: data?.endorsement_start_at,
    };
  };

  const saveUnderwritingQuestionsState = async (keyword?: string, overrideData = {}) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = state;

      const preparedUnderwritingQuestionsData = prepareUnderwritingQuestionsForSave(
        keyword,
        overrideData,
      );

      const res = await updateEndorsementDetail(
        data?.policy?.locator!,
        data?.locator!,
        preparedUnderwritingQuestionsData,
        { validate: [uwQuestionGroupName, ...eligibilityFieldCodes].join(',') },
        true,
      );

      return res;
    } catch (error) {
      throw error;
    }
  };

  return (
    <EndorsementDetailContext.Provider
      value={{
        ...state,
        fetch,
        getExposures,
        getExposure,
        getFieldConfig,
        resetEndorsementDetailState,
        updateEndorsementDetail,
        getEndorsementPrice,
        getUnderwritingQuestions,
        getRuleEngineResults,
        updateRuleEngineDecisionStatus,
        setUnderwritingQuestionsState,
        saveUnderwritingQuestionsState,
        referEndorsement,
        sendBackEndorsement,
      }}
    >
      {children}
    </EndorsementDetailContext.Provider>
  );
};
