/* eslint-disable consistent-return */
import { Box, Button, Link, Skeleton, Stack, Typography } from '@mui/material';
import convertFileSizeToBytes from 'api/helpers/convertFileSizeToBytes';
import { QuoteDocumentsRetrieve } from 'api/models/Quote/QuoteDocumentsRetrieve.model';
import { quotesDocumentsWithLocator } from 'api/services/Quote';
import Download from 'assets/images/icon_dropdowndownload.svg';
import { defaultDateFormat, defaultDateTimeFormat } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { drawerFooterSecondaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface DocumentDetailProps {
  isDrawerOpen: boolean;
  quoteId: string;
}

const DocumentDetailDrawer: FC<DocumentDetailProps> = ({ isDrawerOpen, quoteId }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { document } = qs.parse(LOCATION.search);
  const activeDocument = {
    data: {} as QuoteDocumentsRetrieve,
    loading: true,
    loaded: false,
  };
  const { formatDateInTimeZone } = useConfig();
  const [state, setState] = useState(activeDocument);
  const [splitDate, setSplitDate] = useState<string[]>([]);
  const [inPageLoading, setInPageLoading] = useState(false);

  const documentDetailLoading = inPageLoading || state?.loading;

  useEffect(() => {
    setInPageLoading(true);

    if (state?.loaded && !isEmpty(state?.data)) {
      setSplitDate(
        formatDateInTimeZone(
          state?.data?.created_at!,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' '),
      );
    }
    setInPageLoading(false);
  }, [state?.loaded]);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['document'],
      }),
    });
  };

  const quotesDocumentDetailsShow = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
      }));

      const res: QuoteDocumentsRetrieve = await quotesDocumentsWithLocator(
        quoteId,
        document as string,
      );

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));

      handleQuery();
      displayBackendErrorMessage(error);
    }
  };

  useEffect(() => {
    quotesDocumentDetailsShow();
  }, []);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      onClose={() => {
        handleQuery();
      }}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 16,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Document Details')}
        </Typography>
      }
      content={
        <Stack gap={3}>
          <Box sx={{ mt: 1.5 }}>
            <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
              {t('Document Title')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!documentDetailLoading ? (
                <>{state?.data?.title ?? '-'}</>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
              {t('Document Type')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!documentDetailLoading ? (
                state?.data?.doc_type?.value?.length! > 0 ? (
                  state?.data?.doc_type?.value
                ) : (
                  '-'
                )
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
              {t('Uploaded at')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!documentDetailLoading ? (
                <>{`${splitDate[0]} ${splitDate[1]} ${splitDate[2]}` ?? '-'}</>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
              {t('Uploaded by')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!documentDetailLoading ? (
                <>{state?.data?.uploaded_by ?? '-'}</>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
              {t('File Name')}
            </Typography>
            <Box
              sx={{
                mt: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer',
              }}
              component={Link}
              target="_blank"
              href={`${state?.data?.url!}`}
            >
              {!documentDetailLoading ? (
                <>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      '&.MuiTypography-root': {
                        wordBreak: 'break-all',
                      },
                      color: (theme) => theme.customColors.linkText,
                      fontSize: '14px',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    <>{state?.data?.file_name ?? '-'}</>
                  </Typography>
                  <Box component="img" src={Download} />
                </>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Box>
            {/* <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 400,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!documentDetailLoading ? (
                state?.data?.file_name
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography> */}
          </Box>
          <Box>
            <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
              {t('File Type')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 500,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!documentDetailLoading ? (
                <>{state?.data?.file_type ?? '-'}</>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>

          {state?.data?.doc_type?.key !== 'socotra' && (
            <Box>
              <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
                {t('File Size')}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  boxShadow: (theme) => theme.customShadows.shadow7,
                  fontWeight: 500,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {!documentDetailLoading ? (
                  state?.data?.file_size && convertFileSizeToBytes(state?.data?.file_size)
                ) : (
                  <Skeleton animation="wave" width="60%" height={20} />
                )}
              </Typography>
            </Box>
          )}
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle, { mr: 0 }]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default DocumentDetailDrawer;
