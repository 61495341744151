import { quoteStatuses } from 'common/constants';
import Head from 'components/Head';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useQuoteOrRenewal from 'hooks/useQuoteOrRenewal/useQuoteOrRenewal';
import { t } from 'i18next';
import QuotePolicyEndorsementDefault from 'layouts/quotePolicyEndorsementDefault';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const QuoteOrRenewalDetailLayout: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const HISTORY = useHistory();
  const { isRenewal } = useQuoteOrRenewal();

  const { fetch, resetQuoteDetailState, data: quoteDetail } = useQuoteDetail();

  const getPageDetail = async (pId: string) => {
    try {
      await fetch(pId, { exclude_price: true, ...(isRenewal ? { renewals: true } : {}) });
    } catch (_error) {
      if (isRenewal) HISTORY.replace('/renewals');
      else HISTORY.replace('/quotes');
    }
  };

  useEffect(() => {
    getPageDetail(id);

    return () => {
      resetQuoteDetailState();
    };
  }, [id]);

  useEffect(() => {
    // Billing tab should only be visible to finalized(accepted) status submissions.
    // Restrict and redirect to submission details on other submission statuses.
    if (
      quoteDetail?.state?.code &&
      quoteDetail?.state?.code !== quoteStatuses.ACCEPTED &&
      HISTORY.location.pathname.endsWith('/billings')
    ) {
      HISTORY.replace({ pathname: 'details' });
    }
  }, [quoteDetail?.state?.code]);

  return (
    <QuotePolicyEndorsementDefault>
      <Head
        title={isRenewal ? t('Renewal - {{ID}}', { ID: id }) : t('Quote - {{ID}}', { ID: id })}
      />
      {children}
    </QuotePolicyEndorsementDefault>
  );
};

export default QuoteOrRenewalDetailLayout;
