import React, { useEffect, useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

import useAuth from 'hooks/useAuth';

import { User } from 'api/models/User/user.model';
import { UserUpdate } from 'api/models/User/userUpdate.model';
import { getCurrentUserInformation, patchCurrentUser } from 'api/services/UserService';

import * as FullStory from '@fullstory/browser';

/// data
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { initialUserData, UserContext } from './UserProviderContext';

export const UserProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialUserData);
  const { auth } = useAuth();

  const identifyFullStory = (user: User) => {
    if (FullStory.isInitialized()) {
      try {
        FullStory.identify(user.uuid!, {
          displayName: user.full_name,
          email: user.email,
        });
      } catch (error) {
        console.log('FullStory error: ', error);
      }
    }
  };

  const fetch = async (): Promise<User> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await getCurrentUserInformation();

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
      }));

      identifyFullStory(res);

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
      }));
      throw error;
    }
  };

  const updateCurrentUser = async (body: UserUpdate): Promise<User> => {
    try {
      setState((prevState) => ({
        ...prevState,
      }));

      const res = await patchCurrentUser(body);

      setState((prevState) => ({
        ...prevState,
        data: res,
      }));

      return res;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
      }));
      throw error;
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (auth) {
          await fetch();
        }
      } catch (error) {
        displayBackendErrorMessage(error);
      }
    };

    fetchUser();
  }, [auth]);

  const resetUserState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialUserData,
    }));
  };

  return (
    <UserContext.Provider value={{ ...state, fetch, resetUserState, updateCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};
