import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { threeExposureNames, threePolicyGroupNames, userRoles } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { WcOwnersMembersDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/WcOwnersMembersDrawerFieldParser';
import { useFormik } from 'formik';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { changeFieldsHiddenStatus, deleteFromQueryStrings, parseLocation } from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PolicyLocationSelect from '../../../Inputs/LocationSelectInput';
import WcClassCodeAutocomplete from '../../../Inputs/WcClassCodeAutocomplete';

export interface WcOwnersMembersDrawerDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  position: string;
}

const WcOwnersMembersDrawerDetailDrawer: FC<WcOwnersMembersDrawerDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  position,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const OWNER_MEMBER = url.owner_member;
  const { loading: policyLoading, exposureList, groups, fields: fieldConfig } = usePolicyDetail();
  const { data: user } = useUser();

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const owners: any[] = groups?.pol_oo?.data ?? [];

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  const owner = useMemo(
    () => owners.find((ow) => ow.locator === OWNER_MEMBER) ?? {},
    [owners, OWNER_MEMBER],
  );

  const [state, setState] = useState<any>({});

  const [fields, setFields] = useState<any[]>([]);

  useEffect(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])?.[0]?.fields?.find(
        (f) => f.code === `${threePolicyGroupNames.OWNER}_wc`,
      )?.nested_fields ?? [];

    setFields(tmpFields);
  }, [fieldConfig]);

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['owner_member'],
      }),
    });
  };

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {},
  });

  useEffect(() => {
    if (isUnderwriter) {
      const integrationFields: string[] = [];

      if (owner.pol_oo_isprimary === 'Yes') {
        integrationFields.push('pol_oo_b505');
        integrationFields.push('pol_oo_ncf_score');

        setFields((prevFields) => changeFieldsHiddenStatus(prevFields, integrationFields, false));
      }
    }

    const parsedLoacation = parseLocation(owner.pol_oo_location);

    const foundExposure = employerExposures.find(
      (e) => e?.data?.emp_state === parsedLoacation.state,
    );

    setState((prevState) => ({
      ...prevState,
      ...owner,
      emp_filing_set_id: foundExposure?.data?.emp_filing_set_id ?? -1,
    }));

    formik.setValues(owner);
  }, [owner, isUnderwriter, employerExposures]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      policyLoading ||
      fieldConfig?.policy?.loading ||
      exposureList?.[`${threeExposureNames.LOCATION}`].loading;

    return defaultLoading || isEmpty(owner);
  }, [owner, policyLoading, exposureList, fieldConfig]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('{{entityPosition}} Details', {
            entityPosition: position,
          })}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <WcOwnersMembersDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={showLoader}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
            LocationSelect={PolicyLocationSelect}
            WcClassCodeAutocomplete={WcClassCodeAutocomplete}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default WcOwnersMembersDrawerDetailDrawer;
