import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { threePolicyGroupNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { ClaimsHistoryDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/ClaimsHistoryDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useLoader from 'hooks/useLoader';
import { isEmpty, omit } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

export interface ClaimsHistoryEditProps {
  isDrawerOpen: boolean;
  isAdd?: boolean;
}

const ClaimsHistoryEditDrawer: FC<ClaimsHistoryEditProps> = ({ isDrawerOpen, isAdd = false }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const CLAIM = url.claim;
  const {
    fields: fieldConfig,
    fields: {
      policy: { loaded: fieldConfigLoaded },
    },
    data: endorsementDetail,
    loaded: endorsementLoaded,
    groups,
    updateEndorsementDetail,
  } = useEndorsementDetail();
  const { loading, setLoading } = useLoader();
  const { setDialogOpen } = useDialog();
  const [state, setState] = useState({
    pol_claims_date: '',
    pol_claims_type: '',
    pol_claims_amount: '',
  });

  const claims: any[] = groups?.pol_claims?.data ?? [];

  const isGroupNewlyAdded = useMemo(() => {
    const isNewlyAdded = CLAIM?.includes('added-');
    const index = isNewlyAdded ? Number(CLAIM?.slice(6)) : undefined;

    return { isNewlyAdded, index };
  }, [CLAIM]);

  const claim = useMemo(() => {
    const { isNewlyAdded, index } = isGroupNewlyAdded;
    return claims.find((ow) => (isNewlyAdded ? ow.index === index : ow.locator === CLAIM)) ?? {};
  }, [claims, CLAIM]);

  const showLoader = isAdd
    ? !endorsementLoaded || !fieldConfigLoaded
    : !endorsementLoaded || !fieldConfigLoaded || isEmpty(state);

  const fields = useMemo(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])
        ?.find((con) => con.code === 'policy-details')
        ?.fields?.find((x) => x.code === threePolicyGroupNames.CLAIMS)?.nested_fields ?? [];

    return tmpFields as DynamicField[];
  }, [fieldConfig]);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['claim', 'addClaim'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (!loading && endorsementLoaded && isEmpty(claim) && !isAdd) {
      displayToastMessage('ERROR', t('Claim not found.'));
      handleQuery();
    }
  }, [claim, endorsementLoaded]);

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: { ...state },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (isEmpty(state) || !isEmpty(CLAIM)) {
      setState((prevState) => ({ ...prevState, ...claim }));
      formik.setValues(claim);
    }
  }, [claim]);

  const handleAdd = async () => {
    try {
      setLoading(true);

      const tmpClaims = [...claims];

      tmpClaims.push(state);

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.CLAIMS}`]: tmpClaims?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        { validate: threePolicyGroupNames.CLAIMS, strategy: 'create' },
      );

      displayToastMessage('SUCCESS', t('The claim has been added.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the claim.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const tmpClaims = [...claims];
      const rowIndex = tmpClaims.findIndex((row) => row.locator === claim.locator);
      tmpClaims[rowIndex] = state;

      const query = claim?.isNewAddedOnEndorsement
        ? { validate: threePolicyGroupNames.CLAIMS, strategy: 'create' }
        : { validate: threePolicyGroupNames.CLAIMS, strategy: 'update', locator: claim.locator };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.CLAIMS}`]: tmpClaims?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        query,
      );

      displayToastMessage('SUCCESS', t('The claim has been updated.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the claim.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    // TODO: fix formik.dirty to work properly
    // if (!formik.dirty) {
    //   return handleQuery();
    // }

    if (isEmpty(errors)) {
      if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    }
    return {};
  };

  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: 'DELETE_CLAIM',
        isOpen: false,
      });
      setLoading(true);

      const tmpClaims = [...claims];
      const rowIndex = tmpClaims.findIndex((row) => row.locator === claim.locator);
      tmpClaims.splice(rowIndex, 1);

      const query = claim?.isNewAddedOnEndorsement
        ? { validate: threePolicyGroupNames.CLAIMS }
        : {
            validate: threePolicyGroupNames.CLAIMS,
            strategy: 'delete',
            locator: claim.locator,
          };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.CLAIMS}`]: tmpClaims?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        query,
      );

      displayToastMessage('SUCCESS', t('The claim has been deleted.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the claim.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        width="476px"
        onClose={handleQuery}
        headerSx={{
          mb: '14px',
          mt: 5,
        }}
        isContentScrollable
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Claim Details')}
          </Typography>
        }
        content={
          <Stack gap={2} sx={{ mb: 6, pt: 1 }}>
            <ClaimsHistoryDrawerFieldParser
              formik={formik}
              state={state}
              fields={fields}
              showLoader={showLoader}
              setState={setState}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
            />
          </Stack>
        }
        footer={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {!isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="20%" height={32} />
              ) : (
                <Button
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'DELETE_CLAIM',
                      isOpen: true,
                      onAccept: () => handleDelete(),
                    })
                  }
                  sx={[drawerFooterSecondaryButtonStyle]}
                >
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}

            <Stack direction="row">
              {showLoader ? (
                <>
                  <Skeleton animation="wave" width="60px" height={32} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default ClaimsHistoryEditDrawer;
