import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { threePolicyGroupNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { DriverDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/DriverDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import {
  addRequiredValidationToDynamicFields,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useLoader from 'hooks/useLoader';
import { isEmpty, omit } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

export interface DriverDetailProps {
  isDrawerOpen: boolean;
  isAdd?: boolean;
}

const DriverEditDrawer: FC<DriverDetailProps> = ({ isDrawerOpen, isAdd = false }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const DRIVER = url.driver;

  const {
    fields: fieldConfig,
    fields: {
      policy: { loaded: fieldConfigLoaded },
    },
    data: endorsementDetail,
    loaded: endorsementDetailLoaded,
    groups,
    updateEndorsementDetail,
  } = useEndorsementDetail();
  const [state, setState] = useState<any>({});

  const incidents = groups?.pol_incidents?.data ?? [];

  const drivers = groups?.pol_drivers?.data ?? [];

  const driver = useMemo(
    () => drivers.find((dr) => dr.pol_drivers_id === DRIVER) ?? {},
    [drivers, DRIVER],
  );

  const { loading, setLoading } = useLoader();
  const { setDialogOpen } = useDialog();

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['driver', 'addDriver'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (!loading && endorsementDetailLoaded && isEmpty(driver) && !isAdd) {
      displayToastMessage('ERROR', t('Driver not found.'));
      handleQuery();
    }
  }, [driver, endorsementDetailLoaded]);

  const fields = useMemo(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])
        ?.find((con) => con.code === 'policy-details')
        ?.fields?.find((x) => x.code === threePolicyGroupNames.DRIVERS)?.nested_fields ?? [];

    return tmpFields as DynamicField[];
  }, [fieldConfig]);

  const initialValues = useMemo(
    () => (isAdd ? fields.reduce((res, f) => ({ ...res, [`${f.code}`]: '' }), {}) : driver),
    [fields, driver, isAdd],
  );

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!isAdd) {
      if (isEmpty(state)) {
        setState((prevState) => ({ ...prevState, ...driver }));
      }
    }
  }, [driver]);

  const handleAdd = async () => {
    try {
      setLoading(true);

      const tmpDrivers = [...drivers];

      tmpDrivers.push({ ...formik.values });

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.DRIVERS}`]: tmpDrivers?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        { validate: threePolicyGroupNames.DRIVERS, strategy: 'create' },
      );

      displayToastMessage('SUCCESS', t('The driver has been added.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the driver.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      // Delete driver related incidents
      const relatedIncidents = incidents.filter((row) => {
        // pol_incidents_driver contains semicolon seperated uuid and incidentNumber (uuid + ; + incidentNumberSequence)
        const driverId = row.pol_incidents_driver?.split(';')?.[0];
        return driverId === driver.pol_drivers_id;
      });
      const tmpIncidents = [...incidents];

      // eslint-disable-next-line no-restricted-syntax
      for await (const incident of relatedIncidents) {
        const rowIndex = tmpIncidents.findIndex((row) => row.locator === incident.locator);
        tmpIncidents.splice(rowIndex, 1);

        const query = incident?.isNewAddedOnEndorsement
          ? { validate: threePolicyGroupNames.INCIDENTS }
          : {
              validate: threePolicyGroupNames.INCIDENTS,
              strategy: 'delete',
              locator: incident.locator,
            };

        await updateEndorsementDetail(
          endorsementDetail?.policy?.locator as string,
          endorsementDetail?.locator as string,
          {
            data: {
              [`${threePolicyGroupNames.INCIDENTS}`]: tmpIncidents?.map((r) =>
                r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
              ),
            },
          },
          query,
        );
      }

      // Delete driver
      const tmpDrivers = [...drivers];
      const rowIndex = tmpDrivers.findIndex((row) => row.pol_drivers_id === driver.pol_drivers_id);
      tmpDrivers.splice(rowIndex, 1);

      const query = driver?.isNewAddedOnEndorsement
        ? { validate: threePolicyGroupNames.DRIVERS, strategy: 'create' }
        : { validate: threePolicyGroupNames.DRIVERS, strategy: 'update', locator: driver.locator };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.DRIVERS}`]: tmpDrivers?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        query,
      );

      if (relatedIncidents?.length > 0) {
        displayToastMessage(
          'SUCCESS',
          t('The driver and driving incidents related to this driver have been deleted.'),
        );
      } else {
        displayToastMessage('SUCCESS', t('The driver has been deleted.'));
      }
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the driver.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const tmpDrivers = [...drivers];
      const rowIndex = tmpDrivers.findIndex((row) => row.pol_drivers_id === driver.pol_drivers_id);
      tmpDrivers[rowIndex] = formik.values;

      const query = driver?.isNewAddedOnEndorsement
        ? { validate: threePolicyGroupNames.DRIVERS, strategy: 'create' }
        : { validate: threePolicyGroupNames.DRIVERS, strategy: 'update', locator: driver.locator };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.DRIVERS}`]: tmpDrivers?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        query,
      );

      displayToastMessage('SUCCESS', t('The driver has been updated.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the driver.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (!formik.dirty && !isAdd) {
      return handleQuery();
    }

    if (isEmpty(errors)) {
      if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    }
    return {};
  };

  const showLoader = isAdd
    ? !endorsementDetailLoaded || !fieldConfigLoaded
    : !endorsementDetailLoaded || !fieldConfigLoaded || isEmpty(state);

  return (
    <DrawerComponent
      isContentScrollable
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={handleQuery}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Driver Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <DriverDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            showLoader={showLoader}
            setState={setState}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
          />
        </Stack>
      }
      footer={
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          {!isAdd ? (
            showLoader ? (
              <Skeleton animation="wave" width="20%" height={32} />
            ) : (
              <Button
                onClick={() =>
                  setDialogOpen({
                    dialog: 'DELETE_DRIVER',
                    isOpen: true,
                    onAccept: () => handleDelete(),
                  })
                }
                sx={[drawerFooterSecondaryButtonStyle]}
              >
                {t('Delete')}
              </Button>
            )
          ) : (
            <Box />
          )}
          <Stack direction="row" gap={1.5}>
            {showLoader ? (
              <>
                <Skeleton animation="wave" width="60px" height={32} />
                <Skeleton animation="wave" width="60px" height={32} />
              </>
            ) : (
              <>
                <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                  {t('Cancel')}
                </Button>
                <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                  {t('Save')}
                </Button>
              </>
            )}
          </Stack>
        </Box>
      }
    />
  );
};

export default DriverEditDrawer;
