import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@tanstack/react-query';
import { stateList } from 'common/constants';
import LocationShow from 'components/LocationShow';
import {
  detailPageGridContainerStyles,
  detailPageGridItemStyles,
  detailPageRowKeyStyles,
  detailPageRowValueStyles,
  detailPageStackStyles,
} from 'helpers/MuiSharedStyles';
import { formatLocation, parseLocation } from 'helpers/Utils';
import { useParams } from 'react-router-dom';
import { getAgentWithId } from '../../api/services/Agents';
import Head from '../../components/Head';
import PageHeader from '../../components/PageHeader';

const AgentDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: agent, isLoading } = useQuery({
    queryKey: ['agency', id],
    queryFn: () => getAgentWithId(id),
  });
  const { t } = useTranslation();

  const tableRows = [
    { id: 1, key: 'agency_name', displayName: t('Team'), value: '', type: 'string' },
    { id: 3, key: 'first_name', displayName: t('First Name'), value: '' },
    { id: 4, key: 'last_name', displayName: t('Last Name'), value: '' },
    { id: 5, key: 'phone_number', displayName: t('Phone Number'), value: '' },
    { id: 6, key: 'mobile_number', displayName: t('Mobile Number'), value: '' },
    { id: 7, key: 'email', displayName: t('Email'), value: '' },
    {
      id: 2,
      key: 'national_producer_number',
      displayName: t('National Producer Number'),
      value: '',
    },
    {
      id: 8,
      key: 'preferred_communication',
      displayName: t('Preferred communication'),
      value: '',
      type: 'string',
    },
    { id: 9, key: 'address', displayName: t('Address'), value: '', type: 'address' },
  ];

  const [rows, setRows] = useState(tableRows);
  useEffect(() => {
    if (agent) {
      const newRows = rows.map((e) => {
        let value = agent![`${e.key}`];

        if (value && e.type === 'string') {
          value = agent![`${e.key}`].name || agent![`${e.key}`].value || value;
        }

        if (value && e.type === 'boolean') {
          value = value ? 'Yes' : 'No';
        }

        if (e.type === 'address') {
          const { address_line_1, address_line_2, city, zip_code } = agent;
          let { state } = agent as any;
          state = stateList.find((s) => s.code === state)?.name;
          value = formatLocation({
            addressLine1: address_line_1!,
            addressLine2: address_line_2,
            city,
            state,
            zip: zip_code,
          }).storing;
        }

        return { ...e, value };
      });
      setRows(newRows);
    }
  }, [agent]);

  const renderRow = (row) => (
    <Grid key={row.id} item xs={1} md={1} sx={[detailPageGridItemStyles]}>
      <Stack sx={detailPageStackStyles} flexDirection="row">
        <Typography
          sx={[
            detailPageRowKeyStyles,
            {
              color: (theme) => theme.customColors.grey800,
              minWidth: '142px',
              maxWidth: '142px',
            },
          ]}
        >
          {isLoading && row.displayName === '' ? (
            <Skeleton animation="wave" width="80%" />
          ) : (
            row.displayName
          )}
        </Typography>
        <Typography
          sx={[
            detailPageRowValueStyles,
            {
              minWidth: '144px',
            },
          ]}
        >
          {isLoading ? (
            <Skeleton animation="wave" width="80%" />
          ) : row.key === 'address' ? (
            <LocationShow location={formatLocation(parseLocation(row.value)).showing} />
          ) : (
            row.value || '-'
          )}
        </Typography>
      </Stack>
    </Grid>
  );

  return (
    <>
      <Head title={t('Agent - {{ID}}', { ID: id })} />
      <PageHeader
        sx={{ py: 1, px: 3, boxShadow: 'none' }}
        left={
          <Box gap={0.75}>
            <Typography
              variant="h6"
              sx={{
                mb: 0.5,
                fontWeight: '500',
                color: (theme) => theme.customColors.pageHeader.title,
              }}
            >
              {isLoading ? (
                <Skeleton animation="wave" width={200} />
              ) : (
                `${agent?.first_name} ${agent?.last_name}`
              )}
            </Typography>
            <Typography sx={{ color: (theme) => theme.customColors.pageHeader.subTitle }}>
              {isLoading ? <Skeleton animation="wave" width={100} /> : `Agent #${id}`}
            </Typography>
          </Box>
        }
        right={<></>}
      />
      <Divider />
      <Box sx={{ px: 3, pb: 1 }}>
        <Stack
          sx={{
            flexDirection: { sx: 'column', sm: 'column', lg: 'row' },
          }}
          columnGap={(theme) => theme.columnGap}
        >
          <Grid
            sx={[
              detailPageGridContainerStyles,
              {
                maxWidth: 480,
                height: 'min-content',
                width: '100%',
                marginTop: '0px !important',
              },
            ]}
            container
            spacing={1}
            columns={1}
          >
            {rows.slice(0, Math.ceil(rows.length / 2)).map((row) => renderRow(row))}
          </Grid>
          <Grid
            sx={[
              detailPageGridContainerStyles,
              {
                maxWidth: 480,
                height: 'min-content',
                paddingTop: { xs: 2.5, sm: 2.5, md: 2.5, lg: 0 },
                width: '100%',
                marginTop: '0px !important',
              },
            ]}
            container
            spacing={1}
            columns={1}
          >
            {rows.slice(Math.round(rows.length / 2)).map((row) => renderRow(row))}
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default AgentDetail;
