import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import ClaimsHistoryDetailDrawer from './Detail';
import ClaimsHistoryEditDrawer from './Edit';

export interface IClaimsHistoryDrawer {
  isEdit: boolean;
}
const ClaimsHistoryDrawer: FC<IClaimsHistoryDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);

  const isClaimsHistoryDrawerOpen =
    url?.tab === 'claims_history' && (!isEmpty(url?.claim) || !isEmpty(url?.addClaim));

  return isClaimsHistoryDrawerOpen ? (
    isEdit ? (
      <ClaimsHistoryEditDrawer
        isDrawerOpen={isClaimsHistoryDrawerOpen ?? false}
        isAdd={!isEmpty(url?.addClaim)}
      />
    ) : (
      <ClaimsHistoryDetailDrawer isDrawerOpen={isClaimsHistoryDrawerOpen ?? false} />
    )
  ) : (
    <></>
  );
};
export default ClaimsHistoryDrawer;
