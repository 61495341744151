import { Box, Typography } from '@mui/material';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { getStripeConfig } from 'api/services/Payments';
import useLoader from 'hooks/useLoader';
import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const enrollmentInfoMessage =
  'Autopay enrollment could not be completed. Try again after paying all outstanding invoices.';
const generalInfoMessage =
  'Could not complete autopay enrollment. Please, contact your support team.';
const generalSuccessMessage = 'Payment completed successfully.';

const SuccessPage = () => {
  const LOCATION = useLocation();
  const { enrollment_failed, status, payment_intent_client_secret } = qs.parse(LOCATION.search);
  const stripe = useStripe();

  const { setLoading } = useLoader();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMicrodeposit, setIsMicrodeposit] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (!stripe || !payment_intent_client_secret) {
      return;
    }

    stripe
      .retrievePaymentIntent(payment_intent_client_secret as string)
      .then(({ paymentIntent }) => {
        const isTypeMicrodeposit = paymentIntent?.next_action?.type === 'verify_with_microdeposits';

        setIsMicrodeposit(isTypeMicrodeposit);
      })
      .finally(() => {
        setLoading(false);
        setIsLoaded(true);
      });
  }, [stripe]);

  return (
    <>
      {isLoaded ? (
        <Box
          sx={{
            textAlign: 'center',
            color: (theme) => theme.customColors.successGreen,
            maxWidth: '409px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {!isMicrodeposit ? (
            <>
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: 'inherit',
                }}
                component="span"
                data-test="payment-success-header"
              >
                SUCCESS
              </Typography>
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: '28px',
                    fontWeight: 400,
                    display: 'inline',
                    whiteSpace: 'pre-wrap',
                    color: 'inherit',
                  }}
                  component="span"
                  data-test="payment-success-message"
                >
                  {generalSuccessMessage}
                </Typography>
              </Box>
            </>
          ) : null}

          {enrollment_failed === 'true' ? (
            <Box
              sx={{
                textAlign: 'center',
                mt: 3,
                color: (theme) => theme.customColors.grey800,
                p: 2,
                backgroundColor: (theme) => theme.customColors.white100,
                borderRadius: '0.5rem',
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  lineHeight: '28px',
                  fontWeight: 400,
                  display: 'inline',
                  whiteSpace: 'pre-wrap',
                  color: 'inherit',
                }}
                component="span"
              >
                {status === '400' ? enrollmentInfoMessage : generalInfoMessage}
              </Typography>
            </Box>
          ) : null}

          {isMicrodeposit ? (
            <Box
              sx={{
                textAlign: 'center',
                mt: 2,
                color: (theme) => theme.customColors.black,
                p: 2,
                backgroundColor: (theme) => theme.customColors.white50,
                borderRadius: '0.5rem',
              }}
            >
              <Typography
                sx={{
                  fontSize: 17,
                  lineHeight: '28px',
                  fontWeight: 400,
                  display: 'inline',
                  whiteSpace: 'pre-wrap',
                  color: 'inherit',
                }}
                component="span"
                data-test="payment-microdeposit-success-message"
              >
                Please monitor your bank account for the microdeposit over the next 1-2 business
                days in order to complete account verification.
                <br />
                <Typography
                  sx={{
                    all: 'inherit',
                    display: 'inline-block',
                    marginTop: 1,
                  }}
                  component="span"
                >
                  Until verification is complete and payment is initiated, coverage is not bound and
                  the policy will not be issued.
                </Typography>
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

const Wrapper: FC = () => {
  // Make sure to call loadStripe outside of a component’s render to avoid
  // recreating the Stripe object on every render.
  const [stripePromise, setStripePromise] = useState<undefined | Promise<any>>();

  const loadStripeJS = async () => {
    const stripeConfig = await getStripeConfig();
    const stripePromiseRes = loadStripe(stripeConfig.publishable_key);
    setStripePromise(stripePromiseRes);
  };

  useEffect(() => {
    loadStripeJS();
  }, []);

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <SuccessPage />
        </Elements>
      )}
    </>
  );
};

export default Wrapper;
