import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
  wcAuditPolOoFields,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { WcOwnersMembersDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/WcOwnersMembersDrawerFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import {
  changeFieldsHiddenStatus,
  deleteFromQueryStrings,
  makeFieldsReadonly,
  parseLocation,
} from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useUser from 'hooks/useUser';
import { isEmpty, isNil } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import EndorsementLocationSelect from '../../../Inputs/LocationSelectInput';
import WcClassCodeAutocomplete from '../../../Inputs/WcClassCodeAutocomplete';

export interface WcOwnersMembersDrawerDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  position: string;
}

const WcOwnersMembersDrawerDetailDrawer: FC<WcOwnersMembersDrawerDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  position,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const OWNER_MEMBER = url.owner_member;
  const {
    loading: endorsementLoading,
    loaded,
    exposureList,
    groups,
    fields: fieldConfig,
    isWcFinal,
    isWc,
  } = useEndorsementDetail();
  const { data: user } = useUser();

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const owners = groups?.pol_oo?.data ?? [];

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  const isGroupNewlyAdded = useMemo(() => {
    const isNewlyAdded = OWNER_MEMBER?.includes('added-');
    const index = isNewlyAdded ? Number(OWNER_MEMBER?.slice(6)) : undefined;

    return { isNewlyAdded, index };
  }, [OWNER_MEMBER]);

  const owner = useMemo(() => {
    const { isNewlyAdded, index } = isGroupNewlyAdded;

    return (
      owners?.find((ow) => (isNewlyAdded ? ow.index === index : ow.locator === OWNER_MEMBER)) ?? {}
    );
  }, [owners, OWNER_MEMBER, isGroupNewlyAdded]);

  const [state, setState] = useState<any>({});

  const [fields, setFields] = useState<any[]>([]);

  useEffect(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])?.[0]?.fields?.find(
        (f) => f.code === `${threePolicyGroupNames.OWNER}_wc`,
      )?.nested_fields ?? [];

    setFields(tmpFields);
  }, [fieldConfig]);

  useEffect(() => {
    if (isWc) {
      setFields((prevFields) => {
        const { ESTIMATED, FINAL, ORIGINALS } = wcAuditPolOoFields;

        let tmpFields = changeFieldsHiddenStatus(
          prevFields,
          isWcFinal ? [...FINAL, ...ESTIMATED] : ESTIMATED,
          false,
        );

        tmpFields = makeFieldsReadonly(
          tmpFields,
          isWcFinal ? [...ESTIMATED, ...ORIGINALS] : ORIGINALS,
          true,
        );

        return tmpFields;
      });
    }
  }, [isWcFinal, fieldConfig, isWc]);

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['owner_member'],
      }),
    });
  };

  useEffect(() => {
    if (loaded && !isNil(isGroupNewlyAdded.isNewlyAdded) && isEmpty(owner)) {
      displayToastMessage(
        'ERROR',
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'owner',
        }),
      );
      handleQuery();
    }
  }, [owner, loaded]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {},
  });

  useEffect(() => {
    if (isUnderwriter) {
      const integrationFields: string[] = [];

      if (owner.pol_oo_isprimary === 'Yes') {
        integrationFields.push('pol_oo_b505');
        integrationFields.push('pol_oo_ncf_score');

        setFields((prevFields) => changeFieldsHiddenStatus(prevFields, integrationFields, false));
      }
    }

    const parsedLoacation = parseLocation(owner.pol_oo_location);

    const foundExposure = employerExposures.find(
      (e) => e?.data?.emp_state === parsedLoacation.state,
    );

    setState((prevState) => ({
      ...prevState,
      ...owner,
      emp_filing_set_id: foundExposure?.data?.emp_filing_set_id ?? -1,
    }));

    formik.setValues(owner);
  }, [owner, isUnderwriter, employerExposures]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      endorsementLoading ||
      fieldConfig?.policy?.loading ||
      exposureList?.[`${threeExposureNames.LOCATION}`].loading;

    return defaultLoading || isEmpty(owner);
  }, [owner, endorsementLoading, exposureList, fieldConfig]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('{{entityPosition}} Details', {
            entityPosition: position,
          })}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <WcOwnersMembersDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={showLoader}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
            LocationSelect={EndorsementLocationSelect}
            WcClassCodeAutocomplete={WcClassCodeAutocomplete}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default WcOwnersMembersDrawerDetailDrawer;
