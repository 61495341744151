import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton } from '@mui/material';
import { GridAlignment } from '@mui/x-data-grid';
import { billingChipCategories, quoteStatuses } from 'common/constants';
import InvoicesTable from 'components/Policies/PolicyDetail/Billing/InvoicesTable';
import PayInvoiceDialog from 'components/Policies/PolicyDetail/Billing/PayInvoiceDialog';
import PaymentInfo from 'components/Policies/PolicyDetail/Billing/PaymentInfo';
import PaymentLinkDialog from 'components/Policies/PolicyDetail/Billing/PaymentLinkDialog';
import TableFilters from 'components/Policies/PolicyDetail/Billing/TableFilters';
import QuoteOrRenewalDetailHeader from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/QuoteOrRenewalDetailHeader';
import QuoteOrRenewalDetailTopActionBar from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/QuoteOrRenewalDetailTopActionBar';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export interface IColumns {
  name: string;
  headerName: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const QuoteDetailBillings: FC = () => {
  const { id: quoteId } = useParams<{ id: string }>();
  const {
    getBillingData,
    resetPolicyInvoices,
    invoices,
    billingPaymentInfo,
    groupedInvoices,
    totalBillingPaymentAmounts,
    setExpandedBillingAccordions,
  } = usePolicyDetail();

  const {
    data: quoteDetail,
    loading: quoteDetailLoading,
    loaded: quoteDetailLoaded,
  } = useQuoteDetail();
  const { policy_locator: policyId = '' } = quoteDetail || {};

  const [chips, setChips] = React.useState([{ code: 'all invoices', label: 'All Invoices' }]);
  const [activeChip, setActiveChip] = React.useState<string>(chips[0].code);
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);

  const handleChipClick = (chip: string) => {
    setActiveChip(chip);
  };

  const isPayInvoiceDialogOpen = url?.payInvoice === 'true' && url?.step === '1';
  const isPaymentLinkDialogOpen = url?.payInvoice === 'true' && url?.step === '2';
  const isQuoteAccepted =
    quoteDetail?.state?.code && quoteDetail?.state?.code === quoteStatuses.ACCEPTED;

  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!hasInitialized.current && invoices?.loaded && quoteDetailLoaded) {
      // Initial expanded state for a single accordion
      const initialExpandedState = {
        [policyId]: invoices?.[policyId]?.loaded && !isEmpty(invoices?.[policyId]?.data),
      };

      // Update the expanded state
      setExpandedBillingAccordions(initialExpandedState);
      hasInitialized.current = true;
    }
  }, [invoices, policyId, quoteDetailLoaded]);

  const fillChipsDynamically = () => {
    if (groupedInvoices && !isEmpty(groupedInvoices)) {
      Object.entries(groupedInvoices).forEach(([_, invoiceData]) => {
        if (!isEmpty(invoiceData)) {
          Object.entries(invoiceData).forEach(([status, value]) => {
            if (!isEmpty(value)) {
              if (billingChipCategories.some((e) => e.code === status)) {
                const chip = billingChipCategories.find((e) => e.code === status);
                if (chip) {
                  setChips((val) => {
                    // If a chip with the same code has already been added, do not add it again
                    if (!val.some((existingChip) => existingChip.code === chip.code)) {
                      return [...val, { code: chip.code, label: chip.label }];
                    }
                    return val;
                  });
                }
              }
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    // Second parameter assumes that the customer is not enrolled in autopay
    // https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/13065
    // Request billing data only for quotes with accepted status
    if (policyId && isQuoteAccepted && quoteDetailLoaded)
      getBillingData(policyId, true, quoteDetail?.policyholder?.locator);

    return () => {
      resetPolicyInvoices();
    };
  }, [policyId, isQuoteAccepted, quoteDetailLoaded]);

  useEffect(() => {
    if (invoices.loading === false) fillChipsDynamically();
  }, [invoices.loading]);

  return (
    <>
      <QuoteOrRenewalDetailTopActionBar />

      <QuoteOrRenewalDetailHeader currentTab={isQuoteAccepted ? 4 : 0} quoteId={quoteId} />

      {isPayInvoiceDialogOpen && <PayInvoiceDialog isOpen={isPayInvoiceDialogOpen} />}

      {isPaymentLinkDialogOpen && (
        <PaymentLinkDialog
          isOpen={isPaymentLinkDialogOpen}
          customerEmail={quoteDetail?.characteristics?.[0].data?.pol_business_email as string}
          policyId={quoteDetail?.policy_locator!}
        />
      )}

      {quoteDetailLoading ? (
        <Skeleton sx={{ ml: 3, mt: 5 }} animation="wave" width={200} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: (theme) => `calc(100vh - ${theme.quoteDetailFixedItemsHeight})`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              p: 2,
              pt: 5,
            }}
          >
            <Box
              sx={{
                pb: 6,
              }}
            >
              <PaymentInfo
                totalBillingPaymentAmounts={totalBillingPaymentAmounts}
                billingPaymentInfo={billingPaymentInfo}
                loading={invoices.loading}
                paymentPlanValue={quoteDetail?.payment_schedule}
                isEnrollInAutopayButtonVisible={false}
              />

              {invoices.loading ? (
                <Box sx={{ display: 'flex' }}>
                  {[...Array(3).keys()].map((_, i) => (
                    <Skeleton
                      sx={{ ml: 2, py: 1, mb: 3 }}
                      animation="wave"
                      width={100}
                      key={i as number}
                    />
                  ))}
                </Box>
              ) : (
                <TableFilters
                  activeChip={activeChip}
                  handleChipClick={handleChipClick}
                  chips={chips}
                />
              )}

              {invoices?.loaded && quoteDetailLoaded && (
                <Accordion
                  sx={{
                    mt: 1,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    padding: (theme) => theme.spacing(0, 2),
                    '&:before': {
                      display: 'none',
                    },
                    '& > .MuiCollapse-root': {
                      borderBottomRightRadius: 1,
                      borderBottomLeftRadius: 1,
                    },
                  }}
                  defaultExpanded={
                    invoices?.[policyId]?.loaded && !isEmpty(invoices?.[policyId]?.data)
                  }
                  expanded
                >
                  <AccordionSummary />
                  <AccordionDetails sx={{ padding: '0 16px' }}>
                    {!isEmpty(invoices[policyId] ?? []) && (
                      <Box sx={{ py: 1.5 }}>
                        <InvoicesTable activeChip={activeChip} policyLocator={policyId} />
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default QuoteDetailBillings;
