import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import AddScheduleIcon from 'assets/images/icon_schedule_mod.svg';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  smodLines,
  submissionDetailInfoTabs,
  threeEmployerExposurePerilNames,
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
  wcExposureRelatedKeyValues,
  wcExposureRelatedKeyValuesMap,
} from 'common/constants';
import DataTable from 'components/DataTable';
import LocationShow from 'components/LocationShow';
import WCExclusionToogle from 'components/QuotePolicyDetailEndorsement/Buttons/WCExclusionToogle';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { primaryButtonStyle, tabTitleStyles } from 'helpers/MuiSharedStyles';
import { calculateSchedMod } from 'helpers/ScheduleModificationHelpers';
import {
  createColumnVisibilityModel,
  currencyFormat,
  determineOwnerOfficerPositions,
  fieldHasValue,
  formatLocation,
  getNestedValueFromObject,
  parseLocation,
  sortComputedWcRowsByMainStatus,
  updateQueryStrings,
} from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser';
import { isEmpty, omit, uniqBy } from 'lodash-es';
import qs from 'query-string';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import ClassDrawer from './Drawers/ClassDrawer/ClassDrawer';
import ExpModeDrawer from './Drawers/ExpMod/ExpMod';
import WCExposureScheduleModDrawer from './Drawers/WCExposureScheduleModDrawer/WCExposureScheduleModDrawer';
import WcOwnersMembersDrawer from './Drawers/WcOwnersMembersDrawer/WcOwnersMembersDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

interface IWCExposures {
  id: string;
  state: string;
  location: string;
  name: string;
  index?: number;
  isNewAddedOnEndorsement?: boolean;
  class_code?: string;
  payroll?: string;
  exposure_mod?: string;
  status: string;
  scheduleMod: string | null;
}

const WCExposures = forwardRef<any, any>((_props, ref) => {
  const { t } = useTranslation();
  const [locationFilter, setLocationFilter] = useState('Location');
  const themeHook = useTheme();
  const { setLoading } = useLoader();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { data: userData } = useUser();
  const [selectedClassID, setSelectedClassID] = useState<string>('');

  const isUW = userData?.role?.code === userRoles.UNDERWRITER.code;

  const {
    exposureList,
    groups,
    data: endorsementDetail,
    canEdit,
    updateEndorsementDetail,
  } = useEndorsementDetail();

  const { fetch: fetchKeyValues, data: keyValueStore } = useKeyValues();

  const url = qs.parse(LOCATION.search);
  const OWNER_MEMBER = url.owner_member;

  const isGroupNewlyAdded = useMemo(() => {
    const isNewlyAdded = OWNER_MEMBER?.includes('added-');
    const index = isNewlyAdded ? Number(OWNER_MEMBER?.slice(6)) : undefined;

    return { isNewlyAdded, index };
  }, [OWNER_MEMBER]);

  const SCHED_MOD_TABLES =
    keyValueStore?.[`${wcExposureRelatedKeyValues.SCHED_MOD_TABLES}`]?.data?.value ?? [];

  useEffect(() => {
    try {
      Promise.allSettled(wcExposureRelatedKeyValuesMap.map((key) => fetchKeyValues(key)));
      // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  const currentWcExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`];

  const schedModGroupFields: any[] =
    groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.data ?? [];

  const owners = groups?.pol_oo?.data ?? [];

  const owner = useMemo(() => {
    const { isNewlyAdded, index } = isGroupNewlyAdded;

    return (
      owners?.find((ow) => (isNewlyAdded ? ow.index === index : ow.locator === OWNER_MEMBER)) ?? {}
    );
  }, [owners, OWNER_MEMBER, isGroupNewlyAdded]);

  const ownersMembersRows = useMemo(
    () =>
      groups?.pol_oo?.data?.map((m) => {
        const {
          pol_oo_name_first = '',
          pol_oo_name_last = '',
          pol_oo_location: location,
          pol_oo_wc_payroll: payroll,
          pol_oo_wc_payroll_audit_est: payroll_audit_est,
          pol_oo_wc_payroll_audit_final: payroll_audit_final,

          pol_oo_wc_class_code,
          pol_oo_wc_class_code_desc,
          pol_oo_wc_excluded: status,
          locator,
          index,
          isNewAddedOnEndorsement,
        } = m;

        const determinePayroll = () => {
          if (fieldHasValue(payroll_audit_final, [0, '0'])) {
            return payroll_audit_final;
          } else if (fieldHasValue(payroll_audit_est, [0, '0'])) {
            return payroll_audit_est;
          }

          return payroll;
        };

        const { state } = parseLocation(location);
        const name = `${pol_oo_name_first} ${pol_oo_name_last}`;

        const exposureData = currentWcExposures?.data?.find(
          (e) => e.data?.emp_state === state,
        )?.data;

        const exposure_mod = fieldHasValue(exposureData?.emp_xmod_override)
          ? exposureData?.emp_xmod_override
          : fieldHasValue(exposureData?.emp_xmod)
          ? exposureData?.emp_xmod
          : '-';

        const filteredTableRows = SCHED_MOD_TABLES?.[`${smodLines.EMPLOYER}`]
          ?.filter((item) => String(item.FilingSetID) === exposureData?.emp_filing_set_id)
          ?.map((row) => ({
            ...row,
            MaxCredit: -row?.MaxCredit,
          }));

        const relatedFieldGroups = schedModGroupFields.filter(
          (schedMod) =>
            schedMod.pol_sched_mod_line === smodLines.EMPLOYER &&
            schedMod.pol_sched_mod_state === exposureData?.emp_state,
        );

        return {
          id: locator,
          state,
          location,
          name: name?.trim() === '' ? '-' : name,
          class_code: `${pol_oo_wc_class_code ?? ''} - ${pol_oo_wc_class_code_desc ?? ''}`,
          payroll: determinePayroll(),
          exposure_mod,
          status,
          scheduleMod: calculateSchedMod(relatedFieldGroups, filteredTableRows),
          index,
          isNewAddedOnEndorsement,
        };
      }) ?? [],
    [groups, currentWcExposures, keyValueStore],
  );

  const classCodeRows = useMemo(
    () =>
      currentWcExposures?.data?.flatMap((m) => {
        const exposureData = m.data;

        const exposure_mod = fieldHasValue(exposureData?.emp_xmod_override)
          ? exposureData?.emp_xmod_override
          : fieldHasValue(exposureData?.emp_xmod)
          ? exposureData?.emp_xmod
          : '-';

        const filteredTableRows = SCHED_MOD_TABLES.WC?.filter(
          (item) => String(item.FilingSetID) === exposureData?.emp_filing_set_id,
        )?.map((row) => ({
          ...row,
          MaxCredit: -row?.MaxCredit,
        }));

        const relatedFieldGroups = schedModGroupFields.filter(
          (schedMod) =>
            schedMod.pol_sched_mod_line === smodLines.EMPLOYER &&
            schedMod.pol_sched_mod_state === exposureData?.emp_state,
        );

        const determineEmployeePayroll = (data) => {
          if (fieldHasValue(data?.man_payroll_w2_audit_final, [0, '0'])) {
            return data?.man_payroll_w2_audit_final;
          } else if (fieldHasValue(data?.man_payroll_w2_audit_est, [0, '0'])) {
            return data?.man_payroll_w2_audit_est;
          }

          return data?.man_payroll_w2;
        };

        const determineUninsuredSubPayroll = (data) => {
          if (fieldHasValue(data?.man_payroll_sub_audit_final, [0, '0'])) {
            return data?.man_payroll_sub_audit_final;
          } else if (fieldHasValue(data?.man_payroll_sub_audit_est, [0, '0'])) {
            return data?.man_payroll_sub_audit_est;
          }

          return data?.man_payroll_sub;
        };

        return (
          m.perils
            ?.filter((p) => p.name === threeEmployerExposurePerilNames.MANUAL_PREMIUM)
            ?.map(({ locator, data, index }) => ({
              id: locator,
              exposureId: m.locator,
              exposureIndex: m.index,
              perilIndex: index,
              state: exposureData?.emp_state,
              location: data?.man_location,
              classCode: `${data?.man_class_code ?? ''} - ${data?.man_class_code_desc ?? ''}`,
              employeePayroll: determineEmployeePayroll(data),
              uninsuredSubPayroll: determineUninsuredSubPayroll(data),
              exposure_mod,
              isMain: data?.man_ismain,
              isAncillary: data?.man_isancillary,
              scheduleMod: calculateSchedMod(relatedFieldGroups, filteredTableRows),
            })) ?? []
        );
      }) ?? [],
    [currentWcExposures, keyValueStore, groups],
  );

  const isTablesLoading = useMemo(
    () =>
      currentWcExposures?.loading ||
      groups?.pol_oo?.loading ||
      groups?.pol_sched_mod?.loading ||
      wcExposureRelatedKeyValuesMap.some((map) => keyValueStore?.[`${map}`]?.loading ?? false),
    [groups, currentWcExposures, keyValueStore],
  );

  const setScheduleModeColor = (value: number) => {
    if (value > 1) {
      return themeHook.customColors.errorRed;
    } else if (value < 1) {
      return themeHook.customColors.successGreen;
    }

    return themeHook.customColors.gunMetal;
  };

  const wcExposuresError = (data: IWCExposures[]) =>
    !data?.every(
      (item) =>
        item.status === 'Yes' ||
        (fieldHasValue(item.class_code, ['-', ' - ']) && fieldHasValue(item.payroll, ['-', ' - '])),
    );

  const handleExclude = async (
    ownerLocator: string,
    excluded: boolean,
    isNewAddedOnEndorsement: boolean,
    index: number,
  ) => {
    if (!excluded) {
      HISTORY.push({
        search: updateQueryStrings({
          locationSearch: LOCATION.search,
          newQueries: {
            owner_member: isNewAddedOnEndorsement ? `added-${index}` : ownerLocator,
            forceCover: true,
          },
        }),
      });

      return;
    }

    try {
      setLoading(true);

      const tmpOwners = [...owners];
      const rowIndex = tmpOwners.findIndex((row) => row.locator === ownerLocator);
      tmpOwners[rowIndex] = {
        ...tmpOwners[rowIndex],
        pol_oo_wc_excluded: excluded ? 'Yes' : 'No',
      };

      const query = {
        validate: threePolicyGroupNames.OWNER,
        strategy: 'update',
        locator: owner.locator,
      };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.OWNER}`]: tmpOwners?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        query,
      );

      displayToastMessage('SUCCESS', t('The owner has been updated.'));
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the owner.'));
    } finally {
      setLoading(false);
    }
  };

  const columns: IColumns[] = [
    {
      name: 'state',
      display_name: t('State'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.5,
      type: 'string',
      minWidth: 80,
    },
    {
      name: 'location',
      display_name: t('Location'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1.5,
      type: 'string',
      minWidth: 150,
    },
    {
      name: 'classCode',
      display_name: t('Class Code'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 130,
    },
    ...(isUW
      ? ([
          {
            name: 'isMain',
            display_name: t('Is Main'),
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 1,
            type: 'string',
            minWidth: 90,
          },
        ] as IColumns[])
      : []),
    {
      name: 'employeePayroll',
      display_name: t('Employee Payroll'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.3,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 110,
    },
    {
      name: 'uninsuredSubPayroll',
      display_name: t('Uninsured Subcontractor Payroll'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.5,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 130,
    },
    ...(isUW
      ? ([
          {
            name: 'exposure_mod',
            display_name: t('Exp. Mod.'),
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.5,
            align: 'right',
            headerAlign: 'right',
            type: 'string',
            minWidth: 110,
          },
          {
            name: 'scheduleMod',
            display_name: t('Schedule Mod.'),
            type: 'string',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.7,
            minWidth: 140,
            align: 'right',
            headerAlign: 'right',
          },
        ] as IColumns[])
      : []),
  ];

  const wCExposureEmployeeColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.is_link) {
        return row.classCode;
      }

      if (field.name === 'location') {
        return row.location;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        const { exposureId, exposureIndex, id, perilIndex } = params.row;

        const newQueries = {
          wc: exposureIndex ? `added-${exposureIndex}` : exposureId,
          wcp: perilIndex ? `added-${perilIndex}` : id,
        };

        if (fieldValue.length > 10) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries,
              })}`}
              underline="hover"
              title={fieldValue || '-'}
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries,
              })}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        }
      } else if (field.name === 'exposure_mod') {
        return (
          <Box sx={{ display: 'flex' }}>
            <Typography>{fieldValue}</Typography>
            <Typography
              component="span"
              sx={{
                marginLeft: 1.5,
              }}
            >
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: { exp_mod: params.row.state },
                })}`}
                underline="none"
              >
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            </Typography>
          </Box>
        );
        // return <></>;
      } else if (field.name === 'scheduleMod') {
        return (
          <>
            {fieldValue === null || fieldValue === '-' ? (
              '-'
            ) : (
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: {
                    schedule_mod: params.row.state,
                  },
                })}`}
                underline="hover"
                sx={{
                  color: setScheduleModeColor(+fieldValue),
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '14px',
                  textOverflow: 'ellipsis',
                }}
                title={fieldValue}
              >
                {fieldValue}
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            )}
          </>
        );
      } else if (field.name === 'location') {
        const location = parseLocation(params.row.location);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      } else if (field.type === 'currency') {
        return fieldValue && fieldValue !== '-'
          ? currencyFormat('USD', fieldValue, true).merged
          : '$0';
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const individualOwnersColumns: IColumns[] = [
    {
      name: 'state',
      display_name: t('State'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.5,
      type: 'string',
      minWidth: 80,
    },
    {
      name: 'location',
      display_name: t('Location'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1.5,
      type: 'string',
      minWidth: 150,
    },
    {
      name: 'name',
      display_name: t('Name'),
      is_hidden: false,
      is_sortable: true,
      is_link: true,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 100,
    },
    {
      name: 'status',
      display_name: t('WC Election'),
      type: 'toogle',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      name: 'class_code',
      display_name: t('Class Code'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 130,
    },
    {
      name: 'payroll',
      display_name: t('Payroll'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'currency',
      minWidth: 110,
    },
    ...(isUW
      ? ([
          {
            name: 'exposure_mod',
            display_name: t('Exp. Mod.'),
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.5,
            align: 'right',
            headerAlign: 'right',
            type: 'string',
            minWidth: 110,
          },
          {
            name: 'scheduleMod',
            display_name: t('Schedule Mod.'),
            type: 'string',
            is_hidden: false,
            is_sortable: true,
            is_link: false,
            link_type: null,
            flex: 0.7,
            minWidth: 140,
            align: 'right',
            headerAlign: 'right',
          },
        ] as IColumns[])
      : []),
  ];

  const wCExposureIndividualOwnersColumns: GridColDef[] = individualOwnersColumns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.is_link) {
        return row.name;
      }

      if (field.name === 'location') {
        return row.location;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      if (field.is_link) {
        const { isNewAddedOnEndorsement, index, id } = params.row;

        const newQueries = {
          owner_member: isNewAddedOnEndorsement ? `added-${index}` : id,
        };

        if (fieldValue.length > 10) {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries,
              })}`}
              underline="hover"
              title={fieldValue}
              sx={{
                color: (theme) => theme.customColors.table.link,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else {
          return (
            <Link
              component={RouterLink}
              to={`?${updateQueryStrings({
                locationSearch: LOCATION.search,
                newQueries,
              })}`}
              underline="hover"
              title={fieldValue}
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        }
      } else if (field.name === 'exposure_mod') {
        return isTablesLoading ? (
          <></>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Typography>{fieldValue}</Typography>
            <Typography
              component="span"
              sx={{
                marginLeft: 1.5,
              }}
            >
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: { exp_mod: params.row.state },
                })}`}
                underline="none"
              >
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            </Typography>
          </Box>
        );
      } else if (field.name === 'location') {
        const location = parseLocation(params.row.location);

        const formatted = formatLocation({
          ...location,
        });

        return <LocationShow location={formatted.showing} />;
      } else if (field.name === 'scheduleMod') {
        return (
          <>
            {fieldValue === null || fieldValue === '-' ? (
              '-'
            ) : (
              <Link
                component={RouterLink}
                to={`?${updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: {
                    schedule_mod: params.row.state,
                  },
                })}`}
                underline="hover"
                sx={{
                  color: setScheduleModeColor(+fieldValue),
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '14px',
                  textOverflow: 'ellipsis',
                }}
                title={fieldValue}
              >
                {fieldValue}
                <ReactSVG className="icon" src={AddScheduleIcon} />
              </Link>
            )}
          </>
        );
      } else if (field.type === 'currency') {
        return fieldHasValue(fieldValue, ['-'])
          ? currencyFormat('USD', fieldValue, true).merged
          : '$0';
      } else if (field.type === 'toogle') {
        const { status, id: ownerLocator, isNewAddedOnEndorsement, index } = params.row;
        const excluded = status === 'Yes';

        return (
          <WCExclusionToogle
            excluded={excluded}
            handleChange={(_e, v) =>
              handleExclude(ownerLocator, !v, isNewAddedOnEndorsement, index)
            }
            canEdit={canEdit}
          />
        );
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const handleChangeSelection = (event: SelectChangeEvent, filterName) => {
    switch (filterName) {
      case 'location':
        setLocationFilter(event.target.value);
        break;

      default:
        break;
    }
  };

  const entity = endorsementDetail?.policy?.characteristics?.data?.pol_legal_entity ?? '';

  const entityType = useMemo(() => determineOwnerOfficerPositions(entity as string), [entity]);

  const locationFilterOptions = useMemo(
    () =>
      uniqBy(
        [...ownersMembersRows, ...classCodeRows]?.map((r) => {
          const location = parseLocation(r?.location);

          const formatted = formatLocation({
            ...location,
          });

          return {
            code: formatted.storing,
            head: formatted.showing.head,
            tail: formatted.showing.tail,
          };
        }) ?? [],
        (r) => r.code,
      ),
    [ownersMembersRows, classCodeRows],
  );

  const computeOwnersMembersRows = useMemo(() => {
    if (locationFilter === 'Location') {
      return ownersMembersRows;
    } else {
      return ownersMembersRows.filter((r) =>
        isEmpty(r.location)
          ? locationFilter === formatLocation({ addressLine1: '' }).storing
            ? true
            : r.location === locationFilter
          : r.location === locationFilter,
      );
    }
  }, [ownersMembersRows, locationFilter]);

  const computedWcRows = useMemo(() => {
    if (locationFilter === 'Location') {
      const rows = classCodeRows;
      return !isUW ? sortComputedWcRowsByMainStatus(rows) : rows;
    } else {
      const rows = classCodeRows.filter((r) =>
        isEmpty(r?.location)
          ? locationFilter === formatLocation({ addressLine1: '' }).storing
            ? true
            : r?.location === locationFilter
          : r?.location === locationFilter,
      );
      return !isUW ? sortComputedWcRowsByMainStatus(rows) : rows;
    }
  }, [classCodeRows, locationFilter]);

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addWc: true },
      }),
    });
  };

  const replaceBack = () =>
    HISTORY.replace({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.WC_EXPOSURES.code },
      }),
    });

  useImperativeHandle(ref, () => ({
    validateWCExposures: async () => {
      const errors = wcExposuresError(computeOwnersMembersRows);
      if (errors) {
        displayToastMessage(
          'ERROR',
          'Class code election is not finished for all Covered Officers/Owners',
        );
        replaceBack();
      }
    },
  }));

  return (
    <>
      <ClassDrawer isEdit={canEdit} />
      <WcOwnersMembersDrawer isEdit={canEdit} position={entityType.position} />
      <ExpModeDrawer isEdit={canEdit} />

      <WCExposureScheduleModDrawer isEdit={canEdit} />

      <Box
        sx={{
          height: 68,
          boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
          px: 3,
          py: 2,
        }}
      >
        <Stack>
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
                width: 100,
              }}
            >
              {t('Filter by')}
            </Typography>

            <FormControl sx={{ ml: 2, width: 220, height: 37 }} size="small">
              {isTablesLoading ? (
                <Skeleton animation="wave" width="100%" height={37} />
              ) : (
                <Select
                  IconComponent={KeyboardArrowDownRounded}
                  value={locationFilter}
                  onChange={(e) => handleChangeSelection(e, 'location')}
                  MenuProps={{
                    sx: {
                      width: 100,
                    },
                  }}
                >
                  <MenuItem value="Location">{t('Location (All)')}</MenuItem>
                  {locationFilterOptions.map(({ code, head, tail }) => (
                    <MenuItem key={code} value={code} sx={{ w: 100 }}>
                      <LocationShow location={{ head, tail }} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ my: 1.5 }}>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.WC_EXPOSURES.title()}
        </Typography>

        <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
          {quotePolicyEndorsementInfoTitlesDescriptions.WC_EXPOSURES.description()}
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <DataTable
          onRowClick={(r) => setSelectedClassID(r.id === selectedClassID ? '' : (r.id as string))}
          isHeaderWidthFit
          autoRowCellHeight
          rows={computedWcRows}
          columns={wCExposureEmployeeColumns}
          columnVisibilityModel={createColumnVisibilityModel(columns)}
          pageSize={defaultRowVirtualization}
          hideFooterPagination={computedWcRows?.length! < defaultRowVirtualization}
          loading={currentWcExposures?.loading}
          wrapperSx={{
            ...(computedWcRows?.length! < defaultRowVirtualization ? { mb: '-28px' } : {}),
          }}
          focusedRowId={selectedClassID}
          initialState={{
            sorting: {
              sortModel: [
                { field: 'isMain', sort: 'desc' },
                { field: 'isAncillary', sort: 'asc' },
              ],
            },
          }}
        />

        {isUW && canEdit && currentWcExposures?.loaded && (
          <Box>
            <Button onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]}>
              {t('Add Class Code')}
            </Button>
          </Box>
        )}

        <Box sx={{ mt: 7 }}>
          <Typography sx={[tabTitleStyles]}>
            {quotePolicyEndorsementInfoTitlesDescriptions.INDIVIDUAL_OWNERS_OFFICERS.title()}
          </Typography>

          <Typography sx={{ fontSize: 14, fontWeight: 400, lineHeight: '21px', mb: 0.5 }}>
            {quotePolicyEndorsementInfoTitlesDescriptions.INDIVIDUAL_OWNERS_OFFICERS.description()}
          </Typography>
        </Box>

        <DataTable
          onRowClick={(r) => setSelectedClassID(r.id === selectedClassID ? '' : (r.id as string))}
          isHeaderWidthFit
          autoRowCellHeight
          rows={computeOwnersMembersRows}
          columns={wCExposureIndividualOwnersColumns}
          loading={isTablesLoading}
          columnVisibilityModel={createColumnVisibilityModel(individualOwnersColumns)}
          pageSize={defaultRowVirtualization}
          hideFooterPagination={computeOwnersMembersRows?.length! < defaultRowVirtualization}
          focusedRowId={selectedClassID}
        />
      </Box>
    </>
  );
});

export default WCExposures;
