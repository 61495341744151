import React, { useState } from 'react';

/// types
import { IContextProps } from 'providers/types';

/// data
import { Quote } from 'api/models/Quote/quote.model';
import { getQuotes, getThreeSubmissionsStatus } from 'api/services/Quote';
import { initialQuotesData, QuotesContext } from './QuotesProviderContext';
import { IQuotesPagination } from './types';

export const QuotesProvider = ({ children }: IContextProps): React.ReactElement<IContextProps> => {
  const [state, setState] = useState(initialQuotesData);

  const fetchStatus = async (renewals?: IQuotesPagination) => {
    const status = await getThreeSubmissionsStatus(renewals);
    setState((prevState) => ({
      ...prevState,
      filterListArray: {
        ...prevState.filterListArray,
        loading: false,
        loaded: true,
        data: status,
      },
    }));
  };

  const fetch = async (query: IQuotesPagination, newSubmission = false): Promise<Quote[]> => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const statusRequest = () => {
        if (!(state?.filterListArray?.data?.length! > 0) || newSubmission) {
          if (query.renewals === true) {
            return fetchStatus({ renewals: true });
          } else return fetchStatus();
        }

        return Promise.resolve(null);
      };

      const [res] = await Promise.all([
        getQuotes({ ...query, page_size: initialQuotesData.pagination?.page_size }),
        statusRequest(),
      ]);

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res?.results?.map((item) => ({ ...item, id: item.locator })),
        pagination: {
          ...query,
          total_count: res?.count,
          page_size: query.page_size ?? prevState.pagination?.page_size,
        },
      }));

      return res?.results ?? [];
    } catch (error) {
      setState((prevstate) => ({ ...prevstate, loading: false, loaded: true }));
      throw error;
    }
  };

  const resetThreeSubmissionsState = (): void => {
    setState((prevState) => ({
      ...prevState,
      ...initialQuotesData,
    }));
  };

  return (
    <QuotesContext.Provider value={{ ...state, fetch, resetThreeSubmissionsState }}>
      {children}
    </QuotesContext.Provider>
  );
};
