import Head from 'components/Head';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { t } from 'i18next';
import QuotePolicyEndorsementDefault from 'layouts/quotePolicyEndorsementDefault';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const PolicyDetailLayout: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { fetch, resetPolicyDetailState, data, loading } = usePolicyDetail();
  const HISTORY = useHistory();
  const LOCATION = useLocation();

  // if it's true means clicked new business in history tab
  const isNewBusinessView = LOCATION.pathname.endsWith('/new-business/');

  const getPageDetail = async (pId: string) => {
    try {
      await fetch(pId, true, isNewBusinessView);
    } catch (_error) {
      HISTORY.replace('/policies');
    }
  };

  useEffect(() => {
    getPageDetail(id);

    return () => {
      resetPolicyDetailState();
    };
  }, [id, isNewBusinessView]);

  return (
    <QuotePolicyEndorsementDefault>
      {loading ? (
        <Head title={t('Policy')} />
      ) : (
        <Head title={t('Policy - {{Name}}', { Name: data?.policyholder?.name })} />
      )}
      {children}
    </QuotePolicyEndorsementDefault>
  );
};

export default PolicyDetailLayout;
