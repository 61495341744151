import ErrorOutline from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import DeclineIcon from 'assets/images/icon_decline.svg';
import SendBack from 'assets/images/SendBack.svg';
import { drawerFooterPrimaryButtonStyle, secondaryButtonStyle } from 'helpers/MuiSharedStyles';
import useDialog from 'hooks/useDialog';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { DIALOGS } from './dialogRoot';

const Dialogs: FC = () => {
  const { setDialogOpen, dialogs, dialog, context } = useDialog();

  const handleClose = () => {
    setDialogOpen({ isOpen: false, dialog: dialog!, onAccept: dialogs[dialog!]?.onAccept });
  };

  const handleAccept = async () => {
    await dialogs[dialog!]?.onAccept?.();
    setDialogOpen({ isOpen: false, dialog: dialog!, onAccept: dialogs[dialog!]?.onAccept });
  };

  const selectedDialog:
    | {
        title: () => string;
        description: (contextArg?: Record<string, any> | undefined) => string | JSX.Element;
        cancel?: {
          color: string;
          text: () => string;
          variant?: 'text' | 'outlined' | 'contained';
          style?: SxProps<Theme>;
        };
        accept?: {
          color: string;
          text: () => string;
          variant?: 'text' | 'outlined' | 'contained';
          style?: SxProps<Theme>;
        };
        icon?: string;
        maxWidth?: number;
      }
    | undefined = dialog ? DIALOGS[dialog] : undefined;

  return (
    <Dialog
      open={dialogs[dialog!]?.open ?? false}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{ borderRadius: 0 }}
    >
      <Box
        sx={{
          m: 0,
          p: 1,
          maxWidth: selectedDialog?.maxWidth || '480px',
        }}
      >
        <Box
          sx={{
            m: 0,
            padding: (theme) => theme.spacing(2.5, 2),
          }}
        >
          <DialogTitle
            id="dialog-title"
            sx={{ p: 0, m: 0, mb: 1.5, display: 'flex', alignItems: 'center' }}
          >
            {selectedDialog?.icon === 'alert' && (
              <WarningAmberIcon
                sx={{
                  color: (theme) => theme.customColors.alert,
                  fontSize: '20px',
                  mr: 1,
                }}
              />
            )}
            {selectedDialog?.icon === 'info' && (
              <ErrorOutline
                sx={{
                  color: (theme) => theme.customColors.info,
                  fontSize: '20px',
                  mr: 1,
                }}
              />
            )}
            {selectedDialog?.icon === 'decline' && (
              <Box
                sx={{
                  lineHeight: 0,
                  mr: 1,
                  '& .dialog-decline-icon svg': {
                    stroke: (theme) => theme.customColors.errorRed,
                    width: '24px',
                    height: '24px',
                  },
                }}
              >
                <ReactSVG src={DeclineIcon} className="dialog-decline-icon" />
              </Box>
            )}

            {selectedDialog?.icon === 'sendBack' && (
              <Box
                sx={{
                  lineHeight: 0,
                  mr: 1,
                  '& .dialog-send-back-icon svg': {
                    width: '24px',
                    height: '24px',
                    '& path': {
                      stroke: (theme) => theme.customColors.errorRed,
                    },
                  },
                }}
              >
                <ReactSVG src={SendBack} className="dialog-send-back-icon" />
              </Box>
            )}

            {selectedDialog?.icon === '' && null}

            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: (theme) => theme.customColors.black,
              }}
            >
              {selectedDialog?.title()}
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ p: 0, m: 0 }}>
            <DialogContentText
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: (theme) => theme.customColors.black,
                whiteSpace: 'pre-line',
              }}
              id="dialog-description"
            >
              {selectedDialog?.description(context)}
            </DialogContentText>
          </DialogContent>
        </Box>

        <DialogActions sx={{ padding: (theme) => theme.spacing(1, 1), m: 0 }}>
          <Button
            autoFocus
            onClick={handleClose}
            color={selectedDialog?.cancel?.color as ButtonProps['color']}
            variant={selectedDialog?.cancel?.variant}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              textTransform: 'capitalize',
              ...secondaryButtonStyle,
              ...selectedDialog?.cancel?.style,
            }}
          >
            {selectedDialog?.cancel?.text()}
          </Button>

          {selectedDialog?.accept && (
            <Button
              autoFocus
              onClick={handleAccept}
              color={selectedDialog?.accept?.color as ButtonProps['color']}
              variant={selectedDialog?.accept?.variant}
              sx={{
                fontSize: 14,
                fontWeight: 500,
                ml: '0 !important',
                textTransform: 'capitalize',
                ...drawerFooterPrimaryButtonStyle,
                ...selectedDialog?.accept?.style,
              }}
            >
              {selectedDialog?.accept?.text()}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default Dialogs;
