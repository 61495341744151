import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { getXmodByFein } from 'api/services/Integrations/XMod';
import { updateExposure } from 'api/services/NewQuote';
import { expModWorkFlowName, threeExposureNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { ExpModFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/ExpModFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { validateExpModIntegrationsCanRun } from 'helpers/ExpMod';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import {
  deleteFromQueryStrings,
  displayIntegrationErrorMessage,
  handleBackendErrorsWithFormik,
} from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

export interface ExpModeEditDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
}

const ExpModeEditDrawer: FC<ExpModeEditDrawerProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const EXP_MOD = url.exp_mod as string;
  const {
    data: quoteDetail,
    loading: quoteLoading,
    exposureList,
    getExposures,
    fields: fieldConfig,
    underwritingQuestionsState,
  } = useQuoteDetail();

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  const foundEmployerExp = useMemo(
    () => employerExposures?.find((e) => e.data?.emp_state === EXP_MOD) ?? {},
    [employerExposures],
  );

  const [state, setState] = useState<any>({});
  const [fields, setFields] = useState<any[]>([]);

  const { setLoading } = useLoader();

  const { mutate: calculateXmod } = useMutation({
    mutationFn: getXmodByFein,
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (data) => {
      // should always be a hit, but better safe than sorry
      const target = data.find(
        (employer) => employer.employer_locator === foundEmployerExp.locator,
      );

      if (target) {
        setState((prevState) => ({
          ...prevState,
          ...target.data,
        }));
      }
    },
    onError: (error) => {
      displayIntegrationErrorMessage(
        error,
        t('An error occurred while fetching the integration results.'),
      );
    },
  });

  useEffect(() => {
    const tmpFields =
      (fieldConfig?.exposure?.data as ProductWorkFlow[])?.find(
        (con) => con.code === expModWorkFlowName,
      )?.fields ?? [];

    setFields(tmpFields);
  }, [fieldConfig]);

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['exp_mod'],
      }),
    });
  };

  const commonValidations = {};

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema: yup.object({ ...commonValidations }),
    onSubmit: async (_values) => {},
  });

  const showLoader = useMemo(() => {
    const defaultLoading =
      quoteLoading ||
      fieldConfig?.policy?.loading ||
      exposureList?.[`${threeExposureNames.EMPLOYER}`].loading;

    return defaultLoading || isEmpty(foundEmployerExp);
  }, [foundEmployerExp, quoteLoading, exposureList, fieldConfig]);

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const exposureData = { ...state };

      await updateExposure(quoteDetail?.policy_locator!, foundEmployerExp.locator as string, {
        data: exposureData,
      });

      displayToastMessage('SUCCESS', t('The Exp. Mod. has been updated.'));
      handleQuery();
      getExposures(quoteDetail?.locator!, { page_size: 10000 }, threeExposureNames.EMPLOYER);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the Exp. Mod.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      handleUpdate();
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const handleEmitedInputChange = async ({ field }: { field: string; value: any }) => {
    switch (field) {
      default:
        break;
    }
  };

  const handleIntegrationFetch = async () => {
    const effectiveDate = quoteDetail?.characteristics?.[0]?.started_at!;

    if (validateExpModIntegrationsCanRun(underwritingQuestionsState, effectiveDate)) {
      // XX-XXXXXXX to XXXXXXXXX
      const fein = underwritingQuestionsState?.fein?.replaceAll('-', '');

      calculateXmod([
        {
          fein,
          state: EXP_MOD,
          policy_effective_date: effectiveDate,
          employer_locator: String(foundEmployerExp.locator),
        },
      ]);
    } else {
      displayToastMessage(
        'WARNING',
        t(
          'Experience Modification is not requested as the business does not meet the conditions to be rated on Experience Modification.',
        ),
      );
    }
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...(foundEmployerExp?.data ?? {}) }));

    if (employerExposures?.length && isEmpty(foundEmployerExp)) {
      handleQuery();
    }
  }, [foundEmployerExp]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: 2,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Experience Modification Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <ExpModFieldParser
            formik={formik}
            state={state}
            fields={fields}
            showLoader={showLoader}
            setState={setState}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            onChangeEmited={(emitedEvent) => handleEmitedInputChange(emitedEvent)}
            onIntegrationFetch={handleIntegrationFetch}
          />
        </Stack>
      }
      footer={
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Box />

          <Stack direction="row">
            {showLoader ? (
              <>
                <Skeleton animation="wave" width="60px" height={32} sx={{ mr: 1 }} />
                <Skeleton animation="wave" width="60px" height={32} />
              </>
            ) : (
              <>
                <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                  {t('Cancel')}
                </Button>
                <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                  {t('Save')}
                </Button>
              </>
            )}
          </Stack>
        </Box>
      }
    />
  );
};

export default ExpModeEditDrawer;
