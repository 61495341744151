/* eslint-disable dot-notation */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Skeleton, Stack } from '@mui/material';
import SubmissionDetailInfoTab from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/Info/Info';
import PreQualActionBar from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/PreQual/Actionbar';
import PreQualHeader from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/PreQual/Header';
import PreQualSubmissionDetailInfoTab from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/PreQual/Info';
import QuoteOrRenewalDetailHeader from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/QuoteOrRenewalDetailHeader';
import QuoteOrRenewalDetailTopActionBar from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/QuoteOrRenewalDetailTopActionBar';
import SkeletonList from 'components/SkeletonList';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useQuoteOrRenewal from 'hooks/useQuoteOrRenewal/useQuoteOrRenewal';
import { isNil } from 'lodash-es';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

const detailLoadingSkeleton = (
  <>
    <Stack gap={2} direction="row" sx={{ ml: 1 }}>
      <SkeletonList
        count={5}
        sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
        width="5%"
        height={28}
      />
    </Stack>

    <Box
      sx={{
        ml: 3,
        pr: 5,
        mt: 2.5,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Stack gap={4} sx={{ width: '60%' }} flexDirection="row">
        <Box sx={{ width: '70%' }}>
          <Skeleton animation="wave" width="40%" height={28} />

          <Stack gap={1} direction="row">
            <Skeleton animation="wave" width="100%" height={28} sx={{ maxWidth: '400px' }} />
            <Skeleton animation="wave" width="100%" height={28} sx={{ maxWidth: '80px' }} />
          </Stack>
        </Box>
        <Stack sx={{ width: '100%' }} justifyContent="end">
          <SkeletonList count={3} width="20%" height={28} sx={{ maxWidth: '125px' }} />
        </Stack>
      </Stack>

      <Box
        sx={{
          display: 'grid',
          flex: 1,
          width: 1,
          justifyItems: 'end',
        }}
      >
        <Stack gap={1} direction="row" sx={{ width: '100%' }} justifyContent="end">
          <SkeletonList count={2} width="20%" height={28} sx={{ maxWidth: '116px' }} />
        </Stack>

        <Stack gap={1} direction="row" sx={{ width: '100%' }} justifyContent="end">
          <SkeletonList count={2} width="20%" height={28} sx={{ maxWidth: '116px' }} />
        </Stack>
      </Box>
    </Box>

    <Stack gap={1} direction="row" sx={{ width: '100%', mx: 2.3, mt: 2 }}>
      <SkeletonList
        count={4}
        width="5%"
        height={28}
        sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
      />
    </Stack>

    <Stack gap={1} direction="row" sx={{ width: '100%', mx: 2.3, mt: 1 }}>
      <Box sx={{ width: '190px' }}>
        <SkeletonList
          count={15}
          width="100%"
          height={36}
          sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
        />
      </Box>

      <Box sx={{ width: '100%', ml: 3, mt: 1 }}>{PreQualQuestionsLoading()}</Box>
    </Stack>
  </>
);

const QuoteOrRenewalDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isPreQual } = useQuoteDetail();
  const { isRenewal } = useQuoteOrRenewal();

  return isNil(isPreQual) ? (
    detailLoadingSkeleton
  ) : (
    <>
      {!isRenewal && isPreQual ? (
        <>
          <PreQualActionBar />

          <PreQualHeader currentTab={0} quoteId={id} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: (theme) => `calc(100vh - ${theme.quoteDetailFixedItemsHeight})`,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <PreQualSubmissionDetailInfoTab />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <QuoteOrRenewalDetailTopActionBar />
          <QuoteOrRenewalDetailHeader currentTab={0} quoteId={id} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: (theme) => `calc(100vh - ${theme.quoteDetailFixedItemsHeight})`,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <SubmissionDetailInfoTab />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default QuoteOrRenewalDetail;
