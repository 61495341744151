import { Box, Button, Stack, Typography } from '@mui/material';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { threePolicyGroupNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { DriverDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/DriverDrawerFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface DriverDetailProps {
  isDrawerOpen: boolean;
}

const DriverDetailDrawer: FC<DriverDetailProps> = ({ isDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const DRIVER = url.driver;
  const {
    fields: fieldConfig,
    fields: {
      policy: { loading: fieldConfigLoading },
    },
    loading: quoteDetailLoading,
    loaded: quoteDetailLoaded,
    groups,
  } = useQuoteDetail();
  const [state, setState] = useState<any>({});

  const drivers: any[] = groups?.pol_drivers?.data ?? [];

  const driver = useMemo(
    () => drivers.find((dr) => dr.pol_drivers_id === DRIVER) ?? {},
    [drivers, DRIVER],
  );

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['driver', 'addDriver'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (quoteDetailLoaded && isEmpty(driver)) {
      displayToastMessage('ERROR', t('Driver not found.'));
      handleQuery();
    }
  }, [driver, quoteDetailLoaded]);

  const fields = useMemo(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])
        ?.find((con) => con.code === 'policy-details')
        ?.fields?.find((x) => x.code === threePolicyGroupNames.DRIVERS)?.nested_fields ?? [];

    return tmpFields as DynamicField[];
  }, [fieldConfig]);

  const formik = useFormik({
    initialValues: {
      ...driver,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setValues(driver);
    setState(driver);
  }, [driver]);

  const showLoader = fieldConfigLoading || quoteDetailLoading || isEmpty(formik.values);

  return (
    <DrawerComponent
      isContentScrollable
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={handleQuery}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Driver Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <DriverDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            showLoader={showLoader}
            setState={setState}
            isReadOnly
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default DriverDetailDrawer;
