import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  threeExposureNames,
} from 'common/constants';
import DataTable from 'components/DataTable';
import { primaryButtonStyle, tabTitleStyles, truncatedTextStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import { isEmpty } from 'lodash-es';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import AdditionalInterestsDrawer from './Drawers/AdditionalInterestsDrawer/AdditionalInterestsDrawer';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden?: boolean;
  is_sortable?: boolean;
  is_link?: boolean;
  link_type?: string | null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  minWidth?: number;
  flex?: number;
}

const AdditionalInterests: FC = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const themeHook = useTheme();
  const LOCATION = useLocation();
  const matches = useMediaQuery(themeHook.breakpoints.down('md'));
  const { exposureList, canEdit } = useEndorsementDetail();

  const handleDetail = (Id: number) => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { additional_interest: Id },
      }),
    });
  };

  const policyInformationExposure =
    exposureList?.[`${threeExposureNames.POLICY_INFORMATION}`]?.data?.[0] ?? null;

  const rows = useMemo(() => {
    const r =
      exposureList?.[`${threeExposureNames.POLICY_INFORMATION}`]?.data?.[0]?.data?.info_ai?.map(
        (ai) => ({
          ...ai,
          id: ai.is_newly_added ? `added-${ai.index}` : ai.locator ?? new Date(), // is_newly_added or locator must be received from BE, new Date() is a temporary fallback id solution until BE is fixed
        }),
      ) ?? [];

    return r as any[];
  }, [exposureList]);

  const isListLoading =
    exposureList?.[`${threeExposureNames.POLICY_INFORMATION}`]?.loading ?? false;

  const handleOpenAddDrawer = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addAdditionalInterest: true },
      }),
    });
  };

  const columns: IColumns[] = [
    {
      name: 'info_ai_name',
      display_name: t('Name'),
      flex: 2,
      type: 'string',
      is_sortable: true,
      is_link: true,
      minWidth: 140,
    },
    {
      name: 'info_ai_type',
      display_name: 'Type',
      flex: 2,
      type: 'string',
      is_sortable: false,
      minWidth: 200,
    },
  ];
  const additionalInterestsColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (field.is_link) {
        return (
          <Typography
            onClick={() => handleDetail(params.row.id)}
            sx={[
              truncatedTextStyle,
              {
                color: (theme) => theme.customColors.table.link,
                '&:hover': {
                  textDecoration: 'underline',
                },
                cursor: 'pointer',
              },
            ]}
            title={fieldValue}
          >
            {fieldValue}
          </Typography>
        );
      }

      return (
        <Typography sx={truncatedTextStyle} title={fieldValue}>
          {fieldValue || '-'}
        </Typography>
      );
    },
  }));

  const getRowHeight = useCallback(() => (matches ? 'auto' : null), [matches]);

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <AdditionalInterestsDrawer isEdit={canEdit} />
      <Box>
        <Box
          sx={{
            mb: 1.5,
          }}
        >
          <Typography sx={[tabTitleStyles]}>
            {quotePolicyEndorsementInfoTitlesDescriptions.ADDITIONAL_INTEREST.title()}
          </Typography>
        </Box>

        <Box sx={{ maxWidth: '600px' }}>
          <DataTable
            getRowId={(row) => row.id}
            columns={additionalInterestsColumns}
            rows={rows ?? []}
            loading={isListLoading}
            dynamicRowHeight={matches}
            getRowHeight={getRowHeight}
            columnVisibilityModel={createColumnVisibilityModel(columns)}
            pageSize={defaultRowVirtualization}
            hideFooterPagination={rows?.length! < defaultRowVirtualization}
          />
        </Box>

        {!isEmpty(policyInformationExposure) && canEdit && (
          <Box
            sx={{
              mb: 6,
            }}
          >
            <Button onClick={handleOpenAddDrawer} sx={[primaryButtonStyle]}>
              {t('Add Additional Interest')}
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default AdditionalInterests;
