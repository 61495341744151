import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { expModWorkFlowName, threeExposureNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { ExpModFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/ExpModFieldParser';
import { useFormik } from 'formik';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail/useEndorsementDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface ExpModeDetailDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
}

const ExpModeDetailDrawer: FC<ExpModeDetailDrawerProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const EXP_MOD = url.exp_mod;
  const { loading: endorsementLoading, exposureList, fields: fieldConfig } = useEndorsementDetail();

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  const foundEmployerExp = useMemo(
    () => employerExposures?.find((e) => e.data?.emp_state === EXP_MOD) ?? {},
    [employerExposures],
  );

  const [state, setState] = useState<any>({});

  const [fields, setFields] = useState<any[]>([]);

  useEffect(() => {
    const tmpFields =
      (fieldConfig?.exposure?.data as ProductWorkFlow[])?.find(
        (con) => con.code === expModWorkFlowName,
      )?.fields ?? [];

    setFields(tmpFields);
  }, [fieldConfig]);

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['exp_mod'],
      }),
    });
  };

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {},
  });

  const showLoader = useMemo(() => {
    const defaultLoading =
      endorsementLoading ||
      fieldConfig?.policy?.loading ||
      exposureList?.[`${threeExposureNames.EMPLOYER}`].loading;

    return defaultLoading || isEmpty(foundEmployerExp);
  }, [foundEmployerExp, endorsementLoading, exposureList, fieldConfig]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...(foundEmployerExp?.data ?? {}) }));

    if (employerExposures?.length && isEmpty(foundEmployerExp)) {
      handleQuery();
    }
  }, [foundEmployerExp]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Experience Modification Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <ExpModFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={showLoader}
            isEdit={false}
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default ExpModeDetailDrawer;
