import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ClassDetailDrawer from './Detail';
import ClassEditDrawer from './Edit';

export interface IClassDrawer {
  isEdit: boolean;
}
const ClassDrawer: FC<IClassDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isClassDrawerOpen, setIsClassDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.tab === 'wc_exposures' && (url?.wc || !isEmpty(url?.addWc))) {
      setIsClassDrawerOpen(true);
    } else if (url?.tab === 'wc_exposures') {
      setIsClassDrawerOpen(false);
    }
  }, [LOCATION]);

  return isClassDrawerOpen ? (
    isEdit ? (
      <ClassEditDrawer
        isDrawerOpen={isClassDrawerOpen}
        setIsDrawerOpen={setIsClassDrawerOpen}
        isAdd={!isEmpty(url?.addWc)}
      />
    ) : (
      <ClassDetailDrawer isDrawerOpen={isClassDrawerOpen} setIsDrawerOpen={setIsClassDrawerOpen} />
    )
  ) : (
    <></>
  );
};
export default ClassDrawer;
