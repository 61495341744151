import * as FullStory from '@fullstory/browser';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { fullStoryIntegration } from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

// providers
import { disableReactDevTools } from 'helpers/DisableDevToolsForProd';
import { AdvancedSearchProvider } from 'providers/AdvancedSearchProvider';
import { AgenciesProvider } from 'providers/AgenciesProvider';
import { AuthProvider } from 'providers/AuthProvider';
import { ConfigProvider } from 'providers/ConfigProvider';
import { DialogProvider } from 'providers/DialogProvider';
import { DocumentsProvider } from 'providers/DocumentsProvider';
import { EndorsementDetailProvider } from 'providers/EndorsementDetailProvider';
import { KeyValuesProvider } from 'providers/KeyValuesProvider';
import { LoaderProvider } from 'providers/LoaderProvider';
import { NotesProvider } from 'providers/NotesProvider';
import { PoliciesProvider } from 'providers/PoliciesProvider';
import { PolicyDetailProvider } from 'providers/PolicyDetailProvider';
import { QueuesProvider } from 'providers/QueuesProvider';
import { QuoteDetailProvider } from 'providers/QuoteDetailProvider';
import { QuotesProvider } from 'providers/QuotesProvider';
import { RouterPromptProvider } from 'providers/RouterPromptProvider';
import { SmartyAddressAutocompleteProvider } from 'providers/SmartyAddressAutocompleteProvider';
import { TaskAssignUsersProvider } from 'providers/TaskAssignUsersProvider';
import { TaskAssociatesProvider } from 'providers/TaskAssociatesProvider';
import { TasksProvider } from 'providers/TasksProvider';
import { UserProvider } from 'providers/UserProvider';

import { Auth0Provider } from '@auth0/auth0-react';

import Interceptors from 'helpers/AxiosInterceptor';

import { browserTracingIntegration } from '@sentry/react';
import { checkEnvVariables } from 'helpers/checkEnvVariables';
import { TabProvider } from 'providers/TabProvider';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationError from 'routes/ConfigurationError';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

import theme from './theme';

// Load i18n config
import 'i18n/config';
import ReactQueryClientProvider from './providers/react-query-client';

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_LICENSE ?? '');

if (import.meta.env.NODE_ENV === 'production') disableReactDevTools();

const renderProviders = (providers: any[], el: JSX.Element) =>
  providers
    .reverse()
    .reduce((CurrentEl, CurrentProvider) => <CurrentProvider>{CurrentEl}</CurrentProvider>, el);

const isFullStoryEnabled = import.meta.env.REACT_APP_FULLSTORY_ENABLED === 'true';

if (isFullStoryEnabled) {
  FullStory.init({ orgId: import.meta.env.REACT_APP_FULLSTORY_ORG_ID ?? '' });
}

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN ?? '',
  integrations: isFullStoryEnabled
    ? [
        browserTracingIntegration(),
        fullStoryIntegration(import.meta.env.REACT_APP_SENTRY_ORG_SLUG ?? '', {
          client: FullStory,
        }),
      ]
    : [browserTracingIntegration()],
  tracesSampleRate: import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? 0.05,
  enabled: window.location.hostname !== 'localhost',
  release: `${import.meta.env.MODE ?? ''}@${import.meta.env.REACT_APP_VERSION}`,
  dist: import.meta.env.MODE ?? '',
  environment: import.meta.env.MODE ?? '',
  ignoreErrors: [
    /ResizeObserver loop completed with undelivered notifications./i,
    'Non-Error promise rejection captured',
  ],
  beforeSend: (event, hint) => {
    const { response = null } = hint.originalException ?? ({} as any);

    if (response && response.status && response.status >= 400) {
      return null;
    }
    return event;
  },
});

const isEnvVariablesValid = checkEnvVariables();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  // @ts-ignore
  <BrowserRouter>
    <Auth0Provider
      domain={`${import.meta.env.REACT_APP_AUTH0_DOMAIN}`}
      clientId={`${import.meta.env.REACT_APP_AUTH0_CLIENT_ID}`}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/redirect`,
        audience: `${import.meta.env.REACT_APP_BACKEND_MAIN_API_URL}/`,
        scope: 'read:current_user update:current_user_metadata openid profile email',
      }}
      cacheLocation="localstorage"
    >
      {renderProviders(
        [
          ReactQueryClientProvider,
          LoaderProvider,
          AgenciesProvider,
          AuthProvider,
          UserProvider,
          QueuesProvider,
          ConfigProvider,
          DialogProvider,
          TabProvider,
          PoliciesProvider,
          PolicyDetailProvider,
          TasksProvider,
          QuotesProvider,
          TaskAssociatesProvider,
          TaskAssignUsersProvider,
          KeyValuesProvider,
          RouterPromptProvider,
          NotesProvider,
          DocumentsProvider,
          QuoteDetailProvider,
          EndorsementDetailProvider,
          SmartyAddressAutocompleteProvider,
          AdvancedSearchProvider,
        ],
        <>
          <Interceptors />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {isEnvVariablesValid ? <App /> : <ConfigurationError />}
            </ThemeProvider>
          </StyledEngineProvider>
        </>,
      )}
    </Auth0Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
