import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  threeExposureNames,
  uwQuestionGroupName,
  vehicleExposureVinRelatedFields,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { VehicleDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/VehicleDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import {
  deleteFromQueryStrings,
  updateVisibilityOfEstimatedCurrentValueField,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty, isNil, omit } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import QuoteLocationSelect from '../../../Inputs/LocationSelectInput';
import VehiclesUnderwritingQuestions from '../UnderwritingQuestions';

export interface VehicleDetailDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
}

const makeFieldTypesString = [
  ...vehicleExposureVinRelatedFields,
  'veh_body_category',
  'veh_body_subcategory',
];

const VehicleDetailDrawer: FC<VehicleDetailDrawerProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const VEHICLE_ID = url?.vehicle;
  const {
    fields: fieldConfig,
    data: quoteDetail,
    getExposure,
    exposureList,
    underwritingQuestionsState,
  } = useQuoteDetail();

  const uwQuestionsRef = useRef<{ underwritingQuestionAnswers: () => any }>();

  const vehicleExposures = exposureList?.[`${threeExposureNames.VEHICLE}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => vehicleExposures?.find((e) => e.locator === VEHICLE_ID) ?? {},
    [vehicleExposures, VEHICLE_ID],
  );

  const [state, setState] = useState<any>({ ...(activeExposure?.data ?? {}) });
  const [fields, setFields] = useState<any[]>([]);

  const handleQuery = () => {
    setIsDrawerOpen(false);

    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['vehicle', 'addVehicle'],
      }),
    });
  };

  const formik = useFormik<any>({
    initialValues: {},
    onSubmit: () => {},
  });

  useEffect(() => {
    const vehicleFields =
      (fieldConfig?.exposure?.data as ProductWorkFlow[])?.find(
        (config) => config.code === threeExposureNames.VEHICLE,
      )?.fields ?? [];

    setFields(
      vehicleFields.map((f) => {
        const tmpField = { ...f };

        // make fields read only
        if (makeFieldTypesString.includes(f.code ?? '')) {
          tmpField.type = 'string';
        }

        if (tmpField.code === 'veh_stated_amt' && !tmpField.is_hidden) {
          tmpField.is_optional = false;
        }

        // make veh_gvw required if veh_type is TTT
        if (tmpField.code === 'veh_gvw' && state?.veh_type === 'TTT') {
          tmpField.is_optional = false;
        }

        return tmpField;
      }),
    );
  }, [fieldConfig, state?.veh_type]);

  const fetchExposureDetail = async () => {
    try {
      const detail = await getExposure(quoteDetail?.policy_locator!, VEHICLE_ID as string);

      setState((prevState) => ({ ...prevState, ...(detail?.data ?? {}) }));
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'vehicle',
        }),
      );
      handleQuery();
    }
  };

  useEffect(() => {
    if (!isEmpty(quoteDetail)) {
      // if exposure was found in the exposures list, do not fetch exposure detail from BE
      if (isEmpty(state)) {
        fetchExposureDetail();
      }

      setState((prevState) => ({
        ...prevState,
        date_added: formatDateInTimeZone(quoteDetail?.characteristics?.[0].started_at),
      }));
    }
  }, [quoteDetail]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...(activeExposure?.data ?? {}) }));
  }, [activeExposure]);

  useEffect(() => {
    formik.setValues(state ?? {});
  }, [state]);

  useEffect(() => {
    if (!isNil(state.veh_msrp)) {
      updateVisibilityOfEstimatedCurrentValueField(state, setFields);
    }
  }, [state.veh_msrp, state.veh_vin_isvalid]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      fieldConfig?.exposure?.loading ||
      exposureList?.[`${threeExposureNames.LOCATION}`].loading ||
      exposureList?.[`${threeExposureNames.VEHICLE}`].loading;

    return defaultLoading || isEmpty(activeExposure);
  }, [activeExposure, fieldConfig]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="800px"
      onClose={handleQuery}
      headerSx={{ mb: 2 }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Vehicle Details')}
        </Typography>
      }
      content={
        <Stack gap={2} sx={{ mb: 3, pt: 1 }}>
          <VehicleDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            isEdit={false}
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
            showLoader={showLoader}
            LocationSelect={QuoteLocationSelect}
          />

          {!showLoader && (
            <VehiclesUnderwritingQuestions
              initialData={{
                ...omit(underwritingQuestionsState, uwQuestionGroupName),
                ...omit(state, uwQuestionGroupName),

                // map answered questions to state
                ...((state?.underwriting_question as any[])?.reduce(
                  (c, q) => ({ ...c, [`${q.uwq_question_id}`]: q.uwq_question_answer }),
                  {},
                ) ?? {}),
              }}
              isReadOnly
              formik={formik}
              ref={uwQuestionsRef}
            />
          )}
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default VehicleDetailDrawer;
