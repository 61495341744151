import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import OwnersMembersDetailDrawer from './Detail';

export interface IOwnersMembersDrawer {
  position: string;
}

const OwnersMembersDrawer: FC<IOwnersMembersDrawer> = ({ position }) => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isOwnersMembersDrawerOpen = url?.tab === 'owners_members' && !isEmpty(url?.owner_member);

  return isOwnersMembersDrawerOpen ? (
    <OwnersMembersDetailDrawer
      isDrawerOpen={isOwnersMembersDrawerOpen ?? false}
      position={position}
    />
  ) : (
    <></>
  );
};
export default OwnersMembersDrawer;
