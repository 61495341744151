/* eslint-disable no-lonely-if */
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  LEXIS_NEXIS_SCORE_TYPES,
  productCodes,
  stateList,
  threeExposureNames,
  threePolicyGroupNames,
  userRoles,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { OwnersMembersDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/OwnersMembersDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { fetchLexisNexisScore } from 'helpers/Integrations/LexisNexis';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  changeFieldsHiddenStatus,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
  makeFieldsReadonly,
  makeFieldsRequired,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser';
import { isEmpty, isNil, omit } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import EndorsementLocationSelect from '../../../Inputs/LocationSelectInput';

export interface OwnersMembersEditProps {
  isDrawerOpen: boolean;
  isAdd?: boolean;
  position: string;
}

const OwnersMembersEditDrawer: FC<OwnersMembersEditProps> = ({
  isDrawerOpen,
  isAdd = false,
  position,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const OWNER_MEMBER = url.owner_member;
  const {
    data: endorsementDetail,
    loaded: endorsementLoaded,
    exposureList,
    groups,
    fields: fieldConfig,
    updateEndorsementDetail,
  } = useEndorsementDetail();
  const { data: user } = useUser();

  const [fields, setFields] = useState<any[]>([]);

  const [state, setState] = useState<any>({});

  const isProductCodeThreeWithWorkersCompensation =
    endorsementDetail?.policy?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION;

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const owners = groups?.pol_oo?.data ?? [];

  const isGroupNewlyAdded = useMemo(() => {
    const isNewlyAdded = OWNER_MEMBER?.includes('added-');
    const index = isNewlyAdded ? Number(OWNER_MEMBER?.slice(6)) : undefined;

    return { isNewlyAdded, index };
  }, [OWNER_MEMBER]);

  const owner = useMemo(() => {
    const { isNewlyAdded, index } = isGroupNewlyAdded;
    return (
      owners.find((ow) => (isNewlyAdded ? ow.index === index : ow.locator === OWNER_MEMBER)) ?? {}
    );
  }, [owners, OWNER_MEMBER]);

  const isC212ScoreInFieldsList = useMemo(
    () => fields.some((field) => field.code === 'pol_oo_c212_score'),
    [fields],
  );

  const { loading, setLoading } = useLoader();
  const { setDialogOpen } = useDialog();

  useEffect(() => {
    let tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])?.[0]?.fields?.find(
        (f) => f.code === threePolicyGroupNames.OWNER,
      )?.nested_fields ?? [];

    // if this is the first owner. Make it default primary and don't let user to uncheck it.
    if (isAdd && owners?.length === 0) {
      tmpFields = makeFieldsReadonly(tmpFields, ['pol_oo_isprimary']);

      setState((prevState) => ({
        ...prevState,
        pol_oo_isprimary: 'Yes',
      }));
    } else {
      // if this is an edit mode and selected location is primary make field readonly
      if (!isAdd && owner.pol_oo_isprimary === 'Yes') {
        tmpFields = makeFieldsReadonly(tmpFields, ['pol_oo_isprimary']);
      }
    }

    if (!isAdd && isUnderwriter) {
      const integrationFields: string[] = [];

      if (owner.pol_oo_isprimary === 'Yes') {
        integrationFields.push('pol_oo_b505');
        integrationFields.push('pol_oo_ncf_score');
        integrationFields.push('pol_oo_c212_score');

        tmpFields = changeFieldsHiddenStatus(tmpFields, integrationFields, false);
      }
    }

    const addressStateIndex = tmpFields.findIndex((i) => i.code === 'pol_oo_address_state');
    const addressStateField = tmpFields[addressStateIndex];

    if (addressStateField?.additional_data?.setDefaultStateListAsChoices) {
      addressStateField.choices = stateList.map(({ code, name }) => ({ code, name })) ?? [];
    }

    setFields(tmpFields);
  }, [fieldConfig]);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['owner_member', 'add'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (!loading && endorsementLoaded && isEmpty(owner) && !isAdd) {
      displayToastMessage('ERROR', t('Owner not found.'));
      handleQuery();
    }
  }, [owner, endorsementLoaded]);

  const deleteDialog = useMemo(() => {
    switch (position) {
      case 'Owner':
        return 'DELETE_OWNER';
      case 'LLC Member':
        return 'DELETE_LLC_MEMBER';
      case 'Officer':
        return 'DELETE_OFFICER';
      case 'Partner':
        return 'DELETE_PARTNER';

      default:
        return 'DELETE_OWNER';
    }
  }, [position]);

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };
    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (_values) => {},
  });

  const updatePrimaryFields = (isReadOnly = false) => {
    setFields((prevFields) => {
      let newFields = makeFieldsReadonly(
        prevFields,
        prevFields.flatMap((f) => (f?.additional_data?.pol_oo_isprimary_related ? f?.code : [])),
        isReadOnly,
      );
      newFields = makeFieldsRequired(
        newFields,
        [
          'pol_oo_address_line1',
          'pol_oo_dob',
          'pol_oo_address_city',
          'pol_oo_address_state',
          'pol_oo_address_zip',
        ],
        isReadOnly,
      );
      return newFields;
    });
  };

  useEffect(() => {
    if (isEmpty(state)) {
      setState((prevState) => ({ ...prevState, ...owner }));
    }
  }, [owner, isAdd, isUnderwriter]);

  useEffect(() => {
    const { pol_oo_isprimary } = state;
    updatePrimaryFields(!!(isNil(pol_oo_isprimary) || pol_oo_isprimary === 'No'));
  }, [state, fieldConfig]);

  // Ncf score relies on owner data so reset ncf score when owner data is changed
  // Ncf score will be fetched on coverage summary tab before getting the price indication
  useEffect(() => {
    const { pol_oo_isprimary } = state;
    if (formik.dirty && pol_oo_isprimary && !isAdd) {
      formik.setValues({ ...formik.values, pol_oo_ncf_score: '' });
      setState((prevState) => ({
        ...prevState,
        pol_oo_ncf_score: '',
      }));
    }
  }, [formik.dirty]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      !endorsementLoaded ||
      !fieldConfig?.policy?.loaded ||
      !exposureList?.[`${threeExposureNames.LOCATION}`].loaded;

    return isAdd ? defaultLoading : defaultLoading || isEmpty(state);
  }, [isAdd, state, endorsementLoaded, exposureList, fieldConfig]);

  const handleAdd = async () => {
    const trimmedStateValues = {
      ...state,
      pol_oo_address_city: state?.pol_oo_address_city?.trim(),
      pol_oo_address_line1: state?.pol_oo_address_line1?.trim(),
      pol_oo_address_line2: state?.pol_oo_address_line2?.trim(),
      pol_oo_address_state: state?.pol_oo_address_state?.trim(),
      pol_oo_address_zip: state?.pol_oo_address_zip?.trim(),
    };
    try {
      setLoading(true);

      const [B505, C212] = await Promise.all([
        fetchLexisNexisScore(LEXIS_NEXIS_SCORE_TYPES.B505, state, isUnderwriter, setLoading),
        isC212ScoreInFieldsList
          ? fetchLexisNexisScore(LEXIS_NEXIS_SCORE_TYPES.C212, state, isUnderwriter, setLoading)
          : Promise.resolve(null),
      ]);

      if (isUnderwriter && (B505.errored || (isC212ScoreInFieldsList && C212?.errored))) {
        return;
      }

      let tmpOwners = [...owners];

      // Update pol_oo_isprimary of other owners when new primary owner is added
      if (state.pol_oo_isprimary === 'Yes') {
        tmpOwners = tmpOwners.map((o) => {
          return {
            ...o,
            pol_oo_isprimary: 'No',
          };
        });
      }

      tmpOwners.push({
        ...trimmedStateValues,
        ...(B505.success && B505.score !== null ? { pol_oo_b505: B505.score } : {}),
        ...(isC212ScoreInFieldsList && C212?.success && C212?.score !== null
          ? { pol_oo_c212_score: C212.score }
          : {}),
        ...(isProductCodeThreeWithWorkersCompensation ? { pol_oo_wc_excluded: 'No' } : {}),
      });

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.OWNER}`]: tmpOwners?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        { validate: threePolicyGroupNames.OWNER, strategy: 'create' },
      );

      displayToastMessage('SUCCESS', t('The owner has been added.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the owner.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: deleteDialog,
        isOpen: false,
      });
      setLoading(true);

      let tmpOwners = [...owners];
      const rowIndex = tmpOwners.findIndex((row) => row.locator === owner.locator);
      tmpOwners.splice(rowIndex, 1);
      // locator will not be sent in owner/officers added in endorsement owners/officers
      tmpOwners = tmpOwners?.map((r) =>
        r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
      );

      const query = owner?.isNewAddedOnEndorsement
        ? { validate: threePolicyGroupNames.OWNER, strategy: 'create' }
        : { validate: threePolicyGroupNames.OWNER, strategy: 'update', locator: owner.locator };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.OWNER}`]: tmpOwners,
          },
        },
        query,
      );

      displayToastMessage('SUCCESS', t('The owner has been deleted.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the owner.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    const trimmedStateValues = {
      ...state,
      pol_oo_address_city: state?.pol_oo_address_city?.trim(),
      pol_oo_address_line1: state?.pol_oo_address_line1?.trim(),
      pol_oo_address_line2: state?.pol_oo_address_line2?.trim(),
      pol_oo_address_state: state?.pol_oo_address_state?.trim(),
      pol_oo_address_zip: state?.pol_oo_address_zip?.trim(),
    };
    try {
      setLoading(true);

      const [B505, C212] = await Promise.all([
        fetchLexisNexisScore(LEXIS_NEXIS_SCORE_TYPES.B505, state, isUnderwriter, setLoading),
        isC212ScoreInFieldsList
          ? fetchLexisNexisScore(LEXIS_NEXIS_SCORE_TYPES.C212, state, isUnderwriter, setLoading)
          : Promise.resolve(null),
      ]);

      if (isUnderwriter && (B505.errored || (isC212ScoreInFieldsList && C212?.errored))) {
        return;
      }

      let tmpOwners = [...owners];
      const rowIndex = tmpOwners.findIndex((row) => row.locator === owner.locator);
      tmpOwners[rowIndex] = {
        ...trimmedStateValues,
        ...(B505.success && B505.score !== null ? { pol_oo_b505: B505.score } : {}),
        ...(isC212ScoreInFieldsList && C212?.success && C212?.score !== null
          ? { pol_oo_c212_score: C212.score }
          : {}),
      };

      // Update pol_oo_isprimary of other owners when new primary owner is added
      if (state.pol_oo_isprimary === 'Yes') {
        tmpOwners = tmpOwners.map((o, i) => {
          return { ...o, ...(rowIndex !== i ? { pol_oo_isprimary: 'No' } : {}) };
        });
      }

      const query = owner?.isNewAddedOnEndorsement
        ? { validate: threePolicyGroupNames.OWNER, strategy: 'create' }
        : { validate: threePolicyGroupNames.OWNER, strategy: 'update', locator: owner.locator };

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.OWNER}`]: tmpOwners?.map((r) =>
              r.isNewAddedOnEndorsement ? omit(r, ['isNewAddedOnEndorsement', 'locator']) : r,
            ),
          },
        },
        query,
      );

      displayToastMessage('SUCCESS', t('The owner has been updated.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the owner.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const handleEmitedInputChange = async ({ field, value }: { field: string; value: any }) => {
    switch (field) {
      case 'pol_oo_isprimary': {
        if (value === 'No') {
          const relatedFields = fields.flatMap((f) =>
            f?.additional_data?.pol_oo_isprimary_related ? f?.code : [],
          );

          // clear related field values
          setState((prevState) => ({
            ...prevState,
            ...relatedFields.reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
          }));
        }
        break;
      }

      default:
        break;
    }
  };

  const checkDeletable = () => {
    if (owner.pol_oo_isprimary === 'Yes') {
      setDialogOpen({
        dialog: 'PRIMARY_OWNER_DELETE_ENDORSEMENT',
        isOpen: true,
      });
    } else {
      setDialogOpen({
        dialog: deleteDialog,
        isOpen: true,
        onAccept: () => handleDelete(),
      });
    }
  };

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        width="476px"
        onClose={handleQuery}
        headerSx={{
          mb: 2,
        }}
        isContentScrollable
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('{{entityPosition}} Details', {
              entityPosition: position,
            })}
          </Typography>
        }
        content={
          <Stack sx={{ mb: 6, pt: 1 }}>
            <OwnersMembersDrawerFieldParser
              formik={formik}
              state={state}
              fields={fields}
              showLoader={showLoader}
              setState={setState}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
              onChangeEmited={(emitedEvent) => handleEmitedInputChange(emitedEvent)}
              LocationSelect={EndorsementLocationSelect}
            />
          </Stack>
        }
        footer={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {!isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="20%" height={32} />
              ) : (
                <Button onClick={checkDeletable} sx={[drawerFooterSecondaryButtonStyle]}>
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}

            <Stack direction="row">
              {showLoader ? (
                <>
                  <Skeleton animation="wave" width="60px" height={32} sx={{ mr: 1 }} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default OwnersMembersEditDrawer;
