import { Box, Stack, Typography } from '@mui/material';
import CheckCircleFilled from 'assets/images/CheckCircleFilled.svg';
import {
  productCodes,
  quoteExtraStatuses,
  quoteStatuses,
  submissionDetailInfoTabs,
  subTabsList,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import Head from 'components/Head';
import SkeletonList from 'components/SkeletonList';
import VerticalTabs from 'components/VerticalTabs';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import { useBlockRoute } from 'helpers/RouteBlocker';
import {
  findPositionTabClicked,
  isUWQuestionsResponseExtraStatusReferOrDecline,
  setNextPreviousTabUWSections,
} from 'helpers/Utils';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useRouterPrompt from 'hooks/useRouterPrompt/useRouterPrompt';
import useUser from 'hooks/useUser';
import { cloneDeep, isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { createRef, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

// #region Tabs
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import useQuoteOrRenewal from 'hooks/useQuoteOrRenewal/useQuoteOrRenewal';
import AdditionalInterests from './Tabs/AdditionalInterests';
import AutoExposures from './Tabs/AutoExposures';
import BusinessInfo from './Tabs/BusinessInfo';
import ClaimsHistory from './Tabs/ClaimsHistory';
import Drivers from './Tabs/Drivers';
import LiabilityExposures from './Tabs/LiabilityExposures';
import Locations from './Tabs/Locations';
import Operations from './Tabs/Operations';
import OwnersMembers from './Tabs/OwnersMembers';
import PricingBreakdown from './Tabs/PricingBreakdown';
import PropertyExposures from './Tabs/PropertyExposures';
import SummaryPricing from './Tabs/SummaryPricing';
import UWResults from './Tabs/UWResults';
import WCExposures from './Tabs/WCExposures';

// #endregion

const detailLoadingSkeleton = (
  <Stack gap={1} direction="row" sx={{ width: '100%', p: 3 }}>
    <Box sx={{ width: '190px' }}>
      <SkeletonList
        count={15}
        width="100%"
        height={36}
        sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
      />
    </Box>

    <Box sx={{ width: '100%', ml: 3, mt: 1 }}>{PreQualQuestionsLoading()}</Box>
  </Stack>
);

const tabs = [...Object.values(submissionDetailInfoTabs).map((v) => v)];

const SubmissionDetailInfoTab: FC = () => {
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const { tab } = qs.parse(LOCATION.search) as { tab: string };
  const {
    data,
    loading,
    getExposures,
    getFieldConfig,
    data: quoteDetail,
    getUnderwritingQuestions,
    getQuoteProgress,
    exposureList,
    canEdit,
    progress: { data: progressData, loading: progressLoading, loaded: progressLoaded },
    isHandleNextClicked,
  } = useQuoteDetail();

  const { quoteOrRenewalEntityType, quoteOrRenewalDetailPath } = useQuoteOrRenewal();

  const { data: user } = useUser();
  const { setWhen } = useRouterPrompt();

  const [positionTabClicked, setPositionTabClicked] = useState('');

  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const isProgressTabs = isProducer && canEdit;

  const isSubmissionCompleted =
    data?.state?.code === quoteStatuses.QUOTED || data?.state?.code === quoteStatuses.ACCEPTED;

  const isTriggeredRule = isUWQuestionsResponseExtraStatusReferOrDecline(
    quoteExtraStatuses,
    quoteDetail,
  );

  const vehicleExposures = exposureList?.[`${threeExposureNames.VEHICLE}`];
  const isDriverTabHidden = (exposureList?.[`${threeExposureNames.VEHICLE}`].data?.length || 0) > 5;

  const isProductCodeThreeWithWorkersCompensation =
    quoteDetail?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION;

  const businessInfoRef = createRef<{
    savePageInfo: (bool: boolean) => Promise<{}>;
    isDirty: () => {};
  }>();
  const operationsRef = createRef<{
    savePageInfo: ({
      triggerHistoryChange,
      saveWithoutValidation,
    }: {
      triggerHistoryChange: boolean;
      saveWithoutValidation: boolean;
      positionTabClicked: string;
    }) => Promise<{}>;
    isDirty: () => {};
  }>();
  const propertyExposuresRef = createRef<{
    savePageInfo: ({
      triggerHistoryChange,
      saveWithoutValidation,
    }: {
      triggerHistoryChange: boolean;
      saveWithoutValidation: boolean;
      positionTabClicked: string;
    }) => Promise<{}>;
    isDirty: () => {};
  }>();
  const wcExposuresRef = createRef<{
    validateWCExposures: ({
      validateWCExposures,
    }: {
      positionTabClicked: string;
      validateWCExposures: boolean;
    }) => Promise<{}>;
  }>();
  const summaryPricingRef = createRef<{
    savePageInfo: ({ saveWithoutValidation }: { saveWithoutValidation: boolean }) => Promise<{}>;
    isDirty: () => {};
  }>();

  const fetchExposures = async () => {
    try {
      await getExposures(id, { page_size: 10000 });
    } catch (error) {
      displayBackendErrorMessage(error);
    }
  };

  const quoteTabs = useMemo(
    () =>
      tabs
        .map((currTab) => {
          const tmpTab = cloneDeep(currTab);

          if (
            currTab.code === submissionDetailInfoTabs.PRICING_BREAKDOWN.code &&
            user?.role?.code !== userRoles.UNDERWRITER.code
          ) {
            tmpTab.disabled = true;
          }

          // unhide liablity tab for underwriters
          if (
            submissionDetailInfoTabs.LIABILITY_EXPOSURES.code === currTab.code &&
            user?.role?.code === userRoles.UNDERWRITER.code
          ) {
            tmpTab.hidden = false;
          }

          // hide Pricing Breakdown tab for producers
          if (
            submissionDetailInfoTabs.PRICING_BREAKDOWN.code === currTab.code &&
            user?.role?.code === userRoles.AGENT.code
          ) {
            tmpTab.hidden = true;
          }

          // Display Workers’ Comp tab for three_with_workers_compensation product
          if (
            submissionDetailInfoTabs.WC_EXPOSURES.code === currTab.code &&
            isProductCodeThreeWithWorkersCompensation
          ) {
            tmpTab.hidden = false;
          }

          // Hide Drivers Tab when more than 5 vehicles added
          if (currTab.code === submissionDetailInfoTabs.DRIVERS.code && isDriverTabHidden) {
            tmpTab.hidden = true;
          }

          // Display / Hide UW results sections
          if (
            subTabsList.includes(currTab.code) &&
            isProgressTabs &&
            !loading &&
            !progressLoading
          ) {
            const isShown = currTab.code === tab && isTriggeredRule;

            tmpTab.hidden = !isShown;
          }

          return tmpTab;
        })
        .filter((ta) => !ta.hidden),
    [
      tabs,
      user,
      quoteDetail,
      progressLoading,
      loading,
      tab,
      isHandleNextClicked,
      isDriverTabHidden,
    ],
  );

  const quoteTabsDependingOnRole = useMemo(() => {
    if (isProgressTabs) {
      return quoteTabs.map((quoteTab, index) => {
        const prevTab = quoteTabs[index - 1];
        const isSubTab = subTabsList.includes(quoteTab?.code);
        const subTabOpennedIndex = quoteTabs.findIndex((_tab) => subTabsList.includes(_tab.code));
        const isTabCurrCompleted = progressData?.[quoteTab?.code]?.is_completed;

        const isPrevTabCompleted = progressData?.[prevTab?.code]?.is_completed;

        const isPrevTabCompletedAndCurrTabNotCompletedButMatchesUrl =
          !isSubTab && isPrevTabCompleted && quoteTab?.code === tab;

        const isUWSectionVisible = isSubTab && isPrevTabCompleted;

        const isActive =
          isTabCurrCompleted ||
          isUWSectionVisible ||
          isPrevTabCompletedAndCurrTabNotCompletedButMatchesUrl;

        return {
          ...quoteTab,
          icon: progressData?.[quoteTab.code]?.is_completed ? (
            <Box width={20}>
              <ReactSVG className="filled-check-icon" src={CheckCircleFilled} />
            </Box>
          ) : (
            <Typography
              m={0}
              maxWidth={isSubTab ? 35 : 20}
              minWidth={isSubTab ? 25 : 20}
              sx={{
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: '16px',
                color: (theme) =>
                  isActive
                    ? theme.customColors.tabTextColor
                    : theme.customColors.tabTextColorDisabled,

                fontWeight: (theme) => theme.typography.body2.fontWeight,
              }}
            >
              {isSubTab
                ? `${index}.1`
                : index > subTabOpennedIndex && subTabOpennedIndex > 0
                ? index
                : index + 1}
            </Typography>
          ),
          disabled: !isActive,
          subTab: isSubTab,
        };
      });
    }
    return quoteTabs;
  }, [tabs, user, quoteDetail?.product?.code, progressLoading, quoteTabs, data?.state?.code]);

  const findTabWithIndex = (index: number) => quoteTabsDependingOnRole[index];

  const findIndexInTabs = (step: string) => {
    const found = quoteTabsDependingOnRole.findIndex((s) => s.code === step && !s.disabled);

    return found === -1 ? 0 : found;
  };

  const [activeTab, setActiveTab] = useState(findIndexInTabs(tab));

  useEffect(() => {
    setWhen(true);
    setTimeout(() => {
      fetchExposures();
    }, 100);
  }, []);

  useEffect(() => {
    if (isProgressTabs) {
      getQuoteProgress(id);
    }
  }, [isProgressTabs]);

  // to scroll on tab change
  useEffect(() => {
    const el = document?.getElementById('scroll-anchor');
    // Scroll to top on load
    setTimeout(() => {
      el?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 200);
  }, [activeTab]);

  // Get exposure field configs
  useEffect(() => {
    const getFieldConfigs = async () => {
      try {
        if (!isEmpty(quoteDetail?.product?.code)) {
          const productCode = quoteDetail?.product!.code!;
          const policyLocator = quoteDetail?.policy_locator;
          const query = { policy_locator: policyLocator };

          await Promise.all([
            getUnderwritingQuestions(productCode, 'policy', undefined, query),
            getFieldConfig(productCode, 'exposure', undefined, query),
            getFieldConfig(productCode, 'policy', undefined, query),
            getFieldConfig(productCode, 'peril', threeExposureNames.EMPLOYER, query),
            getFieldConfig(productCode, 'peril', threeExposureNames.LOCATION, query),
          ]);
        }
      } catch (error) {
        displayBackendErrorMessage(error);
      }
    };

    getFieldConfigs();
  }, [quoteDetail?.product?.code]);

  const handleTabChangeSave = async (
    currentTab: number,
    {
      isTabClicked = false,
      isNotValidated = false,
    }: { isTabClicked?: boolean; isNotValidated?: boolean },
  ) => {
    const tabCode = findTabWithIndex(currentTab)?.code;

    if (canEdit) {
      // when chaning tab from business info or operations, save the page informations
      switch (tabCode) {
        case submissionDetailInfoTabs.BUSINESS_INFO.code: {
          await businessInfoRef?.current?.savePageInfo(false);
          break;
        }

        case submissionDetailInfoTabs.OPERATIONS.code: {
          await operationsRef?.current?.savePageInfo({
            triggerHistoryChange: false,
            saveWithoutValidation: isTabClicked && isNotValidated,
            positionTabClicked,
          });
          break;
        }

        case submissionDetailInfoTabs.PROPERTY_EXPOSURES.code: {
          await propertyExposuresRef?.current?.savePageInfo({
            triggerHistoryChange: false,
            saveWithoutValidation: isTabClicked && isNotValidated,
            positionTabClicked,
          });
          break;
        }

        case submissionDetailInfoTabs.WC_EXPOSURES.code: {
          await wcExposuresRef?.current?.validateWCExposures({
            positionTabClicked,
            validateWCExposures: false,
          });
          break;
        }

        case submissionDetailInfoTabs.SUMMARY_PRICING.code: {
          await summaryPricingRef?.current?.savePageInfo({
            saveWithoutValidation: isTabClicked && isNotValidated,
          });
          break;
        }

        default:
          break;
      }
    }
  };

  const updateTab = async (currentTab: number, nextTab: string) => {
    try {
      if (nextTab && findTabWithIndex(currentTab)?.code !== nextTab) {
        await handleTabChangeSave(currentTab, {
          isTabClicked: true,
          isNotValidated: isProducer,
        });
      }

      setActiveTab(
        isSubmissionCompleted && !nextTab
          ? findIndexInTabs(submissionDetailInfoTabs.SUMMARY_PRICING.code)
          : findIndexInTabs(nextTab) ?? 0,
      );

      return null;
    } catch (_error) {
      return null;
    }
  };
  // Producer will see different vertical tab dependent on progress data api when canEdit is true.
  // On other cases vertical tabs are hardcoded with no dependency.
  const isVerticalTabLoaded = isProgressTabs ? progressLoaded : true;

  useEffect(() => {
    if (!isProducer || (isProducer && !canEdit)) {
      if (!tab && isSubmissionCompleted) {
        HISTORY.replace({ search: `?tab=${submissionDetailInfoTabs.SUMMARY_PRICING.code}` });
      } else if (findIndexInTabs(tab) === 0) {
        HISTORY.replace({ search: `?tab=${submissionDetailInfoTabs.BUSINESS_INFO.code}` });
      }
    } else if (isProgressTabs && !progressLoading) {
      // Change query to the default tab. if the tab is not in the tabs list.
      if (!tab && isSubmissionCompleted) {
        HISTORY.replace({ search: `?tab=${submissionDetailInfoTabs.SUMMARY_PRICING.code}` });
      } else if (findIndexInTabs(tab) === 0) {
        HISTORY.replace({ search: `?tab=${submissionDetailInfoTabs.BUSINESS_INFO.code}` });
      }
    }

    setPositionTabClicked('');
    updateTab(activeTab, tab);
  }, [tab, progressLoading, canEdit, isProducer, isDriverTabHidden]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPositionTabClicked(
      findPositionTabClicked(
        findTabWithIndex(activeTab)?.code,
        findTabWithIndex(newValue)?.code,
        Object.values(submissionDetailInfoTabs),
      ),
    );
    HISTORY.push({ search: `?tab=${findTabWithIndex(newValue)?.code ?? ''}` });
  };

  // eslint-disable-next-line consistent-return
  const renderActiveTab = useMemo(() => {
    const tabCode = findTabWithIndex(activeTab)?.code;
    switch (tabCode) {
      case submissionDetailInfoTabs.BUSINESS_INFO.code:
        return <BusinessInfo ref={businessInfoRef} />;

      case submissionDetailInfoTabs.OPERATIONS.code:
        return <Operations ref={operationsRef} />;

      case submissionDetailInfoTabs.LIABILITY_EXPOSURES.code:
        return user?.role?.code === userRoles.UNDERWRITER.code ? <LiabilityExposures /> : <></>;

      case submissionDetailInfoTabs.PROPERTY_EXPOSURES.code:
        return <PropertyExposures ref={propertyExposuresRef} />;

      case submissionDetailInfoTabs.LOCATIONS.code:
        return <Locations />;

      case submissionDetailInfoTabs.DRIVERS.code:
        return <Drivers />;

      case submissionDetailInfoTabs.WC_EXPOSURES.code:
        return <WCExposures ref={wcExposuresRef} />;

      case submissionDetailInfoTabs.AUTO_EXPOSURES.code:
        return <AutoExposures />;

      case submissionDetailInfoTabs.OWNERS_MEMBERS.code:
        return <OwnersMembers />;

      case submissionDetailInfoTabs.CLAIMS_HISTORY.code:
        return <ClaimsHistory />;

      case submissionDetailInfoTabs.ADDITIONAL_INTEREST.code:
        return <AdditionalInterests />;

      case submissionDetailInfoTabs.UW_RESULTS.code:
      case submissionDetailInfoTabs.OPERATIONS_UW.code:
        return (
          <UWResults
            previousNextTab={setNextPreviousTabUWSections(findTabWithIndex(activeTab)?.code)}
          />
        );

      case submissionDetailInfoTabs.SUMMARY_PRICING.code:
        return <SummaryPricing ref={summaryPricingRef} />;

      case submissionDetailInfoTabs.PRICING_BREAKDOWN.code:
        return <PricingBreakdown />;

      default:
        return <></>;
    }
  }, [
    activeTab,
    loading,
    businessInfoRef,
    operationsRef,
    propertyExposuresRef,
    user,
    quoteTabs,
    quoteDetail,
  ]);

  const renderHead = useMemo(
    () =>
      loading && progressLoading ? (
        <Head title={t(`${quoteOrRenewalEntityType}`)} />
      ) : (
        <Head
          title={`${t(`${quoteOrRenewalEntityType}`)} - ${
            data?.policyholder?.name
          } - ${findTabWithIndex(activeTab)?.name?.()}`}
        />
      ),
    [activeTab, loading, progressLoading],
  );

  // block route change until save is done
  useBlockRoute(async (location) => {
    if (!location.pathname.includes(`${quoteOrRenewalDetailPath}/${id}/details`)) {
      try {
        if (
          businessInfoRef?.current?.isDirty() ||
          operationsRef?.current?.isDirty() ||
          propertyExposuresRef?.current?.isDirty() ||
          summaryPricingRef?.current?.isDirty()
        ) {
          await handleTabChangeSave(activeTab, {
            isNotValidated: isProducer,
          });
        }
        setWhen(false);
        return false;
      } catch (_error) {
        return true;
      }
    }

    return false;
  });

  return isVerticalTabLoaded && vehicleExposures?.loaded ? (
    <Stack direction="row" sx={{ height: '100%' }}>
      {renderHead}
      <Box
        sx={{
          height: '100%',
          flexShrink: 0,
          overflowY: 'auto',
          p: 3,
          pr: 2,
        }}
      >
        <VerticalTabs
          tabs={quoteTabsDependingOnRole}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          isProgressTabs={isProgressTabs}
          progressLoading={
            isProgressTabs
              ? progressLoading || vehicleExposures?.loading
              : vehicleExposures?.loading
          }
        />
      </Box>

      <Box
        sx={{ width: '100%', overflow: 'auto', padding: '4px', pt: 3.5, pl: 1.5 }}
        id="tabContentContanier"
      >
        <Box id="scroll-anchor" sx={{ width: '1px' }} />
        {renderActiveTab}
      </Box>
    </Stack>
  ) : (
    detailLoadingSkeleton
  );
};

export default SubmissionDetailInfoTab;
