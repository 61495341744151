/* eslint-disable no-undef */
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { IPrometrixData } from 'api/models/Integrations/Prometrix/addressRequest.model';
import { ZestyRequest } from 'api/models/Integrations/Zesty/ZestyRequest.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  getEstimatedReplacementCost,
  getIntegrationResults,
} from 'api/services/Integrations/Prometrix';
import { getWildfireScore } from 'api/services/Integrations/Zesty';
import { addExposure, deleteExposure, updateExposure } from 'api/services/NewQuote';
import {
  getQuotePrometrixResultsRequest,
  setQuotePrometrixResultsRequest,
} from 'api/services/Quote';
import {
  acceptedBdgOccupancy,
  propertyExposureAffectPremiumCalculationsFields,
  propertyExposureBuildingRelatedFields,
  propertyExposureRelatedKeyValues,
  propertyExposureRelatedKeyValuesMap,
  propertyExposureScheduleBuildingFields,
  propertyExposureScheduleBuildingOccupancies,
  propertyExposureScheduleBuildingOccupanciesKeyValue,
  propertyExposureScheduleBuildingValidationsFields,
  suggestedAllOtherPropertyField,
  threeExposureNames,
  userRoles,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { PropertyDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/PropertyDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  changeFieldsHiddenStatus,
  checkIfNonAlphaNumericCharacter,
  deleteFromQueryStrings,
  displayIntegrationErrorMessage,
  findOptionalFieldWithFieldCode,
  formatLocation,
  handleBackendErrorsWithFormik,
  isAllRequiredFieldsFilled,
  makeFieldsRequired,
  parseLocation,
  setBdgPrometrixHitValue,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty, isEqual, isNil, omit, pick } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import QuoteLocationSelect from '../../../Inputs/LocationSelectInput';

export interface PropertyDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  isAdd?: boolean;
  updateUnderwritingQuestions: (isPropertyTabHasUnderwritingQuestion: boolean) => void;
}

const scheduleBuildingFields = propertyExposureScheduleBuildingFields;

let uwReportRequestController: AbortController;
let estimatedCostRequestController: AbortController;

const PropertyEditDrawer: FC<PropertyDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isAdd = false,
  updateUnderwritingQuestions,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const PROPERTY_ID = url.property;
  const [callCount, setCallCount] = useState(isAdd ? 0 : 1);

  const { data: user } = useUser();

  const [integrationLoading, setIntegrationLoading] = useState(false);
  const [isChangePremiumAffectFields, setIsChangePremiumAffectFields] = useState(false);
  const [initialPrometrixResults, setInitialPrometrixResults] = useState<IPrometrixData>();

  const {
    data: quoteDetail,
    fields: fieldConfig,
    exposureList,
    getExposure,
    getExposures,
  } = useQuoteDetail();

  const [fields, setFields] = useState<any[]>([]);
  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();

  const {
    pol_tax_cf_default,
    pol_tax_cf_rule,
    pol_tax_bm_class,
    pol_bm_class_rule,
    pol_state,
    pol_op_erc_rule,
  } = quoteDetail?.characteristics?.[0]?.data!;

  // get location exposures from provider
  const locationExposures = useMemo(
    () =>
      exposureList?.[`${threeExposureNames.LOCATION}`]?.data?.map((ex) => ({
        ...ex,
        formattedAddress: formatLocation({
          addressLine1: ex.data?.loc_address_line1,
          addressLine2: ex.data?.loc_address_line2,
          city: ex.data?.loc_address_city,
          state: ex.data?.loc_address_state,
          zip: ex.data?.loc_address_zip,
        }).storing,
      })) ?? [],
    [exposureList],
  );

  // get property exposures from provider
  const propertyExposures = exposureList?.[`${threeExposureNames.BUILDING}`]?.data ?? [];

  // check if exposures have questions to own property
  const checkPropertyTabHasUnderwritingQuestion = (propertyExposuresState = propertyExposures) => {
    return propertyExposuresState
      ? propertyExposuresState.reduce((acc, exposure) => {
          const occupancy = exposure?.data?.bdg_occupancy;
          return acc || acceptedBdgOccupancy.includes(occupancy);
        }, false)
      : false;
  };

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => propertyExposures?.find((e) => e.locator === PROPERTY_ID) ?? {},
    [propertyExposures, PROPERTY_ID],
  );

  const { data: keyValueStore } = useKeyValues();

  const CLASS_CODES =
    keyValueStore?.[`${propertyExposureRelatedKeyValues.CLASS_CODES}`]?.data?.value ?? [];

  const mapPropertyValues = () => {
    return {
      bdg_class_code: pol_tax_cf_rule ?? pol_tax_cf_default,
      bdg_bm_occupancy: pol_bm_class_rule ?? pol_tax_bm_class,
      bdg_op_erc_rule: pol_op_erc_rule,
    };
  };

  const [state, setState] = useState<any>({ ...mapPropertyValues() });

  const bdgClassCodeUserChoices = useMemo(
    () =>
      Object.keys(CLASS_CODES ?? {})
        .sort()
        .map((code) => ({ code, name: CLASS_CODES[code] })),
    [CLASS_CODES],
  );

  const isVisibleRefreshERCButtonWarning =
    !isAllRequiredFieldsFilled(state, fields, propertyExposureAffectPremiumCalculationsFields) &&
    propertyExposureScheduleBuildingOccupancies.includes(state?.bdg_occupancy);

  const isIntegrationResultsChanged =
    propertyExposureBuildingRelatedFields.some(
      (fieldName) =>
        String(state?.[fieldName]) !== String(state?.initial_integration_result?.[fieldName]),
    ) || String(state?.bdg_erc_building) !== String(state?.bdg_rrc_building);

  useEffect(() => {
    let tmpFields =
      (fieldConfig?.exposure?.data as ProductWorkFlow[])?.find(
        (con) => con.code === threeExposureNames.BUILDING,
      )?.fields ?? [];

    // sets the required status if it is one of the propertyExposureScheduleBuildingOccupancies
    // when the application is first opened
    const isExposureScheduleBuildingOccupancyIncluded =
      propertyExposureScheduleBuildingOccupancies.includes(activeExposure?.data?.bdg_occupancy);

    tmpFields = tmpFields?.map((tmpField) => {
      if (propertyExposureScheduleBuildingValidationsFields.includes(tmpField.code as string)) {
        return { ...tmpField, is_optional: !isExposureScheduleBuildingOccupancyIncluded };
      }
      return tmpField;
    });

    tmpFields = [...tmpFields, suggestedAllOtherPropertyField]; // add new field to the fields

    if (isAdd) {
      const updatedStaticFields = tmpFields.map((field) => {
        if (field.code === 'bdg_location') {
          return { ...field, is_readonly: false };
        }

        return field;
      });

      tmpFields = updatedStaticFields;
    }

    if (user?.role?.code === userRoles.UNDERWRITER.code) {
      tmpFields = tmpFields.map((f) => {
        const tmpField = { ...f };

        if (tmpField.code === 'bdg_class_code') {
          tmpField.type = 'select';
          tmpField.choices = Object.keys(CLASS_CODES)
            .sort()
            .map((code) => ({ code, name: code }));
          tmpField.additional_data = {
            emitIsChangedEvent: true,
            showOnlyForRoles: ['underwriter'],
          };
        } else if (tmpField.code === 'bdg_class_code_user') {
          tmpField.type = 'autocomplete';
          tmpField.choices = bdgClassCodeUserChoices;
          tmpField.additional_data = {
            emitIsChangedEvent: true,
            showOnlyForRoles: ['underwriter'],
          };
        }

        return tmpField;
      });
    }

    if (
      !propertyExposureScheduleBuildingOccupancies.includes(activeExposure?.data?.bdg_occupancy)
    ) {
      setFields(changeFieldsHiddenStatus(tmpFields, scheduleBuildingFields, true));
    } else {
      setFields(changeFieldsHiddenStatus(tmpFields, scheduleBuildingFields, false));
    }
  }, [fieldConfig, activeExposure]);

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['property', 'addProperty'],
      }),
    });
  };

  const buildingNamesOnCurrentLocation = useMemo(
    () =>
      propertyExposures?.reduce(
        (buildingNames: string[], building) =>
          building?.data?.bdg_location === state.bdg_location
            ? [...buildingNames, building?.data?.bdg_name]
            : buildingNames,
        [],
      ),
    [state.bdg_location],
  );

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state, user?.role?.code),
    bdg_name: yup
      .string()
      .test(
        'unique per location',
        'Building name already in use for the selected location.',
        (value = '') => {
          const _value = [...value]
            .map((char) => (checkIfNonAlphaNumericCharacter(char) ? `\\${char}` : char))
            .join('');

          if (isAdd || activeExposure?.data?.bdg_name !== value) {
            return !buildingNamesOnCurrentLocation?.some((s) =>
              new RegExp(`^${_value}$`, 'i').test(s),
            ) as boolean;
          } else {
            return (
              buildingNamesOnCurrentLocation?.filter((s) => new RegExp(`^${_value}$`, 'i').test(s))
                .length <= 1
            );
          }
        },
      )
      .required('This field cannot be left blank.'),
  };

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema: yup.object({ ...commonValidations }),
    onSubmit: async (_values) => {},
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...{
        ...(activeExposure?.data ?? {}),
        bdg_class_code: pol_tax_cf_rule ?? pol_tax_cf_default,
        bdg_bm_occupancy: pol_bm_class_rule ?? pol_tax_bm_class,
      },
    }));
  }, [activeExposure]);

  useEffect(() => {
    const temp_bdg_class_descr = state.bdg_class_code_user ?? pol_tax_cf_rule ?? pol_tax_cf_default;

    setState((prevState) => ({
      ...prevState,
      bdg_class_descr: CLASS_CODES?.[`${temp_bdg_class_descr}`],
    }));
  }, [state.bdg_class_code_user]);

  useEffect(() => {
    const temp_bdg_class_descr = state.bdg_class_code_user ?? pol_tax_cf_rule ?? pol_tax_cf_default;

    if (isAdd && !isEmpty(CLASS_CODES)) {
      setState((prevState) => ({
        ...prevState,
        bdg_class_descr: CLASS_CODES?.[`${temp_bdg_class_descr}`],
      }));
    }
  }, [CLASS_CODES]);

  useEffect(() => {
    formik.setValues(state ?? {});
  }, [state]);

  const fetchExposureDetail = async () => {
    const temp_bdg_class_descr = state.bdg_class_code_user ?? pol_tax_cf_rule ?? pol_tax_cf_default;

    try {
      const detail = await getExposure(quoteDetail?.policy_locator!, PROPERTY_ID as string);

      setState((prevState) => ({
        ...prevState,
        ...{ ...(detail?.data ?? {}), bdg_class_descr: CLASS_CODES?.[`${temp_bdg_class_descr}`] },
      }));
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'property',
        }),
      );
      handleQuery();
    }
  };

  useEffect(() => {
    if (!isAdd && !isEmpty(quoteDetail)) {
      // if exposure was finded in the exposures list, do not fetch exposure detail from BE
      if (isEmpty(state)) {
        fetchExposureDetail();
      }
    }
  }, [isAdd, quoteDetail]);

  useEffect(() => {
    setBdgPrometrixHitValue(state, setState, isIntegrationResultsChanged);
  }, [
    isIntegrationResultsChanged,
    state?.bdg_occupancy,
    state?.bdg_erc_building,
    state?.bdg_uw_report_type,
  ]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      !fieldConfig?.exposure?.loaded ||
      !exposureList?.[`${threeExposureNames.LOCATION}`].loaded ||
      !exposureList?.[`${threeExposureNames.BUILDING}`].loaded ||
      !propertyExposureRelatedKeyValuesMap.some((map) => keyValueStore?.[`${map}`]?.loaded ?? true);

    return isAdd ? defaultLoading : defaultLoading || isEmpty(activeExposure);
  }, [isAdd, activeExposure, fieldConfig, exposureList, keyValueStore]);

  const getQuoteDetailAndExposures = async () => {
    const exposuresList = await getExposures(
      quoteDetail?.locator!,
      { page_size: 10000 },
      threeExposureNames.BUILDING,
      true,
    );

    const propertyExposuresList =
      exposuresList?.filter((e) => e.name === threeExposureNames.BUILDING).map((item) => item) ??
      [];
    // check if exposures have questions to own property
    const isPropertyTabHasUnderwritingQuestion = checkPropertyTabHasUnderwritingQuestion(
      propertyExposuresList as any[],
    );

    updateUnderwritingQuestions(isPropertyTabHasUnderwritingQuestion);
  };

  const handleAdd = async () => {
    try {
      setLoading(true);

      const res = await addExposure(quoteDetail?.policy_locator!, {
        name: threeExposureNames.BUILDING,
        data: omit(state, [
          'loc_violent_crime_score',
          'loc_property_crime_score',
          'loc_flood_zone',
          'bdg_class_code',
          'bdg_bm_occupancy',
          'initial_integration_result',
        ]),
        quote_locator: quoteDetail?.locator,
      });

      if (propertyExposureScheduleBuildingOccupancies.includes(state?.bdg_occupancy)) {
        setQuotePrometrixResultsRequest(quoteDetail?.locator!, {
          ...initialPrometrixResults,
          [res.locator]: { ...state.initial_integration_result },
        });
      }

      displayToastMessage('SUCCESS', t('The property has been added.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the property.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: 'DELETE_PROPERTY_EXPOSURE',
        isOpen: false,
      });
      setLoading(true);

      await deleteExposure(quoteDetail?.policy_locator!, PROPERTY_ID as string);

      displayToastMessage('SUCCESS', t('The property has been deleted.'));
      handleQuery();

      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the property.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      await updateExposure(quoteDetail?.policy_locator!, PROPERTY_ID as string, {
        data: omit(state, [
          'loc_violent_crime_score',
          'loc_property_crime_score',
          'loc_flood_zone',
          'bdg_class_code',
          'bdg_bm_occupancy',
          'initial_integration_result',
        ]),
      });

      if (
        (propertyExposureScheduleBuildingOccupancies.includes(state?.bdg_occupancy) &&
          !initialPrometrixResults?.[PROPERTY_ID as string]) ||
        !isEqual(initialPrometrixResults?.[PROPERTY_ID as string], state.initial_integration_result)
      ) {
        setQuotePrometrixResultsRequest(quoteDetail?.locator!, {
          ...initialPrometrixResults,
          [PROPERTY_ID as string]: { ...state.initial_integration_result },
        });
      }

      displayToastMessage('SUCCESS', t('The property has been updated.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the property.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      if (integrationLoading) {
        setDialogOpen({
          dialog: 'INTEGRATION_RUNNING',
          isOpen: true,
        });
      } else if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const updateLocationRelatedFields = (address: string) => {
    const foundLocation = locationExposures.find((ex) => ex.formattedAddress === address);

    if (foundLocation) {
      setState((prevState) => ({
        ...prevState,
        loc_flood_zone: foundLocation?.data?.loc_flood_zone,
        loc_violent_crime_score: foundLocation?.data?.loc_violent_crime_score,
        loc_property_crime_score: foundLocation?.data?.loc_property_crime_score,
        loc_dtc: foundLocation?.data?.loc_dtc,
        loc_250ft_flood_zone: foundLocation?.data?.loc_250ft_flood_zone,
      }));
    }
  };

  useEffect(() => {
    if (fields.length > 0) {
      setFields((prevState) =>
        prevState.map((f) => {
          const field = f;

          if (f.additional_data?.integration) {
            field.additional_data!.loading = integrationLoading;
          }

          return field;
        }),
      );
    }
  }, [integrationLoading]);

  const setLoadingRelatedFieldNames = (loading: boolean, ...fieldNames: string[]) =>
    setFields((prevState) =>
      prevState.map((f) => {
        const field = f;

        if ([...fieldNames].includes(f.code)) {
          field.additional_data!.loading = loading;
        }

        return field;
      }),
    );

  const callPrometrixForUWreport = async (
    { addressLine1, city, locationState, zip },
    occupancy: string,
    overwriteBuildingInputs = false,
    onlyChangePPC = false,
    isInitEditCall = false,
  ) => {
    // abort previous request if maded
    uwReportRequestController?.abort();
    uwReportRequestController = new AbortController();

    try {
      setCallCount((prevState) => prevState + 1);

      if (!isInitEditCall) {
        setIntegrationLoading(true);
        setLoadingRelatedFieldNames(true, 'bdg_occupancy');
      }

      const res = await getIntegrationResults(
        {
          StreetName: addressLine1,
          State: locationState,
          City: city,
          Zip: zip,
          fetchReplacementCosts: propertyExposureScheduleBuildingOccupancies.includes(occupancy),
        },
        uwReportRequestController,
      );

      setState((prevState) => {
        const tmpRes = isInitEditCall
          ? {}
          : !overwriteBuildingInputs
          ? omit(res, propertyExposureBuildingRelatedFields)
          : onlyChangePPC
          ? pick(res, ['bdg_protection_class'])
          : { ...res };

        if (!res.bdg_protection_class) {
          tmpRes.bdg_protection_class = 5;
        }

        return {
          ...prevState,
          // if rated replacement was edited do not change it
          ...(prevState.bdg_erc_building !== prevState.bdg_rrc_building
            ? omit(tmpRes, 'bdg_rrc_building')
            : tmpRes),
          initial_integration_result: res,
        };
      });

      setIntegrationLoading(false);
    } catch (error) {
      const e = error as any;

      setIntegrationLoading(e?.message === 'canceled');
      setCallCount((prevState) => prevState - 1);

      setState((prevState) => ({
        ...prevState,
        bdg_protection_class: 5,
      }));

      if (e?.message !== 'canceled') {
        displayIntegrationErrorMessage(
          error,
          t('An error occurred while fetching the integration results.'),
        );
      }
    } finally {
      setLoadingRelatedFieldNames(false, 'bdg_occupancy');
    }
  };

  const checkLocationAndRequestToPrometrix = async (
    location: string,
    occupancy: string,
    onlyChangePPC = false,
    isInitEditCall = false,
  ) => {
    if (!isEmpty(location)) {
      const { addressLine1, city, state: locationState, zip } = parseLocation(location);

      callPrometrixForUWreport(
        { addressLine1, city, locationState, zip },
        occupancy,
        callCount === 0,
        onlyChangePPC,
        isInitEditCall,
      );
    }
  };

  const isWildfireScoresEnabled = (locationState: string) => {
    const wildfireScore = fields?.find((field) => field.code === 'bdg_wildfire_l1score');
    if (!wildfireScore) return false;

    const { enabledStates } = wildfireScore?.additional_data;
    const isEnabledStates = enabledStates?.includes(locationState);

    return isEnabledStates;
  };

  const callZestyIntegration = async (body: ZestyRequest) => {
    try {
      setLoadingRelatedFieldNames(true, 'bdg_wildfire_l1score', 'bdg_wildfire_l2score');
      const { bdg_wildfire_l1score, bdg_wildfire_l2score } = await getWildfireScore(body);

      setState((prevState) => ({
        ...prevState,
        bdg_wildfire_l1score,
        bdg_wildfire_l2score,
      }));
    } catch (error) {
      displayIntegrationErrorMessage(error);
    } finally {
      setLoadingRelatedFieldNames(false, 'bdg_wildfire_l1score', 'bdg_wildfire_l2score');
    }
  };

  const handleEmitedInputChange = async ({
    field,
    value,
    oldValue,
  }: {
    field: string;
    value: any;
    oldValue?: any;
  }) => {
    switch (field) {
      case 'bdg_location': {
        updateLocationRelatedFields(value);

        const { bdg_occupancy } = state;

        const { addressLine1, city, state: locationState, zip } = parseLocation(value);

        if (isWildfireScoresEnabled(locationState)) {
          callZestyIntegration({
            state: locationState,
            has_building_coverage: true,
            location_type: 'address',
            location: value,
          });
        }

        // always run calculation if conditions met
        if (!isEmpty(bdg_occupancy)) {
          if (['Home/Apartment', 'Kiosk'].includes(bdg_occupancy)) {
            callPrometrixForUWreport(
              { addressLine1, city, locationState, zip },
              bdg_occupancy,
              callCount === 0,
              true,
            );
          } else {
            callPrometrixForUWreport(
              { addressLine1, city, locationState, zip },
              bdg_occupancy,
              true,
            );
          }
        }
        break;
      }

      case 'bdg_occupancy': {
        const { bdg_location } = state;

        if (!propertyExposureScheduleBuildingOccupancies.includes(value)) {
          // clear related field values
          setState((prevState) => ({
            ...prevState,
            ...scheduleBuildingFields.reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
          }));

          // hide releated fields
          setFields(() => {
            let newFields = changeFieldsHiddenStatus(fields, scheduleBuildingFields, true);

            // make fields required if user is not admin
            newFields = makeFieldsRequired(
              newFields,
              propertyExposureScheduleBuildingValidationsFields,
              true,
            );
            return newFields;
          });
        }

        switch (value) {
          case 'Home/Apartment':
          case 'Kiosk': {
            setState((prevState) => ({
              ...prevState,
              bdg_num_stories: 1,
              bdg_issprinklered: 'No',
              bdg_sqft: 100,
              bdg_construction_code: 1,
              bdg_wind_symbol: 'B',
              bdg_year_built: '',
            }));

            checkLocationAndRequestToPrometrix(bdg_location, value, true);

            break;
          }

          case propertyExposureScheduleBuildingOccupanciesKeyValue.BUILDING_LEASE:
          case propertyExposureScheduleBuildingOccupanciesKeyValue.BUILDING_OWN_RUN: {
            // show scheduleBuildingFields
            setFields(() => {
              let newFields = changeFieldsHiddenStatus(fields, scheduleBuildingFields, false);

              // make fields required if user is not admin
              newFields = makeFieldsRequired(
                newFields,
                propertyExposureScheduleBuildingValidationsFields,
              );

              return newFields;
            });

            if (!propertyExposureScheduleBuildingOccupancies.includes(oldValue)) {
              checkLocationAndRequestToPrometrix(bdg_location, value);
            }

            break;
          }

          case propertyExposureScheduleBuildingOccupanciesKeyValue.BUILDING_OWN_MANAGE: {
            // show scheduleBuildingFields
            setFields(() => {
              let newFields = changeFieldsHiddenStatus(fields, scheduleBuildingFields, false);

              // make fields required if user is not admin
              newFields = makeFieldsRequired(
                newFields,
                propertyExposureScheduleBuildingValidationsFields,
              );

              return newFields;
            });

            if (!propertyExposureScheduleBuildingOccupancies.includes(oldValue)) {
              checkLocationAndRequestToPrometrix(bdg_location, value);
            }

            break;
          }
          default: {
            checkLocationAndRequestToPrometrix(bdg_location, value);

            break;
          }
        }
        break;
      }

      case 'bdg_class_code_user': {
        setState((prevState) => ({
          ...prevState,
          bdg_class_descr: CLASS_CODES?.[`${value}`],
        }));
        break;
      }

      default:
        break;
    }
  };

  const setLoadingForScheduleBuildingIntegrationFields = (loading: boolean) =>
    setFields((prevState) =>
      prevState.map((f) => {
        const field = f;

        if (['bdg_erc_building', 'bdg_rrc_building'].includes(f.code)) {
          field.additional_data!.loading = loading;
        }

        return field;
      }),
    );

  const fetchEstimatedCost = async () => {
    // abort previous request if maded
    estimatedCostRequestController?.abort();
    estimatedCostRequestController = new AbortController();

    const {
      bdg_construction_code: ConstructionClass,
      bdg_num_stories: Stories,
      bdg_sqft,
      bdg_issprinklered,
      bdg_location,
      bdg_class_code,
      bdg_class_code_user,
    } = state;

    const emptyFields: string[] = [];

    // Check required fields for running the integration
    Object.entries({
      bdg_construction_code: ConstructionClass,
      bdg_num_stories: Stories,
      bdg_sqft,
      bdg_issprinklered,
      bdg_location,
      bdg_class_code,
    }).forEach(([key, value]) => {
      if (isNil(value)) {
        emptyFields.push(key);
      }
    });

    if (emptyFields.length) {
      displayToastMessage(
        'ERROR',
        <Box>
          {t('Following fields are required to run Estimated Replacement Cost integration.')}
          <Box component="ul">
            {emptyFields.map((f) => (
              <Box component="li" key={f}>
                {t(`Field '{{field}}' is required.`, {
                  field: fields.find((field) => field.code === f)?.name,
                })}
              </Box>
            ))}
          </Box>
        </Box>,
      );
    } else {
      const { addressLine1, city, state: locationState, zip } = parseLocation(bdg_location);

      try {
        setLoadingForScheduleBuildingIntegrationFields(true);

        const classCode = bdg_class_code_user
          ? bdg_class_code_user?.toString()?.padStart(4, '0')
          : bdg_class_code?.toString()?.padStart(4, '0');

        const res = await getEstimatedReplacementCost({
          CspOccupancy: classCode,
          ConstructionClass,
          Stories,
          TotalSquareFootage: bdg_sqft,
          SquareFootageOccupiedByInsured: bdg_sqft,
          Sprinklered: bdg_issprinklered === 'Yes',
          Address: {
            StreetName: addressLine1,
            State: locationState,
            City: city,
            Zip: zip,
          },
        });
        // if response is empty, set bdg_erc_building and bdg_rrc_building fields as optional to be able to save
        if (res.bdg_erc_building === '') {
          setFields((prevFields) =>
            prevFields.map((field) => {
              if (field.code === 'bdg_erc_building' || field.code === 'bdg_rrc_building') {
                return {
                  ...field,
                  is_optional: true,
                };
              }
              return field;
            }),
          );
          setState((prevState) => ({
            ...prevState,
            bdg_rrc_building: '',
          }));
        }
        setState((prevState) => ({
          ...prevState,
          ...res,
        }));
      } catch (error) {
        const e = error as any;

        if (e?.message !== 'canceled') {
          displayIntegrationErrorMessage(
            error,
            t('An error occurred while fetching the integration results.'),
          );
        }
      } finally {
        setLoadingForScheduleBuildingIntegrationFields(false);
        setIsChangePremiumAffectFields(false);
      }
    }
  };

  useEffect(() => {
    if (!showLoader) {
      updateLocationRelatedFields(state.bdg_location);
    }
  }, [showLoader]);

  // call updateLocationRelatedFields on initial load
  useEffect(() => {
    if (state.bdg_location && state.loc_violent_crime_score === undefined) {
      updateLocationRelatedFields(state.bdg_location);
    }
  }, [state.bdg_location]);

  useEffect(() => {
    // get quote prometrix results only for scheduled buildings
    if (
      propertyExposureScheduleBuildingOccupancies.includes(state?.bdg_occupancy) &&
      quoteDetail?.locator
    ) {
      const getQuotePrometrixResults = async (quotelocator: string) => {
        try {
          const res = await getQuotePrometrixResultsRequest(quotelocator);
          setInitialPrometrixResults({
            ...res?.['prometrix-data'],
          });

          if (res?.['prometrix-data']?.[PROPERTY_ID as string]) {
            setState((prevState) => ({
              ...prevState,
              initial_integration_result: res?.['prometrix-data']?.[PROPERTY_ID as string],
            }));
          } else if (!isAdd) {
            // if initial prometrix results is not in aux data call integration
            checkLocationAndRequestToPrometrix(
              state?.bdg_location,
              state?.bdg_occupancy,
              false,
              true,
            );
          }
        } catch (error) {
          displayBackendErrorMessage(error);
        }
      };

      getQuotePrometrixResults(quoteDetail?.locator);
    }
  }, [quoteDetail?.locator, state?.bdg_occupancy]);

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        width="476px"
        onClose={handleQuery}
        headerSx={{ mb: 2 }}
        isContentScrollable
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Property Details')}
          </Typography>
        }
        content={
          <Stack gap={2} sx={{ mb: 3, pt: 1 }}>
            <PropertyDrawerFieldParser
              formik={formik}
              state={state}
              polState={pol_state}
              fields={fields}
              setState={setState}
              isEdit
              splitSize={3}
              columnSpacing={0}
              showLoader={showLoader ?? true}
              rowSpacing={2}
              onReplacementCostFetch={() => fetchEstimatedCost()}
              onChangeEmited={(emitedEvent) => handleEmitedInputChange(emitedEvent)}
              LocationSelect={QuoteLocationSelect}
              setIsChangePremiumAffectFields={setIsChangePremiumAffectFields}
            />
          </Stack>
        }
        footer={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {!isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="20%" height={32} />
              ) : (
                <Button
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'DELETE_PROPERTY_EXPOSURE',
                      isOpen: true,
                      onAccept: () => handleDelete(),
                    })
                  }
                  sx={[drawerFooterSecondaryButtonStyle]}
                >
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}

            <Stack direction="row" gap={1.5}>
              {showLoader ? (
                <>
                  <Skeleton animation="wave" width="60px" height={32} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>

                  <Button
                    onClick={() => {
                      if (
                        isVisibleRefreshERCButtonWarning &&
                        !findOptionalFieldWithFieldCode(fields, 'bdg_erc_building') &&
                        !state.bdg_erc_building
                      ) {
                        setDialogOpen({
                          dialog: 'REFRESH_ERC_BUTTON',
                          isOpen: true,
                        });
                      } else if (isVisibleRefreshERCButtonWarning && isChangePremiumAffectFields) {
                        setDialogOpen({
                          dialog: 'REFRESH_ERC_BUTTON',
                          isOpen: true,
                        });
                      } else {
                        handleSave();
                      }
                    }}
                    sx={[drawerFooterPrimaryButtonStyle]}
                  >
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default PropertyEditDrawer;
