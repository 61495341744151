import { Box, Chip, Paper, Skeleton, Typography } from '@mui/material';
import { defaultDebounceDelay, userRoles } from 'common/constants';
import PageHeader from 'components/PageHeader';
import ViewSearch from 'components/ViewSearch';
import useDialog from 'hooks/useDialog';
import usePolicies from 'hooks/usePolicies';
import useUser from 'hooks/useUser';
import { debounce } from 'lodash-es';
import { IPoliciesPagination } from 'providers/PoliciesProvider/types';
import qs from 'query-string';
import { ChangeEvent, createRef, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface SubmissionStatusProps {
  id?: number;
  label?: string;
  code?: string;
  variant?: string;
}

const PoliciesHeader: FC = () => {
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const queryParams = qs.parse(LOCATION.search) as unknown as IPoliciesPagination;
  const [activeChip, setActiveChip] = useState<any>();
  const [search, setSearch] = useState(queryParams.search ?? '');
  const [submissionStatusList, setSubmissionStatusList] = useState<SubmissionStatusProps[]>([]);
  const { filterListArray, loading: policiesLoading } = usePolicies();
  const { setDialogOpen } = useDialog();
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  const searchInputRef = createRef<HTMLInputElement>();

  const handleClick = (chipId: number, isClicked: boolean = false) => {
    // checked status is mapped here
    const selectedChip = submissionStatusList.find((chip) => chip.id === chipId);
    const code = selectedChip?.code === 'total_count' ? '' : selectedChip?.code;

    // Canceled second request until the first request is completed
    if (policiesLoading && isClicked) {
      setDialogOpen({
        dialog: 'LOADING_POLICIES',
        isOpen: true,
      });
    } else {
      if (selectedChip) {
        setActiveChip(selectedChip);
      }
      // The request is sent according to the desired status
      // We simply push new status code to url, submissions list auto fetches data
      HISTORY.push({
        search: qs.stringify({
          ...queryParams,
          status: code,
          page: 1,
        }),
      });
    }
  };
  // the turn off the loading dialog when the chip data is loaded
  useEffect(() => {
    if (!policiesLoading) {
      setDialogOpen({
        dialog: 'LOADING_SUBMISSIONS',
        isOpen: false,
      });
    }
  }, [policiesLoading]);

  // Here, the active chip is selected for the default value at first.
  useEffect(() => {
    if (!activeChip && submissionStatusList.length > 0) {
      let selectedChip = submissionStatusList.find((chip) => chip.code === 'total_count');

      if (queryParams.status) {
        selectedChip = submissionStatusList.find((chip) => chip.code === queryParams.status);
        handleClick(selectedChip?.id!);
        setActiveChip(selectedChip);
      } else if (filterListArray?.loaded && selectedChip) {
        setActiveChip(selectedChip);
      }
    }
  }, [submissionStatusList]);

  // The data received here is parsed and converted into appropriate data.
  useEffect(() => {
    if (filterListArray?.loaded) {
      const changeStatusList = filterListArray!.data?.map((filter, index) => ({
        id: index,
        label: `${filter?.status?.value} \u00B7 ${filter?.count!}`,
        code: `${filter?.status?.key}`,
        variant: 'outlined',
        isSelected: filter?.status?.key === 'total_count',
      }));
      setSubmissionStatusList(changeStatusList || []);
    }
  }, [filterListArray]);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Returns the encoded form of the string. test& -> test%26.
    const encodedSearch = encodeURIComponent(event.target.value);

    setSearch(encodedSearch);
    HISTORY.push({ search: qs.stringify({ ...queryParams, search: encodedSearch, page: 1 }) });
  };

  useEffect(() => {
    // Returns the decoded form of the string. test%26 -> test&, etc.
    searchInputRef.current!.value = queryParams.search
      ? decodeURIComponent(queryParams.search)
      : '';
  }, [LOCATION.search]);

  return (
    <>
      <PageHeader
        sx={{
          px: 3,
        }}
        left={
          <Typography
            variant="h6"
            sx={{
              userSelect: 'none',
              color: (theme) => theme.customColors.pageHeader.title,
              fontWeight: '500',
            }}
          >
            {t('Policies')}
          </Typography>
        }
        right={
          <ViewSearch
            onChange={
              handleSearchInputChange
                ? debounce(handleSearchInputChange, defaultDebounceDelay)
                : () => {}
            }
            defaultValue={search ? decodeURIComponent(search) : search}
            textPlaceholder={t(
              isUnderwriter
                ? 'Search by Policy No, Named Insured, Producer, Team'
                : 'Search by Policy No, Named Insured',
            )}
            inputRef={searchInputRef}
          />
        }
      />
      <Box sx={{ mx: 4, mt: 1, mb: 3 }}>
        {filterListArray?.loading ? (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'row',
            }}
          >
            {[...Array(7).keys()].map((chip, i) => (
              <Skeleton animation="wave" width="5%" height={28} sx={{ marginRight: 1.5 }} key={i} />
            ))}
          </Box>
        ) : (
          <Paper
            sx={{
              paddingInlineStart: '0px',
              textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' },
            }}
            component="ul"
            elevation={0}
          >
            {submissionStatusList.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                variant="outlined"
                onClick={() => {
                  handleClick(chip?.id!, true);
                }}
                sx={{
                  m: 0.5,
                  fontSize: 14,
                  borderColor: (theme) => theme.customColors.chip.bg,
                  color:
                    activeChip?.id === chip.id
                      ? (theme) => theme.customColors.white50
                      : (theme) => theme.customColors.chip.bg,
                  backgroundColor:
                    activeChip?.id === chip.id
                      ? (theme) => theme.customColors.chip.itemClicked
                      : (theme) => theme.customColors.white50,
                  '&.MuiButtonBase-root': {
                    transition: 'background-color 300ms, color 300ms',
                    transitionDelay: '50ms',
                  },
                  '&.MuiButtonBase-root:hover': {
                    color: (theme) => theme.customColors.white50,
                    backgroundColor: (theme) => theme.customColors.chip.itemHover,
                  },
                  '& .MuiChip-label': {
                    px: '12px',
                  },
                }}
              />
            ))}
          </Paper>
        )}
      </Box>
    </>
  );
};

export default PoliciesHeader;
