/* eslint-disable @typescript-eslint/no-use-before-define */
import { MoreVert } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { GridAlignment, GridColDef, GridSortModel } from '@mui/x-data-grid';
import Delete from 'assets/images/icon_dropdowndelete.svg';
import Edit from 'assets/images/icon_dropdownedit.svg';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  globalSearchTypes,
} from 'common/constants';
import DataTable from 'components/DataTable';
import MenuComponent from 'components/MenuComponent';
import NoteDetailDrawer from 'components/Notes/Detail';
import EditNoteDrawer from 'components/Notes/EditNote';
import QuoteOrRenewalDetailHeader from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/QuoteOrRenewalDetailHeader';
import QuoteOrRenewalDetailTopActionBar from 'components/QuotesOrRenewals/QuoteOrRenewalDetail/QuoteOrRenewalDetailTopActionBar';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { dropdownItemsStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import useNotes from 'hooks/useNotes';
import { initialNotesData } from 'providers/NotesProvider/NotesProviderContext';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const QuoteDetailNotes: FC = () => {
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const themeHook = useTheme();
  const url = qs.parse(LOCATION.search);

  const { formatDateInTimeZone } = useConfig();
  const { setDialogOpen } = useDialog();
  const { data, resetNotesState, fetch, loading, pagination, deleteNote, reFetchData } = useNotes();

  const isEditNoteDrawerOpen = url?.edit_note_id !== undefined;
  const isDetailNoteDrawerOpen = url?.note !== undefined;
  const [pageNumber, setPage] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: 'subject', sort: 'asc' },
  ]);
  const [isClickedMoreButton, setIsClickedMoreButton] = useState<boolean>(false);

  const getAssociatedWithType = (type: string) => {
    switch (type) {
      case globalSearchTypes.POLICY:
        return 'policies';

      case globalSearchTypes.QUOTE:
        return 'quotes';

      default:
        return '';
    }
  };

  useEffect(() => {
    fetch(
      { ...initialNotesData.pagination, ordering: 'subject' },
      getAssociatedWithType(globalSearchTypes.QUOTE),
      id,
    );

    return () => {
      resetNotesState();
    };
  }, []);

  const handleDeleteNote = async (noteId: number) => {
    setDialogOpen({
      dialog: 'DELETE_NOTE',
      isOpen: false,
    });
    await deleteNote(noteId);
    await reFetchData(getAssociatedWithType(globalSearchTypes.QUOTE), id);

    displayToastMessage('SUCCESS', t('The note has been deleted.'));
  };

  const handleEditedNote = () => {
    reFetchData(getAssociatedWithType(globalSearchTypes.QUOTE), id);
  };

  const handleClickAway = () => {
    setIsClickedMoreButton(false);
    setSelectedRowId('');
  };

  const handleEditNote = (noteId: string) => {
    setSelectedRowId(noteId);
    HISTORY.push({
      pathname: LOCATION.pathname,
      search: `?edit_note_id=${noteId}`,
    });
  };

  const columns: IColumns[] = [
    {
      name: 'subject',
      display_name: t('Subject'),
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 110,
    },
    {
      name: 'button',
      display_name: '',
      flex: 0.05,
      type: 'action_menu',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 50,
    },
    {
      name: 'note',
      display_name: t('Note'),
      minWidth: 220,
      flex: 2,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'created_by',
      display_name: t('Entered by'),
      minWidth: 150,
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
    {
      name: 'created_at',
      display_name: t('Date Created'),
      minWidth: themeHook.dateColumnWidth,
      flex: 0.1,
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
    },
  ];

  const columnsNotes: GridColDef[] = columns.map((field, i) => ({
    flex: field.flex ?? 1,
    field: field.name,
    width: field.width,
    align: field.align,
    minWidth: field.minWidth,
    sortable: field.is_sortable,
    headerAlign: field.headerAlign,
    headerName: field.display_name,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (fieldValue === '-' && i !== 0 && field.type !== dataFieldTypes.ACTION) {
        return fieldValue;
      } else if (i === 0) {
        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { note: params.row.id },
            })}`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (field.type === dataFieldTypes.ACTION) {
        return (
          <Box sx={{}}>
            <MenuComponent
              renderDefaultIcon={false}
              anchor={
                <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
                  <IconButton
                    disableRipple
                    size="small"
                    sx={{
                      bgcolor:
                        selectedRowId === params.row.id && isClickedMoreButton
                          ? (theme) => theme.palette.background.default
                          : 'none',
                      color:
                        selectedRowId === params.row.id && isClickedMoreButton
                          ? (theme) => theme.customColors.black
                          : 'none',
                      '&:hover': {
                        color: (theme) => theme.customColors.black,
                        bgcolor: (theme) => theme.palette.background.default,
                      },
                    }}
                    onClick={() => {
                      setIsClickedMoreButton(true);
                      setSelectedRowId(params.row.id);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </ClickAwayListener>
              }
            >
              <MenuItem
                value="edit"
                sx={dropdownItemsStyle}
                onClick={() => handleEditNote(params.row.id)}
              >
                <ReactSVG src={Edit} />
                {t('Edit')}
              </MenuItem>
              <MenuItem
                value="delete"
                sx={dropdownItemsStyle}
                onClick={() => {
                  setDialogOpen({
                    dialog: 'DELETE_NOTE',
                    isOpen: true,
                    onAccept: () => handleDeleteNote(params.row.id!),
                  });
                }}
              >
                <ReactSVG src={Delete} />
                {t('Delete')}
              </MenuItem>
            </MenuComponent>
          </Box>
        );
      } else if (field.type === dataFieldTypes.DATE) {
        if (!fieldValue) return '-';

        const splitDate = formatDateInTimeZone(
          fieldValue,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' ');
        return (
          <Box
            sx={{
              height: '100%',
              py: (theme) => theme.spacing(0.75),
              color: (theme) => theme.customColors.black,
            }}
          >
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[0]}
            </Typography>
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[1]} {splitDate[2]}
            </Typography>
          </Box>
        );
      } else {
        return (
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);

    if (newModel.length) {
      const [nm] = newModel;
      setPage(0);
      fetch(
        {
          ...pagination,
          page: 1,
          ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
        },
        getAssociatedWithType(globalSearchTypes.QUOTE),
        id,
      );
    } else {
      fetch(
        {
          ...pagination,
          ordering: '',
        },
        getAssociatedWithType(globalSearchTypes.QUOTE),
        id,
      );
    }
  };

  const handleNewNote = () => {
    reFetchData(getAssociatedWithType(globalSearchTypes.QUOTE), id);
  };

  return (
    <>
      {isEditNoteDrawerOpen && (
        <EditNoteDrawer isDrawerOpen={isEditNoteDrawerOpen} onUpdated={handleEditedNote} />
      )}
      {isDetailNoteDrawerOpen && <NoteDetailDrawer isDrawerOpen={isDetailNoteDrawerOpen} />}
      <QuoteOrRenewalDetailTopActionBar onNoteCreated={handleNewNote} />
      <QuoteOrRenewalDetailHeader currentTab={2} quoteId={id} />
      <DataTable
        onRowClick={(r) => setSelectedRowId(r.id === selectedRowId ? '' : (r.id as string))}
        autoRowCellHeight
        rows={data!}
        loading={loading}
        paginationMode="server"
        columns={columnsNotes}
        columnVisibilityModel={createColumnVisibilityModel(columns)}
        paginationModel={{ page: pageNumber, pageSize: pagination.page_size! }}
        rowCount={pagination.total_count}
        pageSizeOptions={[pagination.page_size!]}
        hideFooterPagination={pagination.total_count! < pagination.page_size!}
        onPageChange={(page) => {
          setPage(page);
          fetch(
            { ordering: pagination.ordering, page: page + 1 },
            getAssociatedWithType(globalSearchTypes.QUOTE),
            id,
          );
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        focusedRowId={selectedRowId}
        wrapperSx={{
          px: 3,
          pt: 3,
          overflow: 'auto',
          height: (theme) => `calc(100vh - ${theme.quoteDetailFixedItemsHeight})`,
        }}
      />
    </>
  );
};

export default QuoteDetailNotes;
