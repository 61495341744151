import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ICreatePeril } from 'api/models/NewQuote/createPeril';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { WcClassCodes } from 'api/models/THREEMappings/WcClassCodes/wcClassCodes.model';
import { addExposure, deletePeril, updateExposure } from 'api/services/NewQuote';
import {
  manIsancillaryFieldCode,
  threeEmployerExposurePerilNames,
  threeExposureNames,
  userRoles,
  wcExposureRelatedKeyValues,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { ClassDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/ClassDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  changeFieldsHiddenStatus,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
  makeFieldsReadonly,
  parseLocation,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty, omit } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import QuoteLocationSelect from '../../../Inputs/LocationSelectInput';
import WcClassCodeAutocomplete from '../../../Inputs/WcClassCodeAutocomplete';
import { calculateOwnerPayroll, classCodeOptions } from '../helpers';

export interface PropertyEditProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  isAdd?: boolean;
}

const ClassEditDrawer: FC<PropertyEditProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isAdd = false,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const WC_ID = url?.wc;
  const WCP_ID = url?.wcp;
  const {
    fields: fieldConfig,
    data: quoteDetail,
    getExposures,
    exposureList,
    groups,
  } = useQuoteDetail();

  const { data: keyValueStore } = useKeyValues();

  const { data: user } = useUser();

  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();

  const [fields, setFields] = useState<any[]>([]);

  const isProducer = user?.role?.code === userRoles.AGENT.code;

  const WC_CLASS_CODES: WcClassCodes[] =
    keyValueStore?.[`${wcExposureRelatedKeyValues.WC_CLASS_CODES}`]?.data?.value ?? [];

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['wc', 'wcp', 'addWc'],
      }),
    });
  };

  // get exposures from provider
  const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => employerExposures?.find((e) => e.locator === WC_ID) ?? {},
    [employerExposures, WC_ID],
  );

  // try to find selected exposure in the exposures list
  const activeClassData = useMemo(
    () => activeExposure?.perils?.find((p) => p.locator === WCP_ID)?.data ?? {},
    [activeExposure],
  );

  const {
    pol_man_payrollw2_default,
    pol_man_payrollw2_rule,
    pol_man_payrollsub_default,
    pol_man_payrollsub_rule,
    pol_oo_wc_payroll,
  } = quoteDetail?.characteristics?.[0]?.data!;

  const [state, setState] = useState<any>(
    isAdd
      ? // When manually adding a class code MAN_ISMAIN = "No" by default.
        { man_ismain: 'No' }
      : activeClassData
      ? {
          ...activeClassData,
          man_payroll_w2:
            activeClassData.man_ismain === 'No'
              ? 0
              : pol_man_payrollw2_rule ?? pol_man_payrollw2_default,
          man_payroll_sub:
            activeClassData.man_ismain === 'No'
              ? 0
              : pol_man_payrollsub_rule ?? pol_man_payrollsub_default,
          owner_payroll: pol_oo_wc_payroll,
        }
      : {},
  );

  useEffect(() => {
    const manualPremiumFields =
      (fieldConfig?.peril?.data as ProductWorkFlow[])?.[`${threeExposureNames.EMPLOYER}`]?.find(
        (p) => p.code === threeEmployerExposurePerilNames.MANUAL_PREMIUM,
      )?.fields ?? [];
    // This field should be neither visible nor editable by Producer role.
    const tmpFields = manualPremiumFields.map((field) => {
      if (isProducer) {
        if (field.code === manIsancillaryFieldCode) {
          return { ...field, is_hidden: true };
        }
      }
      return field;
    });

    if (isAdd) {
      const updatedStaticFields = tmpFields.map((field) => {
        if (field.code === 'man_class_code') {
          return { ...field, is_readonly: false };
        }
        return field;
      });
      setFields(makeFieldsReadonly(updatedStaticFields, ['man_location'], false));
    } else {
      setFields(tmpFields);
    }
  }, [fieldConfig]);

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema,
    onSubmit: async (_values) => {},
  });

  const showLoader = isAdd
    ? !fieldConfig?.exposure?.loaded
    : !fieldConfig?.exposure?.loaded || isEmpty(activeExposure);

  const getQuoteDetailAndExposures = async () => {
    await getExposures(
      quoteDetail?.locator!,
      { page_size: 10000 },
      threeExposureNames.EMPLOYER,
      true,
    );
  };

  const handleAdd = async () => {
    try {
      setLoading(true);

      const parsedLoacation = parseLocation(state.man_location);

      const exposureData = {
        emp_state: parsedLoacation.state,
        emp_filing_set_id: state.emp_filing_set_id,
      };

      const perilData = {
        data: omit(state, [
          'emp_filing_set_id',
          'total_rated_payroll',
          'man_payroll_w2',
          'man_payroll_sub',
          'owner_payroll',
        ]),
        name: threeEmployerExposurePerilNames.MANUAL_PREMIUM,
      };

      const foundExposure = employerExposures.find(
        (e) => e?.data?.emp_state === exposureData.emp_state,
      );

      if (foundExposure) {
        // await addPeril(quoteDetail?.policy_locator!, foundExposure.locator!, perilData);
        await updateExposure(quoteDetail?.policy_locator!, foundExposure.locator!, {
          data: exposureData,
          perils: [perilData],
        });
      } else {
        await addExposure(quoteDetail?.policy_locator!, {
          name: threeExposureNames.EMPLOYER,
          data: exposureData,
          quote_locator: quoteDetail?.locator,
          perils: [perilData],
        });
      }

      displayToastMessage('SUCCESS', t('The class code has been added.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the class code.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  // only delete the peril
  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: 'DELETE_CLASS_CODE',
        isOpen: false,
      });
      setLoading(true);

      await deletePeril(quoteDetail?.policy_locator!, WC_ID as string, WCP_ID as string, {
        quote_locator: quoteDetail?.locator,
      });

      displayToastMessage('SUCCESS', t('The class code has been deleted.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the class code.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const parsedLoacation = parseLocation(state.man_location);
      const exposureData = { ...activeExposure.data, emp_state: parsedLoacation.state };

      const peril = activeExposure?.perils?.find((p) => p.locator === WCP_ID) ?? {};

      const perilData = { ...peril, data: state } as ICreatePeril;

      await updateExposure(quoteDetail?.policy_locator!, WC_ID as string, {
        data: exposureData,
        perils: [perilData],
      });

      displayToastMessage('SUCCESS', t('The class code has been updated.'));
      handleQuery();
      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the class code.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  useEffect(() => {
    formik.setValues(state);

    setFields((prevState) =>
      changeFieldsHiddenStatus(prevState, ['man_capita'], state.exposure_type !== 'Per Capita'),
    );

    // remove its value field change its hidden status
    setState((prevState) => ({
      ...prevState,
      man_capita: state.exposure_type !== 'Per Capita' ? undefined : prevState.man_capita,
    }));
  }, [JSON.stringify(state)]);

  const handleEmitedInputChange = async ({ field, value }: { field: string; value: any }) => {
    switch (field) {
      case 'man_class_code': {
        const {
          man_payroll_sub = 0,
          man_payroll_w2 = 0,
          man_location,
          man_payroll_sub_user,
          man_payroll_w2_user,
        } = state;

        const parsedLocation = parseLocation(man_location);

        const owner_payroll = calculateOwnerPayroll(
          value?.ConcatLookup!,
          parsedLocation?.state,
          groups,
        );

        setState((prevState) => ({
          ...prevState,
          man_class_code_desc: value?.FriendlyLabel,
          emp_filing_set_id: value?.FilingSetID,
          exposure_type: value?.ExposureType,
          rate: value?.Rate,
          owner_payroll,
          total_rated_payroll:
            (man_payroll_sub_user ? Number(man_payroll_sub_user) : Number(man_payroll_sub)) +
            (man_payroll_w2_user ? Number(man_payroll_w2_user) : Number(man_payroll_w2)) +
            owner_payroll,
        }));
        break;
      }

      case 'man_payroll_sub_user': {
        const {
          man_payroll_w2,
          owner_payroll = 0,
          man_payroll_w2_user,
          man_payroll_sub = 0,
        } = state;
        const employeePayroll = man_payroll_w2 ? Number(man_payroll_w2) : 0;

        setState((prevState) => ({
          ...prevState,
          total_rated_payroll:
            (man_payroll_w2_user ? Number(man_payroll_w2_user) : employeePayroll) +
            (!isEmpty(value) ? Number(value) : Number(man_payroll_sub)) +
            owner_payroll,
        }));

        break;
      }

      case 'man_payroll_w2_user': {
        const {
          man_payroll_sub,
          owner_payroll = 0,
          man_payroll_sub_user,
          man_payroll_w2 = 0,
        } = state;
        const subPayroll = man_payroll_sub ? Number(man_payroll_sub) : 0;

        setState((prevState) => ({
          ...prevState,
          total_rated_payroll:
            (!isEmpty(value) ? Number(value) : Number(man_payroll_w2)) +
            (man_payroll_sub_user ? Number(man_payroll_sub_user) : subPayroll) +
            owner_payroll,
        }));

        break;
      }

      case 'man_location': {
        const parsedLoacation = parseLocation(value);

        const foundExposure = employerExposures.find(
          (e) => e?.data?.emp_state === parsedLoacation.state,
        );

        if (foundExposure) {
          setState((prevState) => ({
            ...prevState,
            emp_filing_set_id: foundExposure.data?.emp_filing_set_id,
          }));
        }
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    if (!isAdd) {
      const {
        man_payroll_sub = 0,
        man_payroll_w2 = 0,
        man_payroll_sub_user,
        man_payroll_w2_user,
        man_location,
        man_class_code,
        man_class_code_desc,
      } = activeClassData;

      const filingSetId = activeExposure.data?.emp_filing_set_id;
      const parsedLocation = parseLocation(man_location);

      const foundClassCode = classCodeOptions(
        parsedLocation?.state,
        filingSetId,
        WC_CLASS_CODES,
      )?.find((o) => o.ConcatLookup === `${man_class_code} - ${man_class_code_desc}`);

      const owner_payroll = calculateOwnerPayroll(
        foundClassCode?.ConcatLookup!,
        parsedLocation?.state,
        groups,
      );

      setState((prevState) => ({
        ...prevState,
        ...activeClassData,

        total_rated_payroll:
          (man_payroll_sub_user ? Number(man_payroll_sub_user) : Number(man_payroll_sub)) +
          (man_payroll_w2_user ? Number(man_payroll_w2_user) : Number(man_payroll_w2)) +
          Number(owner_payroll),

        emp_filing_set_id: filingSetId,
        exposure_type: foundClassCode?.ExposureType,
        rate: foundClassCode?.Rate,
        owner_payroll,
      }));

      formik.setValues(activeClassData);
    }
  }, [activeClassData]);

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        width="476px"
        onClose={handleQuery}
        headerSx={{
          mb: '14px',
          mt: 5,
        }}
        isContentScrollable
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Class Code Details')}
          </Typography>
        }
        content={
          <Stack gap={2} sx={{ mb: 6, pt: 1 }}>
            <ClassDrawerFieldParser
              formik={formik}
              state={state}
              fields={fields}
              setState={setState}
              showLoader={showLoader}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
              onChangeEmited={(emitedEvent) => handleEmitedInputChange(emitedEvent)}
              LocationSelect={QuoteLocationSelect}
              WcClassCodeAutocomplete={WcClassCodeAutocomplete}
            />
          </Stack>
        }
        footer={
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            {user?.role?.code === userRoles.UNDERWRITER.code && !isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="20%" height={32} />
              ) : (
                <Button
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'DELETE_CLASS_CODE',
                      isOpen: true,
                      onAccept: () => handleDelete(),
                    })
                  }
                  sx={[drawerFooterSecondaryButtonStyle]}
                >
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}

            <Stack direction="row" gap={1.5}>
              {showLoader ? (
                <>
                  <Skeleton animation="wave" width="60px" height={32} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default ClassEditDrawer;
