import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { GridAlignment, GridColDef, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import { Task } from 'api/models/Tasks/task.model';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  taskStatuses,
} from 'common/constants';
import DataTable from 'components/DataTable';
import PolicyDetailHeader from 'components/Policies/PolicyDetail/Header';
import PolicyEndorsementDetailHeader from 'components/Policies/PolicyEndorsementDetail/Header';
import QuickActionMenu from 'components/Tasks/QuickActionMenu';
import TaskDetail from 'components/Tasks/TaskDetail';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useTasks from 'hooks/useTasks';
import useUser from 'hooks/useUser';
import { initialPolicyDetailData } from 'providers/PolicyDetailProvider/PolicyDetailProviderContext';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const DEFAULT_ORDER = { field: 'created_at', sort: 'desc' } as GridSortItem;

const PolicyDetailTasks: FC = () => {
  const LOCATION = useLocation();
  const { formatDateInTimeZone } = useConfig();
  const { data: user } = useUser();
  const { t } = useTranslation();
  const { id, endorsementId } = useParams<{ id: string; endorsementId: string }>();
  const [inPageLoading, setInPageLoading] = useState(false);
  const themeHook = useTheme();
  const url = qs.parse(LOCATION.search);

  const { setDialogOpen } = useDialog();
  const { getTasks, tasks, resetTasks } = usePolicyDetail();
  const [pageNumber, setPage] = useState(0);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([DEFAULT_ORDER]);
  const [selectedRowId, setSelectedRowId] = useState<number | string>(-1);

  const isDetailTaskDrawerOpen = url?.message !== undefined;

  const { deleteTask, closeTask, addToQueue, updateTask } = useTasks();

  const checkIsOrderDefaultField = () => {
    if (DEFAULT_ORDER.sort === 'desc') {
      return `-${DEFAULT_ORDER.field}`;
    } else {
      return DEFAULT_ORDER.field;
    }
  };

  useEffect(() => {
    getTasks(id, {
      ...initialPolicyDetailData.tasks?.pagination,
      ordering: checkIsOrderDefaultField(),
    });

    return () => {
      resetTasks();
    };
  }, []);

  const handleCloseTask = async (task: Task) => {
    try {
      setInPageLoading(true);
      const updatedTask = { status: taskStatuses.CLOSED.key };
      await closeTask(task.display_id!, updatedTask);
      await getTasks(id, { ...tasks?.pagination });
      displayToastMessage('SUCCESS', t('The message has been marked as closed.'));
    } finally {
      setInPageLoading(false);
    }
  };

  const handleDeleteTask = async (taskId: number | string) => {
    try {
      setDialogOpen({
        dialog: 'DELETE_TASK',
        isOpen: false,
      });
      setInPageLoading(true);
      await deleteTask(taskId);
      await getTasks(id, { ...tasks?.pagination });

      displayToastMessage('SUCCESS', t('The message has been deleted.'));
    } finally {
      setInPageLoading(false);
    }
  };

  const handleAssignToQueue = async (task: Task, queueId: number) => {
    try {
      setInPageLoading(true);
      const updatedTask = { task_queue: queueId };
      await addToQueue(task.display_id!, updatedTask);
      await getTasks(id, { ...tasks?.pagination });
      displayToastMessage('SUCCESS', t('The message has been assigned to queue.'));
    } finally {
      setInPageLoading(false);
    }
  };

  const handleAssignToMe = async (task: Task) => {
    try {
      setInPageLoading(true);
      const updatedTask = { assignee: user?.id };
      await updateTask(task.display_id!, updatedTask);
      await getTasks(id, { ...tasks?.pagination });
      displayToastMessage('SUCCESS', t('The message has been assigned to me.'));
    } finally {
      setInPageLoading(false);
    }
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);

    if (newModel.length) {
      const [nm] = newModel;
      setPage(0);
      getTasks(id, {
        ...tasks?.pagination,
        page: 1,
        ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
      });
    } else {
      getTasks(id, {
        ...tasks?.pagination,
        ordering: '',
      });
    }
  };

  const columns: IColumns[] = [
    {
      name: 'title',
      display_name: t('Title'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 130,
      flex: 1,
    },
    {
      name: 'button',
      display_name: '',
      flex: 0.05,
      type: 'action_menu',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 40,
    },
    // {
    //   name: 'reference__reference_type',
    //   display_name: t('Related to'),
    //   type: 'string',
    //   flex: 2,
    //   is_hidden: false,
    //   is_sortable: true,
    //   is_link: false,
    //   link_type: null,
    // },
    {
      name: 'assigned_to__full_name',
      display_name: t('Assigned to'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 140,
      flex: 1,
    },
    {
      name: 'created_by__full_name',
      display_name: t('Created By'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 140,
      flex: 1,
    },
    {
      name: 'created_at',
      display_name: t('Opened at'),
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
      flex: 0.1,
    },
    // {
    //   name: 'due_date',
    //   display_name: t('Due Date'),
    //   type: 'date',
    //   is_hidden: false,
    //   is_sortable: true,
    //   is_link: false,
    //   link_type: null,
    //   minWidth: themeHook.dateColumnWidth,
    //   flex: 0.1,
    // },
    {
      name: 'priority__value',
      display_name: t('Priority'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 120,
      flex: 0.1,
    },
    {
      name: 'status__value',
      display_name: t('Status'),
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: themeHook.dateColumnWidth,
      flex: 0.1,
    },
  ];

  const columnsTasks: GridColDef[] = columns.map((field, i) => ({
    field: field.name,
    width: field.width,
    minWidth: field.minWidth,
    align: field.align,
    headerAlign: field.headerAlign,
    headerName: field.display_name,
    flex: field.flex ?? 1,
    sortable: field.is_sortable,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (fieldValue === '-' && i !== 0 && field.type !== dataFieldTypes.ACTION) {
        return fieldValue;
      } else if (i === 0) {
        const r = params.row as Task;

        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { message: r.display_id },
            })}`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (field.type === dataFieldTypes.ACTION) {
        const r = params.row as Task;

        return (
          <QuickActionMenu
            key={r.id}
            rowData={r}
            selectedRowId={selectedRowId}
            setSelectedRowId={setSelectedRowId}
            handleCloseTask={handleCloseTask}
            handleDeleteTask={handleDeleteTask}
            handleAssignToQueue={handleAssignToQueue}
            handleAssignToMe={handleAssignToMe}
          />
        );
      } else if (field.name === 'reference__reference_type') {
        const r = params.row as Task;
        let mainPath;

        switch (r.reference?.reference_link_type) {
          case 'policy':
            mainPath = `/policies/${r.reference.locator}/overview`;

            break;
          case 'policyholder':
            mainPath = `/policyholders/${r.reference.locator}/details`;

            break;

          default:
            break;
        }

        return (
          <Link
            component={RouterLink}
            to={mainPath}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
            }}
          >
            <Stack
              sx={{
                flexDirection: {
                  sm: 'column',
                },
              }}
            >
              <Typography>{r.reference?.reference_type}</Typography>
              <Typography>{r.reference?.locator}</Typography>
            </Stack>
          </Link>
        );
      } else if (field.name === 'created_at') {
        if (!fieldValue) return '-';

        const splitDate = formatDateInTimeZone(
          fieldValue,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' ');
        return (
          <Box
            display="flex"
            flexWrap="wrap"
            sx={{
              height: '100%',
              py: (theme) => theme.spacing(0.75),
              color: (theme) => theme.customColors.black,
            }}
          >
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                mr: 1,
              }}
            >
              {splitDate[0]}
            </Box>
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[1]} {splitDate[2]}
            </Box>
          </Box>
        );
      } else if (field.type === dataFieldTypes.DATE) {
        return fieldValue && fieldValue !== '-' ? formatDateInTimeZone(fieldValue) : '-';
      } else {
        return (
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  return (
    <>
      {isDetailTaskDrawerOpen && <TaskDetail isDrawerOpen={isDetailTaskDrawerOpen} />}
      {endorsementId ? (
        <PolicyEndorsementDetailHeader
          // search
          currentTab={4}
          policyId={id}
          // handleSearchInputChange={handleSearchInputChange}
        />
      ) : (
        <PolicyDetailHeader
          // search
          currentTab={4}
          policyId={id}
          // handleSearchInputChange={handleSearchInputChange}
        />
      )}
      <DataTable
        autoRowCellHeight
        rows={tasks?.data!}
        rowHeight={75}
        loading={tasks?.loading || inPageLoading}
        paginationMode="server"
        columns={columnsTasks}
        columnVisibilityModel={createColumnVisibilityModel(columns)}
        paginationModel={{ page: pageNumber, pageSize: tasks?.pagination?.page_size! }}
        rowCount={tasks?.pagination?.total_count}
        pageSizeOptions={[tasks?.pagination?.page_size!]}
        hideFooterPagination={tasks?.pagination?.total_count! < tasks?.pagination?.page_size!}
        onPageChange={(page) => {
          setPage(page);
          getTasks(id, { ordering: tasks?.pagination?.ordering, page: page + 1 });
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        wrapperSx={{
          px: 3,
          pt: 3,
          overflow: 'auto',
          height: endorsementId
            ? (theme) => `calc(100vh - ${theme.policyEndorsementDetailFixedItemsHeight})`
            : (theme) => `calc(100vh - ${theme.policyDetailFixedItemsHeight})`,
        }}
      />
    </>
  );
};

export default PolicyDetailTasks;
