// Styles

// Components
import DataTable from '../../../components/DataTable';
import { TEAMS_TABLE_CONFIG } from '../constants/table-config';

const AgencyDetailTeams = ({ teams, isLoading }) => {
  return (
    <DataTable
      isHeaderWidthFit
      loading={isLoading}
      columns={TEAMS_TABLE_CONFIG}
      pageSize={25}
      hideFooterPagination={teams?.length <= 25}
      rows={teams ?? []}
    />
  );
};
export default AgencyDetailTeams;
