import { Elements } from '@stripe/react-stripe-js';
import { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useEffect, useState } from 'react';

import { Grid, useTheme } from '@mui/material';
import { SetupIntent } from 'api/models/Payments/payments.model';
import { getSetupIntent, getStripeConfig } from 'api/services/Payments';
import CheckoutForm from 'components/Payments/CheckoutForm';
import Error from 'components/Payments/Error';
import PaymentHeader from 'components/Payments/PaymentHeader';
import useLoader from 'hooks/useLoader';
import { useParams } from 'react-router-dom';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
let stripePromise: undefined | Promise<any>;

interface ErrorState {
  message?: string;
}

const PaymentSetup = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [setupIntent, setSetupIntent] = useState(undefined as undefined | SetupIntent);
  const themeHook = useTheme();

  const { setupIntentId, policyLocator } = useParams<{
    setupIntentId: string;
    policyLocator: string;
  }>();

  const { setLoading } = useLoader();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<ErrorState | null>(null);

  const fetchSetupIntent = async () => {
    if (!clientSecret) {
      setLoading(true);

      const stripeConfig = await getStripeConfig();
      stripePromise = loadStripe(stripeConfig.publishable_key);

      try {
        const res = await getSetupIntent({ policyLocator, setupIntentId });
        setSetupIntent(res);
        setClientSecret(res.client_secret);
      } catch (e) {
        const err = e as unknown as ErrorState;
        setError(err);
      } finally {
        setLoading(false);
        setIsLoaded(true);
      }
    }
  };

  useEffect(() => {
    // Fetch SetupIntent as soon as the page loads
    fetchSetupIntent();
  }, []);

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: themeHook.palette.secondary.main,
      fontFamily: 'Nexa, sans-serif',
      borderRadius: '0px',
      focusBoxShadow: `0 0 0 1px ${themeHook.palette.secondary.main}`,
      focusOutline: 'none',
    },
    labels: 'floating',
  };

  const options: StripeElementsOptions = {
    fonts: [
      {
        family: 'Nexa',
        src: 'local(Nexa), url(/fonts/Nexa/NexaHeavy.woff2) format(woff2)',
        weight: '700',
      },
      {
        family: 'Nexa',
        src: 'local(Nexa), url(/fonts/Nexa/NexaBold.woff2) format(woff2)',
        weight: '500',
      },
      {
        family: 'Nexa',
        src: 'local(Nexa), url(/fonts/Nexa/NexaRegular.woff2) format(woff2)',
        weight: '400',
      },
    ],
    clientSecret,
    appearance,
  };

  return (
    <>
      {error ? (
        <Error error={error} />
      ) : isLoaded && setupIntent ? (
        <Grid container rowSpacing={4} sx={{ p: 4, maxWidth: '540px' }}>
          <Grid item xs={12}>
            <PaymentHeader />
          </Grid>

          <Grid item xs={12} maxWidth="540px">
            {clientSecret && stripePromise && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  setupIntent={setupIntent}
                  setIsLoading={setLoading}
                  isLoading={!isLoaded}
                />
              </Elements>
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default PaymentSetup;
