import { useEffect, useState } from 'react';

// Styles
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import {
  detailPageGridContainerStyles,
  detailPageGridItemStyles,
  detailPageRowKeyStyles,
  detailPageRowValueStyles,
  detailPageStackStyles,
} from 'helpers/MuiSharedStyles';

// Components
// Hooks
import { userRoles } from 'common/constants';
import LocationShow from 'components/LocationShow';
import { formatLocation, parseLocation } from 'helpers/Utils';

const AgencyDetails = ({ agency, isLoading }: any) => {
  const tableRows = [
    { id: 1, key: 'name', displayName: 'Name', value: '' },
    { id: 2, key: 'phone_number', displayName: 'Phone Number', value: '' },
    { id: 5, key: 'email', displayName: 'Email', value: '' },
    { id: 7, key: 'website', displayName: 'Website', value: '' },
    { id: 8, key: 'address', displayName: 'Address', value: '' },
    {
      id: 9,
      key: 'customer_service_phone_number',
      displayName: 'Customer Service Phone',
      value: '',
    },
  ];
  const [rows, setRows] = useState(tableRows);

  useEffect(() => {
    if (agency) {
      const newRows = rows.map((e) => {
        let value = agency![`${e.key}`];

        if (e.key === 'role') {
          value = agency![`${e.key}`].name;
        }

        if (e.key === 'agency_type') {
          const key = agency![`${e.key}`] || '';
          value =
            Object?.values?.(userRoles)
              ?.find((role) => role?.code === key)
              ?.name() || '';
        }

        if (e.key === 'address') {
          value = formatLocation({
            addressLine1: agency?.address_line_1!,
            addressLine2: agency?.address_line_2,
            city: agency?.city,
            state: agency?.state,
            zip: agency?.zip_code,
          }).storing;
        }

        return { ...e, value };
      });
      setRows(newRows);
    }
  }, [agency]);

  const renderRow = (row) => (
    <Grid key={row.id} item xs={1} md={1} sx={[detailPageGridItemStyles]}>
      <Stack sx={detailPageStackStyles} flexDirection="row">
        <Typography
          sx={[
            detailPageRowKeyStyles,
            {
              color: (theme) => theme.customColors.grey800,
              minWidth: '142px',
              maxWidth: '142px',
            },
          ]}
        >
          {isLoading && row.displayName === '' ? (
            <Skeleton animation="wave" width="80%" />
          ) : (
            row.displayName
          )}
        </Typography>
        <Typography
          sx={[
            detailPageRowValueStyles,
            {
              minWidth: '144px',
            },
          ]}
        >
          {isLoading ? (
            <Skeleton animation="wave" width="80%" />
          ) : row.key === 'address' ? (
            <LocationShow location={formatLocation(parseLocation(row.value)).showing} />
          ) : (
            row.value || '-'
          )}
        </Typography>
      </Stack>
    </Grid>
  );

  return (
    <Box sx={{ px: 3, pb: 1 }}>
      <Stack
        sx={{
          flexDirection: { sx: 'column', sm: 'column', lg: 'row' },
        }}
        columnGap={(theme) => theme.columnGap}
      >
        <Grid
          sx={[
            detailPageGridContainerStyles,
            {
              maxWidth: 480,
              height: 'min-content',
              width: '100%',
              marginTop: '0px !important',
            },
          ]}
          container
          spacing={1}
          columns={1}
        >
          {rows.slice(0, Math.ceil(rows.length / 2)).map((row) => renderRow(row))}
        </Grid>
        <Grid
          sx={[
            detailPageGridContainerStyles,
            {
              maxWidth: 480,
              height: 'min-content',
              paddingTop: { xs: 2.5, sm: 2.5, md: 2.5, lg: 0 },
              width: '100%',
              marginTop: '0px !important',
            },
          ]}
          container
          spacing={1}
          columns={1}
        >
          {rows.slice(Math.round(rows.length / 2)).map((row) => renderRow(row))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default AgencyDetails;
