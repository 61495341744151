import { Box, Button, FormControl, Skeleton, Stack, TextField, Typography } from '@mui/material';
import convertFileSizeToBytes from 'api/helpers/convertFileSizeToBytes';
import DrawerComponent from 'components/DrawerComponent';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useDocuments from 'hooks/useDocuments';
import useUser from 'hooks/useUser';
import qs from 'query-string';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

interface SendDocumentMailProps {
  isDrawerOpen: boolean;
  policyId: string;
}

const SendDocumentMailDrawer: FC<SendDocumentMailProps> = ({
  isDrawerOpen,
  policyId,
}: SendDocumentMailProps) => {
  const { data: user } = useUser();
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { send_document_id } = qs.parse(LOCATION.search);
  const { activeDocument, fetchDocument } = useDocuments();

  useEffect(() => {
    fetchDocument(policyId, send_document_id as string);
  }, []);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['send_document_id'],
      }),
    });
  };

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      onClose={() => handleQuery()}
      header={
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Send Document via Email')}
        </Typography>
      }
      content={
        <form id="sendDocumentForm" noValidate>
          <Stack gap={2}>
            <Box sx={{ mt: 1 }}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: '24px',
                  color: (theme) => theme.customColors.drawer.header,
                }}
              >
                {t('Attachment')}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  fontWeight: 400,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {!activeDocument?.loading ? (
                  activeDocument?.data?.file_name
                ) : (
                  <Skeleton animation="wave" width="60%" height={20} />
                )}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  boxShadow: (theme) => theme.customShadows.shadow7,
                  fontWeight: 400,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {!activeDocument?.loading ? (
                  activeDocument?.data?.file_size &&
                  convertFileSizeToBytes(activeDocument?.data?.file_size)
                ) : (
                  <Skeleton animation="wave" width="60%" height={20} />
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
                {t('Document Title')}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  boxShadow: (theme) => theme.customShadows.shadow7,
                  fontWeight: 400,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {!activeDocument?.loading ? (
                  activeDocument?.data?.title
                ) : (
                  <Skeleton animation="wave" width="60%" height={20} />
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ color: (theme) => theme.customColors.grey800 }}>
                {t('Document Type')}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  boxShadow: (theme) => theme.customShadows.shadow7,
                  fontWeight: 400,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {!activeDocument?.loading ? (
                  activeDocument?.data?.doc_type!.value
                ) : (
                  <Skeleton animation="wave" width="60%" height={20} />
                )}
              </Typography>
            </Box>
            <FormControl size="small" sx={{ flex: 1 }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: (theme) => theme.customColors.drawer.header,
                  }}
                >
                  {t('Mail')}
                </Typography>
              </Box>
            </FormControl>

            <FormControl size="small" sx={{ flex: 1 }}>
              <TextField
                required
                disabled
                size="small"
                name="from"
                label={t('From')}
                value={user?.email ?? ''}
                // onChange={formik.handleChange}
                // helperText={formik.touched.display_name && formik.errors.display_name}
                // error={formik.touched.display_name && Boolean(formik.errors.display_name)}
              />
            </FormControl>
            <FormControl size="small" sx={{ flex: 1 }}>
              <TextField
                required
                size="small"
                name="to"
                label={t('To')}
                // value={formik.values.display_name}
                // onChange={formik.handleChange}
                // helperText={formik.touched.display_name && formik.errors.display_name}
                // error={formik.touched.display_name && Boolean(formik.errors.display_name)}
              />
            </FormControl>
            <FormControl size="small" sx={{ flex: 1 }}>
              <TextField
                required
                size="small"
                name="subject"
                label={t('Subject')}
                // value={formik.values.display_name}
                // onChange={formik.handleChange}
                // helperText={formik.touched.display_name && formik.errors.display_name}
                // error={formik.touched.display_name && Boolean(formik.errors.display_name)}
              />
            </FormControl>
            <FormControl
              size="small"
              sx={{
                flex: 1,
              }}
            >
              <TextField
                multiline
                required
                rows={6}
                // value={formik.values.message}
                // helperText={
                //   formik.touched.message && Boolean(formik.errors.message)
                //     ? formik.errors.message
                //     : `${formik.values?.message?.length}/500`
                // }
                inputProps={{ maxLength: 500, minLength: 1 }}
                name="body"
                // error={formik.touched.message && Boolean(formik.errors.message)}
                label={t('Body')}
                // onChange={formik.handleChange}
                sx={{
                  '&>.MuiInputBase-root': {
                    padding: (theme) => theme.spacing(2),
                    height: 'auto',
                  },
                  '&.MuiTextField-root': {
                    height: '100%',
                  },
                }}
              />
            </FormControl>
            {/* <FormControl
                size="small"
                sx={{
                  flex: 1,
                }}
              >
                <FilePresentIcon
                  sx={{
                    fontSize: '24px',
                    height: '24px',
                    marginBottom: (theme) => theme.spacing(1),
                    color: (theme) => theme.customColors.info,
                  }}
                />
              </FormControl> */}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button type="submit" form="sendDocumentForm" sx={[drawerFooterPrimaryButtonStyle]}>
                {t('Send')}
              </Button>
            </Box>
          </Stack>
        </form>
      }
      footer={
        <Box sx={{ ml: 'auto', display: 'flex' }}>
          <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
            {t('Cancel')}
          </Button>
        </Box>
      }
    />
  );
};

export default SendDocumentMailDrawer;
