import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
} from '@mui/material';
import { GridAlignment } from '@mui/x-data-grid';
import { waiveFee } from 'api/services/Policies';
import { billingChipCategories } from 'common/constants';
import BillingConfirmFeeWaiverDialog from 'components/Policies/PolicyDetail/Billing/BillingConfirmFeeWaiverDialog';
import EnrollInAutopayDialog from 'components/Policies/PolicyDetail/Billing/EnrollInAutopayDialog';
import InvoicesTable from 'components/Policies/PolicyDetail/Billing/InvoicesTable';
import PayInvoiceDialog from 'components/Policies/PolicyDetail/Billing/PayInvoiceDialog';
import PaymentInfo from 'components/Policies/PolicyDetail/Billing/PaymentInfo';
import PaymentLinkDialog from 'components/Policies/PolicyDetail/Billing/PaymentLinkDialog';
import StopAutopayDialog from 'components/Policies/PolicyDetail/Billing/StopAutopayDialog';
import TableFilters from 'components/Policies/PolicyDetail/Billing/TableFilters';
import PolicyDetailHeader from 'components/Policies/PolicyDetail/Header';
import PolicyDetailTopActionBar from 'components/Policies/PolicyDetail/TopActionBar';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export interface IColumns {
  name: string;
  headerName: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const PolicyDetailBillings: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: policyDetail,
    getBillingData,
    loading: policyDetailLoading,
    loaded: policyDetailLoaded,
    resetPolicyInvoices,
    invoices,
    groupedInvoices,
    totalBillingPaymentAmounts,
    billingPaymentInfo,
    expandedBillingAccordions,
    setExpandedBillingAccordions,
  } = usePolicyDetail();
  const { setLoading } = useLoader();
  const [chips, setChips] = React.useState([{ code: 'all invoices', label: 'All Invoices' }]);
  const [activeChip, setActiveChip] = React.useState<string>(chips[0].code);
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const url = qs.parse(LOCATION.search);

  const handleChipClick = (chip: string) => {
    setActiveChip(chip);
  };

  const isPayInvoiceDialogOpen = url?.payInvoice === 'true' && url?.step === '1';
  const isPaymentLinkDialogOpen = url?.payInvoice === 'true' && url?.step === '2';
  const isStopAutopayDialogOpen = url?.stopAutopay === 'true';
  const isEnrollInAutopayDialogOpen = url?.enrollInAutopay === 'true';
  const isWaiveFeeDialogOpen = url?.waive !== undefined;

  const reversedPolicyTerms = useMemo(() => {
    return Object.entries(policyDetail?.policy_terms ?? {}).reverse();
  }, [policyDetail?.policy_terms]);

  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!hasInitialized.current && invoices?.loaded && policyDetailLoaded) {
      // Initialize expanded state object for all accordions
      const initialExpandedState = {};
      // Iterate through policy terms and set the expanded state based on whether invoices are loaded and have data
      Object.entries(policyDetail?.policy_terms ?? {}).forEach(([_, value]) => {
        initialExpandedState[value] = invoices?.loaded && !isEmpty(invoices?.[value]?.data);
      });
      // Update the expanded state with the initialized values
      setExpandedBillingAccordions(initialExpandedState);
      hasInitialized.current = true; // Mark as initialized to prevent repeated execution
    }
  }, [invoices?.loaded, policyDetail]);

  const handleAccordionChange = (key) => {
    setExpandedBillingAccordions({
      ...expandedBillingAccordions,
      [key]: !expandedBillingAccordions[key],
    });
  };

  const handleFeeWaive = async (invoiceId, policyId) => {
    try {
      setLoading(true);
      await waiveFee(invoiceId, policyId);
      getBillingData(id);
    } catch (error) {
      displayBackendErrorMessage(error);
    } finally {
      setLoading(false);
      HISTORY.push({
        search: deleteFromQueryStrings({
          locationSearch: LOCATION.search,
          omitKeys: ['waive'],
        }),
      });
    }
  };

  const fillChipsDynamically = () => {
    if (groupedInvoices && !isEmpty(groupedInvoices)) {
      Object.entries(groupedInvoices).forEach(([_, invoiceData]) => {
        if (!isEmpty(invoiceData)) {
          Object.entries(invoiceData).forEach(([status, value]) => {
            if (!isEmpty(value)) {
              if (billingChipCategories.some((e) => e.code === status)) {
                const chip = billingChipCategories.find((e) => e.code === status);
                if (chip) {
                  setChips((val) => {
                    // If a chip with the same code has already been added, do not add it again
                    if (!val.some((existingChip) => existingChip.code === chip.code)) {
                      return [...val, { code: chip.code, label: chip.label }];
                    }
                    return val;
                  });
                }
              }
            }
          });
        }
      });
    }
  };

  const fetchBillingData = async (locator = id) => {
    setLoading(true);
    try {
      await getBillingData(locator, false, policyDetail?.policyholder?.locator);
    } catch (error) {
      displayBackendErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (policyDetailLoaded) {
      fetchBillingData();
    }

    return () => {
      resetPolicyInvoices();
    };
  }, [policyDetailLoaded]);

  useEffect(() => {
    if (invoices.loading === false) fillChipsDynamically();
  }, [invoices.loading]);

  return (
    <>
      <PolicyDetailTopActionBar policyId={id} />

      <PolicyDetailHeader currentTab={5} policyId={id} />

      {isWaiveFeeDialogOpen && (
        <BillingConfirmFeeWaiverDialog
          isOpen={isWaiveFeeDialogOpen}
          onAccept={handleFeeWaive}
          onCancel={() => {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['waive'],
              }),
            });
          }}
        />
      )}

      {isPayInvoiceDialogOpen && <PayInvoiceDialog isOpen={isPayInvoiceDialogOpen} />}

      {isPaymentLinkDialogOpen && (
        <PaymentLinkDialog
          isOpen={isPaymentLinkDialogOpen}
          customerEmail={policyDetail?.characteristics?.data?.pol_business_email as string}
          policyId={id}
        />
      )}

      {isEnrollInAutopayDialogOpen && (
        <EnrollInAutopayDialog
          isOpen={isEnrollInAutopayDialogOpen}
          policyDetail={policyDetail}
          onClose={() => {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['enrollInAutopay'],
              }),
            });
          }}
        />
      )}

      {isStopAutopayDialogOpen && (
        <StopAutopayDialog
          isOpen={isStopAutopayDialogOpen}
          onClose={() => {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['stopAutopay'],
              }),
            });
          }}
        />
      )}

      {policyDetailLoading ? (
        <Skeleton sx={{ ml: 3, mt: 5 }} animation="wave" width={200} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: (theme) => `calc(100vh - ${theme.policyDetailFixedItemsHeight})`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              p: 2,
              pt: 5,
            }}
          >
            <Box
              sx={{
                pb: 6,
              }}
            >
              <PaymentInfo
                totalBillingPaymentAmounts={totalBillingPaymentAmounts}
                billingPaymentInfo={billingPaymentInfo}
                loading={invoices.loading}
                paymentPlanValue={policyDetail?.payment_schedule?.value}
              />
              {invoices.loading ? (
                <Box sx={{ display: 'flex' }}>
                  {[...Array(3).keys()].map((_, i) => (
                    <Skeleton
                      sx={{ ml: 2, py: 1, mb: 3 }}
                      animation="wave"
                      width={100}
                      key={i as number}
                    />
                  ))}
                </Box>
              ) : (
                <TableFilters
                  activeChip={activeChip}
                  handleChipClick={handleChipClick}
                  chips={chips}
                />
              )}
              {invoices?.loaded &&
                reversedPolicyTerms.map(([key, value]) => {
                  return (
                    <Accordion
                      key={key}
                      sx={{
                        mt: 1,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        padding: (theme) => theme.spacing(0, 2),
                        '&:before': {
                          display: 'none',
                        },
                        '& > .MuiCollapse-root': {
                          borderBottomRightRadius: 1,
                          borderBottomLeftRadius: 1,
                        },
                      }}
                      defaultExpanded={
                        invoices?.[value]?.loaded && !isEmpty(invoices?.[value]?.data)
                      }
                      expanded={expandedBillingAccordions[value] || false}
                      onChange={() => {
                        if (isEmpty(invoices?.[value]?.data)) {
                          fetchBillingData(value);
                        }
                        handleAccordionChange(value);
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore sx={{ fontSize: 27 }} />}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '20px',
                              lineHeight: '22px',
                              color: (theme) => theme.customColors.pageHeader.title,
                            }}
                          >
                            {`Term ${key}`}
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails sx={{ padding: '0 16px' }}>
                        {!isEmpty(invoices[value] ?? []) && (
                          <Box sx={{ py: 1.5 }}>
                            <InvoicesTable activeChip={activeChip} policyLocator={value} />
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PolicyDetailBillings;
