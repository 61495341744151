import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { Field, ProductWorkFlow } from 'api/models/Claims/productWorkFlow.model';
import { Make } from 'api/models/Integrations/VINtelligence/IMakes.model';
import { Model } from 'api/models/Integrations/VINtelligence/IModelsResponse.model';
import { decodeVin, getMakes, getModels } from 'api/services/Integrations/VINtelligence';
import {
  addNewEndorsementExposure,
  deleteEndorsementExposure,
  deleteEndorsementNewlyAddedExposure,
  updateEndorsementExposure,
  updateEndorsementNewlyAddedExposure,
} from 'api/services/PolicyEndorsement';
import {
  threeExposureNames,
  uwQuestionGroupName,
  vehicleExposureRelatedMappings,
  vehicleExposureRelatedMaps,
  vehicleExposureVinRelatedFields,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { VehicleDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/VehicleDrawerFieldParser';
import {
  getDepreciatedCost,
  getVehicleDTType,
  getVehicleSubtype,
  getVehicleType,
} from 'components/QuotePolicyDetailEndorsement/Vehicles/helpers';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  changeFieldsLoadingStatus,
  checkAndSetFieldsReadonlyState,
  deleteFromQueryStrings,
  fieldHasValue,
  handleBackendErrorsWithFormik,
  handleEstimatedCurrentValueFieldValidation,
  isFulfilled,
  parseLocation,
  updateVisibilityOfEstimatedCurrentValueField,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser';
import { isEmpty, isNil, omit, reduce } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import EndorsementLocationSelect from '../../../Inputs/LocationSelectInput';
import VehiclesUnderwritingQuestions from '../UnderwritingQuestions';

export interface IVehicleDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  isAdd: boolean;
}

const yearRelatedFields = ['veh_make', 'veh_model'];
const makeRelatedFields = ['veh_model'];
const categoryRelatedFields = ['veh_body_category', 'veh_body_subcategory'];
const selectFields = [...categoryRelatedFields, ...yearRelatedFields, 'veh_year'];
const numberFields = ['veh_msrp', 'veh_msrp_dpr', 'veh_stated_amt', 'veh_stated_amt_uw'];

const makeReadonlyOnEditIfVinVerified = [
  ...vehicleExposureVinRelatedFields,
  ...categoryRelatedFields,
];

const requestedFieldsFromVINtelligence = [
  'MAK_CD',
  'MAK_NM',
  'MDL_YR',
  'MDL_CD',
  'MDL_DESC',
  'SHIP_WGHT_LBS',
  'TRIM_DESC',
  'NADA_MSRP1',
  'NADA_GVWC1',
  'NADA_GCW1',
  'BODY_STYLE_CD',
  'BODY_STYLE_DESC',
  'VEH_TYP_CD',
  'VEH_TYP_DESC',
].join(',');

let decodeVinRequestController: AbortController;

const VehicleEditDrawer: FC<IVehicleDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isAdd = false,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const VEHICLE_ID = url?.vehicle;
  const [fields, setFields] = useState<any[]>([]);
  const {
    fields: fieldConfig,
    data: endorsementDetail,
    exposures,
    getExposure,
    getExposures,
    exposureList,
    underwritingQuestionsState,
  } = useEndorsementDetail();
  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();
  const { data: keyValueStore } = useKeyValues();
  const [fetchLoading, setFetchLoading] = useState(false);
  const { data: user } = useUser();

  const uwQuestionsRef = useRef<{ underwritingQuestionAnswers: () => any }>();

  const { vintelligenceYears, formatDateInTimeZone } = useConfig();

  const endorsementDetailData = endorsementDetail?.policy?.characteristics?.data ?? {};

  const vehicleExposures = exposureList?.[`${threeExposureNames.VEHICLE}`]?.data ?? [];

  // This value is determined by the state and the effective date. Since the state does not change during the quote process.
  // All locations have same fillingSetID. So we can obtain fillingSetID from one of the locations.
  const locFilingSetId =
    exposureList?.[`${threeExposureNames.LOCATION}`]?.data?.[0]?.data?.loc_filing_set_id;

  const activeExposure = useMemo(() => {
    const isNewlyAdded = VEHICLE_ID?.includes('added-');
    const index = isNewlyAdded ? VEHICLE_ID?.slice(6) : undefined;
    return (
      vehicleExposures?.find((e) =>
        isNewlyAdded ? e.index === index : e.locator === VEHICLE_ID,
      ) ?? {}
    );
  }, [vehicleExposures, VEHICLE_ID]);

  const [state, setState] = useState<any>({ ...activeExposure?.data } ?? {});
  const [visibleUnderwritingQuestions, setVisibleUnderwritingQuestions] = useState<Field[]>([]);
  // setting up only if vin code was verified by click "Verify VIN" and return code 0 - 3 button except case when it is edit drawer
  const [lastVerifiedVIN, setLastVerifiedVIN] = useState<string>('');
  const [makesAndModels, setMakesAndModels] = useState<PromiseSettledResult<Make[] | Model[]>[]>(
    [],
  );

  const initialUnderWritingQuestionsAnswer = useMemo(
    () =>
      (state?.underwriting_question as any[])?.reduce(
        (c, q) => ({ ...c, [`${q.uwq_question_id}`]: q.uwq_question_answer }),
        {},
      ) ?? {},
    [JSON.stringify(state?.underwriting_question)],
  );

  const vehicleLocationState = parseLocation(state.veh_location).state;

  const {
    vehicleBodyCategoryMapping,
    bodyStyleToCategoryMapping,
    makeCdMakeNmMapping,
    gcwMapping,
    tblGCW,
    tblDepreciation,
  } = useMemo(
    () => ({
      vehicleBodyCategoryMapping:
        keyValueStore?.[`${vehicleExposureRelatedMaps.BODY_CATEGORY}`]?.data?.value ?? [],
      bodyStyleToCategoryMapping:
        keyValueStore?.[`${vehicleExposureRelatedMaps.BODY_STYLE_TO_CATEGORY}`]?.data?.value ?? [],
      makeCdMakeNmMapping:
        keyValueStore?.[`${vehicleExposureRelatedMaps.MAKE_CD_MAKE_NM}`]?.data?.value ?? [],
      gcwMapping: keyValueStore?.[`${vehicleExposureRelatedMaps.GCW}`]?.data?.value ?? [],
      tblGCW: keyValueStore?.[`${vehicleExposureRelatedMaps.TBL_GCW}`]?.data?.value ?? [],
      tblDepreciation:
        keyValueStore?.[`${vehicleExposureRelatedMaps.TBL_DEPRECIATIOn}`]?.data?.value ?? [],
    }),
    [keyValueStore],
  ) as { [key: string]: any[] };

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(
      [...fields, ...visibleUnderwritingQuestions],
      state,
      user?.role?.code,
      handleEstimatedCurrentValueFieldValidation(fields),
    ),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };
    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema,
    onSubmit: async (_values) => {},
  });

  const handleQuery = () => {
    setIsDrawerOpen(false);

    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['vehicle', 'addVehicle'],
      }),
    });
  };

  const updateVehSubcategoryOptions = (category: string) => {
    const filteredSubCategories = vehicleBodyCategoryMapping
      .filter((c) => c.veh_body_category === category)
      ?.map(({ veh_body_subcategory }) => ({
        code: veh_body_subcategory,
        name: veh_body_subcategory,
      }));

    const hasNoSubCategory =
      filteredSubCategories?.length === 1 && filteredSubCategories?.[0].code === '';

    setFields((prevFields) =>
      prevFields.map((f) => {
        const tmpField = { ...f };

        if (hasNoSubCategory && f.code === 'veh_body_subcategory') {
          checkAndSetFieldsReadonlyState(tmpField, true);

          tmpField.type = 'string';
        } else if (!isEmpty(category) && f.code === 'veh_body_subcategory') {
          checkAndSetFieldsReadonlyState(tmpField, false);

          tmpField.type = 'select';
          tmpField.choices = filteredSubCategories;
        }

        return tmpField;
      }),
    );
  };

  const getVehLimit = () => {
    const { veh_stated_amt_uw: uwVal, veh_stated_amt: estVal, veh_msrp_dpr: depreVal } = state;

    return !isEmpty(uwVal) ? uwVal : !isEmpty(estVal) ? estVal : depreVal;
  };

  const parseUwQuestions = () => {
    const uwAnswers = uwQuestionsRef?.current?.underwritingQuestionAnswers() ?? {};

    return {
      [`${uwQuestionGroupName}`]: Object.entries(uwAnswers).map(([key, value]) => ({
        uwq_question_id: key,
        uwq_question_answer: value,
      })),
    };
  };

  const vehDTType = () => {
    const { veh_body_category, veh_body_subcategory, veh_subtype, veh_type, veh_seat_capacity } =
      state;

    return ['PPT', 'TTT', 'Public'].some((v) => v === veh_type)
      ? {
          veh_dt_type: getVehicleDTType({
            veh_body_category,
            veh_body_subcategory,
            veh_subtype,
            veh_type,
            veh_seat_capacity,
          }),
        }
      : {};
  };

  const getEndorsementDetailAndExposures = async () => {
    await getExposures(
      endorsementDetail?.policy?.locator!,
      endorsementDetail?.locator!,
      threeExposureNames.VEHICLE,
      true,
    );
  };

  const handleAdd = async () => {
    try {
      setLoading(true);

      await addNewEndorsementExposure(
        endorsementDetail?.policy?.locator!,
        endorsementDetail?.locator!,
        {
          name: 'vehicle',
          data: {
            ...state,
            veh_limit: getVehLimit(),
            ...vehDTType(),
            ...parseUwQuestions(),
          },
        },
      );

      displayToastMessage('SUCCESS', t('The vehicle has been added.'));
      handleQuery();
      await getEndorsementDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the vehicle.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const payload = {
        name: threeExposureNames.VEHICLE,
        data: {
          ...state,
          veh_limit: getVehLimit(),
          ...vehDTType(),
          ...parseUwQuestions(),
        },
      };

      if (activeExposure?.is_newly_added) {
        await updateEndorsementNewlyAddedExposure(
          endorsementDetail?.policy?.locator!,
          endorsementDetail?.locator!,
          activeExposure.index!,
          payload,
        );
      } else {
        await updateEndorsementExposure(
          endorsementDetail?.policy?.locator!,
          endorsementDetail?.locator!,
          url.vehicle as string,
          payload,
        );
      }
      displayToastMessage('SUCCESS', t('The vehicle has been updated.'));
      handleQuery();
      await getEndorsementDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the vehicle.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      if (isAdd) {
        await handleAdd();
      } else {
        await handleUpdate();
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const fetchExposureDetail = async () => {
    try {
      setFetchLoading(true);
      const detail = await getExposure(VEHICLE_ID as string, threeExposureNames.VEHICLE);
      setState((prevState) => ({ ...prevState, ...(detail?.data ?? {}) }));
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while fetching the {{variable}} information.', {
          variable: 'vehicle',
        }),
      );
      handleQuery();
    } finally {
      setFetchLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: 'DELETE_VEHICLE_EXPOSURE',
        isOpen: false,
      });
      setLoading(true);

      if (activeExposure?.is_newly_added) {
        await deleteEndorsementNewlyAddedExposure(
          endorsementDetail?.policy?.locator!,
          endorsementDetail?.locator!,
          activeExposure.index!,
        );
      } else {
        await deleteEndorsementExposure(
          endorsementDetail?.policy?.locator!,
          endorsementDetail?.locator!,
          url.vehicle as string,
        );
      }

      displayToastMessage('SUCCESS', t('The vehicle has been deleted.'));
      handleQuery();
      await getEndorsementDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the vehicle.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const getMakesAndModels = async (exposure: any, returnCode?: string) => {
    const { veh_year, veh_make } = exposure;

    if (veh_year && veh_make) {
      try {
        setFetchLoading(true);
        // activate loading on related fields
        setFields((prevFields) => changeFieldsLoadingStatus(prevFields, yearRelatedFields, true));

        const taskOne = getMakes(veh_year);
        const taskTwo = getModels({
          year: veh_year,
          make: makeCdMakeNmMapping.find(({ MAK_NM }) => MAK_NM === veh_make)?.MAK_CD ?? '',
        });

        const [makesResult, modelsResult] = await Promise.allSettled([taskOne, taskTwo]);

        setMakesAndModels([makesResult, modelsResult]);

        const makes = isFulfilled(makesResult) ? makesResult?.value : [];
        const models = isFulfilled(modelsResult) ? modelsResult?.value : [];

        setFields((prevFields) =>
          prevFields.map((f) => {
            const tmpField = { ...f };

            if (f.code === 'veh_make') {
              tmpField.choices =
                makes.map(({ description }) => ({
                  code: description,
                  name: description,
                })) ?? [];
              tmpField.additional_data.loading = false;
            }

            if (f.code === 'veh_model') {
              tmpField.choices =
                models.map(({ description }) => ({
                  code: description,
                  name: description,
                })) ?? [];

              tmpField.additional_data.loading = false;
            }

            /**
             * This condition manage type for fields which was received during fetching VIN info
             * veh_vin, veh_location, veh_gvw - fields which should be untouched
             *
             * (isAdd || (!isAdd && ...)) - specifies behavior for edit and add drawers
             * !['2', '3'].includes(returnCode ?? '') - specifies behavior for cases if vin has typo and was corrected
             */
            if (
              exposure[f.code] &&
              f.code !== 'veh_vin' &&
              f.code !== 'veh_location' &&
              f.code !== 'veh_gvw' &&
              (isAdd || (!isAdd && exposure.veh_vin !== activeExposure.data?.veh_vin)) &&
              !['2', '3'].includes(returnCode ?? '')
            ) {
              tmpField.is_readonly = true;
              tmpField.type = numberFields.includes(f.code) ? 'number' : 'string';
            }

            return tmpField;
          }),
        );
      } catch (_error) {
        setFields((prevFields) => changeFieldsLoadingStatus(prevFields, yearRelatedFields, false));
        displayToastMessage('ERROR', t('An error occurred while fetching makes and models.'));
      } finally {
        setFetchLoading(false);
      }
    }
  };

  const handleInitializeVehicleFields = () => {
    const vehicleFields =
      (fieldConfig?.exposure?.data as ProductWorkFlow[])?.find(
        (config) => config.code === threeExposureNames.VEHICLE,
      )?.fields ?? [];

    setFields(() => [
      ...vehicleFields?.map((f) => {
        const tmpField = { ...f };

        if (f.code === 'veh_body_category') {
          tmpField.type = 'select';
          tmpField.choices = vehicleBodyCategoryMapping.map(({ veh_body_category }) => ({
            code: veh_body_category,
            name: veh_body_category,
          }));
        }

        if (f.code === 'veh_year') {
          tmpField.choices =
            vintelligenceYears.data?.map((year) => ({ code: year, name: year })) ?? [];
        }

        if (f.code === 'veh_gvw') {
          tmpField.type = 'select';
          tmpField.choices =
            gcwMapping?.map(({ dropdown }) => ({ code: dropdown, name: dropdown })) ?? [];
        }

        return tmpField;
      }),
    ]);
  };

  useEffect(() => {
    handleInitializeVehicleFields();
  }, [fieldConfig, vehicleBodyCategoryMapping, vintelligenceYears.loaded]);

  useEffect(() => {
    if (!isAdd && !isEmpty(activeExposure) && activeExposure?.data?.veh_vin_isvalid === 'Yes') {
      setFields((prevFields) =>
        prevFields.map((f) => {
          const tmpField = { ...f };

          // make fields read only
          if (makeReadonlyOnEditIfVinVerified.includes(f.code)) {
            tmpField.is_readonly = true;
            tmpField.type = 'string';
          }

          return tmpField;
        }),
      );
    }
  }, [isAdd, fieldConfig, activeExposure]);

  useEffect(() => {
    if (exposures?.loaded && !isAdd && !isEmpty(endorsementDetail)) {
      // if exposure was found in the exposures list, do not fetch exposure detail from BE
      if (isEmpty(state)) {
        fetchExposureDetail();
      }
    }
  }, [isAdd, endorsementDetail, exposures?.loaded]);

  useEffect(() => {
    if (!isEmpty(endorsementDetail)) {
      const isNewlyAdded = VEHICLE_ID?.includes('added-');

      setState((prevState) => ({
        ...prevState,
        date_added: formatDateInTimeZone(
          isNewlyAdded
            ? endorsementDetail?.endorsement_start_at
            : endorsementDetail?.policy?.characteristics?.started_at,
        ),
      }));
    }
  }, [endorsementDetail]);

  useEffect(() => {
    if (!isEmpty(fieldConfig)) {
      const activedata = activeExposure?.data ?? {};

      if (!isEmpty(activeExposure)) {
        updateVehSubcategoryOptions(activeExposure?.data?.veh_body_category);
        if (!isAdd && activeExposure?.data?.veh_vin_isvalid !== 'Yes') {
          getMakesAndModels(activeExposure?.data);
        }
      }
      setState((prevState) => ({ ...prevState, ...activedata }));
    }
    // make initial determinations
  }, [fieldConfig, activeExposure]);

  useEffect(() => {
    if (!isNil(state.veh_msrp)) {
      updateVisibilityOfEstimatedCurrentValueField(state, setFields);
    }
  }, [state.veh_msrp, state.veh_vin_isvalid]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      !exposures?.loaded ||
      !fieldConfig?.exposure?.loaded ||
      !exposureList?.[`${threeExposureNames.LOCATION}`].loaded ||
      !exposureList?.[`${threeExposureNames.VEHICLE}`].loaded ||
      !vehicleExposureRelatedMappings.some((map) => keyValueStore?.[`${map}`]?.loaded ?? true) ||
      fetchLoading;

    return isAdd ? defaultLoading : defaultLoading || isEmpty(activeExposure);
  }, [isAdd, activeExposure, fieldConfig, keyValueStore, fetchLoading, exposures?.loaded]);

  useEffect(() => {
    if (!showLoader) {
      const underwritingQuestionKeyValues = !isEmpty(
        uwQuestionsRef?.current?.underwritingQuestionAnswers(),
      )
        ? uwQuestionsRef?.current?.underwritingQuestionAnswers()
        : initialUnderWritingQuestionsAnswer;

      formik.setValues({
        ...state,
        // map answered questions to state
        ...underwritingQuestionKeyValues,
      });
    }
  }, [state, showLoader]);

  const addVehSubTypeAndClassToData = (result: any) => {
    const r = { ...result };

    const {
      veh_body_category,
      veh_body_subcategory,
      veh_gvw,
      veh_type,
      veh_gcw,
      veh_seat_capacity,
    } = r;

    const determined = getVehicleSubtype({
      policyData: endorsementDetailData,
      veh_body_category,
      veh_body_subcategory,
      veh_type,
      veh_gvw,
      tblGVW: gcwMapping,
      veh_gcw,
      tblGCW,
    });

    const vehDttype =
      ['PPT', 'TTT', 'Public'].some((v) => v === veh_type) &&
      getVehicleDTType({
        veh_body_category,
        veh_body_subcategory,
        veh_subtype: determined?.veh_subtype,
        veh_type,
        veh_seat_capacity,
      });

    r.veh_subtype = determined?.veh_subtype;
    r.veh_class = determined?.veh_class;
    // veh_deductible default `500` https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11093/
    r.veh_deductible = '500';
    if (vehDttype) {
      r.veh_dt_type = vehDttype;
    }

    return r;
  };

  const handleEmitedInputChange = async ({ field, value }: { field: string; value: any }) => {
    switch (field) {
      case 'veh_location': {
        // update location related field if category and subcategory is selected
        if (!isEmpty(state.veh_body_category) && !isEmpty(state.veh_body_subcategory)) {
          const newVehicleLocationState = parseLocation(value)?.state;
          setState((prevState) => {
            const veh_type = getVehicleType({
              policyData: endorsementDetailData,
              bodyCategory: prevState.veh_body_category,
              bodySubCategory: prevState.veh_body_subcategory,
              table: vehicleBodyCategoryMapping,
              vehicleLocationState: newVehicleLocationState,
              locFilingSetId,
            });

            let result = {
              ...prevState,
              veh_type,
            };

            result = addVehSubTypeAndClassToData(result);

            return { ...result };
          });
        }

        break;
      }
      case 'veh_body_category': {
        updateVehSubcategoryOptions(value);

        setState((prevState) => {
          const veh_type = getVehicleType({
            policyData: endorsementDetailData,
            bodyCategory: value,
            bodySubCategory: '',
            table: vehicleBodyCategoryMapping,
            vehicleLocationState,
            locFilingSetId,
          });

          let result = {
            ...prevState,
            veh_body_subcategory: '',
            veh_type,
          };

          result = addVehSubTypeAndClassToData(result);

          return { ...result };
        });

        break;
      }

      case 'veh_body_subcategory': {
        setState((prevState) => {
          const veh_type = getVehicleType({
            policyData: endorsementDetailData,
            bodyCategory: state.veh_body_category,
            bodySubCategory: value,
            table: vehicleBodyCategoryMapping,
            vehicleLocationState,
            locFilingSetId,
          });

          let result = {
            ...prevState,
            veh_type,
          };

          result = addVehSubTypeAndClassToData(result);

          return { ...result };
        });
        break;
      }

      case 'veh_gvw': {
        setState((prevState) => {
          let result = {
            ...prevState,
          };

          result = addVehSubTypeAndClassToData(result);

          return { ...result };
        });
        break;
      }

      case 'veh_year': {
        try {
          // reset related fields
          setState((prevState) => ({
            ...omit(prevState, yearRelatedFields),
          }));

          // activate loading on related fields
          setFields((prevFields) => changeFieldsLoadingStatus(prevFields, yearRelatedFields, true));

          const makes = (await getMakes(value)) ?? [];

          setFields((prevFields) =>
            prevFields.map((f) => {
              const tmpField = { ...f };

              if (f.code === 'veh_make') {
                tmpField.choices =
                  makes?.map(({ description }) => ({
                    code: description,
                    name: description,
                  })) ?? [];
              }

              if (yearRelatedFields.includes(f.code)) {
                tmpField.additional_data.loading = false;
              }

              return tmpField;
            }),
          );
        } catch (_error) {
          setFields((prevFields) =>
            changeFieldsLoadingStatus(prevFields, yearRelatedFields, false),
          );
        }

        break;
      }

      case 'veh_make': {
        try {
          // reset related fields
          setState((prevState) => ({
            ...omit(prevState, makeRelatedFields),
          }));

          // activate loading on related fields
          setFields((prevFields) => changeFieldsLoadingStatus(prevFields, makeRelatedFields, true));

          const models =
            (await getModels({
              year: state.veh_year,
              make: makeCdMakeNmMapping.find((m) => m.MAK_NM === value)?.MAK_CD ?? '',
            })) ?? [];

          setFields((prevFields) =>
            prevFields.map((f) => {
              const tmpField = { ...f };

              if (f.code === 'veh_model') {
                tmpField.choices =
                  models?.map(({ description }) => ({
                    code: description,
                    name: description,
                  })) ?? [];
              }

              if (makeRelatedFields.includes(f.code)) {
                tmpField.additional_data.loading = false;
              }

              return tmpField;
            }),
          );
        } catch (_error) {
          setFields((prevFields) =>
            changeFieldsLoadingStatus(prevFields, makeRelatedFields, false),
          );
        }

        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    setFields((prevFields) =>
      prevFields.map((f) => {
        const tmpField = { ...f };

        // make estimated cost required if vin is not verified
        if (f.code === 'veh_stated_amt') {
          if (!f.is_hidden) {
            tmpField.is_optional = false;
          } else {
            tmpField.is_optional = true;
          }
        }

        // make veh_gvw readOnly if veh_type is not TTT
        if (['veh_gvw'].includes(f.code ?? '')) {
          if (state.veh_type === 'TTT') {
            checkAndSetFieldsReadonlyState(tmpField, false);
            tmpField.is_optional = false;
          } else {
            checkAndSetFieldsReadonlyState(tmpField, true);
            tmpField.is_optional = true;
            // also reset it's value
            setState((prevState) => ({ ...prevState, veh_gvw: '' }));
          }
        }

        return tmpField;
      }),
    );
  }, [JSON.stringify(state), JSON.stringify(fields)]);

  const makeVinRelatedFieldLoading = (loading: boolean) => {
    setFields((prevFields) =>
      changeFieldsLoadingStatus(
        prevFields,
        prevFields
          .filter((fiel) => fiel.additional_data?.vinDecodeRelatedField === true)
          .map((f) => f.code),
        loading,
      ),
    );
  };

  const findErrorMessage = (returnCode?: string) => {
    switch (returnCode) {
      case '2':
        return t(
          'Valid VIN, but unable to pull complete details. Please enter vehicle details manually.',
        );
      case '3':
        return t('Invalid VIN. Retry or enter vehicle details manually.');
      case '4':
        return t(
          'Valid VIN, but unable to pull complete details. Please enter vehicle details manually.',
        );
      case '5':
        return t('Invalid VIN. Retry or enter vehicle details manually.');

      default:
        return t('VIN service unavailable. Please come back later to enter vehicle details.');
    }
  };

  const handleSetMakesAndModelsChoices = async (veh_year, veh_make) => {
    const makes = getMakes(veh_year);
    const models = getModels({
      year: veh_year,
      make: makeCdMakeNmMapping.find(({ MAK_NM }) => MAK_NM === veh_make)?.MAK_CD ?? '',
    });

    const res = await Promise.allSettled([makes, models]);

    setMakesAndModels(res);
  };

  useEffect(() => {
    // set fields readonly status when drawer was newly opened and VIN is valid
    if (state.veh_vin_isvalid === 'Yes') {
      setFields((prevFields) =>
        prevFields.map((f) => {
          const tmpField = { ...f };
          if (makeReadonlyOnEditIfVinVerified.includes(f.code)) {
            tmpField.is_readonly = true;
            tmpField.type = 'string';
          }

          return tmpField;
        }),
      );
    }

    // reset fields and state if vin was changed
    if (state.veh_vin !== lastVerifiedVIN && lastVerifiedVIN) {
      // set to don't fall in this condition again if vin was not verified
      setLastVerifiedVIN('');
      setFields((prevFields) =>
        prevFields.map((f) => {
          const tmpField = { ...f };
          tmpField.is_readonly = false;
          if (selectFields.includes(tmpField.code)) {
            tmpField.type = 'select';
          }
          const [makes, models] = makesAndModels;

          if (tmpField.code === 'veh_make') {
            tmpField.choices =
              isFulfilled(makes) && makes?.value
                ? (makes.value as Make[]).map(({ description }) => ({
                    code: description,
                    name: description,
                  }))
                : [];
          }

          if (tmpField.code === 'veh_model') {
            tmpField.choices =
              isFulfilled(models) && models?.value
                ? (models.value as Model[]).map(({ description }) => ({
                    code: description,
                    name: description,
                  }))
                : [];
          }

          return tmpField;
        }),
      );
      setState((prevState) => ({
        ...prevState,
        veh_vin_isvalid: 'No',
        veh_msrp: '',
        veh_msrp_dpr: '',
      }));
    }

    // set makes and models if edit drawer was opened and VIN field was changed. Check vin valid to ensure of need fetch makes and models
    if (!isAdd && !makesAndModels.length && state.veh_vin_isvalid === 'Yes') {
      handleSetMakesAndModelsChoices(state.veh_year, state.veh_make);
      // set last verified VIN for trigger logic in upper condition
      setLastVerifiedVIN(state.veh_vin);
    }
  }, [state.veh_vin]);

  const fetchVinInfo = async () => {
    try {
      // abort previous request if made
      decodeVinRequestController?.abort();
      decodeVinRequestController = new AbortController();
      let isVinValid = 'No';
      let temporaryState = { ...state };

      makeVinRelatedFieldLoading(true);

      handleInitializeVehicleFields();
      setState((prevState) => ({
        veh_vin: prevState.veh_vin,
        date_added: prevState.date_added,
        veh_location: prevState.veh_location,
      }));

      const decodedVin =
        (
          await decodeVin(
            {
              vin_requests: [{ vin: state.veh_vin }],
              requested_fields: requestedFieldsFromVINtelligence,
            },
            decodeVinRequestController,
          )
        )?.[0] ?? {};

      const { fields: rawFields, returnCode } = decodedVin;
      const returnCodeNumber = Number(returnCode);

      const decodedFields =
        reduce(
          rawFields,
          (acc, field) => ({ ...acc, [`${field.name}`]: field.value ?? '' }),
          {} as Record<string, string>,
        ) ?? {};

      if (!isNil(returnCodeNumber) && returnCodeNumber < 4) {
        setState((prevState) => {
          let tmpState = { ...prevState };

          const findedCategory = bodyStyleToCategoryMapping.find(
            (c) => c.body_style_cd === decodedFields?.BODY_STYLE_CD,
          );

          tmpState.veh_year = decodedFields?.MDL_YR || '';
          tmpState.veh_make = decodedFields?.MAK_NM || '';
          tmpState.veh_model = decodedFields?.MDL_DESC || '';
          tmpState.veh_trim = decodedFields?.TRIM_DESC || '';
          tmpState.veh_msrp = decodedFields?.NADA_MSRP1;

          if (!isNil(decodedFields?.NADA_GCW1)) {
            const foundInGCWMapping = tblGCW?.find(
              (mapping) =>
                decodedFields?.NADA_GCW1! >= mapping.gcwMin &&
                decodedFields?.NADA_GCW1! <= mapping.gcwMax,
            );

            if (foundInGCWMapping) {
              tmpState.veh_gcw = foundInGCWMapping?.dropdown ?? '';
            }
          }

          if (!isNil(decodedFields?.NADA_GVWC1)) {
            const foundInGCWMapping = gcwMapping?.find(
              (mapping) =>
                decodedFields?.NADA_GVWC1! >= mapping.gvwMin &&
                decodedFields?.NADA_GVWC1! <= mapping.gvwMax,
            );

            if (foundInGCWMapping) {
              tmpState.veh_gvw = foundInGCWMapping?.dropdown ?? '';
            }
          }

          tmpState.veh_gcwr = decodedFields?.NADA_GCW1;
          tmpState.veh_gvwr = decodedFields?.NADA_GVWC1;

          isVinValid =
            returnCodeNumber === 0 &&
            [tmpState.veh_year, tmpState.veh_make, tmpState.veh_model].every((v) =>
              fieldHasValue(v),
            )
              ? 'Yes'
              : 'No';

          tmpState.veh_vin_isvalid = isVinValid;

          tmpState.veh_type = getVehicleType({
            policyData: endorsementDetailData,
            bodyCategory: '',
            bodySubCategory: '',
            table: vehicleBodyCategoryMapping,
            vehicleLocationState,
            locFilingSetId,
          });

          if (findedCategory) {
            updateVehSubcategoryOptions(findedCategory.veh_body_category);
            tmpState.veh_body_category = findedCategory.veh_body_category;
            tmpState.veh_body_subcategory = findedCategory.veh_body_subcategory;
            tmpState.veh_type = getVehicleType({
              policyData: endorsementDetailData,
              bodyCategory: findedCategory.veh_body_category,
              bodySubCategory: findedCategory.veh_body_subcategory,
              table: vehicleBodyCategoryMapping,
              vehicleLocationState,
              locFilingSetId,
            });
          } else {
            tmpState.veh_body_category = '';
            tmpState.veh_body_subcategory = '';
          }

          tmpState = addVehSubTypeAndClassToData(tmpState);

          tmpState.veh_msrp_dpr = getDepreciatedCost({
            veh_msrp: tmpState.veh_msrp,
            veh_year: tmpState.veh_year,
            yearAdded: tmpState.date_added?.split('/').pop(),
            tblDepreciation,
          });

          temporaryState = { ...tmpState };

          return { ...tmpState };
        });

        setLastVerifiedVIN(state.veh_vin);

        if (isVinValid === 'Yes') {
          handleSetMakesAndModelsChoices(temporaryState.veh_year, temporaryState.veh_make);
          // make related fields read only after the response
          setFields((prevFields) =>
            prevFields.map((fiel) => {
              const tmpField = { ...fiel };
              // check if recived fields have value and set readonly status
              if (makeReadonlyOnEditIfVinVerified.includes(fiel.code)) {
                tmpField.is_readonly =
                  fiel.code === 'veh_trim' ? true : !!temporaryState[fiel.code];
                tmpField.type =
                  !temporaryState[fiel.code] && selectFields.includes(fiel.code)
                    ? 'select'
                    : 'string';
              }

              return tmpField;
            }),
          );
        } else {
          await getMakesAndModels(temporaryState, returnCode);
        }

        if (returnCodeNumber > 1) {
          displayToastMessage('ERROR', findErrorMessage(returnCode));
        }
      } else {
        displayToastMessage('ERROR', findErrorMessage(returnCode));
        // reset values on invalid VIN
        setState((prevState) => ({
          ...prevState,
          veh_body_category: '',
          veh_body_subcategory: '',
          veh_year: '',
          veh_make: '',
          veh_model: '',
          veh_trim: '',
          veh_gvw: '',
          veh_msrp: '',
          veh_msrp_dpr: '',
          veh_stated_amt: '',
          veh_vin_isvalid: false,
        }));
        // if vin was invalid(returnCode 4,5) drop value
        setLastVerifiedVIN('');
      }

      if (isVinValid === 'No') {
        setState((prevState) => {
          const tmpState = { ...prevState };
          tmpState.veh_vin_isvalid = isVinValid;
          return { ...tmpState };
        });
      }

      makeVinRelatedFieldLoading(false);
    } catch (error) {
      const e = error as any;

      if (e?.message !== 'canceled') {
        displayBackendErrorMessage(
          error,
          t('VIN service unavailable. Please come back later to enter vehicle details.'),
        );

        makeVinRelatedFieldLoading(false);
      }
    }
  };

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        width="800px"
        onClose={handleQuery}
        headerSx={{ mb: 2 }}
        isContentScrollable
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Vehicle Details')}
          </Typography>
        }
        content={
          <Stack gap={2} sx={{ mb: 3, pt: 1 }}>
            <VehicleDrawerFieldParser
              formik={formik}
              state={state}
              fields={fields}
              setState={setState}
              showLoader={showLoader}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
              handleVerifyVINClick={() => fetchVinInfo()}
              onChangeEmited={(emitedEvent) => handleEmitedInputChange(emitedEvent)}
              LocationSelect={EndorsementLocationSelect}
            />

            {(isAdd
              ? !showLoader
              : !showLoader && !isEmpty(initialUnderWritingQuestionsAnswer)) && (
              <VehiclesUnderwritingQuestions
                initialData={{
                  ...omit(underwritingQuestionsState, uwQuestionGroupName),
                  ...omit(state, uwQuestionGroupName),

                  // map answered questions to state
                  ...initialUnderWritingQuestionsAnswer,
                }}
                formik={formik}
                setVisibleUnderwritingQuestions={setVisibleUnderwritingQuestions}
                isReadOnly={false}
                ref={uwQuestionsRef}
              />
            )}
          </Stack>
        }
        footer={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {!isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="60px" height={32} />
              ) : (
                <Button
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'DELETE_VEHICLE_EXPOSURE',
                      isOpen: true,
                      onAccept: () => handleDelete(),
                    })
                  }
                  sx={[drawerFooterSecondaryButtonStyle]}
                >
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}

            <Stack direction="row" gap={1.5}>
              {showLoader ? (
                <>
                  <Skeleton animation="wave" width="60px" height={32} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    data-test="save"
                    onClick={handleSave}
                    sx={[drawerFooterPrimaryButtonStyle]}
                  >
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default VehicleEditDrawer;
