/* eslint-disable no-restricted-syntax */
import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { getKeyValuesService } from 'api/services/KeyValues';
import {
  hazardRelatedFieldCodes,
  liabilityExposurePerils,
  liabilityExposureRelatedKeyValues,
  liabilityExposureRelatedKeyValuesMap,
  liabilityExposuresFieldCodes,
  LIABILITY_PERILS as liabilityPerils,
  lookupTableTypes,
  threeExposureNames,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { LiabilityExposurePerilDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/LiabilityExposurePerilDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useKeyValues from 'hooks/useKeyValues';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import QuoteLocationSelect from '../../../Inputs/LocationSelectInput';

const { perilType, classCode, classCodeDescription, basis, divider, rate } =
  liabilityExposuresFieldCodes;

export interface LiabilityExposurePerilDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
}

const LiabilityExposurePerilDetailDrawer: FC<LiabilityExposurePerilDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const { exposureId, perilId } = url;
  const { fields: fieldConfig, exposureList, data: quoteDetail } = useQuoteDetail();
  const [fields, setFields] = useState<any[]>([]);

  const quoteDetailData = quoteDetail?.characteristics?.[0]?.data ?? {};

  const { data: keyValueStore } = useKeyValues();

  const LIABILITY_PERILS =
    keyValueStore?.[`${liabilityExposureRelatedKeyValues.LIABILITY_PERILS}`]?.data?.value ?? [];

  function extractData(param) {
    const matchedData = LIABILITY_PERILS.find((d) => d.name === param.name);

    const extractedData = {};
    if (!param?.data && !matchedData) return {};
    for (const [key, value] of Object.entries(param?.data)) {
      const fieldKey = Object.entries(matchedData?.fieldKeys!).find(([_, v]) => v === key)?.[0];
      if (fieldKey) {
        extractedData[fieldKey] = value;
      }
    }

    return extractedData;
  }

  function getPerilValue(perilName, key) {
    const peril = LIABILITY_PERILS.find((p) => p.name === perilName);
    return peril ? peril[key] : null;
  }

  // get exposures from provider
  const liabilityExposures = exposureList?.[`${threeExposureNames.LOCATION}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => liabilityExposures?.find((e) => e.locator === exposureId) ?? {},
    [liabilityExposures, exposureId],
  );

  const activePeril = useMemo(
    () => activeExposure?.perils?.find((p) => p.locator === perilId) ?? {},
    [activeExposure],
  );

  const [state, setState] = useState<any>({});

  const getPerilSpecificFields = (exactPeril, perilData) => {
    const {
      pol_tax_gl_default,
      pol_tax_gl_rule,
      pol_gl_exposure_amount_default,
      pol_gl_exposure_amount_rule,
      pol_gl_oo_count_rule,
      pol_tax_gl_91583_default,
      pol_gl_91583_exposure_amount_default,
      pol_gl_91583_exposure_amount_rule,
      pol_gl_91583_oo_count_default,
      pol_tax_gl_46622_default,
      pol_gl_46622_exposure_amount_rule,
      pol_gl_46622_exposure_amount_default,
      pol_gl_46622_oo_count_default,
      pol_gltx_revenue_default,
      pol_gltx_revenue_rule,
      pol_tax_cy_hazardgroup,
      pol_cy_hazard_rule,
      pol_cy_revenue_default,
      pol_cy_revenue_rule,
      pol_fidu_ee_count_default,
      pol_fidu_ee_count_rule,
      pol_fidu_indicator_default,
      pol_fidu_indicator_rule,
      pol_tax_do_hazardgroup,
      pol_do_hazard_rule,
      pol_do_revenue_default,
      pol_do_revenue_rule,
      pol_do_indicator_default,
      pol_do_indicator_rule,
      pol_tax_ep_hazardgroup,
      pol_epli_hazard_rule,
      pol_epli_ee_count_default,
      pol_epli_ee_count_rule,
      pol_epli_indicator_default,
      pol_epli_indicator_rule,
      pol_gk_revenue_default,
      pol_gk_revenue_rule,
      pol_tax_liq_default,
      pol_liq_class_code_rule,
      pol_liq_alcohol_sales_default,
      pol_liq_alcohol_sales_rule,
      pol_tax_liq_indicator,
      pol_liq_indicator_rule,
      pol_tax_pl_hazardgroup,
      pol_pl_hazardgroup_rule,
      pol_pl_revenue_default,
      pol_pl_revenue_rule,
      pol_pl_contract_quality_default,
      pol_pl_contract_quality_rule,
      pol_tax_pl_indicator,
      pol_pl_indicator_rule,
      pol_pl_lawyer_fte_default,
      pol_pl_lawyer_fte_rule,
      pol_pl_lawyer_pte_default,
      pol_pl_lawyer_pte_rule,
      pol_catx_revenue_default,
      pol_catx_revenue_rule,
      pol_trix_revenue_default,
      pol_trix_revenue_rule,
    } = quoteDetailData;

    return {
      // glpo peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.glpo.code && {
        class_code: (() => {
          switch (perilData?.glpo_fixed_classcode) {
            case '91583':
              return pol_tax_gl_91583_default;
            case '46622':
              return pol_tax_gl_46622_default;
            default:
              return pol_tax_gl_rule ?? pol_tax_gl_default;
          }
        })(),
        exposure_amount: (() => {
          switch (perilData?.glpo_fixed_classcode) {
            case '91583':
              return pol_gl_91583_exposure_amount_rule ?? pol_gl_91583_exposure_amount_default;
            case '46622':
              return pol_gl_46622_exposure_amount_rule ?? pol_gl_46622_exposure_amount_default;
            default:
              return pol_gl_exposure_amount_rule ?? pol_gl_exposure_amount_default;
          }
        })(),
        glpo_oo_count: (() => {
          switch (perilData?.glpo_fixed_classcode) {
            case '91583':
              return pol_gl_91583_oo_count_default;
            case '46622':
              return pol_gl_46622_oo_count_default;
            default:
              return pol_gl_oo_count_rule;
          }
        })(),
      }),
      // glpcOperations peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.glpcOperations.code && {
        class_code: (() => {
          switch (perilData?.glpc_fixed_classcode) {
            case '91583':
              return pol_tax_gl_91583_default;
            default:
              return pol_tax_gl_rule ?? pol_tax_gl_default;
          }
        })(),
        exposure_amount: (() => {
          switch (perilData?.glpc_fixed_classcode) {
            case '91583':
              return pol_gl_91583_exposure_amount_rule ?? pol_gl_91583_exposure_amount_default;
            default:
              return pol_gl_exposure_amount_rule ?? pol_gl_exposure_amount_default;
          }
        })(),
        glpc_oo_count: (() => {
          switch (perilData?.glpc_fixed_classcode) {
            case '91583':
              return pol_gl_91583_oo_count_default;
            default:
              return pol_gl_oo_count_rule;
          }
        })(),
      }),
      // generalLiabilityTerrorism peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.generalLiabilityTerrorism.code && {
        exposure_amount: pol_gltx_revenue_rule ?? pol_gltx_revenue_default,
      }),
      // cyberLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.cyberLiability.code && {
        hazard_group: pol_cy_hazard_rule ?? pol_tax_cy_hazardgroup,
        exposure_amount: pol_cy_revenue_rule ?? pol_cy_revenue_default,
      }),
      // fiduciary peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.fiduciaryLiability.code && {
        fidu_indicator: pol_fidu_indicator_rule ?? pol_fidu_indicator_default,
        exposure_amount: pol_fidu_ee_count_rule ?? pol_fidu_ee_count_default,
      }),
      // directorsOfficersLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.directorsOfficersLiability.code && {
        hazard_group: pol_do_hazard_rule ?? pol_tax_do_hazardgroup,
        exposure_amount: pol_do_revenue_rule ?? pol_do_revenue_default,
        do_indicator: pol_do_indicator_rule ?? pol_do_indicator_default,
      }),
      // employmentPracticesLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.employmentPracticesLiability.code && {
        hazard_group: pol_epli_hazard_rule ?? pol_tax_ep_hazardgroup,
        exposure_amount: pol_epli_ee_count_rule ?? pol_epli_ee_count_default,
        epli_indicator: pol_epli_indicator_rule ?? pol_epli_indicator_default,
      }),
      // garagekeepersLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.garagekeepersLiability.code && {
        exposure_amount: pol_gk_revenue_rule ?? pol_gk_revenue_default,
      }),
      // generalLiabilityLiquorLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.generalLiabilityLiquorLiability.code && {
        class_code: pol_liq_class_code_rule ?? pol_tax_liq_default,
        exposure_amount: pol_liq_alcohol_sales_rule ?? pol_liq_alcohol_sales_default,
        liq_indicator: pol_liq_indicator_rule ?? pol_tax_liq_indicator,
      }),
      // professionalLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.professionalLiability.code && {
        hazard_group: pol_pl_hazardgroup_rule ?? pol_tax_pl_hazardgroup,
        exposure_amount: pol_pl_revenue_rule ?? pol_pl_revenue_default,
        pl_contract_quality: pol_pl_contract_quality_rule ?? pol_pl_contract_quality_default,
        pl_indicator: pol_pl_indicator_rule ?? pol_tax_pl_indicator,
        pl_lawyer_fte: pol_pl_lawyer_fte_rule ?? pol_pl_lawyer_fte_default,
        pl_lawyer_pte: pol_pl_lawyer_pte_rule ?? pol_pl_lawyer_pte_default,
      }),
      // commercialAutoTerrorism peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.commercialAutoTerrorism.code && {
        exposure_amount: pol_catx_revenue_rule ?? pol_catx_revenue_default,
      }),
      // trailerInterchange peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.trailerInterchange.code && {
        exposure_amount: pol_trix_revenue_rule ?? pol_trix_revenue_default,
      }),
    };
  };

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['perilId', 'exposureId'],
      }),
    });
  };

  const getPerilData = async () => {
    const perilData = extractData(activePeril) as any;

    const exactPeril = LIABILITY_PERILS.find((peril) => peril.name === activePeril.name);

    return { perilData, exactPeril };
  };

  const getTerritory = async (exposure) => {
    const territoryQuery = {
      search__FilingSetID: exposure!.data?.loc_filing_set_id,
      search__ZipCode: exposure!.data?.loc_address_zip,
      policy_locator: quoteDetail?.policy_locator,
    };

    const lookupTableResponse = await getKeyValuesService(lookupTableTypes.TERRITORY_LOOKUP, {
      query: territoryQuery,
    });
    const data = lookupTableResponse?.value?.[0] ?? {};

    if (isEmpty(data)) throw new Error();

    return data?.Territory;
  };

  const getBasisDividerRate = async (exactPeril, exposure, territory, perilData) => {
    const query = {
      search__Peril: exactPeril?.shortName,
      search__FilingSetID: exposure!.data?.loc_filing_set_id,
      ...(territory && { search__Territory: territory }),
      ...(perilData?.class_code_override
        ? { search__ClassCode: perilData.class_code_override }
        : perilData?.class_code && { search__ClassCode: perilData.class_code }),
      ...(perilData?.hazard_group && { search__HazardGroup: perilData.hazard_group }),
      policy_locator: quoteDetail?.policy_locator,
    };

    const lookupTableResponse = await getKeyValuesService(lookupTableTypes.LIABILITY_PERILS, {
      query,
    });
    const data = lookupTableResponse?.value?.[0] ?? {};

    return data;
  };

  const getPerilInfo = async () => {
    try {
      if (isEmpty(activePeril)) throw new Error();

      // eslint-disable-next-line prefer-const
      let { perilData, exactPeril } = await getPerilData();

      perilData = {
        ...perilData,
        ...getPerilSpecificFields(exactPeril, activePeril.data),
      };

      if (exactPeril.hasClassCode || exactPeril.hasHazard) {
        let territory;
        if (['GLPO', 'GLPC'].includes(exactPeril?.shortName)) {
          territory = await getTerritory(activeExposure);
        }

        const data = await getBasisDividerRate(exactPeril, activeExposure, territory, perilData);

        const { ClassDescription, ExposureBasis, ExposureDivisor, Rate } = data ?? {};

        setState((prevState) => ({
          ...prevState,
          ...activePeril.data,
          peril_type: activePeril.name,
          location: activeExposure?.locator,
          ...perilData,
          ...(perilData.class_code &&
            !isEmpty(data) && { class_code_description: ClassDescription }),
          basis: ExposureBasis ?? '',
          divider: ExposureDivisor ?? 'NULL',
          rate: Rate ?? 'NULL',
        }));

        if (isEmpty(data)) throw new Error();
      } else {
        setState((prevState) => ({
          ...prevState,
          ...activePeril.data,
          peril_type: activePeril.name,
          location: activeExposure?.locator,
          ...perilData,
          basis: exactPeril.basis ?? '',
          divider: 'NULL',
          rate: 'NULL',
        }));
      }
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('The {{variable}} is not correct.', {
          variable: state.class_code ? 'class code' : 'hazard group',
        }),
      );
      handleQuery();
    }
  };

  useEffect(() => {
    let tmpFields =
      (fieldConfig?.peril?.data?.[`${threeExposureNames.LOCATION}`] as ProductWorkFlow[])?.find(
        (config) => config.code === liabilityPerils,
      )?.fields ?? [];

    tmpFields = tmpFields.map((f) => {
      const tmpField = { ...f };

      if (f.code === perilType) {
        const allPerils =
          LIABILITY_PERILS?.map(({ name, title }) => ({
            code: name,
            name: title,
          })) ?? [];
        tmpField.choices = allPerils as any;
      }

      if (state.peril_type) {
        if (f.code === classCode || f.code === classCodeDescription) {
          tmpField.is_hidden = !getPerilValue(state.peril_type, 'hasClassCode');
        }

        if (hazardRelatedFieldCodes.includes(f.code as any)) {
          tmpField.is_hidden = !getPerilValue(state.peril_type, 'hasHazard');
          if (!tmpField.is_hidden) {
            const hazardGroupDropdownValues = getPerilValue(
              state.peril_type,
              'hazardGroupDropdown',
            ).map((item) => ({
              code: item,
              name: item,
            }));
            tmpField.choices = hazardGroupDropdownValues;
          }
        }
      }

      if (f.code === classCode) {
        tmpField.additional_data!.hasBlurinInput = isEmpty(state.class_code_override);
      }

      if ([basis, divider, rate].includes(f.code!)) {
        tmpField.is_hidden = state[f.code!] === 'NULL';
      }
      if (
        [
          'glpo_class_code_user',
          'glpc_class_code_user',
          'glpo_oo_count_user',
          'glpc_oo_count_user',
        ].includes(f.code!) &&
        (state?.glpo_fixed_classcode || state?.glpc_fixed_classcode)
      ) {
        tmpField.is_hidden = true;
      }

      return tmpField;
    });
    setFields(tmpFields);
  }, [fieldConfig, activeExposure, state]);

  useEffect(() => {
    if (exposureList?.[`${threeExposureNames.LOCATION}`].loaded) {
      if (!isEmpty(activePeril)) {
        getPerilInfo();
      } else {
        displayToastMessage('ERROR', t('An error occurred while fetching the peril information.'));
        handleQuery();
      }
    }
  }, [JSON.stringify(activePeril), exposureList]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      fieldConfig?.peril?.loading ||
      exposureList?.[`${threeExposureNames.LOCATION}`].loading ||
      liabilityExposureRelatedKeyValuesMap.some(
        (map) => keyValueStore?.[`${map}`]?.loading ?? false,
      );

    return (
      defaultLoading || isEmpty(activeExposure) || isEmpty(activePeril) || isEmpty(state.peril_type)
    );
  }, [fieldConfig, exposureList, keyValueStore, activeExposure, activePeril, state.peril_type]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async (_values) => {},
  });

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="476px"
      onClose={handleQuery}
      isContentScrollable
      headerSx={{ mb: 2 }}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Peril Details')}
        </Typography>
      }
      content={
        <Stack gap={2} sx={{ mb: 3, pt: 1 }}>
          <LiabilityExposurePerilDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={showLoader}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
            LocationSelect={QuoteLocationSelect}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default LiabilityExposurePerilDetailDrawer;
