import { userRoles } from 'common/constants';
import useUser from 'hooks/useUser';
import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropertyExposureScheduleModDetailDrawer from './Detail/PropertyExposureScheduleModDetailDrawer';

export interface IPropertyExposureScheduleModDrawer {
  isEdit: boolean;
}

const PropertyExposureScheduleModDrawer: FC<IPropertyExposureScheduleModDrawer> = ({ isEdit }) => {
  const LOCATION = useLocation();
  const [isPropertyScheduleModDrawerOpen, setIsPropertyScheduleModDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  useEffect(() => {
    if (url?.tab === 'property_exposures' && url?.property && url?.schedule_mod) {
      setIsPropertyScheduleModDrawerOpen(true);
    } else if (url?.tab === 'property_exposures') {
      setIsPropertyScheduleModDrawerOpen(false);
    }

    if (!isUnderwriter) {
      setIsPropertyScheduleModDrawerOpen(false);
    }
  }, [LOCATION, isUnderwriter]);

  return isPropertyScheduleModDrawerOpen ? (
    <PropertyExposureScheduleModDetailDrawer
      isDrawerOpen={isPropertyScheduleModDrawerOpen}
      setIsDrawerOpen={setIsPropertyScheduleModDrawerOpen}
      isEdit={isEdit}
    />
  ) : (
    <></>
  );
};
export default PropertyExposureScheduleModDrawer;
