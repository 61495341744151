import StaticErrorPage from 'components/StaticErrorPage';
import { FC } from 'react';

const ConfigurationError: FC = () => (
  <StaticErrorPage
    title="Configuration Error"
    error="Configuration Error"
    error_description="Some important information necessary for our website to run smoothly is missing. Please contact technical support team for assistance."
    tracking="Check environment variables"
    displayReturnToHomePageButton={false}
  />
);

export default ConfigurationError;
