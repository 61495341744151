import { useAuth0 } from '@auth0/auth0-react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
/* eslint-disable no-param-reassign */
import axios, { AxiosResponse } from 'axios';
import { errorCodes } from 'common/constants';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// data
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import TokenStorage from 'helpers/TokenStorage';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import useAuth from 'hooks/useAuth';
import { IAxiosError } from 'types/ErrorResponseTypes';
import { RadityError } from './types';

interface IRequestStore {
  [key: string]: boolean;
}

let isRefreshing = false;
let expiredTokenRequestQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error, token?: string) => {
  try {
    expiredTokenRequestQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    // eslint-disable-next-line no-empty
  } catch {}

  expiredTokenRequestQueue = [];
};

const Interceptors: FC = () => {
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const { data: user } = useUser();
  const { getAccessTokenSilently, logout } = useAuth0();
  const { getAccessTokenFromSSO } = useAuth();
  const { data: quoteDetail } = useQuoteDetail();
  const userRef = useRef(user);
  userRef.current = user;

  const requestStore: IRequestStore = {};
  let replacing = false;

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (err: IAxiosError) => {
      const { response, request } = err;

      if (response) {
        const { url } = response.config;
        if (response?.status === 401 && response?.config && !requestStore[`${url}`]) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              expiredTokenRequestQueue.push({ resolve, reject });
            })
              .then((token) => {
                err.config!.headers!.Authorization = `Bearer ${token}`;

                return axios(err?.config!);
              })
              .catch((e) => Promise.reject(e));
          }

          isRefreshing = true;
          requestStore[`${url}`] = true;

          return new Promise((resolve, reject) => {
            if (TokenStorage.get()?.isSSOSession) {
              getAccessTokenSilently()
                .then((result) => {
                  const accessToken = result;
                  if (accessToken !== null) {
                    TokenStorage.set({
                      access: accessToken,
                      isSSOSession: true,
                    });
                    err.config!.headers!.Authorization = `Bearer ${accessToken}`;
                    requestStore[`${url}`] = false;
                    processQueue(null, accessToken);

                    resolve(axios(err?.config!));
                  } else {
                    throw TypeError();
                  }
                  isRefreshing = false;
                })
                .catch(async (error) => {
                  TokenStorage.clear();
                  requestStore[`${url}`] = false;
                  window.location.replace(
                    `/login?${new URLSearchParams({
                      redirect: window.location.pathname,
                      ...{ sessionExpired: 'true' },
                    }).toString()}`,
                  );
                  if (await getAccessTokenFromSSO(false)) {
                    processQueue(error, undefined);
                    reject(error);
                  }
                })
                .finally(() => {
                  isRefreshing = false;
                });
            }
          });
        } else if (response.status === 400 && !!response.data && response.data.code === 1010) {
          const radityError: RadityError<unknown> = {
            ...err,
            isRadityError: true,
            errorCode: response.data.code,
            backendErrors: {
              ...(() =>
                Object.entries(response.data.field_errors).reduce(
                  (acc, entry) => (acc = { ...acc, [entry[0]]: entry[1] }),
                  {},
                ))(),
            },
            messages: response.data.messages,
          };
          return radityError;
        } else if (
          response.status === 400 &&
          response.data.code === errorCodes.SUBMISSION_HAS_ALREADY_FINALIZED
        ) {
          /**
           * TODO Conditions and imports with quoteDetail should be deleted when BE fix issue for price fetching
           * Till ticket bellow unresolved this condition uses to awoid continiously reloading on summary and pricing tabs
           * https://3pager.visualstudio.com/NLS-3Pager-Agent-Portal/_workitems/edit/146035
           */
          if (quoteDetail?.has_accepted_related_quote !== undefined) {
            setTimeout(() => {
              if (!quoteDetail?.has_accepted_related_quote) {
                window.location.reload();
                window.location.search = qs.stringify({
                  tab: qs.parse(HISTORY.location.search).tab,
                });
              }
            }, 1);

            return err;
          }
        } else if (response.status === 403 && response.config) {
          displayToastMessage('ERROR', response?.data.messages[0]);
          HISTORY.replace('/403', HISTORY.location.pathname);
          return err;
        } else if (response.status === 408 && response.config) {
          displayBackendErrorMessage(
            err,
            t(
              'This request takes a too long time to process, please try again later or contact your support team.',
            ),
          );
          return err;
        } else if (response.status === 409 && response.config) {
          if (!replacing) {
            replacing = true;
            TokenStorage.clear();
            window.location.replace(
              `/login?${new URLSearchParams({
                redirect: window.location.pathname,
                concurrencyError: 'true',
              }).toString()}`,
            );
          }
          return err;
        } else if (response.status === 413 && response.config) {
          displayBackendErrorMessage(
            err,
            t(`'Request entity too large. Please try with the lower-size entity.`),
          );
          return err;
        } else if (response.status === 475 && response.config) {
          TokenStorage.clear();
          requestStore[`${url}`] = false;
          window.location.replace(
            `/login?${new URLSearchParams({
              authenticationFailed: 'true',
            }).toString()}`,
          );
          displayToastMessage('ERROR', 'Authentication failed. Please contact your support team.');
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/login?${new URLSearchParams({
                authenticationFailed: 'true',
              }).toString()}`,
            },
          });
          return err;
        } else if (response.status === 429 && response.config) {
          if (
            err?.config?.url !== `${import.meta.env.REACT_APP_BACKEND_MAIN_API_URL}/auth/token/` &&
            LOCATION.pathname !== '/login'
          ) {
            TokenStorage.clear();
            window.location.reload();
          }
          return err;
        } else if (response.status === 500 && response.config) {
          displayBackendErrorMessage(err, t(`Server Error (500)`));
          return err;
        } else if (response.status === 501 && response.config) {
          displayBackendErrorMessage(
            err,
            t(`The requested service is not implemented yet. Please contact your support team.`),
          );
          return err;
        } else if (response.status === 502 && response.config) {
          displayBackendErrorMessage(
            err,
            t(
              `The service is temporarily available. Please try again later and if the problem persists contact your support team.`,
            ),
          );
          return err;
        } else if (response.status === 503 && response.config) {
          displayBackendErrorMessage(
            err,
            t(
              `The service you requested is not available. Please try again later or contact your support team.`,
            ),
          );
          return err;
        } else if (response.status === 504 && response.config) {
          displayBackendErrorMessage(
            err,
            t(
              `The server could not complete your request. If the problem persists, contact your support team.`,
            ),
          );
          return err;
        }
      } else if (request) {
        if (err.message === 'Network Error') {
          displayToastMessage('ERROR', t('Network Error'));
          return err;
        }
      }

      return Promise.reject(err);
    },
  );

  return null;
};

export default Interceptors;
