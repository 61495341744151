import { Box, FormControl, Stack, Typography } from '@mui/material';
import Head from 'components/Head';
import SettingsHeader from 'components/Settings/Header/SettingsHeader';
import SettingsTopActionBar from 'components/Settings/TopActionBar/SettingsTopActionBar';
import {
  detailPageRowKeyStyles,
  detailPageRowValueStyles,
  detailPageStackStyles,
} from 'helpers/MuiSharedStyles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const SettingsAccount: FC = () => {
  const { t } = useTranslation();

  const langSwitch = [{ code: 'en', title: t('English') }];
  return (
    <>
      <Head title={t('Settings Account')} />
      <SettingsTopActionBar />
      <SettingsHeader currentTab={1} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          spacing={{ xs: 2 }}
          sx={{
            ml: 3,
            mt: 3,
          }}
        >
          <Stack
            gap={{ xs: 2 }}
            sx={{
              width: 1,
              flexDirection: {
                xs: 'row',
                sm: 'row',
              },
            }}
          >
            <FormControl size="small" sx={{ flex: { xs: 1, sm: 0.5 }, maxWidth: '480px' }}>
              <Stack sx={detailPageStackStyles} flexDirection="row">
                <Typography
                  sx={[
                    detailPageRowKeyStyles,
                    {
                      color: (theme) => theme.customColors.grey800,
                      marginRight: '6px',
                      minWidth: '142px',
                      maxWidth: '142px',
                    },
                  ]}
                >
                  {t('Language')}
                </Typography>
                <Typography sx={[detailPageRowValueStyles, { paddingLeft: '8px' }]}>
                  {langSwitch[0].title}
                </Typography>
              </Stack>
            </FormControl>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default SettingsAccount;
