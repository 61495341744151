/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import {
  liabilityExposureRelatedKeyValues,
  MAXIMUM_MODIFICATION,
  propertyExposureRelatedKeyValuesMap,
  smodLines,
  threeExposureNames,
  threePolicyGroupNames,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import LocationShow from 'components/LocationShow';
import ScheduleModTable from 'components/ScheduleModTable';
import { ISchedModTableRow } from 'components/ScheduleModTable/ScheduleModTable';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import { calculateTotalOfRow, mergeFieldsAndValues } from 'helpers/ScheduleModificationHelpers';
import { deleteFromQueryStrings, formatLocation, parseLocation } from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import { isEmpty, omit } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface PropertyExposureScheduleModDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  isEdit: boolean;
}

const PropertyExposureScheduleModDetailDrawer: FC<PropertyExposureScheduleModDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isEdit,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const PROPERTY_ID = url.property;
  const {
    data: endorsementDetail,
    exposureList,
    updateEndorsementDetail,
    groups,
  } = useEndorsementDetail();

  const [state, setState] = useState<ISchedModTableRow[]>([]);
  const { setLoading } = useLoader();

  const { data: keyValueStore } = useKeyValues();

  const SCHED_MOD_TABLES =
    keyValueStore?.[`${liabilityExposureRelatedKeyValues.SCHED_MOD_TABLES}`]?.data?.value ?? [];

  const liabilityExposures = exposureList?.[`${threeExposureNames.LOCATION}`]?.data ?? [];

  // get property exposures from provider
  const propertyExposures = exposureList?.[`${threeExposureNames.BUILDING}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(() => {
    const isNewlyAdded = PROPERTY_ID?.includes('added-');
    const index = isNewlyAdded ? PROPERTY_ID?.slice(6) : undefined;
    return (
      propertyExposures?.find((e) =>
        isNewlyAdded ? e.index === index : e.locator === PROPERTY_ID,
      ) ?? {}
    );
  }, [propertyExposures, PROPERTY_ID]);

  const relatedLocationExposure = useMemo(
    () =>
      liabilityExposures?.find(
        (exposure) => exposure?.data?.loc_seq_no === activeExposure?.data?.bdg_loc_seq_no,
      )?.data,
    [liabilityExposures, activeExposure],
  );

  const otherSchedModGroupFields = useMemo(() => {
    const r =
      groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.data?.filter(
        (item) =>
          item.pol_sched_mod_line !== smodLines.PROPERTY ||
          item.pol_sched_mod_state !== relatedLocationExposure?.loc_address_state,
      ) ?? [];

    return r as any[];
  }, [groups, relatedLocationExposure]);

  const filteredSchedModGroupFields = useMemo(() => {
    const r =
      groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.data?.filter(
        (item) =>
          item.pol_sched_mod_line === smodLines.PROPERTY &&
          item.pol_sched_mod_state === relatedLocationExposure?.loc_address_state,
      ) ?? [];

    return r as any[];
  }, [groups, relatedLocationExposure]);

  const filteredTableByFilingSetID = useMemo(
    () =>
      SCHED_MOD_TABLES[smodLines.PROPERTY]
        ?.filter((item) => String(item.FilingSetID) === relatedLocationExposure?.loc_filing_set_id)
        ?.map((row) => ({
          ...row,
          MaxCredit: -row?.MaxCredit,
        })) ?? '',
    [SCHED_MOD_TABLES, relatedLocationExposure],
  );

  const maximumModification =
    filteredTableByFilingSetID &&
    filteredTableByFilingSetID?.find((item) => item.Category === MAXIMUM_MODIFICATION);

  const filteredTableWithGroupFields = useMemo(
    () => mergeFieldsAndValues(filteredSchedModGroupFields, filteredTableByFilingSetID),
    [filteredSchedModGroupFields, filteredTableByFilingSetID],
  );

  const schedModTableData = useMemo(
    () =>
      filteredTableWithGroupFields.map((item: ISchedModTableRow) => {
        let { MaxCredit, MaxDebit, pol_sched_mod_value_rule, pol_sched_mod_value_uw } = item;

        pol_sched_mod_value_rule = !isEmpty(pol_sched_mod_value_rule)
          ? (+pol_sched_mod_value_rule * 100).toFixed()
          : '0';
        pol_sched_mod_value_uw = !isEmpty(pol_sched_mod_value_uw)
          ? (+pol_sched_mod_value_uw * 100).toFixed()
          : '0';

        const total = calculateTotalOfRow(
          +pol_sched_mod_value_rule,
          +pol_sched_mod_value_uw,
          MaxDebit,
          MaxCredit,
        );

        return {
          ...item,
          pol_sched_mod_value_rule,
          pol_sched_mod_value_uw,
          total,
        };
      }),
    [filteredTableWithGroupFields],
  );

  useEffect(() => {
    if (!isEmpty(schedModTableData)) {
      setState(schedModTableData);
    }
  }, [schedModTableData]);

  useEffect(() => {
    if (url?.tab === 'property_exposures' && !url?.schedule_mod) {
      setIsDrawerOpen(false);
    }
  }, [LOCATION]);

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['property', 'schedule_mod'],
      }),
    });
  };

  useEffect(() => {
    if (
      isDrawerOpen &&
      exposureList?.[`${threeExposureNames.LOCATION}`].loaded &&
      exposureList?.[`${threeExposureNames.BUILDING}`].loaded &&
      groups?.[`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]?.loaded &&
      url?.schedule_mod
    ) {
      if (
        isEmpty(liabilityExposures) ||
        isEmpty(activeExposure) ||
        isEmpty(filteredTableByFilingSetID) ||
        isEmpty(filteredSchedModGroupFields)
      ) {
        displayToastMessage(
          'ERROR',
          t('An error occurred while fetching the schedule modification information.'),
        );
        handleQuery();
      }
    }
  }, [exposureList, isDrawerOpen, groups]);

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const unchangedSchedModGroupFields = filteredSchedModGroupFields.filter((item) =>
        state.every((el) => el.locator !== item.locator),
      );

      const mappedState = state.map((item: any) => {
        let newState = omit(item, [
          'Category',
          'Classification',
          'FilingSetID',
          'MaxCredit',
          'MaxDebit',
          'total',
        ]);

        newState = {
          ...newState,
          pol_sched_mod_value_rule: +newState.pol_sched_mod_value_rule / 100,
          pol_sched_mod_value_uw: +newState.pol_sched_mod_value_uw / 100,
        };

        return newState;
      });

      const allSchedModGroupFields = [
        ...otherSchedModGroupFields,
        ...unchangedSchedModGroupFields,
        ...mappedState,
      ];

      await updateEndorsementDetail(
        endorsementDetail?.policy?.locator as string,
        endorsementDetail?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.SCHEDULE_MODIFICATION}`]: allSchedModGroupFields,
          },
        },
        {
          validate: threePolicyGroupNames.SCHEDULE_MODIFICATION,
        },
      );

      displayToastMessage('SUCCESS', t('The schedule modification has been updated.'));
      handleQuery();
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while updating the schedule modification.'),
      );
    } finally {
      setLoading(false);
    }
  };

  const renderLocation = (exposureData) => {
    const location = parseLocation(exposureData);

    const formatted = formatLocation({
      ...location,
    });
    return <LocationShow location={formatted.showing} />;
  };

  const onCancel = () => {
    handleQuery();
  };

  const showLoader = useMemo(() => {
    const defaultLoading =
      exposureList?.[`${threeExposureNames.LOCATION}`].loading ||
      exposureList?.[`${threeExposureNames.BUILDING}`].loading ||
      propertyExposureRelatedKeyValuesMap.some(
        (map) => keyValueStore?.[`${map}`]?.loading ?? false,
      );

    return defaultLoading;
  }, [keyValueStore, exposureList]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="1152px"
      onClose={handleQuery}
      headerSx={{ mb: 2 }}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Schedule Modification Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 3, pt: 2 }}>
          {showLoader ? (
            <Skeleton animation="wave" width="20%" height={32} sx={{ mb: 1.5 }} />
          ) : (
            <>
              <Typography
                sx={{
                  py: 1,
                  fontWeight: 500,
                  fontSize: '16px',
                  color: (theme) => theme.customColors.black,
                }}
              >
                {renderLocation(activeExposure.data?.bdg_location)}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  fontWeight: 500,
                  fontSize: '16px',
                  color: (theme) => theme.customColors.black,
                }}
              >
                {activeExposure.data?.bdg_name ?? ''}
              </Typography>
            </>
          )}
          {showLoader ? (
            [...Array(5).keys()].map((v) => (
              <Box key={`skelTab${v}`}>
                <Skeleton animation="wave" width="100%" height={35} sx={{ marginBottom: 0.5 }} />
              </Box>
            ))
          ) : (
            <ScheduleModTable
              rowTotal={maximumModification}
              state={state}
              setState={setState}
              isEdit={isEdit}
            />
          )}
        </Stack>
      }
      footer={
        <Stack direction="row">
          {showLoader ? (
            <>
              <Skeleton animation="wave" width="60px" height={32} sx={{ mr: 1 }} />
              <Skeleton animation="wave" width="60px" height={32} />
            </>
          ) : (
            <>
              <Box>
                <Button
                  onClick={onCancel}
                  sx={[
                    drawerFooterSecondaryButtonStyle,
                    {
                      ...(!isEdit
                        ? {
                            mr: 0,
                          }
                        : {}),
                    },
                  ]}
                >
                  {t('Cancel')}
                </Button>
              </Box>
              {isEdit && (
                <Box>
                  <Button onClick={handleUpdate} sx={[drawerFooterPrimaryButtonStyle]}>
                    {t('Save')}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Stack>
      }
    />
  );
};

export default PropertyExposureScheduleModDetailDrawer;
