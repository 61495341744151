/* eslint-disable @typescript-eslint/no-use-before-define */
import { Download, Edit, MoreVert } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, ClickAwayListener, IconButton, Link, MenuItem, Typography } from '@mui/material';
import { GridAlignment, GridColDef, GridSortModel } from '@mui/x-data-grid';
import Delete from 'assets/images/icon_dropdowndelete.svg';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  globalSearchTypes,
} from 'common/constants';
import DataTable from 'components/DataTable';
import DocumentDetailDrawer from 'components/Documents/Detail';
import EditDocumentDrawer from 'components/Documents/EditDocument';
import SendDocumentMailDrawer from 'components/Documents/SendDocumentMail';
import MenuComponent from 'components/MenuComponent';
import PolicyDetailHeader from 'components/Policies/PolicyDetail/Header';
import PolicyDetailTopActionBar from 'components/Policies/PolicyDetail/TopActionBar';
import PolicyEndorsementDetailHeader from 'components/Policies/PolicyEndorsementDetail/Header';
import PolicyEndorsementDetailTopActionBar from 'components/Policies/PolicyEndorsementDetail/TopActionBar';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { dropdownItemsStyle } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useDialog from 'hooks/useDialog';
import useDocuments from 'hooks/useDocuments';
import useLoader from 'hooks/useLoader';
import { initialDocumentsData } from 'providers/DocumentsProvider/DocumentsProviderContext';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { IAxiosError } from 'types/ErrorResponseTypes';

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align?: GridAlignment;
  headerAlign?: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const PolicyDetailDocuments: FC = () => {
  const { id, endorsementId } = useParams<{ id: string; endorsementId: string }>();
  const HISTORY = useHistory();
  const { setLoading } = useLoader();
  const LOCATION = useLocation();
  const { t } = useTranslation();
  const url = qs.parse(LOCATION.search);

  const { setDialogOpen } = useDialog();
  const { data, resetDocumentsState, fetch, reFetchData, loading, pagination, deleteDocument } =
    useDocuments();

  const [pageNumber, setPage] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState<number>(-1);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: 'title', sort: 'asc' },
  ]);

  const isSendDocumentDrawerOpen = url?.send_document_id !== undefined;
  const isEditDocumentDrawerOpen = url?.edit_document_id !== undefined;
  const [isClickedMoreButton, setIsClickedMoreButton] = useState<boolean>(false);
  const isDetailDocumentDrawerOpen = url?.document !== undefined;
  const { formatDateInTimeZone } = useConfig();

  const getAssociatedWithType = (type: string) => {
    switch (type) {
      case globalSearchTypes.POLICY:
        return 'policies';

      default:
        return '';
    }
  };

  useEffect(() => {
    fetch(
      { ...initialDocumentsData.pagination, ordering: 'title' },
      getAssociatedWithType(globalSearchTypes.POLICY),
      id,
    );
    return () => {
      resetDocumentsState();
    };
  }, []);

  const handleEditedDocument = () => {
    reFetchData(getAssociatedWithType(globalSearchTypes.POLICY), id);
  };

  const handleClickAway = () => {
    setIsClickedMoreButton(false);
    setSelectedRowId(-1);
  };

  const handleEditDocument = (documentId: number) => {
    setSelectedRowId(documentId);
    HISTORY.push({
      pathname: LOCATION.pathname,
      search: `?edit_document_id=${documentId}`,
    });
  };

  const handleSendDocument = (documentId: number) => {
    setSelectedRowId(documentId);
    HISTORY.push({
      pathname: LOCATION.pathname,
      search: `?send_document_id=${documentId}`,
    });
  };

  const handleDetailDocument = (documentId: number) => {
    setSelectedRowId(documentId);
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { document: documentId },
      }),
    });
  };

  const deleteDocumentsFunc = async (policyId, documentId: string) => {
    try {
      setDialogOpen({
        dialog: 'DELETE_DOCUMENT',
        isOpen: false,
      });
      await deleteDocument(policyId, documentId);
      handleNewDocument();
      displayToastMessage('SUCCESS', t('The document has been deleted.'));
    } catch (error) {
      const e = error as IAxiosError;
      if (e.isAxiosError) {
        if (e.response?.data.messages && e.response?.data.messages[0]) {
          displayToastMessage('ERROR', e.response?.data.messages[0]);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const columns: IColumns[] = [
    {
      name: 'title',
      display_name: t('Document Title'),
      flex: 1.5,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 200,
    },
    {
      name: 'button',
      display_name: '',
      flex: 0.05,
      type: 'action_menu',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      minWidth: 50,
    },
    {
      name: 'doc_type',
      display_name: t('Document Type'),
      type: 'string',
      flex: 1.5,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 180,
    },
    {
      name: 'created_at',
      display_name: t('Uploaded at'),
      type: 'date',
      flex: 0.4,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 130,
    },
    {
      name: 'uploaded_by',
      display_name: t('Uploaded by'),
      type: 'string',
      flex: 1.5,
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      minWidth: 140,
    },
  ];

  const columnsDocuments: GridColDef[] = columns.map((field, i) => ({
    flex: field.flex ?? 1,
    field: field.name,
    width: field.width,
    align: field.align,
    minWidth: field.minWidth,
    sortable: field.is_sortable,
    headerAlign: field.headerAlign,
    headerName: field.display_name,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);
      const activeEditButton: boolean = params?.row.doc_type?.key !== 'socotra';
      if (fieldValue === '-' && i !== 0 && field.type !== dataFieldTypes.ACTION) {
        return fieldValue;
      } else if (i === 0) {
        return (
          <Link
            component={RouterLink}
            to={`?${updateQueryStrings({
              locationSearch: LOCATION.search,
              newQueries: { document: params.row.id },
            })}`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (i === 2) {
        return (
          <Box
            onClick={() => handleDetailDocument(params.row.id)}
            sx={{
              minWidth: 400,
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
              },
              color: (theme) => theme.customColors.table.link,
            }}
          >
            {fieldValue.value}
          </Box>
        );
      } else if (field.type === dataFieldTypes.ACTION) {
        return (
          <Box sx={{}}>
            <MenuComponent
              renderDefaultIcon={false}
              anchor={
                <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
                  <IconButton
                    disableRipple
                    size="small"
                    sx={{
                      bgcolor:
                        selectedRowId === params.row.id && isClickedMoreButton
                          ? (theme) => theme.palette.background.default
                          : 'none',
                      color:
                        selectedRowId === params.row.id && isClickedMoreButton
                          ? (theme) => theme.customColors.black
                          : 'none',
                      '&:hover': {
                        color: (theme) => theme.customColors.black,
                        bgcolor: (theme) => theme.palette.background.default,
                      },
                    }}
                    onClick={() => {
                      setIsClickedMoreButton(true);
                      setSelectedRowId(params.row.id);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </ClickAwayListener>
              }
            >
              <MenuItem
                value="sendmail"
                onClick={() => handleSendDocument(params.row.id)}
                sx={dropdownItemsStyle}
                disabled
              >
                <MailOutlineIcon
                  sx={{
                    color: (theme) => theme.customColors.black900,
                    fontSize: (theme) => theme.globalSearch.searchIconSize,
                    mr: 1,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.customColors.black900, fontSize: 14 }}
                >
                  {t('Send via Mail')}
                </Typography>
              </MenuItem>
              <Link href={`${params.row.url}`} target="_blank" sx={{ textDecoration: 'none' }}>
                <MenuItem value="download" sx={dropdownItemsStyle}>
                  <Download
                    sx={{
                      color: (theme) => theme.customColors.black900,
                      fontSize: (theme) => theme.globalSearch.searchIconSize,
                      mr: 1,
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ color: (theme) => theme.customColors.black900, fontSize: 14 }}
                  >
                    {t('Download')}
                  </Typography>
                </MenuItem>
              </Link>
              {params.row.doc_type.key !== 'socotra' && (
                <>
                  <MenuItem
                    value="edit"
                    onClick={() => handleEditDocument(params.row.id)}
                    sx={dropdownItemsStyle}
                    disabled={!activeEditButton}
                  >
                    <Edit
                      sx={{
                        color: (theme) => theme.customColors.black900,
                        fontSize: (theme) => theme.globalSearch.searchIconSize,
                        mr: 1,
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{ color: (theme) => theme.customColors.black900, fontSize: 14 }}
                    >
                      {t('Edit')}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    value="delete"
                    sx={dropdownItemsStyle}
                    disabled={!activeEditButton}
                    onClick={() => {
                      setDialogOpen({
                        dialog: 'DELETE_DOCUMENT',
                        isOpen: true,
                        onAccept: () => deleteDocumentsFunc(id, params.row.id),
                      });
                    }}
                  >
                    <ReactSVG src={Delete} />
                    <Typography
                      variant="body1"
                      sx={{ color: (theme) => theme.customColors.black900, fontSize: 14 }}
                    >
                      {t('Delete')}
                    </Typography>
                  </MenuItem>
                </>
              )}
            </MenuComponent>
          </Box>
        );
      } else if (field.name === 'created_at') {
        if (!fieldValue) return '-';

        const splitDate = formatDateInTimeZone(
          fieldValue,
          `${defaultDateFormat} ${defaultDateTimeFormat}`,
        ).split(' ');

        return (
          <Box
            display="flex"
            flexWrap="wrap"
            sx={{
              height: '100%',
              py: (theme) => theme.spacing(0.75),
              color: (theme) => theme.customColors.black,
            }}
          >
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                mr: 1,
              }}
            >
              {splitDate[0]}
            </Box>
            <Box
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
              }}
            >
              {splitDate[1]} {splitDate[2]}
            </Box>
          </Box>
        );
      } else {
        return (
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);

    if (newModel.length) {
      const [nm] = newModel;
      setPage(0);
      fetch(
        {
          ...pagination,
          page: 1,
          ordering: `${nm.sort === 'desc' ? '-' : ''}${nm?.field}`,
        },
        getAssociatedWithType(globalSearchTypes.POLICY),
        id,
      );
    } else {
      fetch(
        {
          ...pagination,
          ordering: '',
        },
        getAssociatedWithType(globalSearchTypes.POLICY),
        id,
      );
    }
  };

  const handleNewDocument = () => {
    reFetchData(getAssociatedWithType(globalSearchTypes.POLICY), id);
  };

  return (
    <>
      {isDetailDocumentDrawerOpen && (
        <DocumentDetailDrawer isDrawerOpen={isDetailDocumentDrawerOpen} policyId={id} />
      )}
      {isSendDocumentDrawerOpen && (
        <SendDocumentMailDrawer isDrawerOpen={isSendDocumentDrawerOpen} policyId={id} />
      )}
      {isEditDocumentDrawerOpen && (
        <EditDocumentDrawer
          isDrawerOpen={isEditDocumentDrawerOpen}
          policyId={id}
          onUpdated={handleEditedDocument}
        />
      )}
      {endorsementId ? (
        <PolicyEndorsementDetailTopActionBar policyId={id} onDocumentCreated={handleNewDocument} />
      ) : (
        <PolicyDetailTopActionBar policyId={id} onDocumentCreated={handleNewDocument} />
      )}
      {endorsementId ? (
        <PolicyEndorsementDetailHeader currentTab={2} policyId={id} />
      ) : (
        <PolicyDetailHeader currentTab={2} policyId={id} />
      )}
      <DataTable
        autoRowCellHeight
        getRowId={(row) => row.id}
        rows={data!}
        loading={loading}
        paginationMode="server"
        columns={columnsDocuments}
        columnVisibilityModel={createColumnVisibilityModel(columns)}
        paginationModel={{ page: pageNumber, pageSize: pagination?.page_size! }}
        rowCount={pagination.total_count}
        pageSizeOptions={[pagination.page_size!]}
        hideFooterPagination={pagination.total_count! < pagination.page_size!}
        onPageChange={(page) => {
          setPage(page);
          fetch(
            { ...pagination, page: page + 1 },
            getAssociatedWithType(globalSearchTypes.POLICY),
            id,
          );
        }}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        wrapperSx={{
          px: 3,
          pt: 3,
          pb: 3,
          overflow: 'auto',
          height: endorsementId
            ? (theme) => `calc(100vh - ${theme.policyEndorsementDetailFixedItemsHeight})`
            : (theme) => `calc(100vh - ${theme.policyDetailFixedItemsHeight})`,
        }}
      />
    </>
  );
};

export default PolicyDetailDocuments;
