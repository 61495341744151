import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  additionalInterestTypes,
  multiChoiceSubjectOfInterestTypes,
  stateList,
  threeExposureNames,
  threePolicyGroupNames,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { AdditionalInterestsDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/AdditionalInterestsDrawerFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings, handleBackendErrorsWithFormik } from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface AdditionalInterestsDetailProps {
  isDrawerOpen: boolean;
}

const initialValues = {
  info_ai_name: '',
  info_ai_type: '',
  info_ai_address_line1: '',
  info_ai_address_line2: '',
  info_ai_address_city: '',
  info_ai_address_state: '',
  info_ai_address_zip: '',
  info_ai_address_phone: '',
  info_ai_address_email: '',
  info_ai_subject: '',
};

const AdditionalInterestsDetailDrawer: FC<AdditionalInterestsDetailProps> = ({ isDrawerOpen }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const [state, setState] = useState({ ...initialValues });
  const url = qs.parse(LOCATION.search);
  const ADDITIONAL_INTEREST = url.additional_interest;
  const { exposureList, exposures, fields: fieldConfig, loaded: policyLoaded } = usePolicyDetail();
  const [fields, setFields] = useState<any[]>([]);

  const additionalInterestFields = useMemo(
    () =>
      (fieldConfig?.exposure?.data as ProductWorkFlow[])
        ?.find((con) => con.code === threeExposureNames.POLICY_INFORMATION)
        ?.fields?.find((x) => x.code === threePolicyGroupNames.ADDITIONAL_INTEREST)
        ?.nested_fields ?? [],
    [fieldConfig],
  );

  const propertyExposures = useMemo(
    () => exposureList?.[`${threeExposureNames.BUILDING}`]?.data ?? [],
    [exposureList, exposures],
  );

  const vehicleExposures = useMemo(
    () => exposureList?.[`${threeExposureNames.VEHICLE}`]?.data ?? [],
    [exposureList, exposures],
  );

  const isMultipleTypeSelected = useMemo(
    () => multiChoiceSubjectOfInterestTypes.includes(state?.info_ai_type),
    [state],
  );

  const handleFields = (infoType: string) => {
    if (!additionalInterestFields.length) {
      return;
    }
    const tmpFields = [...additionalInterestFields];

    const subjectOfInterestIndex = tmpFields.findIndex((i) => i.code === 'info_ai_subject');
    const subjectOfInterestField = tmpFields[subjectOfInterestIndex];

    subjectOfInterestField.choices = [];

    const addressStateIndex = tmpFields.findIndex((i) => i.code === 'info_ai_address_state');
    const addressStateField = tmpFields[addressStateIndex];

    if (addressStateField?.additional_data?.setDefaultStateListAsChoices) {
      addressStateField.choices = stateList.map(({ code, name }) => ({ code, name })) ?? [];
    }

    if (isMultipleTypeSelected) {
      if (propertyExposures?.length || vehicleExposures?.length) {
        const buildings = propertyExposures.map((property) => ({
          code: property?.locator ?? '',
          name: property?.data?.bdg_location,
        }));

        let subjectChoices = [...buildings];

        if (infoType === additionalInterestTypes.LOSS_PAYEE) {
          const vehicles = vehicleExposures.map((vehicle) => ({
            code: vehicle?.locator ?? '',
            name: vehicle?.data?.veh_vin,
          }));

          subjectChoices = [...subjectChoices, ...vehicles];
        }

        subjectOfInterestField.choices = subjectChoices;
      }

      subjectOfInterestField.is_hidden = false;
    } else {
      subjectOfInterestField.is_hidden = true;
    }
    setFields(tmpFields);
  };

  const policyInformationExposure =
    exposureList?.[`${threeExposureNames.POLICY_INFORMATION}`]?.data?.[0] ?? {};

  const additionalInterests: any[] = policyInformationExposure?.data?.info_ai ?? [];

  const additionalInterest = useMemo(
    () => additionalInterests.find((ow) => ow.locator === ADDITIONAL_INTEREST) ?? {},
    [additionalInterests, ADDITIONAL_INTEREST],
  );

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['additional_interest'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (policyLoaded && isEmpty(additionalInterest)) {
      displayToastMessage('ERROR', t('Additional Interest not found.'));
      handleQuery();
    }
  }, [additionalInterest, policyLoaded]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {
      if (formik.isValid) {
        try {
          displayToastMessage('SUCCESS', t('Changes have been saved.'));
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
        }
      }
    },
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...additionalInterest }));
    formik.setValues({ ...initialValues, ...additionalInterest });
  }, [additionalInterest]);

  useEffect(() => {
    if (isMultipleTypeSelected && !isEmpty(state.info_ai_subject)) {
      let tmpAdditionalInterest = { ...state };

      if (multiChoiceSubjectOfInterestTypes.includes(additionalInterest?.info_ai_type)) {
        tmpAdditionalInterest = {
          ...tmpAdditionalInterest,
          info_ai_subject: Array.isArray(additionalInterest?.info_ai_subject)
            ? additionalInterest?.info_ai_subject?.[0]?.split(',')
            : additionalInterest?.info_ai_subject?.split(','),
        };
      }

      setState((prevState) => ({ ...prevState, ...tmpAdditionalInterest }));
      formik.setValues({ ...tmpAdditionalInterest });
    }
  }, [isMultipleTypeSelected]);

  useEffect(() => {
    handleFields(formik.values?.info_ai_type);
  }, [exposureList, additionalInterestFields, formik.values?.info_ai_type]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Additional Interest Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1.25 }}>
          <AdditionalInterestsDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={fieldConfig.exposure.loading}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default AdditionalInterestsDetailDrawer;
