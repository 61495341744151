/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import { Info } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GridCellModes, GridColDef } from '@mui/x-data-grid';
import { useMutation } from '@tanstack/react-query';
import { Choice, ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { getNCFScore } from 'api/services/Integrations/LexisNexis';
import { getXmodByFein } from 'api/services/Integrations/XMod';
import { bulkExposureUpdateQuote, updatePeril } from 'api/services/NewQuote';
import RatingIcon from 'assets/images/RatingIcon.svg';
import {
  acceptedBdgOccupancy,
  dataFieldTypes,
  defaultCurrency,
  defaultRowVirtualization,
  productCodes,
  quotePolicyEndorsementInfoTitlesDescriptions,
  selectionChoices,
  states,
  submissionDetailInfoTabs,
  threeExposureNames,
  userRoles,
  uwTaxOptions,
} from 'common/constants';
import DataTable from 'components/DataTable';
import DataTableFieldParser from 'components/DataTableFieldParser/DataTableFieldParser';
import DataTablePro from 'components/DataTablePro';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { emitter, Events } from 'helpers/EventBus';
import { validateExpModIntegrationsCanRun } from 'helpers/ExpMod';
import { prepareOwnerForLexisNexisRequest } from 'helpers/Integrations/LexisNexis';
import { primaryButtonStyle, tabTitleStyles } from 'helpers/MuiSharedStyles';
import {
  checkIfValueHasLetters,
  currencyFormat,
  displayIntegrationErrorMessage,
  findFilingSetId,
  formatLocation,
  parseLocation,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig/useConfig';
import useLoader from 'hooks/useLoader/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail/useQuoteDetail';
import useQuoteOrRenewal from 'hooks/useQuoteOrRenewal/useQuoteOrRenewal';
import useUser from 'hooks/useUser/useUser';
import { isEmpty, startCase } from 'lodash-es';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

const SummaryPricing = forwardRef<any, any>((_props, ref) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any[]>([]);
  const [cellModesModel, setCellModesModel] = useState<any>({});
  const [isDataChanged, setIsDataChanged] = useState(false);
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const themeHook = useTheme();
  const { data: userData } = useUser();
  const { isRenewal } = useQuoteOrRenewal();

  const { setLoading } = useLoader();

  const {
    data: quoteDetail,
    exposureList,
    loaded: quoteLoaded,
    getQuotePrice,
    price,
    isCoveragesSummaryPriceCalculated,
    setIsCoveragesSummaryPriceCalculated,
    fields,
    exposures,
    underwritingQuestionsState,
    getExposures,
    canEdit,
    updateQuoteDetail,
    updateQuoteProgress,
    fetch: fetchQuoteDetail,
    progress: { data: progressData, loading: progressLoading },
  } = useQuoteDetail();

  const characteristic = quoteDetail?.characteristics?.[0] ?? {};

  const isTaxIDInvalid = useMemo(() => {
    const taxID = ((characteristic?.data?.underwriting_question as any[]) ?? []).find(
      (item) => item.uwq_question_id === 'taxIDType',
    );
    const answer = taxID ? taxID.uwq_question_answer : '';

    return !(answer === uwTaxOptions.FEIN || answer === uwTaxOptions.SOCIAL_SECURITY_NUMBER);
  }, [quoteDetail]);

  const { mutateAsync: calculateXmod } = useMutation({ mutationFn: getXmodByFein });

  const visibleCoverageSummaryRows = useMemo(() => {
    const policyFormset = fields.policy?.data as ProductWorkFlow[];
    const coverageSummaryRows = policyFormset?.find((x) => x.code === 'coverage-summary-rows');

    return coverageSummaryRows?.fields?.reduce(
      (acc, curr) => ({ ...acc, [curr.code!]: curr }),
      {},
    ) as { [key: string]: ProductWorkFlow };
  }, [fields.policy?.data]);

  const isProducer = userData?.role?.code === userRoles.AGENT.code;
  const isUnderwriter = userData?.role?.code === userRoles.UNDERWRITER.code;

  const isProgressTabs = isProducer && canEdit;
  const wasPriceCalculated = progressData.summary_pricing
    ? progressData.summary_pricing.is_price_calculated
    : false;

  const isProductCodeThreeWithWorkersCompensation =
    quoteDetail?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION;

  const quoteCharacteristics = quoteDetail?.characteristics?.[0].data;
  const policyState = quoteCharacteristics?.pol_state;
  const { locFilingIdTable } = useConfig();
  const [earthquakeDeductibleFirstChoice, setEarthquakeDeductibleFirstChoice] = useState<string>();

  const isEarthquakeRowVisible =
    visibleCoverageSummaryRows?.earthquake?.additional_data?.enabledStates?.includes(policyState);

  const isWindRowVisible =
    visibleCoverageSummaryRows?.wind?.additional_data?.enabledStates?.includes(policyState);

  const isFloodRowVisible =
    visibleCoverageSummaryRows?.flood?.additional_data?.enabledStates?.includes(policyState);

  const formatCellModes = (rows) => {
    const obj = {};
    rows.forEach((item) => {
      if (item.deductible?.isEditMode) {
        obj[item.id] = { ...obj[item.id], deductible: { mode: GridCellModes.Edit } };
      } else if (!item.deductible?.isEditMode) {
        obj[item.id] = { ...obj[item.id], deductible: { mode: GridCellModes.View } };
      }
      if (item.limit?.isEditMode) {
        obj[item.id] = { ...obj[item.id], limit: { mode: GridCellModes.Edit } };
      }
    });

    return obj;
  };

  const validationSchema = yup.object({
    limitbusiness_interruption: yup.number().required(t('This field may not be blank.')),
  });

  useEffect(() => {
    if (canEdit) {
      const cellModes = formatCellModes(rows);
      setCellModesModel(cellModes);
    }
  }, [rows]);

  const formik = useFormik({
    initialValues: {} as any,
    validationSchema,
    validateOnChange: false,
    onSubmit: async () => {},
  });

  const handleBusinessInterruptionValue = (value) => {
    if (value) {
      switch (Number(value)) {
        case 0:
          return value;
        case 1:
          return `${value} day`;
        default:
          return `${value} days`;
      }
    }
    return '-';
  };

  const columns = [
    {
      field: 'coverages',
      headerName: t('Coverages'),
      flex: 2,
      minWidth: 262,
      type: 'string',
      sortable: false,
      renderCell(params) {
        return (
          <>
            <Typography
              sx={{
                overflow: 'hidden',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                alignSelf:
                  params.row.id === 'business_interruption_estimated' ? 'flex-start' : 'unset',
              }}
              title={params.row.coverages}
            >
              {params.row.coverages}
            </Typography>
            {params.row.tooltip && (
              <Tooltip arrow title={params.row.tooltip} enterTouchDelay={0}>
                <Info
                  sx={{
                    cursor: 'help',
                    fontSize: '24px',
                    ml: 0.5,
                    color: (theme) => theme.customColors.primary.buttonBg,
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: 'additional',
      headerName: '',
      flex: 2,
      minWidth: 146,
      type: 'string',
      sortable: false,
      editable: true,
      align: 'right',
      renderCell: (params) => {
        if (typeof params.value === 'object') {
          switch (params.row.id) {
            case 'business_interruption':
              return (
                <DataTableFieldParser
                  props={{
                    ...params,
                    field: `limit${params.row.id}`,
                    value: formik.values?.[`new_limit${params.row.id}`] || '',
                  }}
                  definitions={{
                    autoFocus: false,
                    required: false,
                    formik,
                    type: dataFieldTypes.RADIO_BUTTON,
                    align: 'right',
                    withDollarIcon: true,
                    formatted: true,
                    label: 'User Specified',
                    fieldName: `new_limit${params.row.id}`,
                    controlledFieldName: 'limitbusiness_interruption',
                    placeholder: `${t('Limit')}`,
                    isChanged: () => {
                      setIsDataChanged(true);
                    },
                  }}
                />
              );

            case 'business_interruption_estimated':
              return (
                <DataTableFieldParser
                  props={{
                    ...params,
                    field: `limit${params.row.id}`,
                    value: formik.values?.[`limit${params.row.id}`] || '',
                  }}
                  definitions={{
                    autoFocus: false,
                    required: false,
                    formik,
                    type: dataFieldTypes.RADIO_BUTTON,
                    align: 'right',
                    withDollarIcon: true,
                    formatted: true,
                    label: 'Suggested',
                    isValueDefault: true,
                    isDisabled: !formik.values?.[`limit${params.row.id}`],
                    fieldName: `limit${params.row.id}`,
                    controlledFieldName: 'limitbusiness_interruption',
                    placeholder: `${t('Limit')}`,
                    isChanged: () => {
                      setIsDataChanged(true);
                    },
                  }}
                />
              );
            default:
              return (
                <DataTableFieldParser
                  props={{
                    ...params,
                    field: `limit${params.row.id}`,
                    value: formik.values?.[`limit${params.row.id}`] || '',
                  }}
                  definitions={{
                    autoFocus: false,
                    required: false,
                    formik,
                    type: dataFieldTypes.RADIO_BUTTON,
                    align: 'right',
                    withDollarIcon: true,
                    formatted: true,
                    label: 'User Specified',
                    isDisabled: true,
                    fieldName: `limit${params.row.id}`,
                    controlledFieldName: `limit${params.row.id}`,
                    placeholder: `${t('Limit')}`,
                    isChanged: () => {
                      setIsDataChanged(true);
                    },
                  }}
                />
              );
          }
        } else {
          return params.value;
        }
      },
    },
    {
      field: 'limit',
      headerName: t('Limit'),
      flex: 2,
      minWidth: 170,
      type: 'string',
      sortable: false,
      editable: true,
      headerAlign: 'right',
      align: 'right',
      renderEditCell(params) {
        switch (params.row.limit.type) {
          case 'string':
            return (
              <DataTableFieldParser
                props={{
                  ...params,
                  field: `limit${params.row.id}`,
                  value: formik.values?.[`limit${params.row.id}`] || '',
                }}
                definitions={{
                  autoFocus: false,
                  required: true,
                  formik,
                  type: dataFieldTypes.NUMBER,
                  align: 'right',
                  withDollarIcon: true,
                  formatted: true,
                  fieldName: `limit${params.row.id}`,
                  controlledFieldName:
                    params.row.id === 'business_interruption' ? `new_limit${params.row.id}` : '',
                  placeholder:
                    params.row.id === 'all_other_property_estimated_replacement_cost_override'
                      ? `${t('ERC')}`
                      : `${t('Limit')}`,
                  isChanged: () => {
                    setIsDataChanged(true);
                  },
                }}
              />
            );

          case 'select':
            return (
              <DataTableFieldParser
                props={{
                  ...params,
                  field: `limit${params.row.id}`,
                  value: formik.values?.[`limit${params.row.id}`] || '',
                }}
                definitions={{
                  autoFocus: false,
                  required: true,
                  formik,
                  type: dataFieldTypes.SELECT,
                  align: 'right',
                  withDollarIcon: false,
                  formatted: params.row.limit?.formatted !== 'text',
                  fieldName: `limit${params.row.id}`,
                  placeholder: `${t('Limit')} *`,
                  choices: params.row.limit?.choices,
                  choice: params.row.limit?.choice,
                  isChanged: () => setIsDataChanged(true),
                }}
              />
            );

          default:
            return (
              <DataTableFieldParser
                props={{
                  ...params,
                  field: `limit${params.row.id}`,
                  value: formik.values?.[`limit${params.row.id}`] || '',
                }}
                definitions={{
                  autoFocus: false,
                  required: true,
                  formik,
                  type: dataFieldTypes.SELECT,
                  align: 'right',
                  withDollarIcon: false,
                  formatted: params.row.limit?.formatted !== 'text',
                  fieldName: `limit${params.row.id}`,
                  placeholder: `${t('Limit')} *`,
                  choices: params.row.limit?.choices,
                  choice: params.row.limit?.choice,
                  isChanged: () => setIsDataChanged(true),
                }}
              />
            );
        }
      },

      renderCell: (params) => {
        switch (params.id) {
          case 'wind':
            return formik.values?.[`limit${params.row.id}`]!;
          case 'workers_compensation':
            return formik.values?.[`limit${params.row.id}`]!
              ? currencyFormat('USD', formik.values?.[`limit${params.row.id}`]!).merged.slice(0, -3)
              : params.row.limit || '-';
          default:
            return (
              (formik.values?.[`limit${params.row.id}`] &&
                currencyFormat('USD', formik.values?.[`limit${params.row.id}`]!).merged.slice(
                  0,
                  -3,
                )) ||
              '-'
            );
        }
      },
    },
    {
      field: 'deductible',
      headerName: t('Deductible'),
      flex: 1,
      minWidth: 170,
      type: 'string',
      sortable: false,
      align: 'right',
      editable: true,
      headerAlign: 'right',

      renderEditCell(params) {
        return (
          <DataTableFieldParser
            props={{
              ...params,
              field: `deductible${params.row.id}`,
              value: formik.values?.[`deductible${params.row.id}`] || '-',
            }}
            definitions={{
              autoFocus: false,
              required: true,
              formik,
              type: dataFieldTypes.SELECT,
              align: 'right',
              withDollarIcon: false,
              formatted:
                params.row.id === 'workers_compensation'
                  ? !!Number(formik.values?.[`deductible${params.row.id}`])
                  : params.row.deductible?.formatted,
              fieldName: `deductible${params.row.id}`,
              placeholder: `${t('Deductible')} *`,
              choices: params.row.deductible?.choices,
              choice: params.row.deductible?.choice,
              isChanged: () => setIsDataChanged(true),
            }}
          />
        );
      },

      renderCell: (params) => {
        switch (params.id) {
          case 'business_liability_extra':
          case 'all_other_property_estimated_replacement_cost':
          case 'all_other_property_estimated_replacement_cost_override':
          case 'business_interruption_estimated':
            return '';
          case 'flood':
            return formik.values?.[`deductible${params.row.id}`]
              ? formik.values?.[`deductible${params.row.id}`]
              : '';
          case 'business_interruption':
            return handleBusinessInterruptionValue(formik.values?.[`deductible${params.row.id}`]);
          case 'workers_compensation':
            return formik.values?.[`deductible${params.row.id}`]!
              ? currencyFormat('USD', formik.values?.[`deductible${params.row.id}`]).merged.slice(
                  0,
                  -3,
                )
              : params.row.deductible || '-';
          default:
            return (
              (formik.values?.[`deductible${params.row.id}`] &&
                currencyFormat('USD', formik.values?.[`deductible${params.row.id}`]).merged.slice(
                  0,
                  -3,
                )) ||
              '-'
            );
        }
      },
    },
  ];

  const viewModeColumns = [
    {
      field: 'coverages',
      headerName: t('Coverages'),
      flex: 2,
      minWidth: 280,
      type: 'string',
      sortable: false,
      renderCell(params) {
        return (
          <Tooltip title={params.row.coverages}>
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {params.row.coverages}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'additional',
      headerName: '',
      flex: 2,
      minWidth: 120,
      type: 'string',
      sortable: false,
      align: 'right',
    },
    {
      field: 'limit',
      headerName: t('Limit'),
      flex: 2,
      minWidth: 170,
      type: 'string',
      sortable: false,
      editable: true,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) =>
        params.row.limitChoices
          ? params.row.limitChoices.find((choice) => choice.code === params.row.limit)?.name
          : params.row.limit
          ? typeof params.row.limit !== 'object'
            ? !checkIfValueHasLetters(params.row.limit)
              ? currencyFormat('USD', params.row.limit).merged.slice(0, -3)
              : params.row.limit
            : !isEmpty(params.row.limit.value)
            ? currencyFormat('USD', params.row.limit.value).merged.slice(0, -3)
            : '-'
          : '-',
    },
    {
      field: 'deductible',
      headerName: t('Deductible'),
      flex: 1,
      minWidth: 170,
      type: 'string',
      sortable: false,
      align: 'right',
      editable: true,
      headerAlign: 'right',
      renderCell: (params) =>
        [
          'business_liability_extra',
          'all_other_property_estimated_replacement_cost',
          'all_other_property_estimated_replacement_cost_override',
          'business_interruption_estimated',
        ].includes(params.id as string)
          ? ''
          : params.id === 'flood'
          ? params.row.deductible
            ? params.row.deductible
            : ''
          : params.id === 'business_interruption'
          ? handleBusinessInterruptionValue(params.row.deductible)
          : params.row.deductibleChoices
          ? params.row.deductibleChoices.find((choice) => choice.code === params.row.deductible)
              ?.name
          : params.row.deductible
          ? !checkIfValueHasLetters(params.row.deductible)
            ? currencyFormat('USD', params.row.deductible).merged.slice(0, -3)
            : params.row.deductible
          : '-',
    },
  ];

  const handleChoiceAndRows = async () => {
    try {
      if (
        !isEmpty(fields.exposure.data) &&
        !isEmpty(fields.policy.data) &&
        !isEmpty(fields.peril.data) &&
        quoteLoaded &&
        exposures?.loaded &&
        !exposures.loading
      ) {
        // choices and fields
        let pol_bl_limit_occurrence_choices: Choice[] = [];
        let pol_bl_deductible_choices: Choice[] = [];
        let pol_op_deductible_choices: Choice[] = [];
        let pol_eq_excluded_choices: Choice[] = [];
        let pol_fl_excluded_choices: Choice[] = [];
        let pol_wd_deductible_choices: Choice[] = [];
        let pol_eq_deductible_choices: Choice[] = [];
        let pol_al_limit_choices: Choice[] = [];

        let bdg_deductible_choices: Choice[] = [];
        let veh_deductible_choices: Choice[];

        let buildingFields: any[] = [];

        let vehicleFields: any[] = [];

        let exposureValues = {};
        // policy fields choices loop
        (fields?.policy?.data as ProductWorkFlow[]).forEach((item) => {
          item.fields?.forEach((field) => {
            if (field.code === 'pol_bl_limit_occurrence') {
              if (field.choices) {
                // Filter choices based on excluded states
                const filteredChoices = field.choices.filter((choice) => {
                  if (choice.additional_data?.excludedStates) {
                    const { excludedStates = [] } = choice.additional_data;

                    const policyState = quoteCharacteristics?.pol_state;
                    const isChoiceVisible = !excludedStates?.includes(policyState as string);

                    return isChoiceVisible;
                  }
                  return true;
                });

                pol_bl_limit_occurrence_choices = filteredChoices;
              }
            }
            if (field.code === 'pol_bl_deductible') {
              pol_bl_deductible_choices = field.choices!;
            }
            if (field.code === 'pol_op_deductible') {
              pol_op_deductible_choices = field.choices!;
            }
            if (field.code === 'pol_eq_excluded') {
              pol_eq_excluded_choices = field.choices!;
            }
            if (field.code === 'pol_eq_deductible') {
              pol_eq_deductible_choices = field.choices!;
              setEarthquakeDeductibleFirstChoice(pol_eq_deductible_choices[0].code);
            }
            if (field.code === 'pol_al_limit') {
              pol_al_limit_choices = field.choices!;
            }
            if (field.code === 'pol_flood_indicator') {
              pol_fl_excluded_choices = field.choices!;
            }
            if (field.code === 'pol_deductible_wind') {
              pol_wd_deductible_choices = field.choices!;
            }
          });
        });

        // exposure fields choices loop
        (fields?.exposure?.data as ProductWorkFlow[]).forEach((item) => {
          item.fields?.forEach((field) => {
            if (field.code === 'bdg_deductible') {
              bdg_deductible_choices = field.choices!;
            }
            if (field.code === 'veh_deductible') {
              veh_deductible_choices = field.choices!;
            }
          });
        });

        exposures?.data?.forEach((exposure, index) => {
          const coveragesParseLocation = parseLocation(exposure?.data?.bdg_location);
          const coveragesLocation = `${formatLocation(coveragesParseLocation).showing.head}${
            formatLocation(coveragesParseLocation).showing.tail
          }`;
          if (
            exposure.name === 'building' &&
            acceptedBdgOccupancy.includes(exposure.data?.bdg_occupancy)
          ) {
            exposureValues = {
              ...exposureValues,
              [`deductible${exposure.locator}`]: exposure.data?.bdg_deductible,
              [`limit${exposure.locator}`]: exposure.data?.bdg_tiv_building,
            };
            buildingFields = canEdit
              ? [
                  ...buildingFields,
                  {
                    coverages: `${
                      exposure?.data?.bdg_name ? `${exposure?.data?.bdg_name},` : ''
                    } ${coveragesLocation} `,
                    id: exposure?.locator,
                    section: index === 0,
                    locator: exposure?.locator,
                    limit: exposure.data?.bdg_tiv_building || '-',
                    deductible: {
                      value: exposure.data?.bdg_deductible || '',
                      choices: bdg_deductible_choices,
                      choice: {
                        value: 'code',
                        displayValue: 'name',
                      },
                      isEditMode: true,
                    },
                  },
                ]
              : [
                  ...buildingFields,
                  {
                    coverages: `${
                      exposure?.data?.bdg_name ? `${exposure?.data?.bdg_name},` : ''
                    } ${coveragesLocation} `,
                    id: exposure?.locator,
                    section: index === 0,
                    limit: exposure.data?.bdg_tiv_building || '',
                    deductible: exposure.data?.bdg_deductible || '',
                  },
                ];
          }

          if (exposure.name === 'vehicle') {
            exposureValues = {
              ...exposureValues,
              [`limit${exposure.locator}`]: isEmpty(exposure.data?.veh_limit)
                ? ''
                : exposure.data?.veh_limit,
              [`deductible${exposure.locator}`]: exposure.data?.veh_deductible,
            };
            vehicleFields = canEdit
              ? [
                  ...vehicleFields,
                  {
                    coverages: exposure.data?.veh_vin
                      ? `${exposure.data.veh_vin} | ${exposure.data.veh_year ?? ''}, ${
                          exposure.data.veh_make ?? ''
                        }, ${exposure.data.veh_model ?? ''}`
                      : `${exposure.data?.veh_year ?? ''}, ${exposure.data?.veh_make ?? ''}, ${
                          exposure.data?.veh_model ?? ''
                        }`,
                    id: exposure?.locator,
                    section: index === 0,
                    limit: exposure.data?.veh_limit,
                    deductible: {
                      value: exposure.data?.veh_deductible || '',
                      choices: veh_deductible_choices,
                      choice: {
                        value: 'code',
                        displayValue: 'name',
                      },
                      isEditMode: true,
                    },
                  },
                ]
              : [
                  ...vehicleFields,
                  {
                    coverages: exposure.data?.veh_vin
                      ? `${exposure.data.veh_vin} | ${exposure.data.veh_year ?? ''}, ${
                          exposure.data.veh_make ?? ''
                        }, ${exposure.data.veh_model ?? ''}`
                      : `${exposure.data?.veh_year ?? ''}, ${exposure.data?.veh_make ?? ''}, ${
                          exposure.data?.veh_model ?? ''
                        }`,
                    id: exposure?.locator,
                    section: index === 0,
                    limit: exposure.data?.veh_limit || '',
                    deductible: exposure.data?.veh_deductible || '',
                  },
                ];
          }
          if (exposure.name === 'employer') {
            exposure.perils?.forEach((peril) => {
              if (peril.name === 'small_deductible_program') {
                exposureValues = {
                  ...exposureValues,
                  [`deductibleworkers_compensation`]: peril?.data?.ded_deductible,
                };
              }
            });
          }
        });

        formik.setValues(
          {
            ...formik.values,
            ...exposureValues,
            pay_plan: quoteDetail?.payment_schedule || '',
            limitbusiness_liability: quoteCharacteristics?.pol_bl_limit_occurrence || '',
            deductiblebusiness_liability: quoteCharacteristics?.pol_bl_deductible || '',
            limitcyber_incident_response: quoteCharacteristics?.pol_cy_limit || '',
            limitbusiness_liability_extra: quoteCharacteristics?.pol_bl_limit_aggregate || '',
            deductiblecyber_incident_response: quoteCharacteristics?.pol_cy_deductible || '',
            limitall_other_property: quoteCharacteristics?.pol_op_limit || '',
            limitall_other_property_estimated_replacement_cost:
              quoteCharacteristics?.pol_op_erc_rule || '',
            limitall_other_property_estimated_replacement_cost_override:
              quoteCharacteristics?.pol_op_erc_user || '',
            deductibleall_other_property: quoteCharacteristics?.pol_op_deductible || '',
            new_limitbusiness_interruption:
              !quoteCharacteristics?.pol_bi_limit ||
              quoteCharacteristics?.pol_bi_limit === quoteCharacteristics?.pol_bi_erc
                ? ''
                : quoteCharacteristics?.pol_bi_limit,
            limitbusiness_interruption:
              quoteCharacteristics?.pol_bi_limit || quoteCharacteristics?.pol_bi_erc || '',
            limitbusiness_interruption_estimated: quoteCharacteristics?.pol_bi_erc || '',
            deductiblebusiness_interruption: quoteCharacteristics?.pol_bi_waiting_period || '',
            limitearthquake: quoteCharacteristics?.pol_eq_excluded || '',
            deductibleearthquake: quoteCharacteristics?.pol_eq_deductible || '',
            ...(isFloodRowVisible
              ? {
                  limitflood: quoteCharacteristics?.pol_flood_indicator || '',
                  deductibleflood:
                    quoteCharacteristics?.pol_flood_indicator === selectionChoices.NO
                      ? ''
                      : selectionChoices.FLAT,
                }
              : {}),
            ...(isWindRowVisible
              ? { deductiblewind: quoteCharacteristics?.pol_deductible_wind || '' }
              : {}),
            limitauto_liability: quoteCharacteristics?.pol_al_limit || '',
            deductibleauto_liability: quoteCharacteristics?.pol_al_deductible || '',
          },
          false,
        );

        if (canEdit) {
          // setting isDataChanged true to calculate price for first visit
          if (!wasPriceCalculated) {
            setIsDataChanged(true);
          }

          setRows([
            {
              coverages: 'Business Liability',
              limit: {
                value: quoteCharacteristics?.pol_bl_limit_occurrence || '',
                choices: pol_bl_limit_occurrence_choices,
                choice: {
                  value: 'code',
                  displayValue: 'name',
                },
                isEditMode: true,
              },
              deductible: {
                value: quoteCharacteristics?.pol_bl_deductible || '',
                choices: pol_bl_deductible_choices,
                choice: {
                  value: 'code',
                  displayValue: 'name',
                },
                isEditMode: true,
              },
              section: true,
              id: 'business_liability',
              noBorder: true,
              additional: 'Per occurrence',
            },
            {
              coverages: '',
              limit: quoteCharacteristics?.pol_bl_limit_aggregate || '-',
              deductible: '',
              section: false,
              id: 'business_liability_extra',
              additional: 'Maximum',
            },
            {
              coverages: 'Cyber Incident Response',
              limit: quoteCharacteristics?.pol_cy_limit || '-',
              deductible: quoteCharacteristics?.pol_cy_deductible || '-',
              section: false,
              id: 'cyber_incident_response',
            },
            ...buildingFields,
            {
              coverages: 'All Other Property',
              additional: {
                type: 'radio',
                value: quoteCharacteristics?.pol_op_limit || '-',
                isEditMode: true,
              },
              limit: quoteCharacteristics?.pol_op_limit || '-',
              deductible: {
                value: quoteCharacteristics?.pol_op_deductible || '',
                choices: pol_op_deductible_choices,
                choice: {
                  value: 'code',
                  displayValue: 'name',
                },
                isEditMode: true,
              },
              id: 'all_other_property',
              section:
                exposures?.data?.filter((exposure) => exposure.name === 'building')?.length === 0,
            },
            {
              coverages: '',
              additional: 'Suggested',
              limit: quoteCharacteristics?.pol_op_erc_rule || '-',
              deductible: '',
              id: 'all_other_property_estimated_replacement_cost',
              noBorder: true,
            },
            ...(userData?.role?.code === userRoles.UNDERWRITER.code
              ? [
                  {
                    coverages: '',
                    additional: 'ERC Override',
                    limit: {
                      type: 'string',
                      value: quoteCharacteristics?.pol_op_erc_user || '-',
                      isEditMode: true,
                    },
                    deductible: '',
                    id: 'all_other_property_estimated_replacement_cost_override',
                    noBorder: true,
                  },
                ]
              : []),
            {
              coverages: 'Business Interruption',
              limit: {
                type: 'string',
                value: quoteCharacteristics?.pol_bi_limit || '-',
                isEditMode: true,
              },
              deductible: quoteCharacteristics?.pol_bi_waiting_period || '-',
              id: 'business_interruption',
              additional: {
                type: 'radio',
                value: quoteCharacteristics?.pol_bi_limit || '-',
                isEditMode: true,
              },
              tooltip: 'Please enter Business interruption limit to be able to rate the Quote.',
            },
            {
              coverages: '(Up to 1 Year)',
              limit: quoteCharacteristics?.pol_bi_erc || '-',
              deductible: '',
              section: false,
              id: 'business_interruption_estimated',
              noBorder: true,
              additional: {
                type: 'radio',
                value: quoteCharacteristics?.pol_bi_erc || '-',
                isEditMode: true,
              },
            },
            ...(isEarthquakeRowVisible
              ? [
                  {
                    coverages: 'Earthquake',
                    limit: {
                      value: quoteCharacteristics?.pol_eq_excluded || '',
                      choices: pol_eq_excluded_choices,
                      choice: {
                        value: 'code',
                        displayValue: 'name',
                      },
                      isEditMode: true,
                      formatted: 'text',
                    },
                    deductible: {
                      value: quoteCharacteristics?.pol_eq_deductible || '',
                      choices: pol_eq_deductible_choices,
                      choice: {
                        value: 'code',
                        displayValue: 'name',
                      },
                      isEditMode: true,
                    },
                    id: 'earthquake',
                  },
                ]
              : []),
            ...(isWindRowVisible
              ? [
                  {
                    coverages: 'Wind/Hail Coverage',
                    limit: selectionChoices.INCLUDED,
                    deductible: {
                      value: quoteCharacteristics?.pol_deductible_wind || '',
                      choices: pol_wd_deductible_choices,
                      choice: {
                        value: 'code',
                        displayValue: 'name',
                      },
                      isEditMode: true,
                    },
                    id: 'wind',
                  },
                ]
              : []),
            ...(isFloodRowVisible
              ? [
                  {
                    coverages: 'Flood Coverage',
                    limit: {
                      value: quoteCharacteristics?.pol_flood_indicator || '',
                      choices: pol_fl_excluded_choices,
                      choice: {
                        value: 'code',
                        displayValue: 'name',
                      },
                      isEditMode: true,
                      formatted: 'text',
                    },
                    deductible:
                      quoteCharacteristics?.pol_flood_indicator === selectionChoices.NO
                        ? ''
                        : selectionChoices.FLAT,
                    id: 'flood',
                  },
                ]
              : []),
            ...(!isEmpty(vehicleFields)
              ? [
                  {
                    coverages: 'Auto Liability',
                    limit: {
                      value: quoteCharacteristics?.pol_al_limit || '',
                      choices: pol_al_limit_choices,
                      choice: {
                        value: 'code',
                        displayValue: 'name',
                      },
                      isEditMode: true,
                    },
                    deductible: quoteCharacteristics?.pol_al_deductible || '-',
                    section: true,
                    id: 'auto_liability',
                  },
                ]
              : []),
            ...vehicleFields,
            ...(isProductCodeThreeWithWorkersCompensation
              ? [
                  {
                    coverages: 'Workers Compensation',
                    limit: 'As required by law',
                    deductible: policyState === states.CA ? 'None' : '0',
                    section: true,
                    id: 'workers_compensation',
                  },
                ]
              : []),
          ]);
        } else {
          setRows([
            {
              coverages: 'Business Liability',
              limit: quoteCharacteristics?.pol_bl_limit_occurrence || '-',
              deductible: quoteCharacteristics?.pol_bl_deductible || '-',
              section: true,
              id: 'business_liability',
              additional: 'Per occurrence',
            },
            {
              coverages: '',
              limit: quoteCharacteristics?.pol_bl_limit_aggregate || '-',
              deductible: '',
              section: false,
              id: 'business_liability_extra',
              additional: 'Maximum',
            },
            {
              coverages: 'Cyber Incident Response',
              limit: quoteCharacteristics?.pol_cy_limit || '-',
              deductible: quoteCharacteristics?.pol_cy_deductible || '-',
              section: true,
              id: 'cyber_incident_response',
            },
            ...buildingFields,
            {
              coverages: 'All Other Property',
              limit: quoteCharacteristics?.pol_op_limit || '-',
              deductible: quoteCharacteristics?.pol_op_deductible || '-',
              id: 'all_other_property',
            },
            ...(isUnderwriter
              ? [
                  {
                    coverages: 'All Other Property ERC',
                    limit: quoteCharacteristics?.pol_op_erc_rule || '-',
                    deductible: '',
                    id: 'all_other_property_estimated_replacement_cost',
                    section:
                      exposures?.data?.filter((exposure) => exposure.name === 'building')
                        ?.length === 0,
                  },
                ]
              : []),
            ...(isUnderwriter
              ? [
                  {
                    coverages: 'All Other Property ERC Override',
                    limit: {
                      type: 'string',
                      value: quoteCharacteristics?.pol_op_erc_user || '-',
                      isEditMode: true,
                    },
                    deductible: '',
                    id: 'all_other_property_estimated_replacement_cost_override',
                    section:
                      exposures?.data?.filter((exposure) => exposure.name === 'building')
                        ?.length === 0,
                  },
                ]
              : []),
            {
              coverages: 'Business Interruption Estimated Limit',
              limit: quoteCharacteristics?.pol_bi_erc || '-',
              deductible: '',
              id: 'business_interruption_estimated',
            },
            {
              coverages: 'Business Interruption',
              limit: quoteCharacteristics?.pol_bi_limit || '-',
              deductible: quoteCharacteristics?.pol_bi_waiting_period || '-',
              id: 'business_interruption',
              additional: 'Up to 1 Year',
            },
            ...(isEarthquakeRowVisible
              ? [
                  {
                    coverages: 'Earthquake',
                    limit: quoteCharacteristics?.pol_eq_excluded || '-',
                    limitChoices: pol_eq_excluded_choices,
                    deductible: quoteCharacteristics?.pol_eq_deductible || '-',
                    deductibleChoices: pol_eq_deductible_choices,
                    id: 'earthquake',
                  },
                ]
              : []),
            ...(isWindRowVisible
              ? [
                  {
                    coverages: 'Wind/Hail Coverage',
                    limit: selectionChoices.INCLUDED,
                    deductible: quoteCharacteristics?.pol_deductible_wind || '-',
                    deductibleChoices: pol_wd_deductible_choices,
                    id: 'wind',
                  },
                ]
              : []),
            ...(isFloodRowVisible
              ? [
                  {
                    coverages: 'Flood Coverage',
                    limit: quoteCharacteristics?.pol_flood_indicator || '-',
                    limitChoices: pol_fl_excluded_choices,
                    deductible:
                      quoteCharacteristics?.pol_flood_indicator === selectionChoices.NO
                        ? ''
                        : selectionChoices.FLAT,
                    id: 'flood',
                  },
                ]
              : []),
            ...(!isEmpty(vehicleFields)
              ? [
                  {
                    coverages: 'Auto Liability',
                    limit: quoteCharacteristics?.pol_al_limit || '-',
                    deductible: quoteCharacteristics?.pol_al_deductible || '-',
                    section: true,
                    id: 'auto_liability',
                  },
                ]
              : []),
            ...vehicleFields,
            ...(isProductCodeThreeWithWorkersCompensation
              ? [
                  {
                    coverages: 'Workers Compensation',
                    limit: 'As required by law',
                    deductible: policyState === states.CA ? 'None' : '0',
                    section: true,
                    id: 'workers_compensation',
                  },
                ]
              : []),
          ]);
          await getQuotePrice(quoteDetail?.locator as string);
          setIsCoveragesSummaryPriceCalculated(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setIsCoveragesSummaryPriceCalculated(false);
      if (!quoteDetail?.has_accepted_related_quote)
        displayBackendErrorMessage(error, t('An error occurred while recalculating.'));
    }
  };

  useEffect(() => {
    handleChoiceAndRows();
  }, [
    fields?.policy?.data,
    fields?.exposure?.data,
    fields?.peril?.data,
    userData,
    quoteLoaded,
    exposures?.loaded,
    exposures?.loading,
    canEdit,
  ]);

  const handleReCalculate = async ({
    isRefreshPriceButtonClicked = false,
    saveWithoutValidation = false,
  }) => {
    await formik.submitForm();
    const errors = await formik.validateForm();

    if (isEmpty(errors) || saveWithoutValidation) {
      try {
        setLoading(true);

        /**
         * Make it false if we get any error from integrations,
         * But still update the quote, exposure and peril values
         */
        let shouldGetPriceRequest = isRefreshPriceButtonClicked;
        let quoteDetailResponse: any;

        const polPayload = {
          data: {
            ...quoteDetail?.characteristics?.[0].data,
            pol_bl_limit_occurrence: formik.values?.limitbusiness_liability || '',
            pol_bl_deductible: formik.values?.deductiblebusiness_liability || '',
            pol_bl_limit_aggregate: formik.values?.limitbusiness_liability_extra || '',
            pol_cy_limit: formik.values?.limitcyber_incident_response || '',
            pol_cy_deductible: formik.values?.deductiblecyber_incident_response || '',
            pol_op_limit: formik.values?.limitall_other_property || '',
            pol_op_erc_rule:
              formik.values?.limitall_other_property_estimated_replacement_cost || '',
            pol_op_erc_user:
              formik.values?.limitall_other_property_estimated_replacement_cost_override || '',
            pol_op_deductible: formik.values?.deductibleall_other_property || '',
            pol_bi_limit: formik.values?.limitbusiness_interruption || '',
            pol_bi_erc: formik.values?.limitbusiness_interruption_estimated || '',
            pol_bi_waiting_period: formik.values?.deductiblebusiness_interruption || '',
            pol_eq_excluded: formik.values?.limitearthquake || '',
            pol_eq_deductible: formik.values?.deductibleearthquake || '',
            ...(isFloodRowVisible
              ? {
                  pol_flood_indicator: formik.values?.limitflood || '',
                }
              : {}),
            ...(isWindRowVisible
              ? {
                  pol_deductible_wind: formik.values?.deductiblewind || '',
                }
              : {}),
            pol_al_limit: formik.values?.limitauto_liability || '',
            pol_al_deductible: formik.values?.deductibleauto_liability || '',
            // Socotra requires pol_pay_plan to be titlecase
            pol_pay_plan: formik.values.pay_plan ? startCase(formik.values.pay_plan) : '',
          },
          // Socotra requires payment_schedule to be titlecase
          payment_schedule: formik.values.pay_plan ? startCase(formik.values.pay_plan) : '',
        } as any;

        const foundPrimaryOwnerIndex =
          polPayload.data?.pol_oo?.findIndex((o) => o.pol_oo_isprimary === 'Yes') ?? -1;

        const primaryOwner = { ...polPayload.data?.pol_oo?.[foundPrimaryOwnerIndex] };
        const isNcfScoreEmpty = isEmpty(primaryOwner.pol_oo_ncf_score);
        const effectiveDate = quoteDetail?.characteristics?.[0]?.started_at!;
        const willUpdateEmployers: any[] = [];
        let shouldSavePolicyData = isDataChanged;
        let shouldSaveExposureData = isDataChanged;
        const isTabChanged = !isRefreshPriceButtonClicked;

        const checkConditionsAndGetNCFScore = async () => {
          if (foundPrimaryOwnerIndex !== -1) {
            const filingId = findFilingSetId(
              locFilingIdTable?.data! as any,
              underwritingQuestionsState?.pol_state,
              underwritingQuestionsState?.effective_date,
            );

            // https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11349/
            if (primaryOwner?.pol_oo_isprimary === 'Yes') {
              if (filingId === 28) {
                primaryOwner.pol_oo_ncf_score = -1;
              } else {
                primaryOwner.pol_oo_ncf_score = 998;
              }
            }

            const vehicles = exposureList?.[`${threeExposureNames.VEHICLE}`]?.data ?? [];

            if (
              primaryOwner.pol_oo_ncf_optin === 'Yes' &&
              primaryOwner.pol_oo_ncf_score?.toString() === '998' &&
              vehicles.length > 0
            ) {
              // Make request to LexisNexis for getting the ncf-score
              try {
                const score = (await getNCFScore(prepareOwnerForLexisNexisRequest(primaryOwner)))
                  .NCF;

                primaryOwner.pol_oo_ncf_score = score;
                // If integrations successful then trigger save ncf score to policy data
                shouldSavePolicyData = true;
              } catch (error) {
                // If integrations failed do not get price
                shouldGetPriceRequest = false;
                displayIntegrationErrorMessage(
                  error,
                  t('An error occurred while fetching the integration results.'),
                );
              }
            }

            const tmp = [...polPayload.data.pol_oo];
            tmp[foundPrimaryOwnerIndex] = primaryOwner;
            polPayload.data.pol_oo = tmp;
          }
        };

        const checkConditionsAndGetExpModIntegrations = async () => {
          if (
            isProductCodeThreeWithWorkersCompensation &&
            validateExpModIntegrationsCanRun(underwritingQuestionsState, effectiveDate)
          ) {
            // XX-XXXXXXX to XXXXXXXXX
            const fein = underwritingQuestionsState?.fein?.replaceAll('-', '');

            // get exposures from provider
            const employerExposures = exposureList?.[`${threeExposureNames.EMPLOYER}`]?.data ?? [];
            const filteredEmployerExposures = employerExposures.filter((item) =>
              Boolean(item?.data?.emp_state),
            );

            try {
              // awaiting a mutation from react-query is pretty non-standard, but there's a lot of dependent logic inline
              // that'd require a lot of refactoring
              const result = await calculateXmod(
                filteredEmployerExposures.map((employer) => ({
                  fein,
                  state: employer.data!.emp_state,
                  policy_effective_date: effectiveDate,
                  employer_locator: String(employer.locator),
                })),
              );

              for (const { employer_locator, data } of result) {
                const employer = filteredEmployerExposures.find(
                  (employer) => employer.locator === employer_locator,
                );

                if (employer) {
                  willUpdateEmployers.push({
                    locator: employer.locator,
                    data: { ...employer.data, ...data },
                  });
                }
              }

              // If integration request successful then trigger saving willUpdateEmployers to exposure data
              shouldSaveExposureData = true;
            } catch (error) {
              shouldGetPriceRequest = false;
              displayIntegrationErrorMessage(
                error,
                t('An error occurred while fetching the integration results.'),
              );
            }
          }
        };

        const updatedExposureBuildingList =
          exposureList?.building.data
            ?.filter((exposure) => acceptedBdgOccupancy.includes(exposure.data?.bdg_occupancy))
            .map((item) => ({
              locator: item?.locator,
              data: {
                bdg_deductible: formik.values?.[`deductible${item.locator}`] || '',
                bdg_tiv_building: formik.values?.[`limit${item.locator}`] || '',
              },
            })) ?? [];

        const updatedExposureVehicleList =
          exposureList?.vehicle.data?.map((item) => ({
            locator: item?.locator,
            data: {
              veh_deductible: formik.values?.[`deductible${item.locator}`] || '',
              veh_limit: formik.values?.[`limit${item.locator}`] || '',
            },
          })) ?? [];

        const smallDeductibleProgramExposure = exposureList?.employer.data?.find((exposure) =>
          exposure?.perils?.find((peril) => peril.name === 'small_deductible_program'),
        );

        const smallDeductibleProgramPeril = smallDeductibleProgramExposure?.perils?.find(
          (peril) => peril.name === 'small_deductible_program',
        );

        const savePolicyData = async () => {
          quoteDetailResponse = await updateQuoteDetail(
            quoteDetail?.policy_locator as string,
            quoteDetail?.locator as string,
            polPayload,
            undefined,
            undefined,
          );
          // If exposure data will not be updated then we should get exposure data here because policy data may changes exposure data
          // TODO: think refactoring using shouldGetExposureData on finally block
          if (
            !isDataChanged ||
            (!(updatedExposureBuildingList?.length! > 0) &&
              !(updatedExposureVehicleList?.length! > 0) &&
              !(willUpdateEmployers?.length > 0) &&
              !smallDeductibleProgramPeril)
          ) {
            // exposure values may also be updated with policy data update, so we should get exposure data
            getExposures(id, { page_size: 10000 });
          }
        };

        let shouldGetQuoteDetailRequest = false;
        const saveExposureData = async () => {
          let shouldGetExposuresRequest = false;
          if (
            updatedExposureBuildingList?.length! > 0 ||
            updatedExposureVehicleList?.length! > 0 ||
            willUpdateEmployers?.length > 0
          ) {
            await bulkExposureUpdateQuote(
              quoteDetail?.policy_locator as string,
              quoteDetail?.locator as string,
              [
                ...updatedExposureBuildingList!,
                ...updatedExposureVehicleList!,
                ...willUpdateEmployers,
              ],
            );
            shouldGetExposuresRequest = true;
            shouldGetQuoteDetailRequest = true; // Exposure update may change quote status
          }

          if (exposureList?.employer?.data?.length! > 0) {
            if (smallDeductibleProgramPeril) {
              // if we find a match in willUpdateEmployers, we should use that instead of the stale data
              const updatedSmallDeductibleProgramData = (
                willUpdateEmployers.find(
                  (employer) => employer.locator === smallDeductibleProgramExposure?.locator,
                ) || smallDeductibleProgramExposure
              )?.data;

              await updatePeril(
                quoteDetail?.policy_locator as string,
                smallDeductibleProgramExposure?.locator as string,
                {
                  name: smallDeductibleProgramExposure?.name as string,
                  data: updatedSmallDeductibleProgramData,
                  display_id: smallDeductibleProgramExposure?.display_id as string,
                  locator: smallDeductibleProgramExposure?.locator as string,
                  perils: [
                    {
                      locator: smallDeductibleProgramPeril?.locator as string,
                      data: {
                        ded_deductible:
                          formik.values?.deductibleworkers_compensation === '-'
                            ? ''
                            : formik.values?.deductibleworkers_compensation || '',
                      },
                      name: smallDeductibleProgramPeril?.name as string,
                    },
                  ],
                },
              );
              shouldGetExposuresRequest = true;
              shouldGetQuoteDetailRequest = true; // Exposure update may change quote status
            }
          }

          if (shouldGetExposuresRequest) {
            getExposures(quoteDetail?.locator!, { page_size: 10000 });
          }
        };

        // Below the actions when user clicks on the refresh price button
        if (isRefreshPriceButtonClicked) {
          if (isNcfScoreEmpty) await checkConditionsAndGetNCFScore();

          // fetch Exp. Mod.s integration results. It should be fetched each time user clicks refresh price button
          await checkConditionsAndGetExpModIntegrations();

          if (shouldSavePolicyData) await savePolicyData();
          if (shouldSaveExposureData) await saveExposureData();
          if (shouldGetPriceRequest) {
            await getQuotePrice(quoteDetail?.locator as string);

            setIsCoveragesSummaryPriceCalculated(true);
            shouldGetQuoteDetailRequest = true; // Price get may change quote status

            displayToastMessage('SUCCESS', 'The quote premium has been recalculated.');
          }
          // Below the actions when user changes the tab
        } else if (isTabChanged) {
          if (shouldSavePolicyData) await savePolicyData();

          if (shouldSaveExposureData) await saveExposureData();

          if (shouldSavePolicyData || shouldSaveExposureData)
            displayToastMessage('SUCCESS', 'The quote data has been updated.');
        }

        if (shouldGetQuoteDetailRequest) {
          await fetchQuoteDetail(
            quoteDetail?.locator as string,
            isRenewal ? { renewals: true } : {},
          );
        }

        if (isProgressTabs && isRefreshPriceButtonClicked) {
          try {
            setLoading(true);
            await updateQuoteProgress({
              locator: id,
              currentTab: submissionDetailInfoTabs.SUMMARY_PRICING.code,
              isCompleted: isEmpty(errors),
              isPriceCalculated: true,
            });
          } catch (error) {
            displayBackendErrorMessage(error);
          } finally {
            setLoading(false);
          }
        }

        setIsDataChanged(false);

        return quoteDetailResponse;
      } catch (error) {
        setIsCoveragesSummaryPriceCalculated(false);
        emitter.emit('preRatingError', {
          error,
          defaultMessage: t('An error occurred while recalculating.'),
        });
        throw error;
      } finally {
        setLoading(false);
      }
    } else {
      displayToastMessage('ERROR', 'Business Interruption Limit cannot be blank.');
      return false;
    }
  };

  // eslint-disable-next-line consistent-return
  const handlepol_bl_limit_occurrence = (value: string) => {
    switch (value) {
      case '300000':
        return { pol_bl_limit_aggregate: '1000000', pol_cy_limit: '75000' };
      case '500000':
        return { pol_bl_limit_aggregate: '1500000', pol_cy_limit: '125000' };
      case '1000000':
        return { pol_bl_limit_aggregate: '3000000', pol_cy_limit: '250000' };
      case '2000000':
        return { pol_bl_limit_aggregate: '5000000', pol_cy_limit: '500000' };
      case '3000000':
        return { pol_bl_limit_aggregate: '6000000', pol_cy_limit: '750000' };
      case '4000000':
        return { pol_bl_limit_aggregate: '8000000', pol_cy_limit: '1000000' };
      case '5000000':
        return { pol_bl_limit_aggregate: '10000000', pol_cy_limit: '1250000' };
      case '6000000':
        return { pol_bl_limit_aggregate: '6000000', pol_cy_limit: '1500000' };
      case '7000000':
        return { pol_bl_limit_aggregate: '7000000', pol_cy_limit: '1750000' };
      case '8000000':
        return { pol_bl_limit_aggregate: '8000000', pol_cy_limit: '2000000' };
      case '9000000':
        return { pol_bl_limit_aggregate: '9000000', pol_cy_limit: '2250000' };
      case '10000000':
        return { pol_bl_limit_aggregate: '10000000', pol_cy_limit: '2500000' };
      case '11000000':
        return { pol_bl_limit_aggregate: '11000000', pol_cy_limit: '2750000' };
      case '12000000':
        return { pol_bl_limit_aggregate: '12000000', pol_cy_limit: '3000000' };
      case '13000000':
        return { pol_bl_limit_aggregate: '13000000', pol_cy_limit: '3250000' };
      case '14000000':
        return { pol_bl_limit_aggregate: '14000000', pol_cy_limit: '3500000' };
      case '15000000':
        return { pol_bl_limit_aggregate: '15000000', pol_cy_limit: '3750000' };
      case '16000000':
        return { pol_bl_limit_aggregate: '16000000', pol_cy_limit: '4000000' };
      case '17000000':
        return { pol_bl_limit_aggregate: '17000000', pol_cy_limit: '4250000' };
      case '18000000':
        return { pol_bl_limit_aggregate: '18000000', pol_cy_limit: '4500000' };
      case '19000000':
        return { pol_bl_limit_aggregate: '19000000', pol_cy_limit: '4750000' };
      case '20000000':
        return { pol_bl_limit_aggregate: '20000000', pol_cy_limit: '5000000' };

      default:
        break;
    }
  };

  useEffect(() => {
    if (formik.values.limitbusiness_liability) {
      const values = {
        limitbusiness_liability_extra: handlepol_bl_limit_occurrence(
          formik.values.limitbusiness_liability as string,
        )?.pol_bl_limit_aggregate,
        limitcyber_incident_response: handlepol_bl_limit_occurrence(
          formik.values.limitbusiness_liability as string,
        )?.pol_cy_limit,
      };
      formik.setValues({ ...formik.values, ...values }, false);
    }
  }, [formik.values.limitbusiness_liability]);

  useEffect(() => {
    if (canEdit && isFloodRowVisible) {
      formik.setFieldValue(
        'deductibleflood',
        formik.values?.limitflood === selectionChoices.NO ? '' : selectionChoices.FLAT,
      );
    }
  }, [formik.values?.limitflood]);

  const showLoader =
    !quoteLoaded ||
    !exposureList?.building.loaded ||
    !exposureList?.employer.loaded ||
    !exposureList?.vehicle.loaded ||
    !fields.policy.loaded ||
    !fields.exposure.loaded ||
    !fields?.peril?.loaded;

  useEffect(() => {
    if (canEdit && isEarthquakeRowVisible) {
      if (formik.values?.limitearthquake === 'Yes') {
        formik.setValues({
          ...formik.values,
          deductibleearthquake: '0',
        });
        setRows(
          rows.map((row) =>
            row.id === 'earthquake'
              ? {
                  ...row,
                  deductible: {
                    ...row.deductible,
                    value: '0',
                    isEditMode: false,
                  },
                }
              : row,
          ),
        );
      } else if (formik.values?.limitearthquake === 'No') {
        const deductibleEarthquakeValue =
          quoteCharacteristics?.pol_eq_deductible !== '0'
            ? quoteCharacteristics?.pol_eq_deductible
            : earthquakeDeductibleFirstChoice;
        formik.setValues({
          ...formik.values,
          deductibleearthquake: deductibleEarthquakeValue,
        });
        setRows(
          rows.map((row) =>
            row.id === 'earthquake'
              ? {
                  ...row,
                  deductible: {
                    ...row.deductible,
                    value: deductibleEarthquakeValue,
                    isEditMode: true,
                  },
                }
              : row,
          ),
        );
      }
    }
  }, [formik.values?.limitearthquake, showLoader, isEarthquakeRowVisible]);

  const replaceBack = () =>
    HISTORY.replace({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.SUMMARY_PRICING.code },
      }),
    });

  useEffect(() => {
    const handleSubmit = async (emitterAction: keyof Events) => {
      await formik.submitForm();
      const errors = await formik.validateForm();
      const isQuoteAction = emitterAction === 'questionEngineInputsValidated';

      // emit event to action bar
      if (isEmpty(errors)) {
        if (isDataChanged) {
          await handleReCalculate({ isRefreshPriceButtonClicked: isQuoteAction });
        }

        emitter.emit(emitterAction, true);
      } else {
        displayToastMessage('ERROR', 'Business Interruption Limit cannot be blank.');
        replaceBack();
      }
    };

    emitter.on('submitCoverageSummary', handleSubmit);
    emitter.on('declineSubmissionEndorsement', handleSubmit);
    emitter.on('sendBackSubmissionEndorsement', handleSubmit);

    return () => {
      emitter.off('submitCoverageSummary', handleSubmit);
      emitter.off('declineSubmissionEndorsement', handleSubmit);
      emitter.off('sendBackSubmissionEndorsement', handleSubmit);
    };
  }, [formik.values, underwritingQuestionsState]);

  useImperativeHandle(ref, () => ({
    savePageInfo: async ({ saveWithoutValidation = false }) => {
      const errors = await formik.validateForm();
      if (!isEmpty(errors) && !saveWithoutValidation) {
        displayToastMessage('ERROR', 'Business Interruption Limit cannot be blank.');
        replaceBack();
        throw new Error('Business Interruption Limit cannot be blank.');
      } else if ((formik.isValid && isDataChanged) || saveWithoutValidation) {
        await handleReCalculate({ saveWithoutValidation });

        const isClonedQuoteLimit =
          !(submissionDetailInfoTabs.SUMMARY_PRICING.code in progressData) &&
          !isDataChanged &&
          formik.values?.limitbusiness_interruption !== '' &&
          !progressLoading;

        if (isProgressTabs && (isDataChanged || isClonedQuoteLimit)) {
          try {
            setLoading(true);
            await updateQuoteProgress({
              locator: id,
              currentTab: submissionDetailInfoTabs.SUMMARY_PRICING.code,
              isPriceCalculated: true,
              isCompleted:
                isDataChanged || submissionDetailInfoTabs.SUMMARY_PRICING.code in progressData
                  ? true
                  : isClonedQuoteLimit,
            });
          } catch (error) {
            displayBackendErrorMessage(error);
          } finally {
            setLoading(false);
          }
        }
      }
    },
    isDirty: () => isDataChanged,
  }));

  const handlePrevious = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.UW_RESULTS.code },
      }),
    });
  };

  useEffect(() => {
    // calculate price only on first visit when formik values set
    if (
      isProgressTabs &&
      !wasPriceCalculated &&
      !formik.submitCount &&
      formik.values.limitbusiness_interruption &&
      formik.values.limitbusiness_interruption_estimated
    ) {
      handleReCalculate({ isRefreshPriceButtonClicked: true });
    }

    if (canEdit) {
      setIsCoveragesSummaryPriceCalculated(false);
    }
  }, [formik.values]);

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <Stack flexDirection="row">
        <Box sx={{ minWidth: 760, mr: 3, mb: 3 }}>
          <Typography sx={[tabTitleStyles]}>
            {quotePolicyEndorsementInfoTitlesDescriptions.SUMMARY_PRICING.title()}
          </Typography>
          <Box
            sx={{
              '& .border-top': {
                borderTop: '1px solid',
                borderColor: (theme) => theme.customColors.gunMetal,
              },
              '& .border-none .MuiDataGrid-cell': {
                border: 'none',
              },
              '& .bg :not(:nth-of-type(2))': {
                backgroundColor: '#f5f5f5',
              },
              '& .bg-additional': {
                backgroundColor: '#f5f5f5 !important',
              },
              '& .bg-error': {
                border: formik.errors.limitbusiness_interruption
                  ? `1px solid ${themeHook.customColors.alert} !important`
                  : `1px solid ${themeHook.customColors.grey850}`,
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap !important',
              },
              '& .disabled': {
                color: `${themeHook.customColors.grey1100} !important`,
              },
            }}
          >
            {canEdit ? (
              <DataTablePro
                loading={showLoader}
                getRowId={(row) => row.id}
                getCellClassName={(params) => {
                  if (params.row.id === 'all_other_property_estimated_replacement_cost') {
                    return 'disabled';
                  }

                  if (
                    params.row.id === 'business_interruption_estimated' &&
                    params.field === 'limit'
                  )
                    if (
                      formik.values.limitbusiness_interruption_estimated !==
                        formik.values.limitbusiness_interruption ||
                      formik.values.new_limitbusiness_interruption ===
                        formik.values.limitbusiness_interruption_estimated
                    )
                      return 'disabled bg-additional';

                  if (params.field === 'additional')
                    return [
                      'business_liability',
                      'auto_liability',
                      'earthquake',
                      'business_interruption',
                      'business_interruption_estimated',
                    ].includes(params.row.id)
                      ? 'bg-additional'
                      : '';

                  if (params.row.id === 'business_interruption' && params.field === 'limit')
                    return ['business_interruption'].includes(params.row.id) ? 'bg-error' : '';

                  if (params.field === 'limit') {
                    return [
                      'all_other_property_estimated_replacement_cost_override',
                      'business_interruption',
                      'business_interruption_estimated',
                    ].includes(params.row.id)
                      ? 'bg-additional'
                      : '';
                  }

                  return '';
                }}
                getRowClassName={(params) =>
                  params.row.id === 'business_liability_extra'
                    ? 'bg border-none'
                    : params.row.noBorder
                    ? 'border-none'
                    : params.row.section && 'border-top'
                }
                columns={columns as GridColDef[]}
                rows={rows}
                pageSize={defaultRowVirtualization}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: (theme) => theme.customColors.gunMetal,
                }}
                isSummaryTable
                cellModesModel={cellModesModel}
                autoRowCellHeight
                hideFooter={rows.length <= defaultRowVirtualization}
                hideFooterPagination={rows.length <= defaultRowVirtualization}
              />
            ) : (
              <DataTable
                loading={showLoader || price.loading}
                getRowId={(row) => row.id}
                autoRowCellHeight
                getRowClassName={(params) => params.row.section && 'border-top'}
                columns={viewModeColumns as GridColDef[]}
                rows={rows}
                pageSize={defaultRowVirtualization}
                hideFooter={rows.length <= defaultRowVirtualization}
                hideFooterPagination={rows.length <= defaultRowVirtualization}
                isCellEditable={() => false}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column', width: 274 }}>
          <Stack
            spacing={1}
            alignItems="center"
            direction="column"
            sx={{
              boxShadow: (theme) => theme.customShadows.shadow15,
              background: (theme) => theme.customColors.grey1150,
              borderRadius: 2,
              py: 2,
              px: 3,
              color: (theme) => theme.customColors.gunMetal,
            }}
          >
            <Box alignSelf="flex-start">
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '18px',
                  fontWeight: 600,
                }}
              >
                {t('Estimated Premium')}
              </Typography>
              <Typography
                sx={{
                  fontSize: '24px',
                  lineHeight: '36px',
                  minWidth: 136,
                  whiteSpace: 'nowrap',
                  ...(((price.loading && canEdit) ||
                    price.loading ||
                    !isCoveragesSummaryPriceCalculated) && {
                    filter: 'blur(4.5px)',
                  }),
                }}
              >
                {price.loading && !isEmpty(price.data) ? (
                  <Skeleton animation="wave" width="100%" height={35} />
                ) : (
                  `${
                    price?.loaded && isCoveragesSummaryPriceCalculated
                      ? currencyFormat(
                          price?.data?.gross_premium_currency || defaultCurrency,
                          price?.data?.gross_premium!,
                        ).merged || '-'
                      : '$10.000,00'
                  }`
                )}
              </Typography>
            </Box>
            {canEdit && (
              <Button
                onClick={() => handleReCalculate({ isRefreshPriceButtonClicked: true })}
                data-test="recalculate-estimated-premium"
                sx={{
                  ...primaryButtonStyle,
                  fontWeight: (theme) => theme.typography.body2.fontWeight,
                  lineHeight: (theme) => theme.typography.body2.lineHeight,
                  backgroundColor: (theme) => theme.customColors.copper,
                }}
                fullWidth
              >
                {t('Get Price Indication')}
              </Button>
            )}
            {isTaxIDInvalid && (
              <Typography>
                {t(
                  'The price indication is provisional. To quote/refer your policy please enter a valid Tax ID.',
                )}
                <br /> <br />
                {t('Note: Price indication may be subject to change')}
              </Typography>
            )}
          </Stack>

          <Box
            sx={{
              boxShadow: (theme) => theme.customShadows.shadow15,
              background: (theme) => theme.customColors.grey1150,
              borderRadius: 2,
              py: 2,
              px: 3,
              color: (theme) => theme.customColors.gunMetal,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {!showLoader && formik.values.pay_plan ? (
              <>
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: 600,
                  }}
                >
                  {t('Pay Plan')}
                </Typography>
                {canEdit ? (
                  <FormControl
                    sx={{
                      width: 92,
                      height: 20,
                      '& .MuiSelect-select': { padding: '0 !important', pl: '6px !important' },
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                      '& .MuiSelect-icon': {
                        right: 0,
                        color: (theme) => theme.customColors.white50,
                      },
                    }}
                    size="small"
                  >
                    <Select
                      name="pay_plan"
                      sx={{
                        backgroundColor: (theme) => theme.customColors.copper,
                        color: (theme) => theme.customColors.white50,
                        borderRadius: 1,
                      }}
                      value={formik.values.pay_plan}
                      onChange={(e) => {
                        setIsDataChanged(true);
                        formik.handleChange(e);
                      }}
                    >
                      {[
                        { code: 'Annual', name: 'Annual' },
                        { code: 'Monthly', name: 'Monthly' },
                      ].map((type) => (
                        <MenuItem key={type.code} value={type.code}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '18px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {quoteDetail?.payment_schedule}
                  </Typography>
                )}
              </>
            ) : (
              <Skeleton animation="wave" width="100%" height={35} />
            )}
          </Box>
          <Box
            sx={{
              boxShadow: (theme) => theme.customShadows.shadow15,
              background: (theme) => theme.customColors.grey1150,
              borderRadius: 2,
              py: 2,
              px: 3,
              color: (theme) => theme.customColors.gunMetal,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '18px',
              }}
            >
              {t('Policy Issued by')}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 600,
              }}
            >
              {t('Berkshire Hathaway Direct')}
              <br />
              {t('Insurance Company')}
            </Typography>
            <Box sx={{ display: 'flex', pt: 1.25, alignItems: 'center' }}>
              <ReactSVG src={RatingIcon} />
              <Box sx={{ pl: 2 }}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: (theme) => theme.customColors.orange150,
                  }}
                  component="span"
                >
                  {`${t('A++')} `}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {t('AM Best Rating')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>

      {isProgressTabs && (
        <NavigationButtons isNextButtonVisible={false} handlePrevious={handlePrevious} />
      )}
    </Stack>
  );
});

export default SummaryPricing;
