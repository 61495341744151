import { Box, Button, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { stateList, threeExposureNames, threePolicyGroupNames, userRoles } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { OwnersMembersDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/OwnersMembersDrawerFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { changeFieldsHiddenStatus, deleteFromQueryStrings } from 'helpers/Utils';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import QuoteLocationSelect from '../../../Inputs/LocationSelectInput';

export interface OwnersMembersDetailProps {
  isDrawerOpen: boolean;
  position: string;
}

const OwnersMembersDetailDrawer: FC<OwnersMembersDetailProps> = ({ isDrawerOpen, position }) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const OWNER_MEMBER = url.owner_member;
  const {
    loading: quoteLoading,
    loaded: quoteLoaded,
    exposureList,
    groups,
    fields: fieldConfig,
  } = useQuoteDetail();
  const [fields, setFields] = useState<any[]>([]);

  const owners: any[] = groups?.pol_oo?.data ?? [];

  const owner = useMemo(
    () => owners.find((ow) => ow.locator === OWNER_MEMBER) ?? {},
    [owners, OWNER_MEMBER],
  );

  const [state, setState] = useState<any>({});

  const { data: user } = useUser();

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  useEffect(() => {
    const tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])?.[0]?.fields?.find(
        (f) => f.code === threePolicyGroupNames.OWNER,
      )?.nested_fields ?? [];

    const addressStateIndex = tmpFields.findIndex((i) => i.code === 'pol_oo_address_state');
    const addressStateField = tmpFields[addressStateIndex];

    if (addressStateField?.additional_data?.setDefaultStateListAsChoices) {
      addressStateField.choices = stateList.map(({ code, name }) => ({ code, name })) ?? [];
    }

    setFields(tmpFields);
  }, [fieldConfig]);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['owner_member'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (quoteLoaded && isEmpty(owner)) {
      displayToastMessage('ERROR', t('Owner not found.'));
      handleQuery();
    }
  }, [owner, quoteLoaded]);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: async () => {},
  });

  useEffect(() => {
    if (isEmpty(state)) {
      setState((prevState) => ({ ...prevState, ...owner }));
      formik.setValues(owner);

      if (isUnderwriter) {
        const integrationFields: string[] = [];

        if (owner.pol_oo_isprimary === 'Yes') {
          integrationFields.push('pol_oo_b505');
          integrationFields.push('pol_oo_ncf_score');
          integrationFields.push('pol_oo_c212_score');

          setFields((prevFields) => changeFieldsHiddenStatus(prevFields, integrationFields, false));
        }
      }
    }
  }, [owner, isUnderwriter]);

  const showLoader = useMemo(() => {
    const defaultLoading =
      quoteLoading ||
      fieldConfig?.policy?.loading ||
      exposureList?.[`${threeExposureNames.LOCATION}`].loading;

    return defaultLoading || isEmpty(state);
  }, [state, quoteLoading, exposureList, fieldConfig]);

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      width="476px"
      onClose={handleQuery}
      headerSx={{
        mb: '14px',
        mt: 5,
      }}
      isContentScrollable
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('{{entityPosition}} Details', {
            entityPosition: position,
          })}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <OwnersMembersDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            setState={setState}
            showLoader={showLoader}
            isEdit
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
            isReadOnly
            LocationSelect={QuoteLocationSelect}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default OwnersMembersDetailDrawer;
