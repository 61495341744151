/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { getKeyValuesService } from 'api/services/KeyValues';
import { addPeril, deletePeril, updatePerilSocotra } from 'api/services/NewQuote';
import {
  dividerRateFieldsHiddenPerilCodes,
  hazardRelatedFieldCodes,
  liabilityExposurePerils,
  liabilityExposureRelatedKeyValues,
  liabilityExposureRelatedKeyValuesMap,
  liabilityExposuresFieldCodes,
  LIABILITY_PERILS as liabilityPerils,
  lookupTableTypes,
  threeExposureNames,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { LiabilityExposurePerilDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/LiabilityExposurePerilDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
  renderAddress,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useKeyValues from 'hooks/useKeyValues';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty, isEqual, omit } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import * as yup from 'yup';
import QuoteLocationSelect from '../../../Inputs/LocationSelectInput';

const {
  perilType,
  location,
  classCode,
  classCodeDescription,
  hazardGroup,
  exposureAmount,
  basis,
  divider,
  rate,
} = liabilityExposuresFieldCodes;

export interface LiabilityExposurePerilEditProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
  isAdd?: boolean;
}

const LiabilityExposurePerilEditDrawer: FC<LiabilityExposurePerilEditProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  isAdd = false,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const { exposureId, perilId } = url;
  const { exposureList, fields: fieldConfig, data: quoteDetail, getExposures } = useQuoteDetail();
  const [fields, setFields] = useState<any[]>([]);
  const [lookupFetchCache, setLookupFetchCache] = useState<any>();
  const [territoryCache, setTerritoryCache] = useState<any>();
  const [integrationLoading, setIntegrationLoading] = useState<boolean>(false);
  const [tableQuery, setTableQuery] = useState<any>({
    lookupQuery: undefined,
    territoryQuery: undefined,
  });
  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();
  const [toastId, setToastId] = useState<Id>();

  const resetSpecificClassCode = (inputValue: string): any => {
    switch (inputValue) {
      case 'GLPO':
        return { glpo_class_code_user: '' };
      case 'LIQ':
        return { liq_class_code_user: '' };
      case 'GLPC':
        return { glpc_class_code_user: '' };
      default:
        return { class_code_override: '' };
    }
  };

  const { data: keyValueStore } = useKeyValues();

  const quoteDetailData = quoteDetail?.characteristics?.[0]?.data ?? {};

  const LIABILITY_PERILS =
    keyValueStore?.[`${liabilityExposureRelatedKeyValues.LIABILITY_PERILS}`]?.data?.value ?? [];

  const LIABILITY_PERILS_CLASS_CODES_AND_DESCRIPTION =
    keyValueStore?.[
      `${liabilityExposureRelatedKeyValues.LIABILITY_PERILS_CLASS_CODES_AND_DESCRIPTION}`
    ]?.data?.value ?? [];

  const glpoAndGlpcLiabilityPerilsClassCodeAndDescriptionChoices = useMemo(
    () =>
      Object.keys(LIABILITY_PERILS_CLASS_CODES_AND_DESCRIPTION ?? {})
        .sort()
        .map((code) => ({ code, name: LIABILITY_PERILS_CLASS_CODES_AND_DESCRIPTION[code] })),
    [LIABILITY_PERILS_CLASS_CODES_AND_DESCRIPTION],
  );

  // Map perils data with generic fields (exposure_amount, class_code)
  function extractData(params) {
    const matchedData = LIABILITY_PERILS.find((d) => d.name === params?.name);

    const extractedData = {};
    if (!params?.data && !matchedData) return {};
    for (const [key, value] of Object.entries(params?.data)) {
      const fieldKey = matchedData
        ? Object.entries(matchedData?.fieldKeys!).find(([_, v]) => v === key)?.[0]
        : null;
      if (fieldKey) {
        extractedData[fieldKey] = value;
      }
    }

    return extractedData;
  }

  function getPerilValue(perilName, key) {
    const peril = LIABILITY_PERILS.find((p) => p.name === perilName);
    return peril ? peril[key] : null;
  }

  function getPerilFieldKey(perilName, key) {
    const peril = LIABILITY_PERILS.find((p) => p.name === perilName);
    return peril && peril.fieldKeys ? peril?.fieldKeys[key] : null;
  }

  // get location exposures from provider
  const locationExposures = exposureList?.[`${threeExposureNames.LOCATION}`]?.data ?? [];

  // try to find selected exposure in the exposures list
  const activeExposure = useMemo(
    () => locationExposures?.find((e) => e.locator === exposureId) ?? {},
    [locationExposures, exposureId],
  );

  const activePeril = useMemo(
    () => activeExposure.perils?.find((e) => e.locator === perilId) ?? {},
    [locationExposures, perilId],
  );

  const [state, setState] = useState<any>({});

  const getPerilSpecificFields = (exactPeril, perilData) => {
    const {
      pol_tax_gl_default,
      pol_tax_gl_rule,
      pol_gl_exposure_amount_default,
      pol_gl_exposure_amount_rule,
      pol_gl_oo_count_rule,
      pol_tax_gl_91583_default,
      pol_gl_91583_exposure_amount_default,
      pol_gl_91583_exposure_amount_rule,
      pol_gl_91583_oo_count_default,
      pol_tax_gl_46622_default,
      pol_gl_46622_exposure_amount_rule,
      pol_gl_46622_exposure_amount_default,
      pol_gl_46622_oo_count_default,
      pol_gltx_revenue_default,
      pol_gltx_revenue_rule,
      pol_tax_cy_hazardgroup,
      pol_cy_hazard_rule,
      pol_cy_revenue_default,
      pol_cy_revenue_rule,
      pol_fidu_ee_count_default,
      pol_fidu_ee_count_rule,
      pol_fidu_indicator_default,
      pol_fidu_indicator_rule,
      pol_tax_do_hazardgroup,
      pol_do_hazard_rule,
      pol_do_revenue_default,
      pol_do_revenue_rule,
      pol_do_indicator_default,
      pol_do_indicator_rule,
      pol_tax_ep_hazardgroup,
      pol_epli_hazard_rule,
      pol_epli_ee_count_default,
      pol_epli_ee_count_rule,
      pol_epli_indicator_default,
      pol_epli_indicator_rule,
      pol_gk_revenue_default,
      pol_gk_revenue_rule,
      pol_tax_liq_default,
      pol_liq_class_code_rule,
      pol_liq_alcohol_sales_default,
      pol_liq_alcohol_sales_rule,
      pol_tax_liq_indicator,
      pol_liq_indicator_rule,
      pol_tax_pl_hazardgroup,
      pol_pl_hazardgroup_rule,
      pol_pl_revenue_default,
      pol_pl_revenue_rule,
      pol_pl_contract_quality_default,
      pol_pl_contract_quality_rule,
      pol_tax_pl_indicator,
      pol_pl_indicator_rule,
      pol_pl_lawyer_fte_default,
      pol_pl_lawyer_fte_rule,
      pol_pl_lawyer_pte_default,
      pol_pl_lawyer_pte_rule,
      pol_catx_revenue_default,
      pol_catx_revenue_rule,
      pol_trix_revenue_default,
      pol_trix_revenue_rule,
    } = quoteDetailData;

    return {
      // glpo peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.glpo.code && {
        class_code: (() => {
          switch (perilData?.glpo_fixed_classcode) {
            case '91583':
              return pol_tax_gl_91583_default;
            case '46622':
              return pol_tax_gl_46622_default;
            default:
              return pol_tax_gl_rule ?? pol_tax_gl_default;
          }
        })(),
        exposure_amount: (() => {
          switch (perilData?.glpo_fixed_classcode) {
            case '91583':
              return pol_gl_91583_exposure_amount_rule ?? pol_gl_91583_exposure_amount_default;
            case '46622':
              return pol_gl_46622_exposure_amount_rule ?? pol_gl_46622_exposure_amount_default;
            default:
              return pol_gl_exposure_amount_rule ?? pol_gl_exposure_amount_default;
          }
        })(),
        glpo_oo_count: (() => {
          switch (perilData?.glpo_fixed_classcode) {
            case '91583':
              return pol_gl_91583_oo_count_default;
            case '46622':
              return pol_gl_46622_oo_count_default;
            default:
              return pol_gl_oo_count_rule;
          }
        })(),
      }),
      // glpcOperations peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.glpcOperations.code && {
        class_code: (() => {
          switch (perilData?.glpc_fixed_classcode) {
            case '91583':
              return pol_tax_gl_91583_default;
            default:
              return pol_tax_gl_rule ?? pol_tax_gl_default;
          }
        })(),
        exposure_amount: (() => {
          switch (perilData?.glpc_fixed_classcode) {
            case '91583':
              return pol_gl_91583_exposure_amount_rule ?? pol_gl_91583_exposure_amount_default;
            default:
              return pol_gl_exposure_amount_rule ?? pol_gl_exposure_amount_default;
          }
        })(),
        glpc_oo_count: (() => {
          switch (perilData?.glpc_fixed_classcode) {
            case '91583':
              return pol_gl_91583_oo_count_default;
            default:
              return pol_gl_oo_count_rule;
          }
        })(),
      }),
      // generalLiabilityTerrorism peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.generalLiabilityTerrorism.code && {
        exposure_amount: pol_gltx_revenue_rule ?? pol_gltx_revenue_default,
      }),
      // cyberLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.cyberLiability.code && {
        hazard_group: pol_cy_hazard_rule ?? pol_tax_cy_hazardgroup,
        exposure_amount: pol_cy_revenue_rule ?? pol_cy_revenue_default,
      }),
      // fiduciary peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.fiduciaryLiability.code && {
        fidu_indicator: pol_fidu_indicator_rule ?? pol_fidu_indicator_default,
        exposure_amount: pol_fidu_ee_count_rule ?? pol_fidu_ee_count_default,
      }),
      // directorsOfficersLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.directorsOfficersLiability.code && {
        hazard_group: pol_do_hazard_rule ?? pol_tax_do_hazardgroup,
        exposure_amount: pol_do_revenue_rule ?? pol_do_revenue_default,
        do_indicator: pol_do_indicator_rule ?? pol_do_indicator_default,
      }),
      // employmentPracticesLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.employmentPracticesLiability.code && {
        hazard_group: pol_epli_hazard_rule ?? pol_tax_ep_hazardgroup,
        exposure_amount: pol_epli_ee_count_rule ?? pol_epli_ee_count_default,
        epli_indicator: pol_epli_indicator_rule ?? pol_epli_indicator_default,
      }),
      // garagekeepersLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.garagekeepersLiability.code && {
        exposure_amount: pol_gk_revenue_rule ?? pol_gk_revenue_default,
      }),
      // generalLiabilityLiquorLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.generalLiabilityLiquorLiability.code && {
        class_code: pol_liq_class_code_rule ?? pol_tax_liq_default,
        exposure_amount: pol_liq_alcohol_sales_rule ?? pol_liq_alcohol_sales_default,
        liq_indicator: pol_liq_indicator_rule ?? pol_tax_liq_indicator,
      }),
      // professionalLiability peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.professionalLiability.code && {
        hazard_group: pol_pl_hazardgroup_rule ?? pol_tax_pl_hazardgroup,
        exposure_amount: pol_pl_revenue_rule ?? pol_pl_revenue_default,
        pl_contract_quality: pol_pl_contract_quality_rule ?? pol_pl_contract_quality_default,
        pl_indicator: pol_pl_indicator_rule ?? pol_tax_pl_indicator,
        pl_lawyer_fte: pol_pl_lawyer_fte_rule ?? pol_pl_lawyer_fte_default,
        pl_lawyer_pte: pol_pl_lawyer_pte_rule ?? pol_pl_lawyer_pte_default,
      }),
      // commercialAutoTerrorism peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.commercialAutoTerrorism.code && {
        exposure_amount: pol_catx_revenue_rule ?? pol_catx_revenue_default,
      }),
      // trailerInterchange peril related field mappings
      ...(exactPeril?.name === liabilityExposurePerils.trailerInterchange.code && {
        exposure_amount: pol_trix_revenue_rule ?? pol_trix_revenue_default,
      }),
    };
  };

  const getPerilData = async (isActivePeril) => {
    const perilData = isActivePeril
      ? { ...(extractData(activePeril) as any), ...activePeril.data }
      : state;

    const exactPeril = LIABILITY_PERILS.find(
      (peril) => peril.name === (isActivePeril ? activePeril.name : state?.peril_type),
    );

    const exposure = isActivePeril
      ? activeExposure
      : locationExposures.find((ex) => ex.locator === state.location);

    return { perilData, exactPeril, exposure };
  };

  const getTerritory = async (exposure) => {
    const territoryQuery = {
      search__FilingSetID: exposure!.data?.loc_filing_set_id,
      search__ZipCode: exposure!.data?.loc_address_zip,
      policy_locator: quoteDetail?.policy_locator,
    };

    if (isEqual(territoryQuery, tableQuery.territoryQuery)) return territoryCache;

    setIntegrationLoading(true);
    setTableQuery((prevState) => ({
      ...prevState,
      territoryQuery,
    }));
    const lookupTableResponse = await getKeyValuesService(lookupTableTypes.TERRITORY_LOOKUP, {
      query: territoryQuery,
    });
    const data = lookupTableResponse?.value?.[0] ?? {};

    setTerritoryCache(data?.Territory);
    setIntegrationLoading(false);

    if (isEmpty(data)) throw new Error();

    return data?.Territory;
  };

  const getBasisDividerRate = async (exactPeril, exposure, territory, perilData) => {
    const query = {
      search__Peril: exactPeril?.shortName,
      search__FilingSetID: exposure!.data?.loc_filing_set_id,
      ...(territory && { search__Territory: territory }),
      ...(perilData?.class_code_override
        ? { search__ClassCode: perilData.class_code_override }
        : perilData?.class_code && { search__ClassCode: perilData.class_code }),
      // TODO: refactor
      // map class_code_override to glpo_class_code_user for glpo
      ...(exactPeril?.shortName === 'GLPO'
        ? perilData?.glpo_class_code_user
          ? { search__ClassCode: perilData.glpo_class_code_user }
          : perilData?.class_code && { search__ClassCode: perilData.class_code }
        : {}),

      // map class_code_override to liq_class_code_user for liq
      ...(exactPeril?.shortName === 'LIQ'
        ? perilData?.liq_class_code_user
          ? { search__ClassCode: perilData.liq_class_code_user }
          : perilData?.class_code && { search__ClassCode: perilData.class_code }
        : {}),

      // map class_code_override to glpc_class_code_user for glpc
      ...(exactPeril?.shortName === 'GLPC'
        ? perilData?.glpc_class_code_user
          ? { search__ClassCode: perilData.glpc_class_code_user }
          : perilData?.class_code && { search__ClassCode: perilData.class_code }
        : {}),

      ...(perilData?.hazard_group && { search__HazardGroup: perilData.hazard_group }),
      policy_locator: quoteDetail?.policy_locator,
    };

    if (isEqual(query, tableQuery.lookupQuery)) return lookupFetchCache;

    setIntegrationLoading(true);

    setTableQuery((prevState) => ({
      ...prevState,
      lookupQuery: query,
    }));

    const lookupTableResponse = await getKeyValuesService(lookupTableTypes.LIABILITY_PERILS, {
      query,
    });
    const data = lookupTableResponse?.value?.[0] ?? {};

    setLookupFetchCache(data);
    setIntegrationLoading(false);

    return data;
  };

  const handleQuery = () => {
    setIsDrawerOpen(false);
    if (toastId) toast.dismiss(toastId);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['perilId', 'exposureId', 'addPeril'],
      }),
    });
  };

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...Object.keys(commonValidations).reduce((a, b) => ({ ...a, [`${b}`]: '' }), {}),
      ...state,
    },
    validationSchema,
    onSubmit: async (_values) => {},
  });

  const handlePerilInfo = async ({ isActivePeril = true }) => {
    const response = await getPerilData(isActivePeril);
    const { exactPeril, exposure } = response;
    let { perilData } = response;

    try {
      perilData = {
        ...perilData,
        ...getPerilSpecificFields(exactPeril, perilData),
      };

      if (exactPeril?.hasClassCode || exactPeril?.hasHazard) {
        let territory;

        if (['GLPO', 'GLPC'].includes(exactPeril?.shortName)) {
          territory = await getTerritory(exposure);
        }

        const data = await getBasisDividerRate(exactPeril, exposure, territory, perilData);

        const { ClassDescription, ExposureBasis, ExposureDivisor, Rate } = data ?? {};
        setState((prevState) => ({
          ...prevState,
          peril_type: activePeril.name,
          location: exposure?.locator,
          ...perilData,
          ...((perilData.class_code ||
            perilData.class_code_override ||
            perilData.glpo_class_code_user ||
            perilData.liq_class_code_user ||
            perilData.glpc_class_code_user) &&
            !isEmpty(data) && { class_code_description: ClassDescription }),
          ...(perilData.class_code && isEmpty(data) && { class_code_description: '' }),
          basis: ExposureBasis ?? '',
          divider: ExposureDivisor ?? 'NULL',
          rate: Rate ?? 'NULL',
        }));
        if (isEmpty(data)) throw new Error();
      } else {
        setState((prevState) => ({
          ...prevState,
          peril_type: activePeril.name,
          location: exposure?.locator,
          ...perilData,
          basis: exactPeril?.basis ?? '',
          divider: 'NULL',
          rate: 'NULL',
        }));
      }
      toast.dismiss(toastId);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        // Reset class code value if incorrect
        ...resetSpecificClassCode(exactPeril?.shortName),
      }));
      if (!formik.values.peril_type) {
        handleQuery();
        const errorMessageToast = displayBackendErrorMessage(
          error,
          t('The {{variable}} is not correct.', {
            variable: state.class_code ? 'class code' : 'hazard group',
          }),
        );
        setToastId(errorMessageToast);
      } else {
        const errorMessageToast = displayBackendErrorMessage(
          error,
          t('The {{variable}} is not correct.', {
            variable: state.class_code ? 'class code' : 'hazard group',
          }),
          false,
        );
        setToastId(errorMessageToast);
      }
    } finally {
      setIntegrationLoading(false);
    }
  };

  // Remove prevState when user change peril type
  useEffect(() => {
    const shouldResetState = state.peril_type !== activePeril.name;

    if (shouldResetState) {
      const exactPeril = LIABILITY_PERILS.find((peril) => peril.name === state?.peril_type);

      const newState: any = {
        peril_type: state.peril_type,
        location: activeExposure.locator ?? state.location ?? '',
        ...(exactPeril ? getPerilSpecificFields(exactPeril, {}) : {}),
      };

      if (!exactPeril?.hasClassCode && !exactPeril?.hasHazard) {
        newState.basis = exactPeril?.basis ?? '';
        newState.divider = 'NULL';
        newState.rate = 'NULL';
      }

      setState(newState);
    }

    if (dividerRateFieldsHiddenPerilCodes.includes(state.peril_type)) {
      setState((prevState) => ({
        ...prevState,
        divider: 'NULL',
        rate: 'NULL',
      }));
    }
  }, [state.peril_type]);

  // Add integration loadings to fields
  useEffect(() => {
    if (fields.length > 0) {
      setFields((prevState) =>
        prevState.map((f) => {
          const field = f;

          if (f.additional_data?.integration) {
            field.additional_data!.loading = integrationLoading;
          }

          return field;
        }),
      );
    }
  }, [integrationLoading]);

  // Iterate fields and operate some filters, functionalities
  useEffect(() => {
    let tmpFields =
      (fieldConfig?.peril?.data?.[`${threeExposureNames.LOCATION}`] as ProductWorkFlow[])?.find(
        (config) => config.code === liabilityPerils,
      )?.fields ?? [];

    tmpFields = tmpFields.map((f) => {
      const tmpField = { ...f };

      if (f.code === perilType) {
        const allPerils =
          LIABILITY_PERILS?.map(({ name, title }) => ({
            code: name,
            name: title,
          })) ?? [];

        tmpField.choices = allPerils;
      }

      if (f.code === location) {
        const allLocations = locationExposures.map((item) => ({
          code: item.locator as string,
          name: renderAddress(item.data),
        }));
        tmpField.choices = allLocations;

        if (!isAdd) {
          tmpField.is_readonly = true;
        }
      }

      if (state.peril_type) {
        if (f.code === classCode || f.code === classCodeDescription) {
          tmpField.is_hidden = !getPerilValue(state.peril_type, 'hasClassCode');
        }

        if (hazardRelatedFieldCodes.includes(f.code!)) {
          tmpField.is_hidden = !getPerilValue(state.peril_type, 'hasHazard');
          if (!tmpField.is_hidden) {
            const hazardGroupDropdownValues = getPerilValue(
              state.peril_type,
              'hazardGroupDropdown',
            ).map((item) => ({
              code: item,
              name: item,
            }));
            tmpField.choices = hazardGroupDropdownValues;
          }
        }
      }

      if (f.code === classCode) {
        tmpField.additional_data!.hasBlurinInput = isEmpty(state.class_code_override);
      }

      if ([basis, divider, rate].includes(f.code!)) {
        tmpField.is_hidden = state[f.code!] === 'NULL';
      }

      if (
        [
          'glpo_class_code_user',
          'glpc_class_code_user',
          'glpo_oo_count_user',
          'glpc_oo_count_user',
        ].includes(f.code!) &&
        (state?.glpo_fixed_classcode || state?.glpc_fixed_classcode)
      ) {
        tmpField.is_hidden = true;
      } else if (
        tmpField.code === 'glpo_class_code_user' ||
        tmpField.code === 'glpc_class_code_user'
      ) {
        tmpField.type = 'autocomplete';
        tmpField.choices = glpoAndGlpcLiabilityPerilsClassCodeAndDescriptionChoices;
      }

      return tmpField;
    });
    setFields(tmpFields);
  }, [fieldConfig, activeExposure, JSON.stringify(state)]);
  useEffect(() => {
    formik.setValues(state ?? {});
  }, [state]);

  const isSaveDisabled = formik.values.class_code_description === '';

  const handleAdd = async () => {
    try {
      setLoading(true);

      let perilData = omit(state, [perilType, location, basis, divider, rate]);

      let payloadOfPeril = { name: state.peril_type, data: perilData };

      Object.keys(state).forEach((item) => {
        const fieldKey = getPerilFieldKey(state.peril_type, item);
        if (fieldKey) {
          perilData = { ...perilData, [`${fieldKey}`]: state[`${item}`] };
        }
      });

      payloadOfPeril = {
        ...payloadOfPeril,
        data: omit(perilData, [
          classCode,
          hazardGroup,
          exposureAmount,
          classCodeDescription,
          // remove glpo peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.glpo.code
            ? [liabilityExposuresFieldCodes.glpoOoCount]
            : []),
          // remove glpcOperations peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.glpcOperations.code
            ? [liabilityExposuresFieldCodes.glpcOoCount]
            : []),
          // remove fiduciary peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.fiduciaryLiability.code
            ? [liabilityExposuresFieldCodes.fiduIndicator, liabilityExposuresFieldCodes.fiduEeCount]
            : []),
          // remove directorsOfficersLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.directorsOfficersLiability.code
            ? [liabilityExposuresFieldCodes.doIndicator]
            : []),
          // remove employmentPracticesLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.employmentPracticesLiability.code
            ? [liabilityExposuresFieldCodes.epliIndicator]
            : []),
          // remove generalLiabilityLiquorLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.generalLiabilityLiquorLiability.code
            ? [liabilityExposuresFieldCodes.liqIndicator]
            : []),
          // remove professionalLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.professionalLiability.code
            ? [
                liabilityExposuresFieldCodes.plIndicator,
                liabilityExposuresFieldCodes.plContractQuality,
                liabilityExposuresFieldCodes.plLawyerFte,
                liabilityExposuresFieldCodes.plLawyerPte,
              ]
            : []),
        ]),
      };

      await addPeril(quoteDetail?.policy_locator!, state.location, {
        ...payloadOfPeril,
        quote_locator: quoteDetail?.locator!,
        exposure_name: threeExposureNames.LOCATION,
        product_code: quoteDetail?.product?.code,
        product_version: quoteDetail?.product?.version,
      });

      displayToastMessage('SUCCESS', t('The peril has been added.'));
      handleQuery();
      getExposures(quoteDetail?.locator!, { page_size: 10000 }, threeExposureNames.LOCATION);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while adding the peril.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      let perilData = omit(state, [perilType, location, basis, divider, rate]);

      let payloadOfPeril = { name: state.peril_type, data: perilData };

      Object.keys(state).forEach((item) => {
        const fieldKey = getPerilFieldKey(state.peril_type, item);
        if (fieldKey) {
          perilData = { ...perilData, [`${fieldKey}`]: state[`${item}`] };
        }
      });

      payloadOfPeril = {
        ...payloadOfPeril,
        data: omit(perilData, [
          classCode,
          hazardGroup,
          exposureAmount,
          classCodeDescription,
          // remove glpo peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.glpo.code
            ? [liabilityExposuresFieldCodes.glpoOoCount]
            : []),
          // remove glpcOperations peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.glpcOperations.code
            ? [liabilityExposuresFieldCodes.glpcOoCount]
            : []),
          // remove fiduciary peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.fiduciaryLiability.code
            ? [liabilityExposuresFieldCodes.fiduIndicator]
            : []),
          // remove directorsOfficersLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.directorsOfficersLiability.code
            ? [liabilityExposuresFieldCodes.doIndicator]
            : []),
          // remove employmentPracticesLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.employmentPracticesLiability.code
            ? [liabilityExposuresFieldCodes.epliIndicator]
            : []),
          // remove generalLiabilityLiquorLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.generalLiabilityLiquorLiability.code
            ? [liabilityExposuresFieldCodes.liqIndicator]
            : []),
          // remove professionalLiability peril related mappings (readonly fields)
          ...(payloadOfPeril.name === liabilityExposurePerils.professionalLiability.code
            ? [
                liabilityExposuresFieldCodes.plIndicator,
                liabilityExposuresFieldCodes.plContractQuality,
                liabilityExposuresFieldCodes.plLawyerFte,
                liabilityExposuresFieldCodes.plLawyerPte,
              ]
            : []),
        ]),
      };

      if (isEqual(activePeril.name, state.peril_type)) {
        await updatePerilSocotra(
          quoteDetail?.policy_locator!,
          activeExposure.locator!,
          activePeril.locator!,
          {
            quote_locator: quoteDetail?.locator,
            ...payloadOfPeril,
          },
        );
      } else {
        await deletePeril(
          quoteDetail?.policy_locator!,
          activeExposure.locator!,
          activePeril.locator!,
          {
            quote_locator: quoteDetail?.locator,
          },
        );
        await addPeril(quoteDetail?.policy_locator!, activeExposure.locator!, {
          ...payloadOfPeril,
          quote_locator: quoteDetail?.locator!,
          exposure_name: threeExposureNames.LOCATION,
          product_code: quoteDetail?.product?.code,
          product_version: quoteDetail?.product?.version,
        });
      }

      displayToastMessage('SUCCESS', t('The peril has been updated.'));
      handleQuery();
      getExposures(quoteDetail?.locator!, { page_size: 10000 }, threeExposureNames.LOCATION);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while updating the peril.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    const errors = await formik.validateForm();
    if (isSaveDisabled) {
      const errorMessageToast = displayToastMessage(
        'ERROR',
        t('The {{variable}} is not correct.', {
          variable: state.class_code ? 'class code' : 'hazard group',
        }),
        false,
      );
      return setToastId(errorMessageToast);
    }

    if (isEmpty(errors)) {
      if (integrationLoading) {
        setDialogOpen({
          dialog: 'INTEGRATION_RUNNING',
          isOpen: true,
        });
      } else if (isAdd) {
        handleAdd();
      } else {
        handleUpdate();
      }
    } else {
      await formik.setTouched(
        {
          ...formik.touched,
          ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
        },
        false,
      );
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      await deletePeril(
        quoteDetail?.policy_locator!,
        activeExposure.locator!,
        activePeril.locator!,
        {
          quote_locator: quoteDetail?.locator,
        },
      );

      displayToastMessage('SUCCESS', t('The peril has been deleted.'));
      handleQuery();
      getExposures(quoteDetail?.locator!, { page_size: 10000 }, threeExposureNames.LOCATION);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while deleting the peril.'));
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const showLoader = useMemo(() => {
    const defaultLoading =
      !fieldConfig?.peril?.loaded ||
      !exposureList?.[`${threeExposureNames.LOCATION}`].loaded ||
      !liabilityExposureRelatedKeyValuesMap.some(
        (map) => keyValueStore?.[`${map}`]?.loaded ?? true,
      );

    return isAdd
      ? defaultLoading
      : defaultLoading ||
          isEmpty(activeExposure) ||
          isEmpty(activePeril) ||
          isEmpty(state.peril_type);
  }, [
    isAdd,
    activeExposure,
    fieldConfig,
    exposureList,
    keyValueStore,
    activePeril,
    state.peril_type,
  ]);

  // Get peril information when edit drawers is opened
  useEffect(() => {
    if (!isAdd && exposureList?.[`${threeExposureNames.LOCATION}`].loaded) {
      if (!isEmpty(activePeril)) {
        handlePerilInfo({ isActivePeril: true });
      } else {
        displayToastMessage('ERROR', t('An error occurred while fetching the peril information.'));
        handleQuery();
      }
    }
  }, [JSON.stringify(activePeril), exposureList]);

  const openDeleteDialog = () => {
    setDialogOpen({
      dialog: 'DELETE_LIABILITY_PERIL',
      isOpen: true,
      onAccept: () => handleDelete(),
    });
  };

  // To get the lookup values immediately, add onBlur function
  const handleOnBlurInput = async () => {
    if (
      !isEmpty(state.peril_type) &&
      !isEmpty(state.location) &&
      !isEmpty(
        state.class_code ||
          state.hazard_group ||
          state.glpo_class_code_user ||
          state.glpc_class_code_user ||
          state.class_code_override ||
          state.liq_class_code_user,
      )
    )
      handlePerilInfo({ isActivePeril: false });
  };

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        width="476px"
        onClose={handleQuery}
        isContentScrollable
        headerSx={{ mb: 2 }}
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Peril Details')}
          </Typography>
        }
        content={
          <Stack gap={2} sx={{ mb: 3, pt: 1 }}>
            <LiabilityExposurePerilDrawerFieldParser
              formik={formik}
              state={state}
              fields={fields}
              setState={setState}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
              showLoader={showLoader}
              onBlur={handleOnBlurInput}
              LocationSelect={QuoteLocationSelect}
            />
          </Stack>
        }
        footer={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {!isAdd ? (
              showLoader ? (
                <Skeleton animation="wave" width="20%" height={32} />
              ) : (
                <Button onClick={openDeleteDialog} sx={[drawerFooterSecondaryButtonStyle]}>
                  {t('Delete')}
                </Button>
              )
            ) : (
              <Box />
            )}

            <Stack direction="row">
              {showLoader ? (
                <Stack direction="row" gap={1.5}>
                  <Skeleton animation="wave" width="60px" height={32} />
                  <Skeleton animation="wave" width="60px" height={32} />
                </Stack>
              ) : (
                <>
                  <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                    {t('Cancel')}
                  </Button>

                  <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                    {t('Save')}
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        }
      />
    </>
  );
};

export default LiabilityExposurePerilEditDrawer;
