import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DrivingIncidentsDetailDrawer from './Detail';

const DrivingIncidentsDrawer: FC = () => {
  const LOCATION = useLocation();
  const [isDrivingIncidentsDrawerOpen, setIsDrivingIncidentsDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);

  useEffect(() => {
    if (url?.tab === 'drivers' && url?.drivingIncident) {
      setIsDrivingIncidentsDrawerOpen(true);
    } else if (url?.tab === 'drivers') {
      setIsDrivingIncidentsDrawerOpen(false);
    }
  }, [LOCATION]);

  return isDrivingIncidentsDrawerOpen ? (
    <DrivingIncidentsDetailDrawer
      isDrawerOpen={isDrivingIncidentsDrawerOpen}
      setIsDrawerOpen={setIsDrivingIncidentsDrawerOpen}
    />
  ) : (
    <></>
  );
};
export default DrivingIncidentsDrawer;
