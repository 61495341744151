/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth0 } from '@auth0/auth0-react';
import TokenStorage from 'helpers/TokenStorage';
import { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

// hooks
import useAuth from 'hooks/useAuth';

// layouts
import { isRenewalEnabled } from 'common/constants';
import DefaultLayout from 'layouts/default';
import PaymentLayout from 'layouts/payment';
import PolicyDetailLayout from 'layouts/policyDetail';
import PolicyDetailEndorsementLayout from 'layouts/policyDetailEndorsement';
import Public from 'layouts/public';
import QuoteOrRenewalDetailLayout from 'layouts/quoteOrRenewalDetail';

// #region Agents
import AgentDetail from 'routes/Agents/agent-detail';
import AgentList from 'routes/Agents/agent-list';
// #endregion
// #region AgencyList
import AgencyList from 'routes/Agencies/AgencyList';
// #endregion
import { isEmpty } from 'lodash-es';

import ErrorPage from './404';
import Login from './Login';
import SSOLoginErrorPage from './SSOLoginError';

// #region Redirect
import RedirectSSO from './RedirectSSO';
// #endregion
// #endregion
// #region Policies
import Policies from './Policies';

// #region Policy Detail
import PolicyDetailBillings from './Policies/PolicyDetail/Billings';
import PolicyDetailDocuments from './Policies/PolicyDetail/Documents';
import PolicyDetailDocumentsDetail from './Policies/PolicyDetail/Documents/Detail';
import PolicyDetailNotes from './Policies/PolicyDetail/Notes';
import PolicyDetail from './Policies/PolicyDetail/Overview';
import PolicyDetailTasks from './Policies/PolicyDetail/Tasks';
import PolicyDetailTransactionHistory from './Policies/PolicyDetail/TransactionHistory';
// #endregion
// #region Quotes
import Quotes from './QuotesOrRenewal';
// #endregion
// #region Quote Detail
import QuoteDetailBillings from './QuotesOrRenewal/QuotesOrRenewalDetail/Billings';
import QuoteDetail from './QuotesOrRenewal/QuotesOrRenewalDetail/Details';
import QuoteDetailDocuments from './QuotesOrRenewal/QuotesOrRenewalDetail/Documents';
import QuoteDetailNotes from './QuotesOrRenewal/QuotesOrRenewalDetail/Notes/Notes';
import QuoteDetailTasks from './QuotesOrRenewal/QuotesOrRenewalDetail/Tasks/Tasks';
// #endregion

// #region Tasks
import Tasks from './Tasks/AllTasks';
import ClosedTasks from './Tasks/ClosedTasks';
import MyTasks from './Tasks/MyTasks';
import OpenTasks from './Tasks/OpenTasks';
// #endregion
// #region Settings
import Settings from './Settings';
import SettingsAbout from './Settings/About';
import SettingsAccount from './Settings/Account/SettingsAccount';
import EditProfile from './Settings/EditProfile';
// #endregion
// #region Queues
import QueueList from './Queues/QueueList';
// #endregion
// #region Search
import UnauthorizedPage from './403/403';
import SearchResults from './AdvancedSearch/Search';
// #endregion
// #region Payments
import AgencyDetail from './Agencies/agency-detail';
import Pay from './Payments/Pay';
import PayV2 from './Payments/PayV2';
import PaymentSetup from './Payments/Setup';
import PaymentSetupSuccess from './Payments/SetupSuccess';
import PaymentSuccess from './Payments/Success';

// #endregion

interface IWithLayout {
  LayoutComp: any;
  ChildComponent: any;
  needsAuth?: boolean;
  isErrorPage?: boolean;
}

const Routes: FC = () => {
  const token = TokenStorage.get()?.access;
  const isSSOSession = TokenStorage.get()?.isSSOSession;
  const { verifyLoading } = useAuth();
  const HISTORY = useHistory();
  const { user, logout, isLoading } = useAuth0();
  /**
   * This HOC handles layout and restriction
   *
   * @param {*} LayoutComp
   * @param {*} ChildComponent
   */
  const withLayout =
    ({ LayoutComp, ChildComponent, needsAuth = false, isErrorPage = false }: IWithLayout) =>
    // eslint-disable-next-line react/no-unstable-nested-components
    (props: any) => {
      const {
        match: { url },
      } = props;

      // If authentication is needed and no token is present, redirect to the login page with the current URL as a query parameter
      if (needsAuth && !token) {
        return <Redirect to={`/login?${new URLSearchParams({ redirect: url }).toString()}`} />;
      }

      // If authentication is needed, it's an SSO session, the user is not loaded and not currently loading, then logout and reload the page
      if (needsAuth && isSSOSession && isEmpty(user) && !isLoading) {
        TokenStorage.destroy();
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/login`,
          },
        });
        HISTORY.push('/login');
        // for clearing all provider states
        window.location.reload();
      }

      // If the page is not an error page, authentication is needed, and the verification is still loading, return an empty fragment
      if (!isErrorPage && verifyLoading) {
        return <></>;
      }

      return (
        <LayoutComp {...props}>
          <ChildComponent {...props} />
        </LayoutComp>
      );
    };

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/redirect" exact component={RedirectSSO} />
      <Route exact path="/">
        {!token ? <Redirect to="/login" /> : token && <Redirect to="/policies" />}
      </Route>
      <Route
        path="/settings"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: Settings,
          needsAuth: true,
        })}
      />
      <Route
        path="/settings/about"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: SettingsAbout,
          needsAuth: true,
        })}
      />
      <Route
        path="/settings/edit-profile"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: EditProfile,
          needsAuth: true,
        })}
      />
      <Route
        path="/settings/account"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: SettingsAccount,
          needsAuth: true,
        })}
      />
      <Route
        path="/quotes"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: Quotes,
          needsAuth: true,
        })}
      />
      {/* #region Quote Detail */}
      <Redirect exact from="/quotes/:id" to="/quotes/:id/details" />
      <Route
        path="/quotes/:id/details"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetail,
          needsAuth: true,
        })}
      />
      <Route
        path="/quotes/:id/documents"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailDocuments,
          needsAuth: true,
        })}
      />
      <Route
        path="/quotes/:id/notes"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailNotes,
          needsAuth: true,
        })}
      />
      <Route
        path="/quotes/:id/messages"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailTasks,
          needsAuth: true,
        })}
      />
      <Route
        path="/quotes/:id/billings"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailBillings,
          needsAuth: true,
        })}
      />
      {/* #endregion Quote Detail */}
      {isRenewalEnabled && (
        <Route
          path="/renewals"
          exact
          render={withLayout({
            LayoutComp: DefaultLayout,
            ChildComponent: Quotes,
            needsAuth: true,
          })}
        />
      )}
      {/* #region Renewal Detail */}
      <Redirect exact from="/renewals/:id" to="/renewals/:id/details" />
      <Route
        path="/renewals/:id/details"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetail,
          needsAuth: true,
        })}
      />
      <Route
        path="/renewals/:id/documents"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailDocuments,
          needsAuth: true,
        })}
      />
      <Route
        path="/renewals/:id/notes"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailNotes,
          needsAuth: true,
        })}
      />
      <Route
        path="/renewals/:id/messages"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailTasks,
          needsAuth: true,
        })}
      />
      <Route
        path="/renewals/:id/billings"
        exact
        render={withLayout({
          LayoutComp: QuoteOrRenewalDetailLayout,
          ChildComponent: QuoteDetailBillings,
          needsAuth: true,
        })}
      />
      {/* #endregion Renewal Detail */}
      <Route
        path="/messages/all-messages"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: Tasks,
          needsAuth: true,
        })}
      />
      <Route
        path="/messages/open-messages"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: OpenTasks,
          needsAuth: true,
        })}
      />
      <Route
        path="/messages/my-messages"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: MyTasks,
          needsAuth: true,
        })}
      />
      <Route
        path="/messages/closed-messages"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: ClosedTasks,
          needsAuth: true,
        })}
      />
      <Redirect exact from="/users" to="/users/portal-admin" />
      <Route
        path="/agents"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: AgentList,
          needsAuth: true,
        })}
      />
      <Route
        path="/agents/:id"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: AgentDetail,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: Policies,
          needsAuth: true,
        })}
      />
      {/* #region Policy Detail */}
      <Route
        path="/policies/:id/overview"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetail,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/new-business"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetail,
          needsAuth: true,
        })}
      />
      <Redirect exact from="/policies/:id" to="/policies/:id/overview" />
      <Route
        path="/policies/:id/history"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetailTransactionHistory,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/billings"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetailBillings,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/messages"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetailTasks,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/documents"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetailDocuments,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/documents/:documentid"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetailDocumentsDetail,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/notes"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailLayout,
          ChildComponent: PolicyDetailNotes,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/endorsement/:endorsementId/overview"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailEndorsementLayout,
          ChildComponent: PolicyDetail,
          needsAuth: true,
        })}
      />
      <Redirect
        exact
        from="/policies/:id/endorsement/:endorsementId"
        to="/policies/:id/endorsement/:endorsementId/overview"
      />
      <Route
        path="/policies/:id/endorsement/:endorsementId/documents"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailEndorsementLayout,
          ChildComponent: PolicyDetailDocuments,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/endorsement/:endorsementId/documents/:documentid"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailEndorsementLayout,
          ChildComponent: PolicyDetailDocumentsDetail,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/endorsement/:endorsementId/notes"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailEndorsementLayout,
          ChildComponent: PolicyDetailNotes,
          needsAuth: true,
        })}
      />
      <Route
        path="/policies/:id/endorsement/:endorsementId/messages"
        exact
        render={withLayout({
          LayoutComp: PolicyDetailEndorsementLayout,
          ChildComponent: PolicyDetailTasks,
          needsAuth: true,
        })}
      />
      {/* #endregion */}
      <Route
        path="/agencies"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: AgencyList,
          needsAuth: true,
        })}
      />
      <Route
        path="/agencies/:id/"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: AgencyDetail,
          needsAuth: true,
        })}
      />
      <Route
        path="/payments/:policyLocator/:paymentIntentId"
        render={withLayout({
          LayoutComp: PaymentLayout,
          ChildComponent: PayV2,
          needsAuth: false,
        })}
        exact
      />
      <Route
        path="/pay"
        render={withLayout({
          LayoutComp: PaymentLayout,
          ChildComponent: Pay,
          needsAuth: false,
        })}
        exact
      />
      <Route
        path="/enroll/:policyLocator/:setupIntentId"
        render={withLayout({
          LayoutComp: PaymentLayout,
          ChildComponent: PaymentSetup,
          needsAuth: false,
        })}
        exact
      />
      <Route
        path="/payments/success"
        render={withLayout({
          LayoutComp: PaymentLayout,
          ChildComponent: PaymentSuccess,
          needsAuth: false,
        })}
        exact
      />
      <Route
        path="/payments/setup-success"
        render={withLayout({
          LayoutComp: PaymentLayout,
          ChildComponent: PaymentSetupSuccess,
          needsAuth: false,
        })}
        exact
      />
      <Route
        path="/queues/:id"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: QueueList,
          needsAuth: true,
        })}
      />
      <Route
        path="/search/:searchType"
        exact
        render={withLayout({
          LayoutComp: DefaultLayout,
          ChildComponent: SearchResults,
          needsAuth: true,
        })}
      />
      <Route
        path="/403"
        exact
        render={withLayout({
          LayoutComp: Public,
          ChildComponent: UnauthorizedPage,
        })}
      />
      <Route path="/sso-error" exact component={SSOLoginErrorPage} />
      <Route
        path="*"
        render={withLayout({ LayoutComp: Public, ChildComponent: ErrorPage, isErrorPage: true })}
      />
    </Switch>
  );
};

export default Routes;
