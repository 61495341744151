import { Box, Skeleton } from '@mui/material';
import DiscardQuote from 'assets/images/discardQuote.svg';
import Send from 'assets/images/Send.svg';
import { quoteStatuses, submissionDetailInfoTabs } from 'common/constants';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';
import TopActionBarDivider from 'components/TopActionBarDivider';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { emitter, Events } from 'helpers/EventBus';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import qs from 'query-string';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

export interface QuoteDetailTopActionBarProps {}

const PreQualActionBar: FC<QuoteDetailTopActionBarProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setDialogOpen } = useDialog();
  const {
    data: quoteDetail,
    discardQuote,
    loading,
    submitPreQual,
    underwritingQuestionsState,
  } = useQuoteDetail();
  const { setLoading } = useLoader();
  const HISTORY = useHistory();
  const { tab } = qs.parse(HISTORY.location.search) as { tab: string };

  const handleQuestionEngineInputsInputsValidated = async (
    isDataValid: Events['questionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await submitPreQual(quoteDetail?.policy_locator!, quoteDetail?.locator!);
        HISTORY.push(`/quotes/${id}`);
        displayToastMessage('SUCCESS', t('The Pre-Qual has been submitted.'));
      } catch (error) {
        const e = error as any;
        displayBackendErrorMessage(e, t('An error occurred while submiting the Pre-Qual.'));
      } finally {
        setLoading(false);
      }
    }
  };

  const isQuestionEnginePage = useMemo(() => {
    switch (tab) {
      case submissionDetailInfoTabs.BUSINESS_INFO.code:
        return true;

      case submissionDetailInfoTabs.OPERATIONS.code:
        return true;

      default:
        return false;
    }
  }, [tab]);

  const handleSubmitPreQual = async () => {
    setDialogOpen({
      dialog: 'SUBMIT_PREQUAL_QUOTE',
      isOpen: false,
    });

    if (isQuestionEnginePage) {
      // emit event to page
      emitter.emit('validateQuestionEngineInputs', 'questionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsInputsValidated(true);
    }
  };

  useEffect(() => {
    emitter.on('questionEngineInputsValidated', handleQuestionEngineInputsInputsValidated);

    return () => {
      emitter.off('questionEngineInputsValidated', handleQuestionEngineInputsInputsValidated);
    };
  }, [underwritingQuestionsState]);

  const handleDiscard = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: 'DISCARD_QUOTE',
        isOpen: false,
      });
      await discardQuote(id);
      displayToastMessage('SUCCESS', t('The quote has been discarded.'));
      HISTORY.push('/quotes');
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while discarding the quote.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TopActionBar>
        {loading &&
          [...Array(5).keys()].map((_, i) => (
            <Skeleton
              animation="wave"
              width="5%"
              height={28}
              sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
              key={i}
            />
          ))}

        {!loading && quoteDetail?.state?.code === quoteStatuses.DRAFT && (
          <>
            <TopActionBarButton
              data-test="submit-prequal"
              onClick={() => {
                setDialogOpen({
                  dialog: 'SUBMIT_PREQUAL_QUOTE',
                  isOpen: true,
                  onAccept: () => handleSubmitPreQual(),
                });
              }}
              startIcon={<Box component="img" src={Send} sx={{ width: '24px', height: '24px' }} />}
            >
              {t('Submit')}
            </TopActionBarButton>

            <TopActionBarDivider />

            <TopActionBarButton
              data-test="discard-quote"
              onClick={() => {
                setDialogOpen({
                  dialog: 'DISCARD_QUOTE',
                  isOpen: true,
                  onAccept: () => handleDiscard(),
                });
              }}
              startIcon={
                <Box component="img" src={DiscardQuote} sx={{ width: '24px', height: '24px' }} />
              }
            >
              {t('Discard')}
            </TopActionBarButton>
          </>
        )}
      </TopActionBar>
    </>
  );
};

export default PreQualActionBar;
