import { Box, Fade, Link, Typography } from '@mui/material';
import { GridAlignment, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { advancedSearchTypes, COLUMNS, dataFieldTypes, userRoles } from 'common/constants';
import DataTable from 'components/DataTable';
import Head from 'components/Head';
import PageHeader from 'components/PageHeader';
import { currencyFormat, getNestedValueFromObject } from 'helpers/Utils';
import useAdvancedSearch from 'hooks/useAdvancedSearch';
import useConfig from 'hooks/useConfig';
import useUser from 'hooks/useUser';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { Pagination } from 'types/Pagination';

const SearchResults: FC = () => {
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const queryParams = qs.parse(LOCATION.search) as unknown as Pagination;
  const { data: user } = useUser();
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const { searchType } = useParams<{ searchType: string }>();
  const [header, setHeader] = useState<string>();
  const { loading, loaded, fetch, data, pagination, resetAdvancedSearchState } =
    useAdvancedSearch();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [pageNumber, setPage] = useState(
    queryParams?.page ? parseInt(queryParams?.page as unknown as string, 10) - 1 : 0,
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  const redirectUser = (to: string) => {
    if (LOCATION.pathname === to) {
      HISTORY.go(0);
    } else {
      HISTORY.push(to);
    }
  };

  const handleListItemClick = (id: string, type: string, role: string = type) => {
    switch (type) {
      case advancedSearchTypes.ACCOUNT: {
        const to = `/accounts/${id}/details`;
        redirectUser(to);
        break;
      }

      case advancedSearchTypes.AGENCY: {
        const to = `/agencies/${id}`;
        redirectUser(to);
        break;
      }

      case advancedSearchTypes.AGENT: {
        const to = `/agents/${id}`;
        redirectUser(to);
        break;
      }

      case advancedSearchTypes.POLICY: {
        const to = `/policies/${id}/overview`;
        redirectUser(to);
        break;
      }

      case advancedSearchTypes.POLICYHOLDER: {
        const to = `/policyholders/${id}/details`;
        redirectUser(to);
        break;
      }

      case advancedSearchTypes.SUBMISSIONS: {
        const to = `/applications/submissions/${id}/overview`;
        redirectUser(to);
        break;
      }

      case advancedSearchTypes.USER: {
        const to = `/users/${role}/${id}`;
        redirectUser(to);
        break;
      }

      case advancedSearchTypes.QUOTE: {
        const to = `/quotes/${id}/details`;
        redirectUser(to);
        break;
      }

      default:
        break;
    }
  };

  const handleHeader = (type: string) => {
    switch (type) {
      case advancedSearchTypes.ACCOUNT: {
        setHeader(t('Search Results in Accounts List'));
        break;
      }

      case advancedSearchTypes.AGENCY: {
        setHeader(t('Search Results in Agencies List'));
        break;
      }

      case advancedSearchTypes.AGENT: {
        setHeader(t('Search Results in Agents List'));
        break;
      }

      case advancedSearchTypes.POLICY: {
        setHeader(t('Search Results in Policies List'));
        break;
      }

      case advancedSearchTypes.POLICYHOLDER: {
        setHeader(t('Search Results in Policyholders List'));
        break;
      }

      case advancedSearchTypes.SUBMISSIONS: {
        setHeader(t('Search Results in Submissions List'));
        break;
      }

      case advancedSearchTypes.USER: {
        setHeader(t('Search Results in Users List'));
        break;
      }

      case advancedSearchTypes.QUOTE: {
        setHeader(t('Search Results in Submissions List'));
        break;
      }

      default:
        setHeader(t('Search Results List'));
        break;
    }
  };

  const renderColumns = (cl): GridColDef[] =>
    cl.map((field, i) => ({
      field: field.code!,
      headerName: field.name,
      flex: field.additional_data?.flex ?? 1,
      minWidth: field.additional_data?.minWidth,
      sortable: field.is_sortable ?? true,
      align: field.additional_data?.headerAlign as GridAlignment,
      headerAlign: field.additional_data?.headerAlign as GridAlignment,
      type: field.type,
      renderCell: (params) => {
        const fieldValue = getNestedValueFromObject(params.row, field.code!);

        if (fieldValue === '-' && i !== 0) {
          return fieldValue;
        } else if (i === 0) {
          return (
            <Box
              onClick={() =>
                handleListItemClick(
                  params.row?.locator || params.row?.display_id || params.row?.id,
                  searchType,
                )
              }
              sx={{
                color: (theme) => theme.customColors.table.link,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: (theme) => theme.typography.body1.fontSize,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {fieldValue || '-'}
            </Box>
          );
        } else if (field.code === 'state__name') {
          if (!fieldValue) return '-';
          const r = params.row;
          const statusColor = r?.state?.code!;
          return (
            <Box
              sx={{
                px: (theme) => theme.spacing(1.5),
                borderRadius: (theme) => theme.spacing(0.5),
                color: (theme) => theme.customColors.black,
                backgroundColor: (theme) => theme.customColors.submissionStatusColor[statusColor],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: (theme) => theme.typography.subtitle2.fontSize,
                  color: (theme) => theme.customColors.white50,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                }}
                variant="body1"
              >
                {r?.state?.name!}
              </Typography>
            </Box>
          );
        } else if (field.code === 'status__value') {
          if (!fieldValue) return '-';
          const statusColor = params.row?.status?.key!;
          return (
            <Box
              sx={{
                px: (theme) => theme.spacing(1.5),
                borderRadius: (theme) => theme.spacing(0.5),
                color: (theme) => theme.customColors.black,
                backgroundColor: (theme) => theme.customColors.policiesStatusColor[statusColor],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: (theme) => theme.typography.subtitle2.fontSize,
                  color: (theme) => theme.customColors.white50,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                }}
                variant="body1"
              >
                {fieldValue}
              </Typography>
            </Box>
          );
        } else if (field.code === 'three_quote_number' || field.code === 'three_policy_number') {
          const locator = getNestedValueFromObject(params.row, 'locator');
          return (
            <Link
              component={RouterLink}
              to={
                field.code === 'three_quote_number'
                  ? `/quotes/${locator}/details`
                  : `/policies/${locator}/overview`
              }
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
              }}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else if (field.type === dataFieldTypes.STRING) {
          if (field.code === 'price__total') {
            return (
              <Typography
                sx={{
                  lineHeight: (theme) => theme.typography.body1.lineHeight,
                  fontSize: (theme) => theme.typography.body1.fontSize,
                  color: (theme) => theme.customColors.black,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {!Number.isNaN(Number(fieldValue))
                  ? `${
                      currencyFormat(
                        getNestedValueFromObject(params.row, 'price__currency'),
                        fieldValue,
                      ).merged
                    }`
                  : '-'}
              </Typography>
            );
          } else {
            return (
              <Typography
                sx={{
                  lineHeight: (theme) => theme.typography.body1.lineHeight,
                  fontSize: (theme) => theme.typography.body1.fontSize,
                  color: (theme) => theme.customColors.black,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                title={fieldValue}
              >
                {fieldValue || '-'}
              </Typography>
            );
          }
        } else if (field.type === dataFieldTypes.SELECT) {
          return (
            <Typography
              sx={{
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: (theme) => theme.typography.body1.fontSize,
                color: (theme) => theme.customColors.black,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Typography>
          );
        } else if (field.type === dataFieldTypes.DATE) {
          return (
            <Typography
              sx={{
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: (theme) => theme.typography.body1.fontSize,
                color: (theme) => theme.customColors.black,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue ? formatDateInTimeZone(fieldValue) : '-'}
            >
              {fieldValue ? formatDateInTimeZone(fieldValue) : '-'}
            </Typography>
          );
        } else if (field.type === dataFieldTypes.EMAIL) {
          return (
            <Link
              href={`mailto:${fieldValue}`}
              underline="hover"
              sx={{
                color: (theme) => theme.customColors.table.link,
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: (theme) => theme.typography.body1.fontSize,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Link>
          );
        } else {
          return (
            <Typography
              sx={{
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: (theme) => theme.typography.body1.fontSize,
                color: (theme) => theme.customColors.black,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue || '-'}
            </Typography>
          );
        }
      },
    }));

  const isColumnExcludedForUser = (fieldCode: string) =>
    ['team', 'agency'].includes(fieldCode!) &&
    ![userRoles.UNDERWRITER.code].includes(user?.role?.code!);

  const memoizedColumns = useMemo(
    () => renderColumns(COLUMNS[`${searchType.toUpperCase()}_COLUMNS`] || []),
    [searchType],
  );

  const checkIsOrderFieldInColumns = (field: string) =>
    memoizedColumns.some((col) => col.field === field?.replaceAll('-', ''));

  useEffect(() => {
    // Returns the encoded form of the string. test& -> test%26.
    const encodedQueryParams: Pagination = Object.fromEntries(
      Object.entries(queryParams).map(([key, value]) => [key, encodeURIComponent(value)]),
    );

    fetch(
      {
        ...encodedQueryParams,
        ordering: checkIsOrderFieldInColumns(encodedQueryParams.ordering ?? '')
          ? encodedQueryParams.ordering
          : '',
      },
      searchType,
    );

    handleHeader(searchType);
  }, [LOCATION]);

  useEffect(() => () => resetAdvancedSearchState(), []);

  useEffect(() => {
    setPage(0);
  }, [searchType]);

  const visibilityModel = useMemo(() => {
    const model = {};

    if (COLUMNS[`${searchType.toLocaleUpperCase()}_COLUMNS`]) {
      COLUMNS[`${searchType.toLocaleUpperCase()}_COLUMNS`].forEach((field) => {
        if (isColumnExcludedForUser(field.code!) || field.is_hidden) {
          model[field.code!] = false;
        }
      });
    }

    return model;
  }, [memoizedColumns, user]);

  return (
    <>
      <Head title={t('Search Results')} />
      <Box ref={scrollRef} />

      <Fade in timeout={500}>
        <Box>
          <PageHeader
            sx={{ px: 3 }}
            left={
              <Typography
                variant="h6"
                sx={{
                  userSelect: 'none',
                  color: (theme) => theme.customColors.pageHeader.title,
                  fontWeight: '500',
                }}
              >
                {header}
              </Typography>
            }
          />

          <DataTable
            key={searchType}
            getRowId={(row) => row.locator ?? row.display_id ?? row.id}
            rows={data!}
            columns={memoizedColumns}
            loading={loading}
            initialLoading={loading && !loaded}
            columnVisibilityModel={visibilityModel}
            paginationModel={{ page: pageNumber, pageSize: pagination?.page_size! }}
            pageSizeOptions={[pagination?.page_size!]}
            rowCount={pagination?.total_count}
            paginationMode="server"
            initialState={{
              sorting: {
                // TODO: Fix in Advanced Search Ticket https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/15261/
                sortModel: [{ field: '', sort: 'asc' }],
              },
            }}
            onPageChange={(page) => {
              setPage(page);
              HISTORY.push({
                search: qs.stringify({ ...queryParams, page: page + 1 }),
              });
              scrollRef?.current?.scrollIntoView();
            }}
            sortingMode="server"
            sortModel={sortModel}
            hideFooterPagination={pagination?.total_count! < pagination?.page_size!}
            onSortModelChange={([newModel]) => {
              setSortModel([newModel]);
              if (newModel) {
                setPage(0);
                HISTORY.push({
                  search: qs.stringify({
                    ...queryParams,
                    ...{ ordering: queryParams.ordering, page: 1 },
                    ordering: `${newModel.sort === 'desc' ? '-' : ''}${newModel?.field}`,
                  }),
                });
              } else {
                HISTORY.push({
                  search: qs.stringify({
                    ...queryParams,
                  }),
                });
              }
            }}
            wrapperSx={{ mx: 3 }}
          />
        </Box>
      </Fade>
    </>
  );
};

export default SearchResults;
