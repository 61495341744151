/* eslint-disable no-multi-assign */
import { Box, Button, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { updateExposure } from 'api/services/NewQuote';
import {
  additionalInterestTypes,
  multiChoiceSubjectOfInterestTypes,
  stateList,
  threeExposureNames,
  threePolicyGroupNames,
  uwQuestionAliases,
} from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { AdditionalInterestsDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/AdditionalInterestsDrawerFieldParser';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  deleteFromQueryStrings,
  handleBackendErrorsWithFormik,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

export interface AdditionalInterestsEditProps {
  isDrawerOpen: boolean;
  isAdd?: boolean;
}

const initialValues = {
  info_ai_name: '',
  info_ai_type: '',
  info_ai_address_line1: '',
  info_ai_address_line2: '',
  info_ai_address_city: '',
  info_ai_address_state: '',
  info_ai_address_zip: '',
  info_ai_address_phone: '',
  info_ai_address_email: '',
  info_ai_subject: '',
};

const AdditionalInterestsEditDrawer: FC<AdditionalInterestsEditProps> = ({
  isDrawerOpen,
  isAdd = false,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const [state, setState] = useState<any>({ ...initialValues });
  const ADDITIONAL_INTEREST = url.additional_interest;
  const {
    data: quoteDetail,
    loaded: quoteLoaded,
    exposureList,
    getExposures,
    fields: fieldConfig,
  } = useQuoteDetail();
  const { loading, setLoading } = useLoader();
  const { setDialogOpen } = useDialog();
  const [fields, setFields] = useState<any[]>([]);

  const quoteCharacteristics = quoteDetail?.characteristics?.[0]?.data;

  const additionalInterestFields = useMemo(
    () =>
      (fieldConfig?.exposure?.data as ProductWorkFlow[])
        ?.find((con) => con.code === threeExposureNames.POLICY_INFORMATION)
        ?.fields?.find((x) => x.code === threePolicyGroupNames.ADDITIONAL_INTEREST)
        ?.nested_fields ?? [],
    [fieldConfig],
  );

  const propertyExposures = exposureList?.[`${threeExposureNames.BUILDING}`]?.data ?? [];

  const policyInformationExposure = useMemo(
    () => exposureList?.[`${threeExposureNames.POLICY_INFORMATION}`]?.data?.[0] ?? {},
    [exposureList],
  );

  const vehicleExposures = exposureList?.[`${threeExposureNames.VEHICLE}`]?.data ?? [];

  const isMultipleTypeSelected = useMemo(
    () => multiChoiceSubjectOfInterestTypes.includes(state?.info_ai_type),
    [state],
  );

  const handleFields = (infoType: string) => {
    if (!additionalInterestFields.length) {
      return;
    }
    const tmpFields = [...additionalInterestFields];

    const subjectOfInterestIndex = tmpFields.findIndex((i) => i.code === 'info_ai_subject');
    const subjectOfInterestField = tmpFields[subjectOfInterestIndex];

    subjectOfInterestField.choices = [];

    const addressStateIndex = tmpFields.findIndex((i) => i.code === 'info_ai_address_state');
    const addressStateField = tmpFields[addressStateIndex];

    if (addressStateField?.additional_data?.setDefaultStateListAsChoices) {
      addressStateField.choices = stateList.map(({ code, name }) => ({ code, name })) ?? [];
    }

    if (isMultipleTypeSelected) {
      if (propertyExposures?.length || vehicleExposures?.length) {
        const buildings = propertyExposures.map((property) => ({
          code: property?.locator ?? '',
          name: property?.data?.bdg_location,
        }));

        let subjectChoices = [...buildings];

        if (infoType === additionalInterestTypes.LOSS_PAYEE) {
          const vehicles = vehicleExposures.map((vehicle) => ({
            code: vehicle?.locator ?? '',
            name: vehicle?.data?.veh_vin,
          }));

          subjectChoices = [...subjectChoices, ...vehicles];
        }

        subjectOfInterestField.choices = subjectChoices;
      }

      subjectOfInterestField.is_hidden = false;
    } else {
      subjectOfInterestField.is_hidden = true;
    }
    setFields(tmpFields);
  };

  const additionalInterests = useMemo(() => {
    const r = policyInformationExposure?.data?.info_ai ?? [];

    return r as any[];
  }, [policyInformationExposure]);

  const additionalInterest = useMemo(
    () => additionalInterests.find((ow) => ow.locator === ADDITIONAL_INTEREST) ?? {},
    [additionalInterests, ADDITIONAL_INTEREST],
  );

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['additional_interest', 'addAdditionalInterest'],
      }),
    });
  };

  // Close drawer if related id not found
  useEffect(() => {
    if (!loading && quoteLoaded && isEmpty(additionalInterest) && !isAdd) {
      displayToastMessage('ERROR', t('Additional Interest not found.'));
      handleQuery();
    }
  }, [additionalInterest, quoteLoaded]);

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(fields, state),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };

    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...state,
      ...initialValues,
    },
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...additionalInterest }));
    formik.setValues({ ...initialValues, ...additionalInterest });
  }, [additionalInterest]);

  useEffect(() => {
    if (isMultipleTypeSelected && !isEmpty(state.info_ai_subject)) {
      let tmpAdditionalInterest = { ...state };

      tmpAdditionalInterest = {
        ...tmpAdditionalInterest,
        info_ai_subject: Array.isArray(state?.info_ai_subject)
          ? state?.info_ai_subject?.[0]?.split(',')
          : state?.info_ai_subject?.split(','),
      };

      setState((prevState) => ({ ...prevState, ...tmpAdditionalInterest }));
      formik.setValues({ ...tmpAdditionalInterest });
    }
  }, [isMultipleTypeSelected]);

  useEffect(() => {
    handleFields(formik.values?.info_ai_type);
  }, [exposureList, additionalInterestFields, formik.values?.info_ai_type]);

  const showLoader = useMemo(() => {
    const defaultLoading = !quoteLoaded;

    return isAdd ? defaultLoading : defaultLoading || isEmpty(state);
  }, [isAdd, state, quoteLoaded]);

  const getQuoteDetailAndExposures = async () => {
    await getExposures(quoteDetail?.locator as string, { page_size: 10000 }, '', true);
  };

  const handleAdd = async () => {
    try {
      setLoading(true);

      const tmpAdditionalInterests = [...additionalInterests];
      let tmpState = {
        ...state,
        info_ai_address_city: state?.info_ai_address_city?.trim(),
        info_ai_address_line1: state?.info_ai_address_line1.trim(),
        info_ai_address_line2: state?.info_ai_address_line2?.trim(),
        info_ai_address_state: state?.info_ai_address_state?.trim(),
        info_ai_address_zip: state?.info_ai_address_zip?.trim(),
      };

      if (isMultipleTypeSelected) {
        tmpState = { ...state, info_ai_subject: state?.info_ai_subject?.join() };
      }

      tmpAdditionalInterests.push(tmpState);
      await updateExposure(
        quoteDetail?.policy_locator!,
        policyInformationExposure?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.ADDITIONAL_INTEREST}`]: tmpAdditionalInterests,
          },
        },
      );

      displayToastMessage('SUCCESS', t('The additional interest has been added.'));
      handleQuery();

      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while adding the additional interest.'),
      );
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDialogOpen({
        dialog: 'DELETE_ADDITIONAL_INTEREST',
        isOpen: false,
      });
      setLoading(true);

      const tmpAdditionalInterests = [...additionalInterests];
      const rowIndex = tmpAdditionalInterests.findIndex(
        (row) => row.locator === additionalInterest.locator,
      );
      tmpAdditionalInterests.splice(rowIndex, 1);

      await updateExposure(
        quoteDetail?.policy_locator as string,
        policyInformationExposure?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.ADDITIONAL_INTEREST}`]: tmpAdditionalInterests,
          },
        },
      );

      displayToastMessage('SUCCESS', t('The additional interest has been deleted.'));
      handleQuery();

      await getQuoteDetailAndExposures();
    } catch (error) {
      displayBackendErrorMessage(
        error,
        t('An error occurred while deleting the additional interest.'),
      );
      handleBackendErrorsWithFormik<unknown>(error, formik);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const tmpAdditionalInterests = [...additionalInterests];
      const rowIndex = tmpAdditionalInterests?.findIndex(
        (row) => row.locator === additionalInterest?.locator,
      );

      let tmpState = {
        ...state,
        info_ai_address_city: state?.info_ai_address_city?.trim(),
        info_ai_address_line1: state?.info_ai_address_line1.trim(),
        info_ai_address_line2: state?.info_ai_address_line2?.trim(),
        info_ai_address_state: state?.info_ai_address_state?.trim(),
        info_ai_address_zip: state?.info_ai_address_zip?.trim(),
      };

      if (isMultipleTypeSelected) {
        tmpState = { ...state, info_ai_subject: state?.info_ai_subject?.join() };
      }

      tmpAdditionalInterests[rowIndex] = tmpState;

      await updateExposure(
        quoteDetail?.policy_locator as string,
        policyInformationExposure?.locator as string,
        {
          data: {
            [`${threePolicyGroupNames.ADDITIONAL_INTEREST}`]: tmpAdditionalInterests,
          },
        },
      );

      displayToastMessage('SUCCESS', t('The additional interest has been updated.'));
      handleQuery();

      await getQuoteDetailAndExposures();
    } catch (error) {
      handleBackendErrorsWithFormik<unknown>(error, formik);
      displayBackendErrorMessage(
        error,
        t('An error occurred while updating the additional interest.'),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await formik.submitForm();
    // await formik.handleSubmit();
    const errors = await formik.validateForm();

    if (isEmpty(errors)) {
      if (isAdd) {
        await handleAdd();
      } else {
        await handleUpdate();
      }
    }
  };

  const handleTypeChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;

    if (name === 'info_ai_type') {
      if (multiChoiceSubjectOfInterestTypes.includes(value) && !isMultipleTypeSelected) {
        formik.setFieldValue('info_ai_subject', []);
        setState((prevState) => ({
          ...prevState,
          info_ai_subject: [],
        }));
      } else if (!multiChoiceSubjectOfInterestTypes.includes(value) && isMultipleTypeSelected) {
        formik.setFieldValue('info_ai_subject', '');
        setState((prevState) => ({
          ...prevState,
          info_ai_subject: '',
        }));
      }
    } else if (name === 'info_ai_tax_id_type') {
      const characteristics = quoteCharacteristics as unknown as any;
      const uw_fein =
        characteristics?.underwriting_question?.find(
          (q) => q.uwq_question_id === uwQuestionAliases.fein,
        )?.uwq_question_answer ?? '';

      const uw_ssn =
        characteristics?.underwriting_question?.find(
          (q) => q.uwq_question_id === uwQuestionAliases.ssn,
        )?.uwq_question_answer ?? '';

      // Prefill FEIN and SSN values from quote data that's coming from in business info tab
      if (value === 'FEIN') {
        setState((prevState) => ({
          ...prevState,
          info_ai_fein: uw_fein,
        }));
      } else if (value === 'Social Security Number') {
        setState((prevState) => ({
          ...prevState,
          info_ai_ssn: uw_ssn,
        }));
      }
    }
  };

  return (
    <>
      <ScrollToFormikError formik={formik} />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        width="476px"
        onClose={handleQuery}
        headerSx={{
          mb: '14px',
          mt: 5,
        }}
        isContentScrollable
        header={
          <Typography
            sx={{
              '&.MuiTypography-root': {
                fontSize: 20,
                lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
              },
              fontWeight: '500',
              letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Additional Interest Details')}
          </Typography>
        }
        content={
          <Stack sx={{ mb: 6, pt: 1.25 }}>
            <AdditionalInterestsDrawerFieldParser
              formik={formik}
              state={state}
              showLoader={showLoader || !fieldConfig.exposure.loaded}
              fields={fields}
              setState={setState}
              isEdit
              splitSize={3}
              columnSpacing={0}
              rowSpacing={2}
              onSelectChange={(e: SelectChangeEvent) => {
                setTimeout(() => {
                  handleTypeChange(e);
                }, 10);
              }}
            />
          </Stack>
        }
        footer={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Box>
              {!isAdd && (
                <Button
                  onClick={() =>
                    setDialogOpen({
                      dialog: 'DELETE_ADDITIONAL_INTEREST',
                      isOpen: true,
                      onAccept: () => handleDelete(),
                    })
                  }
                  sx={[drawerFooterSecondaryButtonStyle]}
                >
                  {t('Delete')}
                </Button>
              )}
            </Box>
            <Box>
              <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
                {t('Cancel')}
              </Button>
              <Button onClick={handleSave} sx={[drawerFooterPrimaryButtonStyle]}>
                {t('Save')}
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default AdditionalInterestsEditDrawer;
