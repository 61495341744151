import Head from 'components/Head';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { t } from 'i18next';
import QuotePolicyEndorsementDefault from 'layouts/quotePolicyEndorsementDefault';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const PolicyDetailEndorsementLayout: FC<PropsWithChildren> = ({ children }) => {
  const { id: policyId, endorsementId } = useParams<{ id: string; endorsementId: string }>();

  const { fetch, data, loading } = usePolicyDetail();
  const { fetch: fetchEndorsement, resetEndorsementDetailState } = useEndorsementDetail();
  const HISTORY = useHistory();

  // TODO: get endosement detail
  const getPageDetail = async (pId: string) => {
    try {
      await fetch(pId);
    } catch (_error) {
      HISTORY.replace('/policies');
    }
  };

  useEffect(() => {
    fetchEndorsement(policyId, endorsementId as string);

    getPageDetail(policyId);

    return () => {
      resetEndorsementDetailState();
    };
  }, [policyId]);

  return (
    <QuotePolicyEndorsementDefault>
      {loading ? (
        <Head title={t('Policy Endorsement')} />
      ) : (
        <Head title={t('Policy Endorsement - {{Name}}', { Name: data?.policyholder?.name })} />
      )}
      {children}
    </QuotePolicyEndorsementDefault>
  );
};

export default PolicyDetailEndorsementLayout;
