import { Box } from '@mui/system';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  endorsementExtraStatuses,
  policyDetailInfoTabs,
  submissionDetailInfoTabs,
} from 'common/constants';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import ScrollToTop from 'components/ScrollToTop';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { emitter, Events } from 'helpers/EventBus';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import { handleShowCondition } from 'helpers/QuestionEngine';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  isUWQuestionsResponseExtraStatusReferOrDecline,
  updateQueryStrings,
} from 'helpers/Utils';
import useEndorsementDetail from 'hooks/useEndorsementDetail/useEndorsementDetail';
import useLoader from 'hooks/useLoader';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import DetailAccordion from './Accordions/DetailAccordion';

const Operations = forwardRef<any, any>((_props, ref) => {
  const { id, endorsementId } = useParams<{ id: string; endorsementId: string }>();
  const {
    data: endorsementDetail,
    underwritingQuestionsState,
    underwritingQuestions,
    setUnderwritingQuestionsState,
    saveUnderwritingQuestionsState,
    loading,
    loaded,
    canEdit,
    getExposures,
  } = useEndorsementDetail();

  const [state, setState] = useState({
    ...underwritingQuestionsState,
  });
  const [canReInitialize, setCanReInitialize] = useState(true);
  const [isOperationsUpdated, setIsOperationsUpdated] = useState(false);
  const { data: user } = useUser();
  const HISTORY = useHistory();

  const { setLoading } = useLoader();

  const operationsFields = useMemo(
    () =>
      (underwritingQuestions?.policy?.data as ProductWorkFlow[])?.find(
        (f) => f.code === policyDetailInfoTabs.OPERATIONS.code,
      )?.fields ?? [],
    [underwritingQuestions],
  );

  const visibleFields = operationsFields
    .flatMap((i) => i.nested_fields)
    .filter((i) => i && handleShowCondition(i, state) && !i?.is_hidden);

  const initialValues = useMemo(
    () =>
      operationsFields
        .flatMap((i) => i.nested_fields)
        .reduce((acc, cur) => ({ ...acc, [cur?.code!]: '' }), {}),
    [operationsFields],
  );

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(visibleFields, state, user?.role?.code),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };
    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...state,
    },
    validationSchema,
    enableReinitialize: canReInitialize,
    onSubmit: (_values) => {},
  });

  const replaceBack = () =>
    HISTORY.replace({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.OPERATIONS.code },
      }),
    });

  const inputsHasError = async () => {
    // validate this page
    await formik.submitForm();
    const pageErrors = await formik.validateForm();

    await formik.setTouched(
      {
        ...formik.touched,
        ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
      },
      false,
    );

    return !isEmpty(pageErrors);
  };

  const redirectToTab = (underwritingQuestionsResponse: any) => {
    const isQuoteStatusValid = isUWQuestionsResponseExtraStatusReferOrDecline(
      endorsementExtraStatuses,
      underwritingQuestionsResponse,
    );

    if (!isQuoteStatusValid) {
      HISTORY.push({
        search: updateQueryStrings({
          locationSearch: HISTORY.location.search,
          newQueries: { tab: submissionDetailInfoTabs.LOCATIONS.code },
        }),
      });
    } else if (isQuoteStatusValid) {
      HISTORY.push({
        search: updateQueryStrings({
          locationSearch: HISTORY.location.search,
          newQueries: { tab: submissionDetailInfoTabs.UW_RESULTS.code },
        }),
      });
    }
  };

  const handleNext = async ({ triggerHistoryChange = true, emitterTriggered = false }) => {
    let underwritingQuestionsResponse = endorsementDetail;
    if (await inputsHasError()) {
      replaceBack();
      throw new Error('Validation error');
    } else if (formik.dirty && (!isOperationsUpdated || emitterTriggered)) {
      try {
        setLoading(true);
        underwritingQuestionsResponse = await saveUnderwritingQuestionsState();
        // rule engine will update the exposures, so we need to fetch them again
        getExposures(id, endorsementId);
      } catch (error) {
        displayBackendErrorMessage(error);
        replaceBack();
        throw error;
      } finally {
        setLoading(false);
        setIsOperationsUpdated(true);
      }
    }
    if (triggerHistoryChange) {
      redirectToTab(underwritingQuestionsResponse);
    }
  };

  const isFormsLoading = underwritingQuestions.policy?.loading || (loading && !loaded);

  useEffect(() => {
    if (!isFormsLoading) {
      if (isEmpty(state) && !isEmpty(underwritingQuestionsState)) {
        setState({ ...underwritingQuestionsState });
      }
    }
  }, [isFormsLoading]);

  useImperativeHandle(ref, () => ({
    savePageInfo: async (triggerHistoryChange = true) => {
      if (isFormsLoading) {
        replaceBack();
        throw new Error('loading');
      }

      await handleNext({ triggerHistoryChange });
    },
    isDirty: () => formik.dirty,
  }));

  useEffect(() => {
    setUnderwritingQuestionsState({ ...state });
  }, [state]);

  // display previous values in case it was declined with blank fields
  useEffect(() => {
    if (!canEdit) {
      setState({ ...underwritingQuestionsState });
      formik.resetForm({
        errors: {},
        touched: {},
      });
    }
  }, [canEdit]);

  useEffect(() => {
    setCanReInitialize(true);

    formik.setValues({ ...initialValues, ...formik.values });

    setTimeout(() => {
      setCanReInitialize(false);
    }, 100);
  }, [JSON.stringify(underwritingQuestions)]);

  useEffect(() => {
    const handleSubmitPreQual = async (emitterAction: keyof Events) => {
      // emit event to action bar
      if (!(await inputsHasError())) {
        await handleNext({ triggerHistoryChange: false, emitterTriggered: true });
        emitter.emit(emitterAction, true);
      }
    };

    emitter.on('validateQuestionEngineInputs', handleSubmitPreQual);
    emitter.on('declineSubmissionEndorsement', handleSubmitPreQual);
    emitter.on('sendBackSubmissionEndorsement', handleSubmitPreQual);

    return () => {
      emitter.off('validateQuestionEngineInputs', handleSubmitPreQual);
      emitter.off('declineSubmissionEndorsement', handleSubmitPreQual);
      emitter.off('sendBackSubmissionEndorsement', handleSubmitPreQual);
    };
  }, [underwritingQuestionsState]);

  return isFormsLoading ? (
    <>{PreQualQuestionsLoading()}</>
  ) : (
    <Box sx={{ m: -0.5 }}>
      <ScrollToFormikError formik={formik} />
      {operationsFields.map((section) => (
        <DetailAccordion
          key={section.code}
          title={section.name ?? ''}
          formik={formik}
          state={state}
          setState={setState}
          fields={section.nested_fields ?? []}
          isReadOnly={!canEdit}
          relationalFields={section?.relatedFields}
        />
      ))}

      <NavigationButtons
        isNextButtonVisible={canEdit}
        isPreviousButtonVisible={false}
        handleNext={() => handleNext({ triggerHistoryChange: true }).catch((e) => e)}
      />

      <ScrollToTop />
    </Box>
  );
});

export default Operations;
