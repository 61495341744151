import { createTheme } from '@mui/material/styles';

type TColor = React.CSSProperties['color'];
type TShadow = React.CSSProperties['boxShadow'];

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}
declare module '@mui/material/Button' {
  export interface ButtonPropsColorOverrides {
    dialogInfo: true;
    dialogAlert: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }

  interface Palette {
    dialogCancel: Palette['primary'];
    dialogAlert: Palette['primary'];
    dialogInfo: Palette['primary'];
  }

  interface PaletteOptions {
    dialogCancel: PaletteOptions['primary'];
    dialogAlert: PaletteOptions['primary'];
    dialogInfo: PaletteOptions['primary'];
  }

  interface ThemeOptions {
    appBarHeight: number;
    appBarUserCharacterLimit: number;
    appBarUserMinWidth: number;
    appBarZIndex: number;
    quoteDetailFixedItemsHeight: string;
    policyDetailFixedItemsHeight: string;
    policyEndorsementDetailFixedItemsHeight: string;
    globalSearch: {
      globalSearchbarHeight: number;
      globalSearchbarWidth: number;
      searcbarIconPadding: string;
      searchbarListTopPadding: string;
      listItemButtonHeight: string;
      searchIconSize: number;
      searcListHeight: number;
    };
    announcements: {
      modalWidth: string;
    };
    sidebarWidth?: number;
    closedSidebarWidth?: number;
    sidebarMobileHeight?: number;
    actionBarHeight: number;
    columnGap: number;
    dateColumnWidth: number;
    statusColumnWidth: number;
    customShadows: {
      shadow1: TShadow;
      shadow2: TShadow;
      shadow3: TShadow;
      shadow4: TShadow;
      shadow5: TShadow;
      shadow6: TShadow;
      shadow7: TShadow;
      shadow8: TShadow;
      shadow9: TShadow;
      shadow10: TShadow;
      shadow11: TShadow;
      shadow12: TShadow;
      shadow13: TShadow;
      shadow14: TShadow;
      shadow15: TShadow;
      shadow16: TShadow;
      formBackground: TShadow;
      cardEffect2: TShadow;
      policyInfoCardEffect: TShadow;
      advancedSearchBoxShadow: TShadow;
    };
    customColors: {
      tableLoadingBackdrop: TColor;
      sidebarText: TColor;
      sidebarHoverText: TColor;
      sidebarItemSelectedBg: TColor;
      activeField: TColor;
      tabIndicator: TColor;
      tabTextColor: TColor;
      tabTextColorDisabled: TColor;
      commonSearchIcons: TColor;
      navigation: TColor;
      menuItemLink: TColor;
      linkText: TColor;
      borderBottomLine: TColor;
      listHover: TColor;
      activeCheckbox: TColor;
      chipPrimary: TColor;
      chipMailing: TColor;
      errorRed: TColor;
      successGreen: TColor;
      iconGray: TColor;
      paleSlate: TColor;
      dropdown: {
        hover: TColor;
        selected: TColor;
      };
      exposureIconColor: {
        location: TColor;
        policyInformation: TColor;
        employer: TColor;
        building: TColor;
        vehicle: TColor;
      };
      advancedSearch: {
        advancedSearchButtonHoverBg: TColor;
        title: TColor;
        closeButtonColor: TColor;
        closeButtonHoverBg: TColor;
        closeButtonHoverColor: TColor;
        panelBg: TColor;
        tabsPanelBg: TColor;
        tabBg: TColor;
        tabColor: TColor;
        tabSelectedColor: TColor;
        secondaryButtonBorderColor: TColor;
        secondaryButtonColor: TColor;
        secondaryButtonHoverBg: TColor;
        secondaryButtonHoverColor: TColor;
        primaryButtonBg: TColor;
        primaryButtonColor: TColor;
        primaryButtonHoverBg: TColor;
      };
      table: {
        header: TColor;
        item: TColor;
        link: TColor;
      };
      primary: {
        buttonHoverBg: TColor;
        buttonBg: TColor;
      };
      sso: {
        darkGrey: TColor;
        lightGrey: TColor;
      };
      containedButton: {
        buttonHoverBg: TColor;
        buttonBg: TColor;
      };
      topNavBar: {
        bg: TColor;
        itemHover: TColor;
        itemClicked: TColor;
      };
      chip: {
        bg: TColor;
        itemHover: TColor;
        itemClicked: TColor;
      };
      environmentChipColor: {
        development: TColor;
        qa: TColor;
        uat: TColor;
      };
      submissionStatusColor: {
        total_count: TColor;
        draft: TColor;
        referred: TColor;
        quoted: TColor;
        declined: TColor;
        expired: TColor;
        accepted: TColor;
        discarded: TColor;
        declined_by_uw: TColor;
        in_moratorium: TColor;
        non_renewal: TColor;
      };
      endorsementDetailStatusColor: {
        issued: TColor;
        invalidated: TColor;
        accepted: TColor;
        quoted: TColor;
        application: TColor;
        discarded: TColor;
        referred: TColor;
        declined: TColor;
        declined_by_uw: TColor;
      };
      policiesTransactionStatusColor: {
        active: TColor;
        application: TColor;
        draft: TColor;
        issued: TColor;
        rescinded: TColor;
        reinstated: TColor;
        discarded: TColor;
        invalidated: TColor;
        inactive: TColor;
        accepted: TColor;
        cancelled: TColor;
        pending_reinstate: TColor;
        quoted: TColor;
        referred: TColor;
        declined_by_uw: TColor;
        declined: TColor;
        in_cancellation: TColor;
        expired: TColor;
      };
      policiesStatusColor: {
        active: TColor;
        in_cancellation: TColor;
        non_issued: TColor;
        cancelled: TColor;
        expired: TColor;
        inactive: TColor;
        quoted: TColor;
        darkCopper: TColor;
      };
      invoiceStatusColor: {
        pastDue: TColor;
        currentDue: TColor;
        outstanding: TColor;
        paid: TColor;
        zeroDue: TColor;
        invalidated: TColor;
        chargeback: TColor;
        ach_return: TColor;
        waived: TColor;
        draft: TColor;
        carriedForward: TColor;
        writtenOff: TColor;
      };
      IndividualOwnersOfficersStatusColor: {
        defaultColor: TColor;
        excludedColor: TColor;
        coveredColor: TColor;
      };
      pageHeader: {
        title: TColor;
        subTitle: TColor;
      };
      menu: {
        itemText: TColor;
        itemBg: TColor;
        itemHoverText: TColor;
        itemHoverBg: TColor;
      };
      actionBar: {
        text: TColor;
      };
      drawer: {
        header: TColor;
        tableBorderColor: TColor;
      };
      radStepper: {
        bgFilled: TColor;
        bgEmpty: TColor;
        labelActive: TColor;
      };
      buttonGroup: {
        activeButton: TColor;
        hoverButton: TColor;
      };
      verticalTab: {
        selectedBg: TColor;
        selectedTabIndicator: TColor;
        selectedTextColor: TColor;
        tabIndicator: TColor;
        tabTextColor: TColor;
      };
      copper: TColor;
      darkCopper: TColor;
      darkGrey: TColor;
      greyMauve: TColor;
      gunMetal: TColor;
      blueSteel: TColor;
      gold: TColor;
      readOnlyBorderColor: TColor;
      black: TColor;
      black100: TColor;
      white50: TColor;
      white100: TColor;
      black750: TColor;
      black800: TColor;
      black900: TColor;
      black1050: TColor;
      blue50: TColor;
      blue100: TColor;
      blue150: TColor;
      blue200: TColor;
      blue250: TColor;
      blue300: TColor;
      blue400: TColor;
      blue450: TColor;
      blue500: TColor;
      blue550: TColor;
      blue600: TColor;
      grey50: TColor;
      grey100: TColor;
      grey150: TColor;
      grey200: TColor;
      grey250: TColor;
      grey300: TColor;
      grey350: TColor;
      grey400: TColor;
      grey600: TColor;
      grey700: TColor;
      grey800: TColor;
      grey850: TColor;
      grey950: TColor;
      grey960: TColor;
      grey1000: TColor;
      grey1100: TColor;
      grey1150: TColor;
      grey1200: TColor;
      green150: TColor;
      green200: TColor;
      green250: TColor;
      green300: TColor;
      orange50: TColor;
      orange100: TColor;
      orange150: TColor;
      red50: TColor;
      red100: TColor;
      red150: TColor;
      alert: TColor;
      warning: TColor;
      info: TColor;
      pink50: TColor;
      placeholderColor: TColor;
    };
  }

  interface Theme extends ThemeOptions {}
}

// A custom theme for this app
// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1700,
    },
  },
  globalSearch: {
    globalSearchbarHeight: 36,
    globalSearchbarWidth: 380,
    searcbarIconPadding: '8px',
    searchbarListTopPadding: '36px',
    listItemButtonHeight: '88px',
    searchIconSize: 20,
    searcListHeight: 380,
  },
  announcements: {
    modalWidth: '325px',
  },
  appBarHeight: 52,
  appBarUserCharacterLimit: 30,
  appBarUserMinWidth: 113,
  appBarZIndex: 5000,
  quoteDetailFixedItemsHeight: '265px',
  policyDetailFixedItemsHeight: '226px',
  policyEndorsementDetailFixedItemsHeight: '245px',
  sidebarWidth: 220,
  closedSidebarWidth: 60,
  actionBarHeight: 40,
  columnGap: 7,
  dateColumnWidth: 120,
  statusColumnWidth: 160,
  customShadows: {
    shadow1: 'inset 0px -1px 0px #E0E0E0',
    shadow2: '1px 1px 25px -6px #0000001A',
    shadow3: 'inset -1px 0px 0px rgba(0, 0, 0, 0.12)',
    shadow4: '1px 1px 25px -6px rgba(0, 0, 0, 0.1)',
    shadow5: 'inset 0px -0.5px 0px rgba(0, 0, 0, 0.12)',
    shadow6: '0px 1px 0px 0px #E0E0E0 inset',
    shadow7: '0px -1px 0px 0px #E0E0E0 inset',
    shadow8: '1px 10px 25px -6px rgba(0, 0, 0, 0.1)',
    shadow9: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12), inset -1px 0px 0px rgba(0, 0, 0, 0.12)',
    shadow10: '0px 1px #E0E0E0',
    shadow11: '0px 2px #E0E0E0',
    shadow12:
      '0px 3px 10px -5px rgba(0, 0, 0, 0.3), 0px -2px 12px -7px rgba(0, 0, 0, 0.3), 0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
    shadow13: '-2px -2px 8px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05)',
    shadow14: '-4px 4px 6px rgba(0, 0, 0, 0.06)',
    shadow15: '2px 0px 6px -6px rgba(0, 0, 0, 0.08), 0px 2px 6px -6px rgba(0, 0, 0, 0.08)',
    shadow16: '1px 1px 20px 0px rgba(0, 0, 0, 0.15)',
    formBackground: '2px 0px 6px 2px rgba(0, 0, 0, 0.08), 0px 2px 6px -6px rgba(0, 0, 0, 0.08)',
    cardEffect2: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    policyInfoCardEffect: '0px 2px 5px rgba(0, 0, 0, 0.08)',
    advancedSearchBoxShadow: '-2px 0px 12px 0px #0000003D, 2px 4px 12px 0px #0000003D',
  },
  customColors: {
    tableLoadingBackdrop: 'rgba(255,255,255,0.7)',
    sidebarText: '#000000',
    sidebarHoverText: '#FFFFFF',
    sidebarItemSelectedBg: 'rgba(0, 0, 0, 0.08)',
    activeField: '#D2682A',
    tabIndicator: '#D2682A',
    navigation: '#572B11',
    commonSearchIcons: '#BB4F09',
    menuItemLink: '#AE5623',
    linkText: '#AE5623',
    borderBottomLine: '#6B6D70',
    tabTextColor: '#02072F',
    tabTextColorDisabled: '#CBC4C1',
    listHover: '#F5F5F5',
    activeCheckbox: '#BB4F09',
    chipPrimary: '#324970',
    chipMailing: '#AB7E55',
    errorRed: '#D82313',
    successGreen: '#00752C',
    iconGray: '#323232',
    paleSlate: '#CBC4C1',
    dropdown: {
      hover: '#D2682A',
      selected: '#BB4F09',
    },
    exposureIconColor: {
      location: '#BB4F09',
      policyInformation: '#FBB68F',
      employer: '#AB7E55',
      building: '#6B6D70',
      vehicle: '#324970',
    },
    advancedSearch: {
      advancedSearchButtonHoverBg: 'rgba(0, 0, 0, 0.04)',
      title: '#2B2B2A',
      closeButtonColor: '#616161',
      closeButtonHoverBg: 'rgba(0, 0, 0, 0.04)',
      closeButtonHoverColor: '#212121',
      panelBg: '#F7F6F6',
      tabsPanelBg: '#FFFFFF',
      tabBg: '#324970',
      tabColor: '#2B2B2A',
      tabSelectedColor: '#FFFFFF',
      secondaryButtonBorderColor: '#BB4F09',
      secondaryButtonColor: '#BB4F09',
      secondaryButtonHoverBg: '#BB4F09',
      secondaryButtonHoverColor: '#FFFFFF',
      primaryButtonBg: '#BB4F09',
      primaryButtonHoverBg: '#913B04',
      primaryButtonColor: '#FFFFFF',
    },
    copper: '#BB4F09',
    darkCopper: '#913B04',
    darkGrey: '#6B6D70',
    greyMauve: '#CBC4C14D',
    gunMetal: '#2B2B2A',
    blueSteel: '#324970',
    gold: '#AB7E55',
    readOnlyBorderColor: '#CACACA',
    table: {
      header: '#2B2B2A', // table header text color
      item: '#2B2B2A', // table body text color
      link: '#913B04', // color of linked table body text
    },
    primary: {
      // mostly drawer footer button color
      buttonHoverBg: '#964A1E',
      buttonBg: '#BB4F09',
    },
    sso: {
      darkGrey: '#2B2B33',
      lightGrey: '#E3E5E7',
    },
    containedButton: {
      // custom contained variant button colors
      buttonHoverBg: '#964A1E',
      buttonBg: '#AE5623',
    },
    topNavBar: {
      bg: '#2B2B2A', // navbar background color
      itemHover: '#913B04', // navbar item hover background color
      itemClicked: '#BB4F09', // navbar item clicked hover background color
    },
    chip: {
      bg: '#324970',
      itemHover: '#324970',
      itemClicked: '#324970',
    },
    environmentChipColor: {
      development: '#27AE60',
      qa: '#2F80ED',
      uat: '#C51162',
    },
    submissionStatusColor: {
      total_count: '#BB4F09', // default
      draft: '#324970',
      referred: '#913B04',
      quoted: '#AB7E55',
      declined: '#D82313',
      expired: '#8C8E90',
      accepted: '#00752C',
      discarded: '#6B6D70',
      declined_by_uw: '#D82313',
      in_moratorium: '#8C8E90',
      non_renewal: '#D82313',
    },
    endorsementDetailStatusColor: {
      application: '#324970',
      quoted: '#913B04',
      accepted: '#AB7E55',
      issued: '#BB4F09',
      invalidated: '#BB4F09',
      discarded: '#D82313',
      referred: '#913B04',
      declined: '#D82313',
      declined_by_uw: '#D82313',
    },
    policiesTransactionStatusColor: {
      draft: '#324970',
      active: '#00752C',
      application: '#324970',
      issued: '#BB4F09',
      rescinded: '#6B6D70',
      reinstated: '#AB7E55',
      discarded: '#6B6D70',
      invalidated: '#913B04',
      accepted: '#00752C',
      inactive: '#4D9F6C',
      cancelled: '#6B6D70',
      pending_reinstate: '#913B04',
      quoted: '#AB7E55',
      referred: '#913B04',
      declined_by_uw: '#D82313',
      declined: '#D82313',
      in_cancellation: '#D82313',
      expired: '#8C8E90',
    },
    policiesStatusColor: {
      active: '#00752C',
      in_cancellation: '#D82313',
      cancelled: '#6B6D70',
      expired: '#8C8E90',
      inactive: '#4D9F6C',
      non_issued: '#324970',
      quoted: '#AB7E55',
      darkCopper: '#913B04',
    },

    invoiceStatusColor: {
      pastDue: '#D82313',
      currentDue: '#913B04',
      outstanding: '#913B04',
      paid: '#00752C',
      zeroDue: '#6B6D70',
      invalidated: '#D82313',
      chargeback: '#D82313',
      ach_return: '#D82313',
      waived: '#BB4F09',
      draft: '#324970',
      carriedForward: '#6B6D70',
      writtenOff: '#000000',
    },
    IndividualOwnersOfficersStatusColor: {
      defaultColor: '#BB4F09', // default
      excludedColor: '#D82313',
      coveredColor: '#00752C',
    },
    pageHeader: {
      title: '#2B2B2A', // page header title color
      subTitle: '#000000', // page header subtitle color
    },
    menu: {
      itemText: '#000000', // menu component item text color
      itemBg: '#ffffff', // menu component item background color
      itemHoverText: '#ffffff', // menu component item hover text color
      itemHoverBg: '#BB4F09', // menu component item hover background color
    },
    actionBar: {
      text: '#000000', // action bar button text color
    },
    drawer: {
      header: '#2B2B2A', // drawer header text color
      tableBorderColor: '#CBC4C1',
    },
    radStepper: {
      bgFilled: '#2C397F', // rad stepper filled background color
      bgEmpty: '#E0E0E0', // rad stepper empty background color
      labelActive: '#2C397F', // rad stepper active label text color
    },
    buttonGroup: {
      activeButton: '#2C397F',
      hoverButton: '#3F51B5',
    },
    verticalTab: {
      selectedBg: 'rgba(121, 134, 203, 0.1)',
      selectedTabIndicator: '#3F51B5',
      selectedTextColor: '#2C397F',
      tabIndicator: '#E0E0E0',
      tabTextColor: '#02072F',
    },
    black: '#000',
    black100: 'rgba(0, 0, 0, 0.54)',
    white50: '#FFFFFF',
    white100: '#E5E5E5',
    black750: '#F5F5F5',
    black800: 'rgba(0, 0, 0, 0.87)',
    black900: '#02072F',
    black1050: 'rgba(0, 0, 0, 0.04)',
    blue50: '#414563',
    blue100: '#3F51B5',
    blue150: 'rgba(63, 81, 181, 0.15)',
    blue200: '#2F80ED',
    blue250: '#2D9CDB',
    blue300: '#2196F3',
    blue400: 'rgba(63, 81, 181, 0.45)',
    blue450: 'rgba(33,150,243,0.06)',
    blue500: '#7986CB1A',
    blue550: '#7986CB',
    blue600: '#303F9F',
    grey50: '#D6D8DD',
    grey100: '#BDBDBD',
    grey150: '#C4C4C4',
    grey200: '#828282',
    grey250: '#666666',
    grey300: '#4F4F4F',
    grey350: '#424242',
    grey400: '#E0E0E0',
    grey600: '#757575',
    grey700: '#616161',
    grey800: '#333333',
    grey850: '#F5F5F5',
    grey950: '#E0E0E4',
    grey960: '#ebebeb',
    grey1000: '#EEEEEE',
    grey1100: '#9E9E9E',
    grey1150: '#F7F6F6',
    grey1200: 'rgba(0, 0, 0, 0.12)',
    green150: '#27AE60',
    green200: '#4CAF50',
    green250: '#00E676',
    green300: '#10564C',
    orange50: '#E98C00',
    orange100: '#ED6C02',
    orange150: '#C15109',
    red50: '#EB5757',
    red100: '#F44336',
    red150: '#FF1744',
    alert: '#C9170B',
    warning: '#BB4F09',
    info: '#2196F3',
    pink50: '#F88078',
    placeholderColor: '#AAA',
  },
  palette: {
    background: {
      default: '#fff',
    },
    mode: 'light',
    primary: {
      main: '#000000',
      light: '#414563',
    },
    secondary: {
      dark: '#913B04',
      main: '#BB4F09',
    },
    error: {
      main: '#C9170B',
    },
    dialogCancel: {
      main: '#424242',
    },
    dialogAlert: {
      main: '#C9170B',
      light: '#C9170B',
      dark: '#C9170B',
      contrastText: '#C9170B',
    },
    dialogInfo: {
      main: '#BB4F09',
    },
  },
  typography: {
    fontFamily: 'Nexa,sans-serif',
    h1: { fontSize: '20px', fontWeight: 500, lineHeight: '28px' },
    h6: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '26px',
    },
    subtitle2: {
      fontWeight: 400,
      letterSpacing: '0.2px',
      lineHeight: '18px',
      fontSize: 12,
    },
    body2: {
      lineHeight: '22px',
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 14,
      lineHeight: '20px',
    },
    body3: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
    },
    body4: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: theme.customColors.activeField,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: '0.2px',
          color: theme.customColors.gunMetal,
          '&.MuiTypography-subtitle2': {
            color: theme.customColors.grey200,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          '&.Mui-focused': {
            '&.Mui-focused fieldset': {
              borderColor: theme.customColors.activeField,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: theme.customColors.dropdown.selected,
            color: theme.customColors.white50,
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: theme.customColors.dropdown.hover,
            color: theme.customColors.white50,
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            backgroundColor: theme.customColors.dropdown.hover,
            color: theme.customColors.white50,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.customColors.dropdown.hover,
            color: theme.customColors.white50,
          },
          '&.Mui-selected': {
            backgroundColor: theme.customColors.dropdown.selected,
            color: theme.customColors.white50,
            '&:hover': {
              backgroundColor: theme.customColors.dropdown.hover,
              color: theme.customColors.white50,
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: theme.customColors.dropdown.hover,
            color: theme.customColors.white50,
          },
          '&.Mui-focusVisible.Mui-selected': {
            backgroundColor: theme.customColors.dropdown.selected,
            color: theme.customColors.white50,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          lineHeight: 14,
          '& label': {
            fontSize: 14,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& label': {
            fontSize: 14,
          },
          '& .MuiInputBase-input:not(textarea) ': {
            overflow: 'hidden',
          },
          // Hide autofill safari icon in input field
          '& ::-webkit-contacts-auto-fill-button': {
            visibility: 'hidden',
            display: 'none!important',
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputAdornment-root button > .MuiSvgIcon-root': { fontSize: 18 },
          '& .MuiInputAdornment-root > button:hover': {
            backgroundColor: theme.customColors.black750,
            width: '28px',
            height: '28px',
            borderRadius: '50%',
          },
          '& .MuiInputAdornment-root > button': {
            width: '28px',
            height: '28px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.customColors.table.item,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: 0,
          padding: '8px 22px',
          fontSize: 15,
          '& > .MuiButton-startIcon svg': {
            fontSize: 24,
          },
        },
        contained: {
          backgroundColor: theme.customColors.containedButton.buttonBg,
          '&:hover': { backgroundColor: theme.customColors.containedButton.buttonHoverBg },
          fontSize: 16,
          whiteSpace: 'nowrap',
        },
        outlined: {
          borderColor: theme.customColors.blue50,
          color: theme.customColors.blue50,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: 0,
          '& .MuiButtonGroup-grouped:not(:last-of-type)': {
            borderColor: '#fff',
          },
        },
        contained: {
          backgroundColor: theme.customColors.containedButton.buttonBg,
          '&:hover': { backgroundColor: theme.customColors.containedButton.buttonHoverBg },
          fontSize: 16,
          whiteSpace: 'nowrap',
        },
        outlined: {
          borderColor: theme.customColors.blue50,
          color: theme.customColors.blue50,
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: `${theme.appBarZIndex + 1} !important`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& > tr > th': {
            fontWeight: 900,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: theme.customColors.activeCheckbox,
          },
        },
      },
    },
  },
});

export default theme;
