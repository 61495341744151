import { Box, Button, Stack, Typography } from '@mui/material';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { threePolicyGroupNames } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import { DrivingIncidentsDrawerFieldParser } from 'components/QuotePolicyDetailEndorsement/FieldParsers/DrivingIncidentsDrawerFieldParser';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { drawerFooterPrimaryButtonStyle } from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface DrivingIncidentDetailProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (state: boolean) => void;
}

const DrivingIncidentsDetailDrawer: FC<DrivingIncidentDetailProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const INCIDENT = url.drivingIncident;
  const {
    fields: fieldConfig,
    fields: {
      policy: { loading: fieldConfigLoading },
    },
    loading: policyDetailLoading,
    loaded: policyDetailLoaded,
    groups,
  } = usePolicyDetail();
  const [state, setState] = useState<any>({});

  const drivers = useMemo(() => {
    const r =
      groups?.pol_drivers?.data?.map((driver) => ({
        ...driver,
        name: `${driver.pol_drivers_first || ''} ${driver.pol_drivers_last || ''}`,
        id: driver.locator,
      })) ?? [];

    return r as any[];
  }, [groups]);

  const incidents: any[] = groups?.pol_incidents?.data ?? [];

  const incident = useMemo(
    () => incidents.find((inc) => inc.locator === INCIDENT) ?? {},
    [incidents, INCIDENT],
  );

  const handleQuery = () => {
    setIsDrawerOpen(false);
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['drivingIncident'],
      }),
    });
  };
  // Close drawer if related id not found
  useEffect(() => {
    if (policyDetailLoaded && isEmpty(incident)) {
      displayToastMessage('ERROR', t('Incident not found.'));
      handleQuery();
    }
  }, [incident, policyDetailLoaded]);

  const fields = useMemo(() => {
    let tmpFields =
      (fieldConfig?.policy?.data as ProductWorkFlow[])
        ?.find((con) => con.code === 'policy-details')
        ?.fields?.find((x) => x.code === threePolicyGroupNames.INCIDENTS)?.nested_fields ?? [];
    const choices = drivers.map((d) => ({ name: d.name, id: d.id }));

    tmpFields = tmpFields.map((f) => {
      if (f.code?.includes('driver')) {
        return { ...f, choices };
      }
      return f;
    });

    return tmpFields as DynamicField[];
  }, [fieldConfig, drivers]);

  const formik = useFormik({
    initialValues: {
      ...incident,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    const pol_incidents_type = incident.pol_incidents_type?.split(';');
    const driverLocator = incident.pol_incidents_driver?.split(';')?.[0] ?? '';
    const pol_incidents_driver = drivers.find((d) => d.pol_drivers_id === driverLocator)?.id ?? '';
    const newState = {
      ...incident,
      pol_incidents_type,
      pol_incidents_driver,
    };
    setState((prevState) => ({ ...prevState, ...newState }));
    formik.setValues({ ...newState });
  }, [incident, drivers]);

  const showLoader = fieldConfigLoading || policyDetailLoading || isEmpty(formik.values);

  return (
    <DrawerComponent
      isContentScrollable
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      width="600px"
      onClose={handleQuery}
      header={
        <Typography
          sx={{
            '&.MuiTypography-root': {
              fontSize: 20,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            },
            fontWeight: '500',
            letterSpacing: (theme) => theme.typography.subtitle2.letterSpacing,
            color: (theme) => theme.customColors.drawer.header,
          }}
        >
          {t('Driving Incident Details')}
        </Typography>
      }
      content={
        <Stack sx={{ mb: 6, pt: 1 }}>
          <DrivingIncidentsDrawerFieldParser
            formik={formik}
            state={state}
            fields={fields}
            showLoader={showLoader}
            setState={setState}
            isReadOnly
            splitSize={3}
            columnSpacing={0}
            rowSpacing={2}
          />
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Close')}
          </Button>
        </Box>
      }
    />
  );
};

export default DrivingIncidentsDetailDrawer;
