import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import AdditionalInterestsDetailDrawer from './Detail';

const AdditionalInterestsDrawer: FC = () => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isAdditionalInterestsDrawerOpen =
    url?.tab === 'additional_interests' && !isEmpty(url?.additional_interest);

  return isAdditionalInterestsDrawerOpen ? (
    <AdditionalInterestsDetailDrawer isDrawerOpen={isAdditionalInterestsDrawerOpen ?? false} />
  ) : (
    <></>
  );
};
export default AdditionalInterestsDrawer;
