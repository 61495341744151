import { isEmpty, isEqual } from 'lodash-es';
import { FC, useEffect } from 'react';
import * as yup from 'yup';

// Styles
import {
  Box,
  FormControl,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Cancel from 'assets/images/CancelIcon.svg';
import Save from 'assets/images/SaveIcon.svg';

// Hooks
import { useFormik } from 'formik';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import useLoader from 'hooks/useLoader';
import useRouterPrompt from 'hooks/useRouterPrompt';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Components
import RouterPrompt from 'components/RouterPrompt';
import SettingsHeader from 'components/Settings/Header';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';

// Helpers - Constants - Services - Data
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { UserUpdate } from 'api/models/User/userUpdate.model';
import { userRoles } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import Head from 'components/Head';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { checkIfValueHasLetters, handleBackendErrorsWithFormik } from 'helpers/Utils';

const EditProfile: FC = () => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const { updateCurrentUser, data: user, loading } = useUser();
  const { setLoading } = useLoader();
  const { when, setWhen } = useRouterPrompt();

  const validationSchema = yup.object({
    first_name: yup
      .string()
      .max(150, t(`First name can't be more than {{charLimit}} characters.`, { charLimit: 150 }))
      .required(t('This field may not be blank.')),
    last_name: yup
      .string()
      .max(150, t(`Last name can't be more than {{charLimit}} characters.`, { charLimit: 150 }))
      .required(t('This field may not be blank.')),
    // email: yup.string().email(t('Enter a valid email address.')).required(t('This field may not be blank.')),
  });

  const formik = useFormik<UserUpdate>({
    initialValues: {
      first_name: '',
      last_name: '',
      // email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setWhen(false);
          setLoading(true);
          await updateCurrentUser({
            ...values,
          });
          HISTORY.push('/settings');
          displayToastMessage('SUCCESS', t('Changes have been saved.'));
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      // email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
    });
  }, [user]);

  useEffect(() => {
    if (!isEmpty(formik.values)) {
      const userFields = {
        // email: user?.email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        // role: user?.role,
      };

      setWhen(!isEqual({ ...userFields, id: user?.id }, { ...formik.values, id: user?.id }));
    }
  }, [formik.values]);

  const handleCancel = () => {
    HISTORY.push('/settings');
  };

  const renderFormItems = ({ label, id, isReadonly }) => {
    if (isReadonly) {
      return loading ? (
        <Skeleton animation="wave" width="100%" height={32} />
      ) : (
        <>
          <Typography
            sx={{
              color: (theme) => theme.customColors.grey800,
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '16px',
              minHeight: 16,
              mr: '6px',
              minWidth: '142px',
              maxWidth: '142px',
            }}
          >
            {label}
          </Typography>
          {id === 'role' || id === 'organization' ? (
            <Typography sx={{ paddingLeft: '8px !important' }}>
              {user?.[id]?.name ?? '-'}
            </Typography>
          ) : (
            <Typography sx={{ paddingLeft: '8px !important' }}>{user?.[id] ?? '-'}</Typography>
          )}
        </>
      );
    }

    return loading ? (
      <Skeleton animation="wave" width="100%" height={32} />
    ) : (
      <>
        <Typography
          sx={{
            color: (theme) => theme.customColors.grey800,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '16px',
            minHeight: 16,
            mr: '6px',
            minWidth: '142px',
            maxWidth: '142px',
          }}
        >
          {label}
        </Typography>

        {id === 'role' ? (
          <FormControl
            size="small"
            variant="outlined"
            sx={{
              flex: 1,
              '& input': {
                minHeight: 32,
                boxSizing: 'border-box',
                padding: '0px !important',
              },
            }}
            error={formik.touched.role && Boolean(formik.errors.role)}
          >
            <CustomNativeSelect
              name={formik.values.role?.name}
              error={formik.touched.role && Boolean(formik.errors.role)}
              id="role"
              value={`${formik.values.role?.code}`}
              IconComponent={KeyboardArrowDownRounded}
              sx={{
                '& > .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: 32,
                  padding: '8px 40px 8px 8px !important',
                },
                minHeight: 32,
              }}
              onChange={(e: SelectChangeEvent<unknown>) => {
                formik.setFieldValue('role', { code: e.target.value, name: e.target.name });
              }}
            >
              {Object.values(userRoles).map((role) => (
                <option key={role.code} value={role.code}>
                  {`${role.name}`}
                </option>
              ))}
            </CustomNativeSelect>
          </FormControl>
        ) : (
          <FormControl
            size="small"
            variant="standard"
            sx={{
              flex: 1,
              '& input': { minHeight: 32, boxSizing: 'border-box', padding: '8px' },
            }}
          >
            <TextField
              id={id}
              required
              size="small"
              name={id}
              value={formik.values[id] ?? ''}
              inputProps={{
                'data-test': id,
              }}
              onChange={(event) => {
                const idsToCheck = ['first_name', 'last_name'];
                if (idsToCheck.includes(id) && !checkIfValueHasLetters(event.target.value)) return;

                formik.handleChange(event);
              }}
              error={formik.touched[id] && Boolean(formik.errors[id])}
              helperText={formik.touched[id] && formik.errors[id]}
              sx={{ minHeight: 32 }}
            />
          </FormControl>
        )}
      </>
    );
  };

  return (
    <>
      <Head title={t('Edit Profile')} />
      <RouterPrompt when={when} />

      <TopActionBar>
        <TopActionBarButton
          disableRipple
          type="submit"
          form="editForm"
          data-test="save-changes"
          startIcon={
            <Box component="img" src={Save} alt="save" sx={{ width: '24px', height: '24px' }} />
          }
        >
          {t('Save Changes')}
        </TopActionBarButton>
        <TopActionBarButton
          disableRipple
          onClick={handleCancel}
          startIcon={
            <Box component="img" src={Cancel} alt="cancel" sx={{ width: '24px', height: '24px' }} />
          }
        >
          {t('Cancel')}
        </TopActionBarButton>
      </TopActionBar>

      <SettingsHeader currentTab={0} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ScrollToFormikError formik={formik} />
        <form id="editForm" onSubmit={formik.handleSubmit} noValidate>
          <Stack
            spacing={{ xs: 2 }}
            sx={{
              pl: 3,
              mt: 3,
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 500,
                minHeight: '32px',
                marginBottom: '8px',
              }}
            >
              {t('Profile')}
            </Typography>
            <Stack
              rowGap={1}
              sx={{
                width: 1,
                flexDirection: {
                  xs: 'column',
                  sm: 'column',
                },
                marginTop: '0px !important',
                maxWidth: 480,
              }}
            >
              <Stack
                sx={{
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderColor: (theme) => theme.customColors.grey400,
                  flexDirection: 'row',
                  maxWidth: '480px',
                  minHeight: 44,
                  py: '6px',
                }}
              >
                {renderFormItems({ label: t('First Name'), id: 'first_name', isReadonly: false })}
              </Stack>

              <Stack
                sx={{
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderColor: (theme) => theme.customColors.grey400,
                  flexDirection: 'row',
                  maxWidth: '480px',
                  minHeight: 44,
                  py: '6px',
                }}
              >
                {renderFormItems({ label: t('Last Name'), id: 'last_name', isReadonly: false })}
              </Stack>

              <Stack
                sx={{
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderColor: (theme) => theme.customColors.grey400,
                  flexDirection: 'row',
                  maxWidth: '480px',
                  minHeight: 44,
                  py: '6px',
                }}
              >
                {renderFormItems({
                  label: t('System Role'),
                  id: 'role',
                  isReadonly: true,
                })}
              </Stack>

              <Stack
                sx={{
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderColor: (theme) => theme.customColors.grey400,
                  flexDirection: 'row',
                  maxWidth: '480px',
                  minHeight: 44,
                  py: '6px',
                }}
              >
                {renderFormItems({
                  label: t('Organization'),
                  id: 'organization',
                  isReadonly: true,
                })}
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default EditProfile;
