import { userRoles } from 'common/constants';
import useUser from 'hooks/useUser';
import qs from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LiabilityExposureScheduleModDetailDrawer from './Detail/LiabilityExposureScheduleModDetailDrawer';

export interface ILiabilityExposureScheduleModDrawer {
  isEdit: boolean;
}

const LiabilityExposureScheduleModDrawer: FC<ILiabilityExposureScheduleModDrawer> = ({
  isEdit,
}) => {
  const LOCATION = useLocation();
  const [isLiabilityScheduleModDrawerOpen, setIsLiabilityScheduleModDrawerOpen] = useState(false);
  const url = qs.parse(LOCATION.search);
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

  useEffect(() => {
    if (
      url?.tab === 'liability_exposures' &&
      url?.perilId &&
      url?.exposureId &&
      url?.schedule_mod
    ) {
      setIsLiabilityScheduleModDrawerOpen(true);
    } else if (url?.tab === 'liability_exposures') {
      setIsLiabilityScheduleModDrawerOpen(false);
    }

    if (!isUnderwriter) {
      setIsLiabilityScheduleModDrawerOpen(false);
    }
  }, [LOCATION, isUnderwriter]);

  return isLiabilityScheduleModDrawerOpen ? (
    <LiabilityExposureScheduleModDetailDrawer
      isDrawerOpen={isLiabilityScheduleModDrawerOpen}
      setIsDrawerOpen={setIsLiabilityScheduleModDrawerOpen}
      isEdit={isEdit}
    />
  ) : (
    <></>
  );
};
export default LiabilityExposureScheduleModDrawer;
