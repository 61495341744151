import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import OwnersMembersDetailDrawer from './Detail';
import OwnersMembersEditDrawer from './Edit';

export interface IOwnersMembersDrawer {
  isEdit: boolean;
  position: string;
}

const OwnersMembersDrawer: FC<IOwnersMembersDrawer> = ({ isEdit, position }) => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isOwnersMembersDrawerOpen =
    url?.tab === 'owners_members' && (!isEmpty(url?.owner_member) || !isEmpty(url?.add));

  return isOwnersMembersDrawerOpen ? (
    isEdit ? (
      <OwnersMembersEditDrawer
        isDrawerOpen={isOwnersMembersDrawerOpen ?? false}
        isAdd={!isEmpty(url?.add)}
        position={position}
      />
    ) : (
      <OwnersMembersDetailDrawer
        isDrawerOpen={isOwnersMembersDrawerOpen ?? false}
        position={position}
      />
    )
  ) : (
    <></>
  );
};
export default OwnersMembersDrawer;
