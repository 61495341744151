import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import DriverDetailDrawer from './Detail';

const DriverDrawer: FC = () => {
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isDriverDrawerOpen = url?.tab === 'drivers' && !isEmpty(url?.driver);

  return isDriverDrawerOpen ? (
    <DriverDetailDrawer isDrawerOpen={isDriverDrawerOpen ?? false} />
  ) : (
    <></>
  );
};
export default DriverDrawer;
